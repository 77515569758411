import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import Section from "./Section";
import AuthUser from "../../helpers/Authuser";
import Widgets from "./Widgets";
import RecentOrders from "./RecentOrders";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { SimpleDonut, SimpleDonut1 } from "../Charts/ApexCharts/PieCharts/PieCharts";
import LeadsView from "../Leads/LeadsView";
import TaskView from "../Task/TaskView";

const AdminDashboard = () => {
  const { http, user, token } = AuthUser();
  const { type } = useParams();
  document.title = "Admin Dashboard | CRM";
  const [rightColumn, setRightColumn] = useState(true);
  const toggleRightColumn = () => {
    setRightColumn(!rightColumn);
  };
  const currentYear = new Date().getFullYear();

  const [formData, setformData] = useState({
    start_date: `${currentYear}-01-01` + " 00:00:00",
    end_date: `${currentYear}-12-31` + " 23:59:59",
    leave_status: 0,
    leave_id: type,
    user_id: user != null ? (user.user.u_type === 1 ? 0 : user.user.uid) : "",
    customer_name: 0,
    lead_id: type ? type : 0,
    assigned_by:
      user != null ? (user.user.u_type === 1 ? 0 : user.user.uid) : "",
  });

  const [shiftedData, setshiftedData] = useState({
    leads: [],
    task: [],
  });
  const getshiftedData = async () => {
    http
      .post("/slt/details", formData)
      .then((response) => {
        if (response.data.leads.length > 0) {
          setshiftedData({
            leads: response.data.leads,
            task: response.data.task,
          });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const [LeadData, SetLeadData] = useState([]);
  const [TaskData, SetTaskData] = useState([]);
  const [LeaveData, SetLeaveData] = useState([]);

  const [tagsData, settagsData] = useState([]);
  const gettagsData = async () => {
    http
      .get("/tags/list")
      .then((response) => {
        if (response.data.length > 0) {
          settagsData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [assignedByData, setassignedByData] = useState([]);
  const getassignedByData = async () => {
    http
      .get("/franchisebusiness/list")
      .then((response) => {
        if (response.data.length > 0) {
          setassignedByData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [totalData, settotalData] = useState({
    taskCount: 0,
    taskStatusCount: [],
    leadCount: 0,
    leadStatusCount: [],
    customerCount: 0,
    attendanceCount: 0,
  });
  // console.log(totalData);

  const handleFilter = async () => {
    //dash board total counts and statuswise report
    http.post("/dashboard/total", formData).then((res) => {
      settotalData({
        taskCount: res.data.taskCount,
        leadStatusCount: res.data.leadStatusCounts,
        taskStatusCount: res.data.taskStatusCounts,
        leadCount: res.data.leadCount,
        customerCount: res.data.customerCount,
        attendanceCount: res.data.attendanceCount,
      });
    });
    http
      .post(`/leads/filter`, formData)
      .then(function (response) {
        if (response.data.length > 0) {
          SetLeadData(response.data);
        } else {
          SetLeadData([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    http
      .post(`/leave/list`, formData)
      .then(function (response) {
        if (response.data.length > 0) {
          SetLeaveData(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    http
      .post(`/task/filter`, formData)
      .then(function (response) {
        if (response.data.length > 0) {
          SetTaskData(response.data);
        } else {
          SetTaskData([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    gettagsData();
    getassignedByData();
    document.title = "Lead | CRM";
    // handleTaskFilter();
    // getAllData();
    getshiftedData();
    handleFilter();
  }, []);
  const [Data, SetData] = useState({});
  const navigate = useNavigate();
  const [filterData, setfilterData] = useState({
    start_date: new Date().toISOString().split("T")[0],
    end_date: new Date().toISOString().split("T")[0],
    remark: 0,
    user_id: user.user.u_type === 1 ? 0 : user.user.uid,
  });
  const [Attendance, SetAttendance] = useState([]);
  useEffect(() => {
    document.title = "User Dashboard CRM";
    http
      .post(`/attendence/filter`, filterData)
      .then(function (response) {
        if (response.data.length > 0) {
          SetAttendance(response.data);
          // console.log(response.data);
        } else {
          // SetAttendance([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const [counts, Setcounts] = useState(1);
  const handleCallback = (data, status) => {
    SetLeadData([]);
    if (status == 1) {
      Setcounts(counts + 1);

    } else {

      Setcounts(counts + 1);
    }
    handleFilter();
  };
  const [FindData, SetFind] = useState([]);
  const [viewModalaStates, setviewModalaStates] = useState(false);
  const ViewPage = (index) => {
    let FindArray = LeadData.filter((item, i) => item.lead_id == index);
    SetFind(FindArray[0]);
    setviewModalaStates(!viewModalaStates);
  };


  const [FindData1, SetFind1] = useState([]);
  const [viewModalaStates1, setviewModalaStates1] = useState(false);
  const ViewPage1 = (index) => {
    let FindArray = TaskData.filter((item, i) => item.task_id == index);
    SetFind1(FindArray[0]);
    setviewModalaStates1(!viewModalaStates1);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section rightClickBtn={toggleRightColumn} />
                <Row>

                  <Col lg={12}>
                    <Row>



                      <Col xl={4}>
                        <Card >
                          <CardHeader>
                            <h4 className="card-title mb-0">Leads</h4>
                          </CardHeader>
                          <CardBody>
                            <SimpleDonut additionalData={totalData.leadStatusCount} dataColors='["--vz-info","--vz-primary","--vz-warning", "--vz-success",  "--vz-danger"]' />
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                              <Link to="/leads-list/0/1" style={{ marginRight: '10px', height: '50px', linHeight: '50px', marginBottom: '-10px', marginTop: '-7px' }}>
                                <span style={{ color: 'var(--vz-info)', fontSize: '3.5em', verticalAlign: 'middle' }}>•</span>
                                <span style={{ fontWeight: 'bold', color: 'black' }}>
                                  New - {
                                    totalData.leadStatusCount
                                      .leadStatus1
                                  }
                                </span>
                              </Link>
                              <Link to="/leads-list/0/2" style={{ marginRight: '10px', height: '50px', linHeight: '50px', marginBottom: '-10px', marginTop: '-7px' }}>
                                <span style={{ fontWeight: 'bold', color: 'var(--vz-primary)', fontSize: '3.5em', verticalAlign: 'middle' }}>•</span> {/* Second bullet with color --vz-primary and larger size */}
                                <span style={{ fontWeight: 'bold', color: 'black' }}>Accept -  {
                                  totalData.leadStatusCount
                                    .leadStatus2
                                }</span>
                              </Link>
                              <Link to="/leads-list/0/3" style={{ marginRight: '10px', height: '50px', linHeight: '50px', marginBottom: '-10px', marginTop: '-7px' }}>
                                <span style={{ fontWeight: 'bold', color: 'var(--vz-warning)', fontSize: '3.5em', verticalAlign: 'middle' }}>•</span> {/* Third bullet with color --vz-warning and larger size */}
                                <span style={{ fontWeight: 'bold', color: 'black' }}>In-Progress -  {
                                  totalData.leadStatusCount
                                    .leadStatus3
                                }</span>
                              </Link>
                              <Link to="/leads-list/0/4" style={{ marginRight: '10px', height: '50px', linHeight: '50px', marginBottom: '-10px', marginTop: '-7px' }}>
                                <span style={{ fontWeight: 'bold', color: 'var(--vz-success)', fontSize: '3.5em', verticalAlign: 'middle' }}>•</span> {/* Fourth bullet with color --vz-success and larger size */}
                                <span style={{ fontWeight: 'bold', color: 'black' }}>Completed - {
                                  totalData.leadStatusCount
                                    .leadStatus4
                                }</span>
                              </Link>
                              <Link to="/leads-list/0/5" style={{ marginRight: '10px', height: '50px', linHeight: '50px', marginBottom: '-10px', marginTop: '-7px' }}>
                                <span style={{ fontWeight: 'bold', color: 'var(--vz-danger)', fontSize: '3.5em', verticalAlign: 'middle' }}>•</span> {/* Fifth bullet with color --vz-danger and larger size */}
                                <span style={{ fontWeight: 'bold', color: 'black' }}>Rejected - {
                                  totalData.leadStatusCount
                                    .leadStatus5
                                }</span>
                              </Link>
                            </div>

                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={4}>
                        <Card>
                          <CardHeader>
                            <h4 className="card-title mb-0">Task</h4>
                          </CardHeader>
                          <CardBody>
                            <SimpleDonut1 additionalData={totalData.taskStatusCount} dataColors='["--vz-info","--vz-primary","--vz-warning", "--vz-success",  "--vz-danger"]' />
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                              <Link to="/task-list/0/1" style={{ marginRight: '10px', height: '50px', linHeight: '50px', marginBottom: '-10px', marginTop: '-7px' }}>
                                <span style={{ color: 'var(--vz-info)', fontSize: '3.5em', verticalAlign: 'middle' }}>•</span>
                                <span style={{ fontWeight: 'bold', color: 'black' }}>
                                  New - {
                                    totalData.taskStatusCount
                                      .taskStatus1
                                  }
                                </span>
                              </Link>
                              <Link to="/task-list/0/2" style={{ marginRight: '10px', height: '50px', linHeight: '50px', marginBottom: '-10px', marginTop: '-7px' }}>
                                <span style={{ fontWeight: 'bold', color: 'var(--vz-primary)', fontSize: '3.5em', verticalAlign: 'middle' }}>•</span> {/* Second bullet with color --vz-primary and larger size */}
                                <span style={{ fontWeight: 'bold', color: 'black' }}>Accept - {
                                  totalData.taskStatusCount
                                    .taskStatus2
                                }</span>
                              </Link>
                              <Link to="/task-list/0/3" style={{ marginRight: '10px', height: '50px', linHeight: '50px', marginBottom: '-10px', marginTop: '-7px' }}>
                                <span style={{ fontWeight: 'bold', color: 'var(--vz-warning)', fontSize: '3.5em', verticalAlign: 'middle' }}>•</span> {/* Third bullet with color --vz-warning and larger size */}
                                <span style={{ fontWeight: 'bold', color: 'black' }}>In-Progress - {
                                  totalData.taskStatusCount
                                    .taskStatus3
                                }</span>
                              </Link>
                              <Link to="/task-list/0/4" style={{ marginRight: '10px', height: '50px', linHeight: '50px', marginBottom: '-10px', marginTop: '-7px' }}>
                                <span style={{ fontWeight: 'bold', color: 'var(--vz-success)', fontSize: '3.5em', verticalAlign: 'middle' }}>•</span> {/* Fourth bullet with color --vz-success and larger size */}
                                <span style={{ fontWeight: 'bold', color: 'black' }}>Completed - {
                                  totalData.taskStatusCount
                                    .taskStatus4
                                }</span>
                              </Link>
                              <Link to="/task-list/0/5" style={{ marginRight: '10px', height: '50px', linHeight: '50px', marginBottom: '-10px', marginTop: '-7px' }}>
                                <span style={{ fontWeight: 'bold', color: 'var(--vz-danger)', fontSize: '3.5em', verticalAlign: 'middle' }}>•</span> {/* Fifth bullet with color --vz-danger and larger size */}
                                <span style={{ fontWeight: 'bold', color: 'black' }}>Rejected - {
                                  totalData.taskStatusCount
                                    .taskStatus5
                                }</span>
                              </Link>
                            </div>

                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={4}>

                        <div className="widget-container col-md-12">
                          <div className="card dashboard-icon-widget clock-in-out-card">
                            <div className="card-body">
                              {Attendance && Attendance[0] && Attendance[0].in_time !== null && Attendance[0].out_time === null ? (
                                <>
                                  <div className="widget-icon bg-coral">
                                    <FeatherIcon size={60} color="text-danger" icon="clock" />
                                    <Link
                                      to="/attendence"
                                      className="btn btn-default text-danger spinning-btn"
                                      title="Clock In"
                                      data-inline-loader="1"
                                      data-closest-target="#js-clock-in-out"
                                      data-act="ajax-request"
                                      data-action-url="https://rise.fairsketch.com/attendance/log_time"
                                      style={{ marginLeft: '80px' }}
                                    >
                                      <FeatherIcon size={24} color="text-danger" icon="log-out" />
                                      Checked Out
                                    </Link>
                                  </div>
                                  <div className="widget-details">
                                    <div className="mt5 bg-transparent-white">You are currently Checked In   </div>
                                    <div className="mt5 bg-transparent-white">In Time : {Attendance && Attendance[0] && Attendance[0] && Attendance[0].in_time
                                    }  </div>
                                  </div>
                                </>
                              ) : Attendance && Attendance[0] && Attendance[0].in_time !== null && Attendance[0].out_time !== null ? (
                                <>
                                  <div className="widget-icon bg-coral">
                                    <FeatherIcon size={60} color="text-info" icon="clock" />
                                    {/* <Link
                                      to="/attendence"
                                      className="btn btn-default text-info spinning-btn"
                                      title="Clock In"
                                      data-inline-loader="1"
                                      data-closest-target="#js-clock-in-out"
                                      data-act="ajax-request"
                                      data-action-url="https://rise.fairsketch.com/attendance/log_time"
                                      style={{ marginLeft: '80px' }}
                                    >
                                      <FeatherIcon size={24} color="text-info" icon="log-out" />
                                      Checked In
                                    </Link> */}
                                  </div>
                                  <div className="widget-details">
                                    <div className="mt5 bg-transparent-white">You are currently Checked Out</div>
                                    <div className="mt5 bg-transparent-white">In Time : {Attendance && Attendance[0] && Attendance[0].in_time
                                    }  </div>
                                    <div className="mt5 bg-transparent-white">Out Time : {Attendance && Attendance[0] && Attendance[0].out_time
                                    }  </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="widget-icon bg-coral">
                                    <FeatherIcon size={60} color="text-info" icon="clock" />
                                    <Link
                                      to="/attendence"
                                      className="btn btn-default text-info spinning-btn"
                                      title="Clock In"
                                      data-inline-loader="1"
                                      data-closest-target="#js-clock-in-out"
                                      data-act="ajax-request"
                                      data-action-url="https://rise.fairsketch.com/attendance/log_time"
                                      style={{ marginLeft: '80px' }}
                                    >
                                      <FeatherIcon size={24} color="text-info" icon="log-out" />
                                      Checked In
                                    </Link>
                                  </div>
                                  <div className="widget-details">
                                    <div className="mt5 bg-transparent-white text-danger">You are currently Not Checked In</div>
                                  </div></>
                              )}

                            </div>
                          </div>
                        </div>

                      </Col>


                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={8}>
                    <Card lg={12}>
                      <CardHeader className="card-header border-0">
                        <Row className="align-items-center gy-3">
                          <div className="col-sm">
                            <h5 className="card-title mb-0">Leads </h5>
                          </div>
                          <div className="col-sm-auto">
                            <div className="d-flex gap-1 flex-wrap">
                              <Link to="/leads-list" className="btn btn-soft-info btn-sm shadow-none">View All</Link>
                            </div>
                          </div>
                        </Row>
                      </CardHeader>
                      <CardBody className="pt-0" style={{ height: '400px', overflowY: 'auto' }}>
                        <div className="table-responsive">
                          <table
                            role="table"
                            className="text-center bg-white table table-responsive"
                            style={{ width: '100%' }} // Set table width to 100%
                          >
                            <thead className=" table-light text-muted text-uppercase">
                              <tr>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  #
                                </th>
                                <th>DATE & TIME</th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  Customer
                                </th>
                                <th>Phone</th>
                                <th>City </th>
                                <th>Product </th>
                                <th>Value</th>
                                <th>Tags</th>
                                <th>Assigned</th>
                                <th>Status</th>
                                <th>Source</th>
                              </tr>
                            </thead>
                            <tbody>
                              {LeadData.length > 0
                                ? LeadData.slice(0, 10).map((item, index) => (
                                  <tr key={index} onClick={() => ViewPage(item.lead_id)}>
                                    <td>

                                      {index + 1}

                                    </td>
                                    <td>
                                      {new Date(item.today_date).toLocaleDateString("en-GB")}<br />
                                      {new Date(
                                        item.today_date
                                      ).toLocaleTimeString()}
                                    </td>
                                    <td>{item.customer_name}</td>
                                    <td>{item.customer_whatsapp_no}</td>
                                    <td>{item.city_name}</td>
                                    <td>{item.product_name}</td>
                                    <td>{item.value}</td>
                                    <td>
                                      {tagsData != null && item.tags
                                        ? tagsData.map((e) =>
                                          JSON.parse(item.tags).includes(
                                            e.tag_id
                                          ) ? (
                                            <div
                                              key={e.tag_id}
                                              className="p-1"
                                            >
                                              <button>{e.tag_name}</button>
                                            </div>
                                          ) : null
                                        )
                                        : "Loading..."}
                                    </td>
                                    <td>
                                      {assignedByData != null &&
                                        item.assigned_by
                                        ? assignedByData.map((e) =>
                                          JSON.parse(
                                            item.assigned_by
                                          ).includes(e.uid) ? (
                                            <div
                                              key={e.uid}
                                              className="p-1"
                                            >
                                              <button className="bg-primary fw-bold text-white border border-primary">
                                                {e.name}
                                              </button>
                                            </div>
                                          ) : null
                                        )
                                        : "Loading..."}
                                    </td>
                                    <td>
                                      <div>

                                        <span
                                          className={`badge bg-info-subtle text-${item.lead_status_id === 1
                                            ? "info"
                                            : item.lead_status_id === 2
                                              ? "primary"
                                              : item.lead_status_id === 3
                                                ? "warning"
                                                : item.lead_status_id === 4
                                                  ? "success"
                                                  : item.lead_status_id === 5
                                                    ? "danger"
                                                    : ""
                                            } text-uppercase`}
                                        >
                                          {item.lead_status_name}
                                        </span>




                                      </div>
                                    </td>
                                    <td>{item.source_name}</td>
                                  </tr>
                                ))
                                : ""}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <RecentOrders />

                </Row>
                <Row>
                  <Col lg={8}>
                    <Card lg={12}>
                      <CardHeader className="card-header border-0">
                        <Row className="align-items-center gy-3">
                          <div className="col-sm">
                            <h5 className="card-title mb-0">Tasks </h5>
                          </div>
                          <div className="col-sm-auto">
                            <div className="d-flex gap-1 flex-wrap">
                              <Link to="/task-list" className="btn btn-soft-info btn-sm shadow-none">View All</Link>

                            </div>
                          </div>
                        </Row>
                      </CardHeader>
                      <CardBody className="pt-0" style={{ height: '400px', overflowY: 'auto' }}>
                        <div className="table-responsive">
                          <table
                            role="table"
                            className="text-center bg-white table"
                          >
                            <thead className="table-light text-muted text-uppercase">
                              <tr>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  #
                                </th>
                                <th

                                >
                                  DATE & TIME
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  Customer Name
                                </th>
                                <th>Status</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Assigned to</th>
                                <th>Tags</th>
                                <th>Priority</th>
                              </tr>
                            </thead>
                            <tbody>
                              {TaskData.slice(0, 10).map((item, index) => (
                                <tr key={index} onClick={() => ViewPage1(item.task_id)}>
                                  <td>

                                    {index + 1}

                                  </td>
                                  <td>
                                    {new Date(item.today_date).toLocaleDateString("en-GB")}<br />
                                    {new Date(
                                      item.today_date
                                    ).toLocaleTimeString()}
                                  </td>
                                  <td>{item.customer_name}</td>
                                  <td>{item.lead_status_name}</td>
                                  <td>
                                    {new Date(
                                      item.minimum_due_date
                                    ).toLocaleDateString("en-GB")}
                                  </td>
                                  <td>
                                    {new Date(
                                      item.maximum_due_date
                                    ).toLocaleDateString("en-GB")}
                                  </td>
                                  <td>
                                    {assignedByData != null && item.assigned_by
                                      ? assignedByData.map((e) =>
                                        JSON.parse(item.assigned_by).includes(
                                          e.uid
                                        ) ? (
                                          <div key={e.uid} className="p-1">
                                            <button className="bg-primary fw-bold text-white border border-primary">
                                              {e.name}
                                            </button>
                                          </div>
                                        ) : null
                                      )
                                      : "Loading..."}
                                  </td>
                                  <td>
                                    {tagsData != null && item.tags
                                      ? tagsData.map((e) =>
                                        JSON.parse(item.tags).includes(
                                          e.tag_id
                                        ) ? (
                                          <div key={e.tag_id} className="p-1">
                                            <button>{e.tag_name}</button>
                                          </div>
                                        ) : null
                                      )
                                      : "Loading..."}
                                  </td>
                                  <td>
                                    {item.priority === 1
                                      ? "Low"
                                      : item.priority === 2
                                        ? "Medium"
                                        : item.priority === 3
                                          ? "High"
                                          : item.priority === 4
                                            ? "Urgent"
                                            : ""}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card lg={12} >
                      <CardHeader className="card-header border-0">
                        <Row className="align-items-center gy-3">
                          <div className="col-sm">
                            <h5 className="card-title mb-0">Shift Leads </h5>
                          </div>
                          {/* <div className="col-sm-auto">
                            <div className="d-flex gap-1 flex-wrap">
                              <button
                                type="button"
                                className="btn fw-bold btn-info add-btn"
                                id="create-btn"
                                onClick={() => navigate("/leads-list")}
                              >
                                <i className="ri-eye-fill align-bottom me-1"></i>{" "}
                                View All
                              </button>
                            </div>
                          </div> */}
                        </Row>
                      </CardHeader>
                      <CardBody style={{ height: '400px', overflowY: 'auto' }}>
                        {shiftedData.leads.map((e, i) => (
                          <>
                            <div className="d-flex align-items-center " style={{ cursor: "pointer" }} onClick={() => navigate(`/leads-list/${e.slt_lead_id}/0`)}>
                              <div>
                                <h5 className="mb-1 fs-13 fw-normal text-dark">
                                  <b>{e.send_by_name} </b>
                                  Shifted The Lead to <b> {e.send_to_name}</b>
                                </h5>
                                <p className="mb-0 fs-12 text-muted">
                                  Reason :{" "}
                                  {e.slt_reason.replace(/<[^>]*>/g, "")}
                                </p>
                              </div>
                            </div>
                            <hr></hr>
                          </>
                        ))}
                      </CardBody>
                    </Card>
                  </Col>

                </Row>

                <Row>
                  <Col lg={8}>
                    <Card lg={12}>
                      <CardHeader className="card-header border-0">
                        <Row className="align-items-center gy-3">
                          <div className="col-sm">
                            <h5 className="card-title mb-0">Leaves </h5>
                          </div>
                          <div className="col-sm-auto">
                            <div className="d-flex gap-1 flex-wrap">
                              <Link to="/leave" className="btn btn-soft-info btn-sm shadow-none">View All</Link>
                            </div>
                          </div>
                        </Row>
                      </CardHeader>
                      <CardBody className="pt-0" style={{ height: '400px', overflowY: 'auto' }}>
                        <div className="table-responsive">
                          <table
                            role="table"
                            className="text-center bg-white table"
                          >
                            <thead className=" table-light text-muted text-uppercase">
                              <tr>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  #
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  DATE
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  EMP NAME
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  REASON
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  FORM DATE
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  TO DATE
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  STATUS
                                </th>

                                <th>STATUS DES</th>
                              </tr>
                            </thead>


                            <tbody>
                              {LeaveData.slice(0, 10).reverse().map((item, index) => (
                                <tr key={index} >
                                  <td>
                                    <a
                                      className="fw-medium link-primary"
                                      href="/apps-ecommerce-order-details"
                                    >
                                      {index + 1}
                                    </a>
                                  </td>
                                  <td>
                                    {item.leaveCreatedAt != undefined
                                      ? new Date(item.leaveCreatedAt).toLocaleDateString("en-GB")
                                      : ""}
                                  </td>
                                  <td>{item.name}</td>
                                  <td
                                    style={{
                                      maxHeight: "5px",
                                      maxWidth: "200px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre-wrap",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: item.leave_reason,
                                    }}
                                  ></td>

                                  <td> {new Date(item.from_date).toLocaleDateString("en-GB")}</td>
                                  <td>{new Date(item.to_date).toLocaleDateString("en-GB")}</td>
                                  {user.user.u_type === 1 ? (
                                    <td>
                                      {item.leave_status === "1" ? (
                                        <span
                                          className="badge bg-info-subtle text-success text-uppercase"
                                        // onClick={() => EditStatus(index)}
                                        >
                                          {/* <i className="ri-add-line align-bottom me-1"></i> */}
                                          Pending
                                        </span>
                                      ) : item.leave_status === "2" ? (
                                        <span
                                          className="badge bg-success-subtle text-success text-uppercase"
                                        // onClick={() => EditStatus(index)}
                                        >
                                          {/* <i className="ri-add-line align-bottom me-1"></i> */}
                                          Approved
                                        </span>
                                      ) : item.leave_status === "3" ? (
                                        <span className="badge bg-danger-subtle text-danger text-uppercase">
                                          {/* <i className="ri-add-line align-bottom me-1"></i> */}
                                          Rejected
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  ) : user.user.u_type === 2 ? (
                                    <td>
                                      {item.leave_status === "1" ? (
                                        <span
                                          className="badge bg-info-subtle text-success text-uppercase"
                                        // onClick={() => EditStatus(index)}
                                        >
                                          {/* <i className="ri-add-line align-bottom me-1"></i> */}
                                          Pending
                                        </span>
                                      ) : item.leave_status === "2" ? (
                                        <span
                                          className="badge bg-success-subtle text-success text-uppercase"
                                        // onClick={() => EditStatus(index)}
                                        >
                                          {/* <i className="ri-add-line align-bottom me-1"></i> */}
                                          Approved
                                        </span>
                                      ) : item.leave_status === "3" ? (
                                        <span className="badge bg-danger-subtle text-danger text-uppercase">
                                          {/* <i className="ri-add-line align-bottom me-1"></i> */}
                                          Rejected
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  ) : (
                                    ""
                                  )}

                                  <td
                                    style={{
                                      maxHeight: "5px",
                                      maxWidth: "200px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre-wrap",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: item.leave_reject_reason,
                                    }}
                                  ></td>
                                </tr>
                              ))}
                            </tbody>

                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card lg={12} >
                      <CardHeader className="card-header border-0">
                        <Row className="align-items-center gy-3">
                          <div className="col-sm">
                            <h5 className="card-title mb-0">Shift Task </h5>
                          </div>
                          {/* <div className="col-sm-auto">
                            <div className="d-flex gap-1 flex-wrap">
                              <button
                                type="button"
                                className="btn fw-bold btn-info add-btn"
                                id="create-btn"
                                onClick={() => navigate("/leads-list")}
                              >
                                <i className="ri-eye-fill align-bottom me-1"></i>{" "}
                                View All
                              </button>
                            </div>
                          </div> */}
                        </Row>
                      </CardHeader>
                      <CardBody style={{ height: '400px', overflowY: 'auto' }}>
                        {shiftedData.task.map((e, i) => (
                          <>
                            <div className="d-flex align-items-center " style={{ cursor: "pointer" }} onClick={() => navigate(`/leads-list/${e.slt_task_id}/0`)}>
                              <div>
                                <h5 className="mb-1 fs-13 fw-normal text-dark">
                                  <b>{e.send_by_name} </b>
                                  Shifted The Task to <b> {e.send_to_name}</b>
                                </h5>
                                <p className="mb-0 fs-12 text-muted">
                                  Reason :{" "}
                                  {e.slt_reason.replace(/<[^>]*>/g, "")}
                                </p>
                              </div>
                            </div>
                            <hr></hr>
                          </>
                        ))}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {viewModalaStates === true ? (
        <LeadsView
          modalStates={viewModalaStates}
          setModalStates={() => {
            setviewModalaStates(false);
          }}
          checkchang={handleCallback}
          edit_data={FindData}
        />
      ) : (
        ""
      )}
      {viewModalaStates1 === true ? (
        <TaskView
          modalStates={viewModalaStates1}
          setModalStates={() => {
            setviewModalaStates1(false);
          }}
          checkchang={handleCallback}
          edit_data={FindData1}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default AdminDashboard;
