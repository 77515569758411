import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

// Import images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { createSelector } from "reselect";
import { setInvoiceDetails } from "../../store/pos/POSSlice";
import { Link } from "react-router-dom";
import AuthUser from "../../helpers/Authuser";
import { IMG_API_URL } from "../../helpers/url_helper";

const ProfileDropdown = () => {
  const selectDashboardData = createSelector(
    (state) => state.Profile.user,
    (user) => user
  );

  const { user, http, logout } = AuthUser();
  const [businessProfileData, setBusinessProfileData] = useState({});

  // Inside your component
  const user1 = useSelector(selectDashboardData);
  const [UserImage, setUserImage] = useState('');
  const [UserId, setUserId] = useState('');
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      setUserId(obj.user.uid ? obj.user.uid : 'User');
    }
  }, [userName, user1]);

  const [Permissions, setPermissions] = useState([]);

  useEffect(() => {
    http
      .get(`/user/show/${user.user.uid}`)
      .then(function (response) {
        setUserName(response.data.name);
        setUserImage(response.data.profile_photo);
      })
      .catch(function (error) {
        console.log(error);
      });

    http.get(`role/show/${user.user.user_role_id}`).then((res) => {
      setPermissions(res.data.rolesAndPermissionsData);
    }).catch((e) => {
      console.log(e);
    });
  }, [UserId]);

  // Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  return (
    <React.Fragment>
      {/* Injecting CSS for keyframes */}
      <style>
        {`
          @keyframes blink {
            0%, 100% {
              opacity: 1;
              background-color: rgba(0, 255, 0, 1); /* Solid green */
            }
            50% {
              opacity: 0;
              background-color: rgba(0, 255, 0, 0.5); /* Faded green */
            }
          }
        `}
      </style>

      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn shadow-none">
          <span className="d-flex align-items-center position-relative">
            <img
              className="rounded-circle header-profile-user"
              src={UserImage ? `${IMG_API_URL}/user/${UserImage}` : avatar1}
              alt="Header Avatar"
              style={{ position: 'relative' }}
            />
            <span
              style={{
                position: 'absolute',
                bottom: '20px', // Adjust position as needed
                right: '1px', // Adjust position as needed
                width: '20px', // Increase size
                height: '20px', // Increase size
                backgroundColor: 'green',
                borderRadius: '50%',
                border: '2px solid white', // Optional: adds a border to make the dot stand out
                animation: 'blink 3s infinite', // Add animation
              }}
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {userName}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                Welcome {userName.split(" ")[0]} !
              </span>
            </span>
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          {Permissions && Permissions.some(permission => permission.permission_category === 'BUSINESSPROFILE') && (
            <DropdownItem>
              <Link to={process.env.PUBLIC_URL + "/business-setting"}>
                <>
                  <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle">Company Profile</span>
                </>
              </Link>
            </DropdownItem>
          )}
          {Permissions && Permissions.some(permission => permission.permission_category === 'USERPROFILE') && (
            <DropdownItem>
              <Link to={process.env.PUBLIC_URL + "/profile"}>
                <>
                  <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">User Profile</span>
                </>
              </Link>
            </DropdownItem>
          )}
          <DropdownItem onClick={() => logout()}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
