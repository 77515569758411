import React, { useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    CardHeader,
    Nav,
    Row,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";

import AuthUser from "../../helpers/Authuser";
import { useEffect } from "react";
import { IMG_API_URL } from "../../helpers/url_helper";
import InfiniteScroll from "react-infinite-scroll-component";
import D_img from "../D_img";

import { useParams } from "react-router-dom";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import jsPDF from "jspdf";

const Followup = () => {
    const { type } = useParams();

    const [modalStates, setModalStates] = useState(false);
    const [UpdatemodalStates, setUpdateModalStates] = useState(false);
    const { http, user, permission } = AuthUser();
    const [counts, Setcounts] = useState(1);
    const [FollowupData, SetFollowupData] = useState([]);
    const [sortedFollowupData, setSortedFollowupData] = useState([]);

    const [filterData, setfilterData] = useState({
        startdate: new Date().toISOString().split("T")[0] + " 00:00:00",
        enddate: new Date().toISOString().split("T")[0] + " 23:59:59",
        user_id: user.user.u_type === 1 ? 0 : user.user.uid,
    });
    console.log(filterData);
    // infinity
    const [Pages, SetPages] = useState(1);

    const [NoMore, SetNoMore] = useState(true);
    const getAllData = async () => {
        http
            .post(`/follow_up`, filterData)
            .then(function (response) {
                // console.log(response.data);

                SetFollowupData(response.data.followups);

                if (response.data.length === 0) {
                    SetNoMore(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                SetNoMore(false);
            });
    };


    const [assignedByData, setassignedByData] = useState([]);
    const getassignedByData = async () => {
        http
            .get("/franchisebusiness/list")
            .then((response) => {
                if (response.data.length > 0) {
                    setassignedByData(response.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        document.title = "Followup | CRM";
        // if (user.user.u_type === 1) {
        getAllData();
        getassignedByData();
        // } else if (user.user.u_type === 2) {
        // getLoginData();
        // }
        // getAllData();
    }, [counts && filterData]);

    useEffect(() => {
        setSortedFollowupData(FollowupData);
    }, [FollowupData]);

    const fetchData = () => {
        Setcounts(counts + 1);
    };
    //   Delete Aleart
    const [deleteModal, setDeleteModal] = useState(false);
    const [ID, SetID] = useState();
    const onClickDelete = (data) => {
        SetID(data);
        setDeleteModal(true);
    };


    // shortcuts for opening add form
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (
                (event.altKey && event.key === "a") ||
                (event.altKey && event.key === "A")
            ) {
                event.preventDefault();
                setModalStates(true);
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);



    const Leavestatus = [
        { value: "0", label: "All" },
        { value: "1", label: "new" },
        { value: "2", label: "Approve" },
        { value: "3", label: "Rejected" },
    ];
    const generatePDF = () => {
        const doc = new jsPDF('landscape');

        // Set table headers
        const headers = [
            "#",
            "DATE",
            "Followup Type",
            "Followup Description",
            " Customer",
            "Employee",

        ];
        // Set table rows
        const data = FollowupData.map((item, index) => [
            index + 1,
            new Date(item.follow_up_today).toLocaleDateString("en-GB"),
            item.lead_id ? 'Lead Followsup' : item.task_id ? "Task Followsup" : '',
            item.follow_up_description,
            item.customer_name,
            item.name,

        ]);

        // Set table style
        doc.setFontSize(12);
        doc.text("Followup Report", 14, 15);
        doc.autoTable({
            head: [headers],
            body: data,
            startY: 20,
        });

        // Save the PDF
        doc.save("FollowupReport.pdf");

        toast.success("PDF generated successfully!");
    };

    const convertToCSVexcel = () => {
        let csvContent =
            "#,DATE,Followup Type,Followup Description, Customer,Employee";

        FollowupData.forEach((item, index) => {
            csvContent += `\n${index + 1},"${new Date(item.follow_up_today).toLocaleDateString("en-GB")}","${item.lead_id ? 'Lead Followsup' : item.task_id ? "Task Followsup" : ''}","${item.follow_up_description}","${item.customer_name}","${item.name}","`;
        });

        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "FollowupReport.csv");
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        toast.success("CSV generated successfully!");
    };
    const filteredData = type == 2 ? FollowupData.filter(item => item.lead_id) : type == 1 ? FollowupData.filter(item => item.task_id) : FollowupData;
    return (
        <div className="page-content">

            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader className="card-header border-0">
                                <Row className="align-items-center gy-3">
                                    <div className="text-center pt-2 pb-4">
                                        <h2 className="fw-bold mb-0">
                                        Follow-up Reports
                                        </h2>
                                    </div>
                                </Row>
                                <Row className="align-items-center gy-3">
                                    <div className="col-sm">
                                        {/* <h5 className="card-title mb-0">Follow-up Reports</h5> */}
                                    </div>

                                    <div className="col-sm-auto">
                                        <div className="d-flex gap-1 flex-wrap">
                                            {user.user.u_type === 1 ? (
                                                <div>
                                                    {/* <div className="fw-bold">By employee</div> */}
                                                    {/* <Select
                                                        className="fw-bold"
                                                        placeholder="Select Employee"
                                                        options={[
                                                            { value: 0, label: "All" },
                                                            ...assignedByData.map((e) => ({
                                                                value: e.uid,
                                                                label: e.name,
                                                            })),
                                                        ]}
                                                        onChange={(e) => {
                                                            setfilterData({
                                                                ...filterData,
                                                                user_id: e.value,
                                                            });
                                                        }}
                                                    ></Select> */}
                                                </div>
                                            ) : (
                                                ""
                                            )}

                                            <div>
                                                <div className="fw-bold">Start Date</div>
                                                <Flatpickr
                                                    className="form-control fw-bold"
                                                    options={{
                                                        dateFormat: "d/m/Y",
                                                        defaultDate: "today",
                                                    }}
                                                    name="payment_date"
                                                    placeholder="Enter start date"
                                                    onChange={(selectedDates) => {
                                                        const selectedDate = selectedDates[0];
                                                        // Convert to YYYY-MM-DD format
                                                        const year = selectedDate.getFullYear();
                                                        const month = (selectedDate.getMonth() + 1)
                                                            .toString()
                                                            .padStart(2, "0");
                                                        const day = selectedDate
                                                            .getDate()
                                                            .toString()
                                                            .padStart(2, "0");
                                                        const formattedDate = `${year}-${month}-${day} 00:00:00`;

                                                        setfilterData({
                                                            ...filterData,
                                                            startdate: formattedDate,
                                                        });
                                                    }}
                                                />
                                            </div>

                                            <div>
                                                <div className="fw-bold">End Date</div>
                                                <Flatpickr
                                                    className="form-control fw-bold"
                                                    options={{
                                                        dateFormat: "d/m/Y",
                                                        defaultDate: "today",
                                                    }}
                                                    name="payment_date"
                                                    placeholder="Enter start date"
                                                    onChange={(selectedDates) => {
                                                        const selectedDate = selectedDates[0];
                                                        // Convert to YYYY-MM-DD format
                                                        const year = selectedDate.getFullYear();
                                                        const month = (selectedDate.getMonth() + 1)
                                                            .toString()
                                                            .padStart(2, "0");
                                                        const day = selectedDate
                                                            .getDate()
                                                            .toString()
                                                            .padStart(2, "0");
                                                        const formattedDate = `${year}-${month}-${day} 23:59:59`;

                                                        setfilterData({
                                                            ...filterData,
                                                            enddate: formattedDate,
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <button
                                                    className="btn mt-3 btn-success "
                                                    onClick={(e) => {
                                                        getAllData();
                                                    }}
                                                >
                                                    Search
                                                </button>
                                            </div>
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn mt-3 btn-info"
                                                    onClick={convertToCSVexcel}
                                                >
                                                    <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                                                    Export
                                                </button>
                                            </div>
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn mt-3 btn-danger"
                                                    onClick={generatePDF}
                                                >
                                                    <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                                                    PDF
                                                </button>
                                            </div>
                                            <div></div>
                                        </div>
                                    </div>

                                </Row>
                            </CardHeader>

                            <CardBody className="pt-0">
                                <div>
                                    <Nav
                                        className="nav-tabs nav-tabs-custom nav-success"
                                        role="tablist"
                                    ></Nav>
                                    <InfiniteScroll
                                        dataLength={FollowupData.length}
                                        next={fetchData}
                                        hasMore={NoMore}
                                    >
                                        {permission.find(permission => permission.permission_category === "LEAVE" && permission.permission_path === "1") && (
                                            <table
                                                role="table"
                                                className="align-middle table-nowrap table table-hover"
                                            >
                                                <thead className="table-light text-muted text-uppercase">
                                                    <tr>
                                                        <th
                                                            title="Leave List"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            #
                                                        </th>
                                                        <th
                                                            className="sort desc"
                                                            onClick={() => {
                                                                SetFollowupData(FollowupData.slice().reverse());
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            DATE
                                                        </th>
                                                        <th

                                                            className="sort desc"
                                                            onClick={() => {
                                                                SetFollowupData(FollowupData.slice().reverse());
                                                            }}
                                                            style={{ cursor: "pointer" }}>
                                                            Followup Type
                                                        </th>
                                                        <th
                                                            className="sort desc"
                                                            onClick={() => {
                                                                SetFollowupData(FollowupData.slice().reverse());
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Followup Description
                                                        </th>
                                                        <th
                                                            className="sort desc"
                                                            onClick={() => {
                                                                SetFollowupData(FollowupData.slice().reverse());
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Customer
                                                        </th>
                                                        <th
                                                            className="sort desc"
                                                            onClick={() => {
                                                                SetFollowupData(FollowupData.slice().reverse());
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Employee
                                                        </th>



                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        filteredData.map((item, index) => (
                                                            <tr key={index} >

                                                                <td>
                                                                    <a
                                                                        className="fw-medium link-primary"
                                                                        href="/apps-ecommerce-order-details"
                                                                    >
                                                                        {index + 1}
                                                                    </a>
                                                                </td>
                                                                <td>

                                                                    {new Date(item.follow_up_today).toLocaleDateString("en-GB")}

                                                                </td>
                                                                <td>
                                                                    {item.lead_id ? 'Lead Followsup' : item.task_id ? "Task Followsup" : ''}



                                                                </td>
                                                                <td
                                                                    style={{
                                                                        maxHeight: "auto",
                                                                        maxWidth: "200px",
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis",
                                                                        whiteSpace: "pre-wrap",
                                                                    }}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: item.follow_up_description,
                                                                    }}
                                                                ></td>

                                                                <td> {item.customer_name}</td>
                                                                <td>  {item.name}</td>





                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>)}
                                    </InfiniteScroll>
                                </div>

                                <ToastContainer closeButton={false} limit={1} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Followup;
