import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Button,
  Form,
  Badge,
} from "reactstrap";

import Flatpickr from "react-flatpickr";

import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
import Select from "react-select";
import LeadStatusAdd from "../LeadStatus/LeadStatusAdd";
import SourceAdd from "../Source/SourceAdd";
import CustomerAdd from "../Customers/CustomerAdd";
import RefrencesAdd from "../Refrences/RefrencesAdd";
import ProductAdd from "../Product/ProductAdd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import D_img from "../D_img";
import { IMG_API_URL } from "../../helpers/url_helper";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';

const LeadsUpdate = (props) => {
  const { http, https, user } = AuthUser();
  const [audioBlob, setAudioBlob] = useState(null); // State for audio blob
  const recorderControls = useAudioRecorder();
  const [leadsData, setLeads] = useState(props.edit_data);
  const [OldImages, setOldImages] = useState(
    props.edit_data.image ? props.edit_data.image : []
  );
  const [newImages, setnewImages] = useState([]);
  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);

    setnewImages([...newImages, ...selectedImages]);
  };
  const addAudioElement = (blob) => {
    const uniqueFileName = `recording_${Date.now()}_${Math.floor(Math.random() * 10000)}.mp3`;

    // Create a new File object from the blob with the random file name
    const mp3File = new File([blob], uniqueFileName, { type: 'audio/mp3' });

    // Update the audio blob state and leadsData with the mp3 file
    setAudioBlob(mp3File);
    setLeads({
      ...leadsData,
      audio_file: mp3File // Add the mp3 file to leadsData
    });
  };
  const handleRemoveImage = (index) => {
    const updatedImages = [...newImages];
    updatedImages.splice(index, 1);
    setnewImages(updatedImages);
  };

  const [tempValid, settempValid] = useState(0);
  const [modal, setModal] = useState(false);
  const [EmployeeDoc, SetEmployeeDoc] = useState("");
  const [EmployeeVideo, SetEmployeeVideo] = useState("");
  const [manageSource, setmanageSource] = useState(0);
  const [manageProduct, setmanageProduct] = useState(0);
  const [manageCustomer, setmanageCustomer] = useState(0);
  const [manageLeadStatus, setmanageLeadStatus] = useState(0);
  const [manageReferBy, setmanageReferBy] = useState(0);

  const [statusModal, setstatusModal] = useState(false);
  const [productModal, setproductModal] = useState(false);
  const [sourceModal, setsourceModal] = useState(false);

  const [customerModal, setcustomerModal] = useState(false);
  const [referBy, setreferBy] = useState(false);

  const priorityData = [
    { label: "Low", value: "1" },
    { label: "Medium", value: "2" },
    { label: "High", value: "3" },
    { label: "Urgent", value: "4" },
  ];

  const [count, setCount] = useState(0);
  const handleCallback = (data, status) => {
    if (status === 1) {
      toast.success(data);
      setCount(count + 1);
    } else {
      toast.warn(data);
      setCount(count + 1);
    }
    getcustomerData();
    getproductData();
    getreferByData();
    getstatusData();
    gettagData();
    getassignedBy();
    getcustomerData();
    getsourceData();
  };

  const [tagData, settagData] = useState([]);
  const gettagData = async () => {
    http
      .get("/tags/list")
      .then((res) => {
        if (res.data.length > 0) {
          settagData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [statusData, setstatusData] = useState([]);
  const getstatusData = async () => {
    http
      .get("/lead/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageLeadStatus === 1) {
            setLeads({
              ...leadsData,
              status: res.data[res.data.length - 1].lead_status_id,
            });
          }
          setstatusData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [referByData, setreferByData] = useState([]);
  const getreferByData = async () => {
    http
      .get("/reference/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageReferBy === 1) {
            setLeads({
              ...leadsData,
              ref_by: res.data[res.data.length - 1].reference_id,
            });
          }
          setreferByData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [productData, setproductData] = useState([]);
  const getproductData = async () => {
    http
      .get("/product/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageProduct === 1) {
            setLeads({
              ...leadsData,
              product: res.data[res.data.length - 1].product_id,
            });
          }
          setproductData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [sourceData, setsourceData] = useState([]);
  const getsourceData = async () => {
    http
      .get("/source/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageSource === 1) {
            setLeads({
              ...leadsData,
              source: res.data[res.data.length - 1].source_id,
            });
          }
          setsourceData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [customerData, setcustomerData] = useState([]);
  const getcustomerData = async () => {
    http
      .get("/customer/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageCustomer === 1) {
            setLeads({
              ...leadsData,
              customer: res.data[res.data.length - 1].customer_id,
            });
          }
          setcustomerData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [assignedBy, setassignedBy] = useState([]);
  const getassignedBy = async () => {
    http
      .get("/franchisebusiness/list")
      .then((res) => {
        if (res.data.length > 0) {
          const filteredemployee = res.data.filter((e) => e.u_type !== 3 && e.u_type !== 5);

          setassignedBy(filteredemployee);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getcustomerData();
    getproductData();
    getreferByData();
    getstatusData();
    gettagData();
    getassignedBy();
    getsourceData();
  }, [count]);
  const Close = () => {
    setModal(false);
    props.setModalStates();
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const SubmitData = () => {
    const updatedObject = {
      ...leadsData,
      image: newImages,
      oldImages: OldImages,
    };

    if (leadsData.assigned_by === "[]") {
      settempValid(1);
    } else if (leadsData.customer === "") {
      settempValid(2);
    } else if (leadsData.description === "") {
      settempValid(3);
    } else {
      https
        .put("/leads/update", updatedObject)
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    document.title = "Update Leads | CRM";
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [tempCustomer, settempCustomer] = useState(0);

  const assignedByIds = leadsData.assigned_by
    ? JSON.parse(leadsData.assigned_by)
    : [];

  const defaultAssignedOptions = assignedByIds.map((tagId) => {
    const tag = assignedBy.find((e) => e.uid === tagId);
    return tag ? { value: tag.uid, label: tag.name } : null;
  });
  const selectedTagIds = leadsData.tags ? JSON.parse(leadsData.tags) : [];

  // Convert tag IDs to options
  const defaultSelectedOptions = selectedTagIds.map((tagId) => {
    const tag = tagData.find((e) => e.tag_id === tagId);
    return tag ? { value: tag.tag_id, label: tag.tag_name } : null;
  });

  return (
    <div>
      <Modal
        className="modal-xl"
        id="fullscreeexampleModal"
        isOpen={modal}
        size={"xl"}
        toggle={toggle}
        centered
      >
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Lead Details{" "}
        </ModalHeader>
        <span className="tablelist-form">
          <ModalBody style={{ maxHeight: "80vh", overflowY: "auto" }}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                SubmitData();
                return false;
              }}
              action="#"
            >
              <Card
                disabled
                className="border card-border-success p-3 shadow-lg"
              >
                <div className="mb-3">
                  <Row>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  "
                      >
                        <div>
                          Team Member<span className="text-danger"> *</span>
                        </div>
                        <div style={{ color: "red" }}>
                          {tempValid === 1 ? "Please select the assigned!" : ""}
                        </div>
                      </Label>
                      {user.user.u_type === 1 || (user.user.u_type === 2 && user.user.user_behave == 1) ? (
                        <Select
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused ? 'white' : 'white',

                            }),
                          }}
                          isMulti
                          className={
                            tempValid === 1
                              ? "fw-bold border border-danger"
                              : "fw-bold"
                          }
                          name="assigned_by"
                          id="assigned_by"
                          value={defaultAssignedOptions}
                          options={[
                            {
                              label: 'Managers', // Group heading for Managers
                              options: assignedBy
                                .filter(e => e.user_behave === 1 && leadsData.department == e.user_role_id)
                                .map((e, i) => ({
                                  value: e.uid,
                                  label: e.name, // Show the name under Managers group
                                  index: i,
                                  behaveType: e.user_behave
                                }))
                            },
                            {
                              label: 'Employees', // Group heading for Employees
                              options: assignedBy
                                .filter(e => e.user_behave === 0 && leadsData.department == e.user_role_id)
                                .map((e, i) => ({
                                  value: e.uid,
                                  label: e.name, // Show the name under Employees group
                                  index: i,
                                  behaveType: e.user_behave
                                }))
                            }
                          ]}
                          onChange={(e) => {
                            if (e) {
                              // Extract values from selected options and store in an array
                              const selectedValues = e.map(
                                (option) => option.value
                              );
                              setLeads({
                                ...leadsData,
                                assigned_by: `[${selectedValues.join(",")}]`,
                              });
                            }
                          }}
                        />
                      ) : assignedBy != null && leadsData.assigned_by ? (
                        assignedBy.map((e) =>
                          JSON.parse(leadsData.assigned_by).includes(e.uid) ? (
                            <div key={e.uid} className="p-1">
                              <Input
                                value={e.name}
                                className="form-control fw-bold"
                                disabled
                              />
                            </div>
                          ) : null
                        )
                      ) : (
                        "Loading..."
                      )}
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  "
                      >
                        <div>Status</div>
                        <button
                          type="button"
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => {
                            setmanageLeadStatus(1);
                            setstatusModal(!statusModal);
                          }}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </button>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        options={statusData.map((e, i) => ({
                          value: e.lead_status_id,
                          label: e.lead_status_name,
                        }))}
                        className="fw-bold"
                        name="leads_status"
                        id="leads_status"
                        onChange={(e) => {
                          setLeads({
                            ...leadsData,
                            status: e.value,
                          });
                        }}
                        placeholder={
                          manageLeadStatus === 0
                            ? leadsData.lead_status_name
                            : statusData[statusData.length - 1].lead_status_name
                        }
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  "
                      >
                        <div>Priority</div>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        className="fw-bold"
                        name="priority"
                        id="priority"
                        options={priorityData.map((e, i) => ({
                          value: e.value,
                          label: e.label,
                        }))}
                        onChange={(e) => {
                          setLeads({
                            ...leadsData,
                            priority: e.value,
                          });
                        }}
                        placeholder={
                          leadsData.priority === 1
                            ? "Low"
                            : leadsData.priority === 2
                              ? "Medium"
                              : leadsData.priority === 3
                                ? "High"
                                : leadsData.priority === 4
                                  ? "Urgent"
                                  : ""
                        }
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  "
                      >
                        <div>Source</div>
                        <button
                          type="button"
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => {
                            setmanageSource(1);
                            setsourceModal(!sourceModal);
                          }}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </button>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        placeholder={
                          manageSource == 0
                            ? leadsData.source_name
                            : sourceData[sourceData.length - 1].source_name
                        }
                        options={sourceData.map((e, i) => ({
                          value: e.source_id,
                          label: e.source_name,
                        }))}
                        onChange={(e) => {
                          setLeads({
                            ...leadsData,
                            source: e.value,
                          });
                        }}
                        className="fw-bold"
                        name="source"
                        id="source"
                      />
                    </Col>

                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>
                          Customer<span className="text-danger"> *</span>
                        </div>
                        <div style={{ color: "red" }}>
                          {tempValid === 2 ? "Select the customer!" : ""}
                          <button
                            className="btn btn-success btn-sm px-1"
                            style={{ padding: "0px" }}
                            onClick={() => {
                              setcustomerModal(!customerModal);
                            }}
                          >
                            <i className="ri-add-line align-bottom"></i>
                          </button>
                        </div>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        className={
                          tempValid === 2
                            ? "fw-bold border border-danger"
                            : "fw-bold"
                        }
                        name="customer"
                        id="customer"
                        placeholder={leadsData.customer_name}
                        onChange={(e) => {
                          settempValid(0);
                          setmanageCustomer(1);
                          settempCustomer(e.index);
                          setLeads({
                            ...leadsData,
                            customer: e.value,
                          });
                        }}
                        options={customerData.map((e, i) => ({
                          value: e.customer_id,
                          label: e.customer_name,
                          index: i,
                        }))}
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Phone</div>
                      </Label>
                      <Input
                        readOnly
                        name="phone"
                        id="phone"
                        className="form-control fw-bold"
                        value={
                          manageCustomer !== 0
                            ? customerData[tempCustomer].customer_whatsapp_no
                            : leadsData.customer_whatsapp_no
                        }
                        type="text"
                        disabled
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Email</div>
                      </Label>
                      <Input
                        readOnly
                        name="email"
                        id="email"
                        className="form-control fw-bold"
                        placeholder={"Email"}
                        type="text"
                        value={
                          manageCustomer !== 0
                            ? customerData[tempCustomer].customer_email
                            : leadsData.customer_email
                        }
                        disabled
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>City</div>
                      </Label>
                      <Input
                        readOnly
                        name="city"
                        id="city"
                        className="form-control fw-bold"
                        placeholder="City"
                        type="text"
                        value={
                          manageCustomer !== 0
                            ? customerData[tempCustomer].city_name
                            : leadsData.city_name
                        }
                        disabled
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Refer By</div>
                        <button
                          type="button"
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => {
                            setmanageReferBy(1);
                            setreferBy(!referBy);
                          }}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </button>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        className="fw-bold"
                        name="ref_by"
                        id="ref_by"
                        placeholder={
                          manageReferBy === 0
                            ? leadsData.reference_name
                            : referByData[referByData.length - 1].reference_name
                        }
                        onChange={(e) => {
                          setLeads({
                            ...leadsData,
                            ref_by: e.value,
                          });
                        }}
                        options={referByData.map((e, i) => ({
                          value: e.reference_id,
                          label: e.reference_name,
                        }))}
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Product</div>
                        <button
                          type="button"
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => {
                            setmanageProduct(1);
                            setproductModal(!productModal);
                          }}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </button>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        className="fw-bold"
                        name="product"
                        id="product"
                        placeholder={
                          manageProduct === 0
                            ? leadsData.product_name
                            : productData[productData.length - 1].product_name
                        }
                        onChange={(e) => {
                          setLeads({
                            ...leadsData,
                            product: e.value,
                          });
                        }}
                        options={productData.map((e, i) => ({
                          value: e.product_id,
                          label: e.product_name,
                        }))}
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Value</div>
                      </Label>
                      <Input
                        required
                        name="value"
                        id="value"
                        className="form-control fw-bold"
                        placeholder="0"
                        onChange={(e) => {
                          setLeads({
                            ...leadsData,
                            value: e.target.value,
                          });
                        }}
                        value={leadsData.value}
                        type="text"
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Tags</div>
                      </Label>
                      {tagData.length !== 0 ? (
                        <Select
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused ? 'white' : 'white',

                            }),
                          }}
                          options={tagData.map((e, i) => ({
                            label: e.tag_name,
                            value: e.tag_id,
                            index: i,
                          }))}
                          isMulti
                          value={defaultSelectedOptions}
                          onChange={(e) => {
                            if (e) {
                              // Extract values from selected options and update in an array
                              const selectedValues = e.map(
                                (option) => option.value
                              );
                              setLeads({
                                ...leadsData,
                                tags: `[${selectedValues.join(",")}]`,
                              });
                            }
                          }}
                        />
                      ) : (
                        <span className="fw-bold text-danger">
                          No tags added
                        </span>
                      )}
                    </Col>
                    {/* <Col lg={2}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Repeat every</div>
                      </Label>

                      <div className="d-flex   align-items-center">
                        <Col lg={3}>
                          <Input
                            type="number"
                            className="fw-bold"
                            value={leadsData.repeat_every_day.split(" ")[0]}
                            onChange={(e) => {
                              setLeads({
                                ...leadsData,
                                repeat_every_day:
                                  e.target.value +
                                  " " +
                                  leadsData.repeat_every_day.split(" ")[1],
                              });
                            }}
                          />
                        </Col>
                        <Col lg={9}>
                          <Select
                            className="fw-bold"
                            placeholder={
                              leadsData.repeat_every_day.split(" ")[1]
                            }
                            options={[
                              { value: "Day(s)", label: "Day(s)" },
                              { value: "Week(s)", label: "Week(s)" },
                              { value: "Month(s)", label: "Month(s)" },
                              { value: "Year(s)", label: "Year(s)" },
                            ]}
                            onChange={(e) => {
                              setLeads({
                                ...leadsData,
                                repeat_every_day:
                                  leadsData.repeat_every_day.split(" ")[0] +
                                  " " +
                                  e.value,
                              });
                            }}
                          ></Select>
                        </Col>
                      </div>
                    </Col> */}
                    {/* <Col lg={2}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Total Cycles</div>
                      </Label>

                      <div className="d-flex   align-items-center">
                     
                        <Col lg={2} className="text-center ">
                          <Input
                            type="checkbox"
                            onClick={(e) => {
                              if (e.target.checked) {
                                settc(!tc);
                                setLeads({
                                  ...leadsData,
                                  total_cycles: "Infinity",
                                });
                              } else {
                                settc(!tc);
                                setLeads({
                                  ...leadsData,
                                  total_cycles: leadsData.total_cycles,
                                });
                              }
                            }}
                          ></Input>
                        </Col>
                        <Col lg={10}>
                          <Input
                            disabled={tc}
                            className="fw-bold"
                            placeholder="0"
                            value={leadsData.total_cycles}
                            onChange={(e) => {
                              setLeads({
                                ...leadsData,
                                total_cycles: e.target.value,
                              });
                            }}
                          />
                        </Col>
                      </div>
                    </Col> */}
                    <Col lg={2}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Start Date</div>
                      </Label>
                      <Flatpickr
                        className="form-control fw-bold"
                        options={{
                          dateFormat: "Y-m-d",
                          defaultDate: leadsData.minimum_due_date,
                        }}
                        onChange={(selectedDates) => {
                          const selectedDate = selectedDates[0];
                          const day = selectedDate
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const month = (selectedDate.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const year = selectedDate.getFullYear();
                          const formattedDate = `${year}-${month}-${day}`;
                          setLeads({
                            ...leadsData,
                            minimum_due_date: formattedDate,
                          });
                        }}
                        name="date"
                      />
                    </Col>
                    <Col lg={2}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>End Date</div>
                      </Label>
                      <Flatpickr
                        className="form-control fw-bold"
                        options={{
                          dateFormat: "Y-m-d",
                          defaultDate: leadsData.maximum_due_date,
                        }}
                        onChange={(selectedDates) => {
                          const selectedDate = selectedDates[0];
                          const day = selectedDate
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const month = (selectedDate.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const year = selectedDate.getFullYear();
                          const formattedDate = `${year}-${month}-${day}`;
                          setLeads({
                            ...leadsData,
                            maximum_due_date: formattedDate,
                          });
                        }}
                        name="date"
                      />
                    </Col>
                    <Col lg={2}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Next Follow Up Date</div>
                      </Label>
                      <Flatpickr
                        className="form-control fw-bold  shadow-sm "
                        options={{
                          dateFormat: "Y-m-d h:i K", // Use 'h:i K' for 12-hour time with AM/PM
                          enableTime: true,          // Enable time selection
                          time_12hr: true,           // Enable 12-hour time format with AM/PM
                          defaultDate: leadsData.next_follow_up,
                        }}
                        onChange={(selectedDates) => {
                          const selectedDate = selectedDates[0];

                          // Extract date components
                          const day = selectedDate.getDate().toString().padStart(2, "0");
                          const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
                          const year = selectedDate.getFullYear();

                          // Extract time components
                          const hours = selectedDate.getHours() % 12 || 12; // 12-hour format
                          const minutes = selectedDate.getMinutes().toString().padStart(2, "0");
                          const ampm = selectedDate.getHours() >= 12 ? "PM" : "AM"; // AM/PM indicator

                          // Format the date and time as "YYYY-MM-DD h:i AM/PM"
                          const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;

                          // Set the task data with both date and time
                          setLeads({
                            ...leadsData,
                            next_follow_up: formattedDateTime,
                          });
                        }}
                        name="date"
                      />
                    </Col>

                    <Col lg={6}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>
                          Description<span className="text-danger"> *</span>
                        </div>
                        <div className="text-danger">
                          {tempValid === 3
                            ? "Description cannot be empty!"
                            : ""}
                        </div>
                      </Label>

                      <CKEditor
                        rows={1}
                        editor={ClassicEditor}
                        onChange={(e, editor) => {
                          settempValid(0);
                          setLeads({
                            ...leadsData,
                            description: editor.getData(),
                          });
                        }}
                        data={leadsData.description}
                      />
                    </Col>
                    <Col lg={6}>
                      <div className="mt-3">
                        <h5 className="fs-15 mb-1">Past Images</h5>
                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="avatar-lg">
                              <div className="avatar-title bg-light rounded">
                                {OldImages !== "[]" ? (
                                  OldImages.split(",")
                                    .slice(0, 7)
                                    .map((image, index) => (
                                      <div
                                        key={index}
                                        className="position-relative"
                                      >
                                        <img
                                          src={
                                            image
                                              ? `${IMG_API_URL}leads/${image.replace(
                                                /^\[|\]$/g,
                                                ""
                                              )}`
                                              : URL.createObjectURL(image)
                                          }
                                          alt={`product-img-${index}`}
                                          className=" m-2"
                                          width={"100px"}
                                          height={"100px"}
                                        />
                                      </div>
                                    ))
                                ) : (
                                  <D_img />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mt-3">
                        <h5 className="fs-15 mb-1">Upload Images</h5>
                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="avatar-lg">
                              <div className="avatar-title bg-light rounded">
                                {newImages.length > 0 ? (
                                  newImages.map((image, index) => (
                                    <div key={index} className="position-relative">
                                      <img
                                        src={URL.createObjectURL(image)}
                                        alt={`product-img-${index}`}
                                        className="m-2"
                                        height={"100px"}
                                        width={"150px"}
                                      />
                                      <button
                                        type="button"
                                        className="position-absolute top-0 end-0 btn-close"
                                        onClick={() => handleRemoveImage(index)}
                                      ></button>
                                    </div>
                                  ))
                                ) : (
                                  <D_img />
                                )}
                              </div>
                            </div>
                            <div className="position-absolute top-0 end-0 translate-middle">
                              <label
                                htmlFor="taskImages"
                                className="mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title=""
                                data-bs-original-title="Select Images"
                              >
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i className="ri-image-fill"></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="taskImages"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                multiple // Allow multiple image selection
                                onChange={(e) => {
                                  handleImageChange(e);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>


                    <Col lg={6}>
                      <div className="mt-3">
                        {/* <h5 className="fs-15 mb-1">Old Data</h5>
                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="avatar-lg">
                              <div className="avatar-title bg-light rounded">

                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mt-5">
                        <h5 className="fs-15 mb-1">AudioRecorder</h5>
                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="avatar-lg d-inline-block align-top"> {/* Changed the parent div to inline-block */}
                              <AudioRecorder
                                onRecordingComplete={addAudioElement}
                                recorderControls={recorderControls}
                              />
                              {/* <button onClick={recorderControls.stopRecording}>Stop recording</button> */}
                              {audioBlob && ( // Render audio element if blob exists
                                <audio controls>
                                  <source src={URL.createObjectURL(audioBlob)} type="audio/wav" />
                                  Your browser does not support the audio element.
                                </audio>
                              )}
                            </div>

                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg={2}>
                      <div className="mt-5">
                        <h5 className="fs-15 mb-1">Video</h5>
                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute top-100 start-100 translate-middle">
                              <label
                                htmlFor="employeeVideo"
                                className="mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Select Video"
                              >
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i
                                      className="ri-video-fill"
                                      style={{
                                        color: "#009CA4",
                                        fontSize: "20px",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="employeeVideo"
                                type="file"
                                accept="video/mp4, video/avi, video/mkv"
                                onChange={(e) => {
                                  setLeads({
                                    ...leadsData,
                                    video: e.target.files[0],
                                  });
                                  SetEmployeeVideo(e.target.files[0]);
                                }}
                              />
                            </div>
                            <div className="avatar-lg">
                              {EmployeeVideo ? (
                                <div className="d-flex flex-column align-items-center">
                                  <i
                                    className="ri-video-fill"
                                    style={{
                                      fontSize: "50px",
                                      color: "#007bff",

                                    }}
                                  ></i>
                                  <p style={{ color: "#007bff" }}>
                                    {EmployeeVideo.name
                                      ? `${EmployeeVideo.name.substring(0, 5)}.${EmployeeVideo.name.split('.').pop()}`
                                      : ''}
                                  </p>

                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg={2}>
                      <div className="mt-5">
                        <h5 className="fs-15 mb-1">Document</h5>
                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute top-100 start-100 translate-middle">
                              <label
                                htmlFor="employeeDocument"
                                className="mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Select Document"
                              >
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i
                                      className="ri-file-upload-fill"
                                      style={{
                                        color: "#009CA4",
                                        fontSize: "20px",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="employeeDocument"
                                type="file"
                                accept=".pdf,.doc,.docx,.txt,.xlsx"
                                onChange={(e) => {
                                  setLeads({
                                    ...leadsData,
                                    document: e.target.files[0],
                                  });
                                  SetEmployeeDoc(e.target.files[0]);
                                }}
                              />
                            </div>
                            <div className="avatar-lg">
                              {EmployeeDoc ? (
                                EmployeeDoc.type.startsWith("image/") ? (
                                  <img
                                    src={URL.createObjectURL(EmployeeDoc)}
                                    alt="Selected file preview"
                                    height="50px"
                                    width="50px"
                                  />
                                ) : (
                                  <div className="d-flex flex-column align-items-center">
                                    <div>
                                      {EmployeeDoc.type.includes("pdf") && (
                                        <i
                                          className="ri-file-pdf-fill"
                                          style={{
                                            fontSize: "50px",
                                            color: "#FF0000",
                                          }}
                                        ></i>
                                      )}
                                      {EmployeeDoc.type.includes("word") && (
                                        <i
                                          className="ri-file-word-fill"
                                          style={{
                                            fontSize: "50px",
                                            color: "#1E90FF",
                                          }}
                                        ></i>
                                      )}
                                      {EmployeeDoc.type.includes("excel") && (
                                        <i
                                          className="ri-file-excel-fill"
                                          style={{
                                            fontSize: "50px",
                                            color: "#28A745",
                                          }}
                                        ></i>
                                      )}
                                      {!EmployeeDoc.type.includes("pdf") &&
                                        !EmployeeDoc.type.includes("word") &&
                                        !EmployeeDoc.type.includes(
                                          "excel"
                                        ) && (
                                          <i
                                            className="ri-file-fill"
                                            style={{
                                              fontSize: "50px",
                                              color: "#6c757d",
                                            }}
                                          ></i>
                                        )}
                                    </div>
                                    <p style={{ color: "#007bff" }}>
                                      {EmployeeDoc.name
                                        ? `${EmployeeDoc.name.substring(0, 5)}.${EmployeeDoc.name.split('.').pop()}`
                                        : ''}
                                    </p>
                                  </div>
                                )
                              ) : (
                                ""
                              )}
                            </div>

                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Form>
          </ModalBody>
          <div className="modal-footer">
            <button
              ref={submitButtonRef}
              name="close"
              id="close"
              type="submit"
              className="btn btn-primary"
              onClick={() => SubmitData()}
            >
              <i className="ri-save-3-line align-bottom me-1"></i>
              Update
            </button>

            <button
              name="close"
              id="close"
              type="button"
              className="btn btn-danger"
              onClick={() => Close()}
            >
              <i className="ri-close-line me-1 align-middle" />
              Close
            </button>
          </div>
        </span>
      </Modal>

      {statusModal === true ? (
        <LeadStatusAdd
          modalStates={statusModal}
          setModalStates={() => {
            setstatusModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {productModal === true ? (
        <ProductAdd
          modalStates={productModal}
          setModalStates={() => {
            setproductModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {referBy === true ? (
        <RefrencesAdd
          modalStates={referBy}
          setModalStates={() => {
            setreferBy(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {customerModal === true ? (
        <CustomerAdd
          modalStates={customerModal}
          setModalStates={() => {
            setcustomerModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {sourceModal === true ? (
        <SourceAdd
          modalStates={sourceModal}
          setModalStates={() => {
            setsourceModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default LeadsUpdate;
