import React, { useRef, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  Row,
  Badge,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
} from "reactstrap";
import Flatpickr from "react-flatpickr";

import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import AuthUser from "../../helpers/Authuser";
import { useEffect } from "react";
import LeadsUpdate from "./LeadsUpdate";
import LeadsAdd from "./LeadsAdd";
import Select from "react-select";
import { useParams } from "react-router-dom";
import ShiftLeadAdd from "./ShiftLead/ShiftLeadAdd";
import ShiftLeadList from "./ShiftLead/ShiftLeadList";
import LeadsView from "./LeadsView";
import jsPDF from "jspdf";
import FolllowUpDetails from "./FollowUp/FollowUpDetails";
import Import from "./Import";

const LeadsList = () => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [selectedLeadIndex, setSelectedLeadIndex] = useState(null);
  const [followUpDetails, setfollowUpDetails] = useState(false);
  const togglePopover = (index) => {
    setPopoverOpen(!popoverOpen);
    setSelectedLeadIndex(index);
  };

  const { type, status } = useParams();
  const { http, https, user, permission } = AuthUser();
  const [shiftModal, setShiftModal] = useState(false);
  const [shiftListModal, setshiftListModal] = useState(false);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const [filterData, setfilterData] = useState({
    start_date: status
      ? new Date(currentYear, 0, 1).toISOString().split("T")[0] + " 00:00:00"
      : currentDate.toISOString().split("T")[0] + " 00:00:00",
    end_date: status
      ? new Date(currentYear, 11, 31).toISOString().split("T")[0] + " 23:59:59"
      : currentDate.toISOString().split("T")[0] + " 23:59:59",
    customer_name: 0,
    status_name: status ? status : 0,
    lead_id: type ? type : 0,
    assigned_by: 0,
    department: 0
  });
  const [modalStates, setModalStates] = useState(false);
  const [modalStatesimport, setModalStatesimport] = useState(false);
  const [UpdatemodalStates, setUpdateModalStates] = useState(false);
  const [viewModalaStates, setviewModalaStates] = useState(false);
  const [followUpStates, setfollowUpStates] = useState(false);
  const [followUpList, setfollowUpList] = useState(false);

  const [counts, Setcounts] = useState(1);
  const [LeadData, SetLeadData] = useState([]);
  // infinity

  const [statusChange, setstatusChange] = useState([]);
  const getstatusChange = async () => {
    http
      .get("/lead/list")
      .then((response) => {
        if (response.data.length > 0) {
          setstatusChange(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [tagsData, settagsData] = useState([]);
  const gettagsData = async () => {
    http
      .get("/tags/list")
      .then((response) => {
        if (response.data.length > 0) {
          settagsData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const ChangeStatus = (index) => {
    let FindArray = LeadData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
  };
  const [assignedByData, setassignedByData] = useState([]);

  const [selectedRoleIds, setSelectedRoleIds] = useState((user.user.u_type == 2 && user.user.user_behave == 1) ? user.user.user_role_id : null);
  const [selectedRoleUserIds, setSelectedRoleUserIds] = useState([]);
  const [statuscheckforAssignedBy, setstatuscheckforAssignedBy] = useState(0);
  const [role, setRole] = useState([]);


  const getRole = async () => {
    try {
      const response = await http.get("/role/list");
      const filteredData = user && (user.user.u_type === 1 || user.user.u_type === 2)
        ? response.data.filter(
          (role) =>
            parseInt(role.role_created_user_id) === user.user.user_user_id ||
            parseInt(role.role_created_user_id) === user.user.uid
        )
        : response.data.filter(
          (role) => parseInt(role.role_created_user_id) === user.user.uid
        );
      setRole(filteredData);
    } catch (error) {
      console.log(error);
    }
  };
  const getassignedByData = async () => {

    const res = await http.get("/franchisebusiness/list");
    const filteredEmployee = res.data.filter(
      (e) => e.u_type !== 3 && e.u_type !== 5
    );
    setassignedByData(filteredEmployee);
  };
  const handleFilter = async () => {
    http
      .post(`/leads/filter`, filterData)
      .then(function (response) {
        SetLeadData(response.data);
        console.log(response.data

        );

      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const [customerData, setcustomerData] = useState([]);
  const getcustomerData = async () => {
    http
      .get("/customer/list")
      .then((response) => {
        if (response.data.length > 0) {
          setcustomerData(response.data);

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getstatusChange();
    gettagsData();
    getassignedByData();
    getcustomerData();
    document.title = "Lead | CRM";
    getRole();
    handleFilter();
  }, [counts && filterData]);
  const fetchData = () => {
    Setcounts(counts + 1);
  };
  //   Delete Aleart
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, SetID] = useState();
  const onClickDelete = (data) => {
    SetID(data);
    setDeleteModal(true);
  };
  const handleDeleteOrder = (data) => {
    if (data._reactName == "onClick") {
      http
        .delete(`/lead/delete/${ID}`)
        .then(function (response) {
          if (response.data.status == 0) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
          Setcounts(counts + 1);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    SetLeadData([]);
    setDeleteModal(false);
  };

  // shortcuts for opening add form
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.altKey && event.key === "a") ||
        (event.altKey && event.key === "A")
      ) {
        event.preventDefault();
        setModalStates(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  //   end Alert
  const handleCallback = (data, status) => {
    SetLeadData([]);
    if (status == 1) {
      Setcounts(counts + 1);
      setModalStates(false);
      setModalStatesimport(false);
      setUpdateModalStates(false);
      setShiftModal(false);
      toast.success(data);
    } else {
      toast.warn(data);
      Setcounts(counts + 1);
    }
    handleFilter();
  };
  // Edit Data
  const [FindData, SetFind] = useState([]);
  const EditUpdate = (index) => {
    let FindArray = LeadData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setUpdateModalStates(!UpdatemodalStates);
  };
  const ViewPage = (index) => {
    let FindArray = LeadData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setviewModalaStates(!viewModalaStates);
  };
  const transeferData = (index) => {
    let FindArray = LeadData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setShiftModal(!shiftModal);
  };
  const FollowUp = (index) => {
    let FindArray = LeadData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setfollowUpStates(!followUpStates);
  };
  const FollowListed = (index) => {
    let FindArray = LeadData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setfollowUpList(!followUpList);
  };
  const tableRef = useRef(null);
  const downloadPDF = () => {
    const table = tableRef.current;
    if (!table) {
      console.error("Table element not found.");
      return;
    }

    // Create a new jsPDF instance with landscape orientation
    const pdf = new jsPDF({
      orientation: "landscape",
    });

    // Add the table to the PDF using autoTable
    pdf.autoTable({ html: table });

    // Output the PDF as a blob
    const blob = pdf.output("blob");
    const url = URL.createObjectURL(blob);

    // Create a link element to download the PDF
    const link = document.createElement("a");
    link.href = url;
    link.download = "leads_list.pdf";

    // Append the link to the document, trigger the download, and then remove the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const downloadCSV = () => {
    const table = tableRef.current;
    if (!table) {
      console.error("Table element not found.");
      return;
    }

    const csvContent = Array.from(table.querySelectorAll("tr"))
      .map((row) =>
        Array.from(row.children)
          .map((cell) => `"${cell.innerText.trim()}"`)
          .join(",")
      )
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "lead_list.csv";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const [file, setFile] = useState(null);
  // const [message, setMessage] = useState('');

  // const onFileChange = (e) => {
  //   setFile(e.target.files[0]);
  // };

  // const onFileUpload = async () => {
  //   if (!file) {
  //     setMessage('Please select a file first.');
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append('file', file);

  //   try {

  //     https
  //       .post("/leads/upload", formData)
  //       .then(function (response) {
  //         // props.checkchang(response.data.message, response.data.status);
  //         if (response.data.status === 1) {
  //           toast.success(response.data.message);
  //         } else {
  //           toast.success(response.data.message);

  //         }

  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //     const response = await https.post('/leads/upload', formData);
  //     setMessage(response.data.message);
  //   } catch (error) {
  //     setMessage('Error uploading file');
  //     console.error('Error:', error);
  //   }
  // };

  return (
    <div className="page-content">
      <table
        ref={tableRef}
        style={{ display: "none" }}
        role="table"
        id="tableToPrint"
        className="text-center bg-white table"
      >
        <thead className="table-light text-muted text-uppercase">
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Time</th>
            <th>Customer</th>
            <th>Phone</th>
            <th>City</th>
            <th>Product </th>
            <th>Source</th>
            <th>Value</th>
            <th>Tags</th>
            <th>Assigned By</th>
            <th>Assigned To</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {LeadData.length > 0
            ? LeadData.map((item, index) => (
              <tr title="Open Lead" style={{ cursor: "pointer" }} key={index}>
                <td>
                  <a
                    className="fw-medium link-primary"
                    href="/apps-ecommerce-order-details"
                  >
                    {index + 1}
                  </a>
                </td>
                <td>
                  {new Date(item.today_date).toLocaleDateString("en-GB")}{" "}
                </td>
                <td>{new Date(item.today_date).toLocaleTimeString()}</td>
                <td>{item.customer_name}</td>
                <td>{item.customer_whatsapp_no}</td>
                <td>{item.city_name}</td>
                <td>{item.product_name}</td>

                <td>{item.source_name}</td>
                <td>{item.value}</td>
                <td>
                  {tagsData != null && item.tags
                    ? tagsData.map((e) =>
                      JSON.parse(item.tags).includes(e.tag_id) ? (
                        <Col key={e.tag_id} lg={6}>
                          <Badge className="badge-gradient-info ">
                            {e.tag_name}
                          </Badge>
                        </Col>
                      ) : null
                    )
                    : "Loading..."}
                </td>
                <td>{item.name}</td>
                <td>
                  {assignedByData != null && item.assigned_by
                    ? assignedByData.map((e) =>
                      JSON.parse(item.assigned_by).includes(e.uid) ? (
                        <div key={e.uid} className="p-1">
                          <button className="bg-primary fw-bold text-white border border-primary">
                            {e.name}
                          </button>
                        </div>
                      ) : null
                    )
                    : "Loading..."}
                </td>
                <td>
                  <div>
                    <div className="hstack flex-wrap gap-2">
                      <Badge
                        color={
                          item.lead_status_color
                        }
                      >
                        {item.lead_status_name}
                      </Badge>
                    </div>
                  </div>
                </td>
              </tr>
            ))
            : ""}
        </tbody>
      </table>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="text-center pt-2 pb-4">
                    <h2 className="fw-bold mb-0"> {filterData.department > 0 ? role.filter((data) => data.role_id == filterData.department)[0].role_name : "All "} Lead's</h2>
                  </div>
                </Row>
                <Row className="align-items-center gy-3">

                  <div className="">
                    <div className="justify-content-end d-flex gap-1 flex-wrap">
                      {user.user.u_type === 1 ? (
                        <div>
                          <div className="fw-bold">
                            Department
                          </div>

                          <Select
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused ? 'white' : 'white',
                              }),
                            }}
                            className="fw-bold"
                            options={[
                              { value: 0, label: "Select All" },
                              ...role.filter(item => item.role_created_for == null).map((e) => ({
                                value: e.role_id,
                                label: e.role_name,
                              }))]} // Map the role data to options
                            onChange={(e) => {

                              setSelectedRoleIds(e.value); // Update the selected role ID(s)

                              setstatuscheckforAssignedBy(statuscheckforAssignedBy + 1)
                              setfilterData({
                                ...filterData,
                                department: e.value
                              })


                            }}
                          />
                        </div>
                      ) : ""}
                      {user.user.u_type === 1 ||
                        (user.user.u_type === 2 && user.user.user_behave == 1) ||
                        user.user.u_type === 3 ? (
                        <div>
                          <div className="fw-bold" style={{ width: "150px" }}>
                            Assigned to
                          </div>
                          <Select
                            className="fw-bold"
                            placeholder="Assigned"
                            options={[
                              { value: 0, label: "Select All" }, // "Select All" option at the top
                              {
                                label: 'Managers', // Group heading for Managers
                                options: assignedByData
                                  .filter((e) => (e.user_role_id == selectedRoleIds || e.role_created_for == selectedRoleIds) && e.user_behave === 1 && (selectedRoleUserIds.length === 0 || selectedRoleUserIds.includes(e.uid.toString()))) // Filter for Managers
                                  .map((e, i) => ({
                                    value: e.uid,
                                    label: e.name, // Show the name under Managers group
                                    index: i,
                                    behaveType: e.user_behave
                                  })),
                              },
                              {
                                label: 'Employees', // Group heading for Employees
                                options: assignedByData
                                  .filter((e) => (e.user_role_id == selectedRoleIds || e.role_created_for == selectedRoleIds) && e.user_behave === 0 && (selectedRoleUserIds.length === 0 || selectedRoleUserIds.includes(e.uid.toString()))) // Filter for Employees
                                  .map((e, i) => ({
                                    value: e.uid,
                                    label: e.name, // Show the name under Employees group
                                    index: i,
                                    behaveType: e.user_behave
                                  })),
                              },
                            ]}
                            onChange={(e) => {
                              setfilterData({
                                ...filterData,
                                assigned_by: e.value,
                              });
                            }}
                          ></Select>
                        </div>
                      ) : (
                        ""
                      )}
                      <div>
                        <div className="fw-bold" style={{ width: "150px" }}>
                          By Customer
                        </div>
                        <Select
                          className="fw-bold"
                          placeholder="Customer"
                          options={[
                            { value: 0, label: "Select All" },
                            ...customerData.map((e) => ({
                              value: e.customer_id,
                              label: e.customer_name,
                            })),
                          ]}
                          onChange={(e) => {
                            setfilterData({
                              ...filterData,
                              customer_name: e.value,
                            });
                          }}
                        ></Select>
                      </div>
                      <div>
                        <div className="fw-bold" style={{ width: "150px" }}>
                          By Status
                        </div>
                        <Select
                          className="fw-bold"
                          placeholder="Status"
                          options={[
                            { value: 0, label: "Select All" },
                            ...statusChange.map((e) => ({
                              value: e.lead_status_id,
                              label: e.lead_status_name,
                            })),
                          ]}
                          onChange={(e) => {
                            setfilterData({
                              ...filterData,
                              status_name: e.value,
                            });
                          }}
                        ></Select>
                      </div>
                      <div>
                        <div className="fw-bold">Start Date</div>
                        <Flatpickr
                          className="form-control fw-bold"
                          options={{
                            dateFormat: "d/m/Y",
                            defaultDate: "today",
                          }}
                          style={{
                            width: "110px",
                          }}
                          name="payment_date"
                          placeholder="Enter start date"
                          onChange={(selectedDates) => {
                            const selectedDate = selectedDates[0];
                            // Convert to YYYY-MM-DD format
                            const year = selectedDate.getFullYear();
                            const month = (selectedDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0");
                            const day = selectedDate
                              .getDate()
                              .toString()
                              .padStart(2, "0");
                            const formattedDate = `${year}-${month}-${day} 00:00:00`;

                            setfilterData({
                              ...filterData,
                              start_date: formattedDate,
                            });
                            Setcounts(counts + 1);
                          }}
                        />
                      </div>

                      <div>
                        <div className="fw-bold">End Date</div>
                        <Flatpickr
                          className="form-control fw-bold"
                          options={{
                            dateFormat: "d/m/Y",
                            defaultDate: "today",
                          }}
                          style={{
                            width: "110px",
                          }}
                          name="payment_date"
                          placeholder="Enter start date"
                          onChange={(selectedDates) => {
                            const selectedDate = selectedDates[0];
                            // Convert to YYYY-MM-DD format
                            const year = selectedDate.getFullYear();
                            const month = (selectedDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0");
                            const day = selectedDate
                              .getDate()
                              .toString()
                              .padStart(2, "0");
                            const formattedDate = `${year}-${month}-${day} 23:59:59`;

                            setfilterData({
                              ...filterData,
                              end_date: formattedDate,
                            });
                            Setcounts(counts + 1);
                          }}
                        />
                      </div>
                      <div>
                        <button
                          className="btn mt-3 btn-success "
                          onClick={(e) => {
                            handleFilter();
                          }}
                        >
                          Search
                        </button>
                      </div>

                      <div>
                        <button
                          type="button"
                          className="btn mt-3 btn-info"
                          onClick={() => downloadCSV()}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          Export
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn mt-3 btn-danger"
                          onClick={() => downloadPDF()}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          Pdf
                        </button>
                      </div>
                      <div>
                        {type != "report"
                          ? permission.find(
                            (permission) =>
                              permission.permission_category === "LEAD" &&
                              permission.permission_path === "2"
                          ) && (
                            <button
                              type="button"
                              className="btn mt-3 fw-bold btn-success add-btn"
                              id="create-btn"
                              onClick={() => setModalStates(!modalStates)}
                            >
                              <i className="ri-add-line align-bottom me-1"></i>{" "}
                              Add Lead
                            </button>
                          )
                          : ""}
                      </div>
                      {/* <div>
                        <button
                          type="button"
                          className="btn mt-3 btn-info"
                          onClick={() =>
                            setModalStatesimport(!modalStatesimport)
                          }
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          Import Data
                        </button>
                      </div> */}
                      <div>
                        {/* {user.user.u_type === 1 || user.user.u_type === 2 ? ( */}
                        <div className="btn btn-info mt-3">
                          <div
                            onClick={() => {
                              setshiftListModal(true);
                            }}
                          >
                            <i className="ri-eye-line align-bottom me-1"></i>{" "}
                            Shifted Leads
                          </div>
                        </div>
                        {/* ) : (
                          ""
                        )} */}
                      </div>
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  ></Nav>
                  {permission.find(
                    (permission) =>
                      permission.permission_category === "LEAD" &&
                      permission.permission_path === "1"
                  ) && (
                      <div className="table-responsive">
                        <table
                          role="table"
                          className="align-middle table-nowrap table table-hover"
                        >
                          <thead className="table-light text-muted text-uppercase">
                            <tr>
                              <th style={{ cursor: "pointer" }}>#</th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Date & time
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Customer
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Phone
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                City
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Product{" "}
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  LeadData.slice().reverse();
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Source
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Value
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Tags
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Assigned BY
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Assigned TO
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Follow Up
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Status
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Shift
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              LeadData.filter((item, index, self) =>
                                index === self.findIndex((t) => (
                                  t.lead_id === item.lead_id
                                ))
                              ).map((item, index) => (
                                <tr
                                  title="Open Task"
                                  style={{
                                    cursor: "pointer",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    transition: "box-shadow 0.3s ease",
                                  }}
                                  onMouseEnter={(e) => {
                                    e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";
                                    e.currentTarget.style.backgroundColor = "#f0f0f0"; // Optional: highlight background on hover
                                  }}
                                  onMouseLeave={(e) => {
                                    e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
                                    e.currentTarget.style.backgroundColor = ""; // Reset background color
                                  }}
                                  key={index}
                                >
                                  <td onClick={() => ViewPage(index)}>
                                    <a
                                      className="fw-medium link-primary"
                                      href="/apps-ecommerce-order-details"
                                    >
                                      {index + 1}
                                    </a>
                                  </td>
                                  <td onClick={() => ViewPage(index)}>
                                    {new Date(
                                      item.today_date
                                    ).toLocaleDateString("en-GB")}
                                    <br />
                                    {item.today_date.substring(11, 16)}
                                  </td>
                                  <td onClick={() => ViewPage(index)}>
                                    {item.customer_name}
                                  </td>
                                  <td onClick={() => ViewPage(index)}>
                                    {item.customer_whatsapp_no}
                                  </td>
                                  <td onClick={() => ViewPage(index)}>
                                    {item.city_name}
                                  </td>
                                  <td onClick={() => ViewPage(index)}>
                                    {item.product_name}
                                  </td>
                                  <td onClick={() => ViewPage(index)}>
                                    {item.source_name}
                                  </td>
                                  <td onClick={() => ViewPage(index)}>
                                    {item.value}
                                  </td>
                                  <td onClick={() => ViewPage(index)}>
                                    {tagsData != null && item.tags
                                      ? tagsData.map((e) =>
                                        JSON.parse(item.tags).includes(
                                          e.tag_id
                                        ) ? (
                                          <Col key={e.tag_id} lg={6}>
                                            <Badge className="badge-gradient-info ">
                                              {e.tag_name}
                                            </Badge>
                                          </Col>
                                        ) : null
                                      )
                                      : "Loading..."}
                                  </td>
                                  <td>{item.name}</td>
                                  <td onClick={() => ViewPage(index)}>
                                    {assignedByData != null && item.assigned_by
                                      ? assignedByData.map((e) =>
                                        JSON.parse(item.assigned_by).includes(
                                          e.uid
                                        ) ? (
                                          <div key={e.uid} className="p-1">
                                            <button className="bg-primary fw-bold text-white border border-primary">
                                              {e.name}
                                            </button>
                                          </div>
                                        ) : null
                                      )
                                      : "Loading..."}
                                  </td>
                                  <td>
                                    <button
                                      className="btn border-0"
                                      style={{
                                        background:
                                          "linear-gradient(to right, #FF69B4, #FF1493)",
                                        color: "white",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        SetFind(
                                          LeadData.filter((_, i) => i === index)
                                        );
                                        setfollowUpDetails(true);
                                      }}
                                    >
                                      Follow Up
                                    </button>
                                  </td>
                                  <td>
                                    <div onClick={() => ChangeStatus(index)}>
                                      <div className="hstack flex-wrap gap-2">
                                        <Button
                                          color={
                                            item.lead_status_color
                                          }
                                          className="btn-label right "
                                        >
                                          {" "}
                                          <i
                                            id={`PopoverDismissible${index}`}
                                            className=" ri-arrow-down-s-line label-icon align-middle fs-16 ms-2"
                                            onClick={() => togglePopover(index)}
                                          ></i>{" "}
                                          {item.lead_status_name}
                                        </Button>

                                        <UncontrolledPopover
                                          trigger="legacy"
                                          placement="bottom-start"
                                          target={`PopoverDismissible${index}`}
                                          isOpen={
                                            popoverOpen &&
                                            selectedLeadIndex === index
                                          }
                                          toggle={() => togglePopover(index)}
                                        >
                                          {statusChange.map((e, i) => (
                                            <PopoverBody key={i}>
                                              <div
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  https
                                                    .put("/leads/update", {
                                                      lead_id: FindData.lead_id,
                                                      status: e.lead_status_id,
                                                    })
                                                    .then(function (response) {
                                                      handleFilter();
                                                      toast.success(
                                                        "Lead status updated successfully!"
                                                      );
                                                      togglePopover();
                                                    })
                                                    .catch(function (err) {
                                                      console.log(err);
                                                    });
                                                }}
                                              >
                                                {e.lead_status_name}
                                              </div>
                                            </PopoverBody>
                                          ))}
                                        </UncontrolledPopover>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <ul className="list-inline hstack gap-2 mb-0">
                                      <li className="list-inline-item edit">
                                        <button
                                          className="btn btn-info border-0"
                                          onClick={() => {
                                            transeferData(index);
                                          }}
                                          style={{
                                            background:
                                              "linear-gradient(to right, #ff7e5f, #feb47b)",
                                          }}
                                        >
                                          Shift{" "}
                                        </button>
                                      </li>
                                    </ul>
                                  </td>
                                  <td>
                                    <ul className="list-inline hstack gap-2 d-flex justify-content-center mb-0">
                                      <li className="list-inline-item edit">
                                        {permission.find(
                                          (permission) =>
                                            permission.permission_category ===
                                            "LEAD" &&
                                            permission.permission_path === "3"
                                        ) && (
                                            <button
                                              className="text-primary border-0 bg-transparent"
                                              onClick={() => EditUpdate(index)}
                                            >
                                              <i className="ri-pencil-fill fs-16" />
                                            </button>
                                          )}
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    )}
                </div>

                {modalStates === true ? (
                  <LeadsAdd
                    modalStates={modalStates}
                    setModalStates={() => {
                      setModalStates(false);
                    }}
                    checkchang={handleCallback}
                  />
                ) : (
                  ""
                )}
                {modalStatesimport === true ? (
                  <Import
                    modalStates={modalStatesimport}
                    setModalStates={() => {
                      setModalStatesimport(false);
                    }}
                    checkchang={handleCallback}
                  />
                ) : (
                  ""
                )}
                {UpdatemodalStates === true ? (
                  <LeadsUpdate
                    modalStates={UpdatemodalStates}
                    setModalStates={() => {
                      setUpdateModalStates(false);
                    }}
                    checkchang={handleCallback}
                    status={1}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                {viewModalaStates === true ? (
                  <LeadsView
                    modalStates={viewModalaStates}
                    setModalStates={() => {
                      setviewModalaStates(false);
                    }}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                {shiftModal === true ? (
                  <ShiftLeadAdd
                    modalStates={shiftModal}
                    setModalStates={() => {
                      setShiftModal(false);
                    }}
                    checkchang={handleCallback}
                    edit_data={FindData}
                    status={0}
                  />
                ) : (
                  ""
                )}

                {shiftListModal === true ? (
                  <ShiftLeadList
                    modalStates={shiftListModal}
                    setModalStates={() => {
                      setshiftListModal(false);
                    }}
                    status={0}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                {followUpDetails === true ? (
                  <FolllowUpDetails
                    modalStates={followUpDetails}
                    setModalStates={() => {
                      setfollowUpDetails(!followUpDetails);
                    }}
                    status={0}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                <ToastContainer closeButton={false} limit={1} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LeadsList;
