import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Button,
  Form,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
import CustomInput from "../Unit/Input";

const Expencess_CateAdd = (props) => {
  const { https } = AuthUser();
  const [modal, setModal] = useState(false);
  const [Expencess_Cate, SetExpencess_Cate] = useState([]);
  // console.log(Expencess_Cate);

  const Close = () => {
    setModal(false);
    props.setModalStates();
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [checkNameStatus, setCheckStatus] = useState({});
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const SubmitData = () => {
    if (loading) return;
    setLoading(true);

    if (Expencess_Cate == "") {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsg("Expencess_Cate can not be empty!");
    } else {
      https
        .post("/expences_category/store", {
          expences_category_name: Expencess_Cate.expences_category_name,
          expences_category_description: Expencess_Cate.expences_category_description,

        })
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          // Reset loading state to allow further submissions if necessary
          setLoading(false);
        });
    }
  };
  const handleExpencess_Cate = (e) => {
    setCheckStatus({});
    setMsg("");
    SetExpencess_Cate(e.target.value);
  };

  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        props.setModalStates(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    <div>
      <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Create Expencess_Cate
        </ModalHeader>
        <span className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              SubmitData();
              return false;
            }}
            action="#"
          >
            <ModalBody>
              <Card className="border card-border-success p-3 shadow-lg">
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Expencess_Catename-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Expencess_Cate Name<span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "red" }}>{msg}</div>
                      </Label>
                      <CustomInput
                        checkNameStatus={checkNameStatus}
                        name="Expencess_Cate_name"
                        id="Expencess_Cate-field"
                        className="form-control fw-bold"
                        placeholder="Expencess_Cate Name"
                        type="text"
                        // value={Expencess_Cate}
                        onChange={(e) => SetExpencess_Cate({ ...Expencess_Cate, ['expences_category_name']: e.target.value })}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Expencess_Catename-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Expencess_Cate Description<span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "red" }}>{msg}</div>
                      </Label>
                      <Input
                        checkNameStatus={checkNameStatus}
                        name="Expencess_Cate_name"
                        id="Expencess_Cate-field"
                        className="form-control fw-bold"
                        placeholder="Expencess_Cate Description"
                        type="text"
                        // value={Expencess_Cate}
                        onChange={(e) => SetExpencess_Cate({ ...Expencess_Cate, ['expences_category_description']: e.target.value })}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <button
                ref={submitButtonRef}
                name="close"
                id="close"
                type="submit"
                className="btn btn-primary"
              // onClick={() => SubmitData()}
              disabled={loading} // Disable button if loading
              >
                <i className="ri-save-3-line align-bottom me-1"></i>
                {loading ? "Saving..." : "Save"}
              </button>
              <button
                name="close"
                id="close"
                type="button"
                className="btn btn-danger"
                onClick={() => Close()}
              >
                <i className="ri-close-line me-1 align-middle" />
                Close
              </button>

            </div>
          </Form>
        </span>
      </Modal>
    </div>
  );
};

export default Expencess_CateAdd;
