import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Form,
  Button,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";

import Flatpickr from "react-flatpickr";
import PaymentModeAdd from "../PaymentMode/PaymentModeAdd";
import PaymentTypeAdd from "../PaymentType/PaymentType Add";

const PaymentUpdate = (props) => {
  // On form submit getPaymentData imported
  const [modalpaymentmode, setModalpaymentmode] = useState(false);
  const [modalpaymentmode1, setModalpaymentmode1] = useState(false);
  const [counts, Setcounts] = useState(1);
  const handleCallback = (data) => {
    Setcounts(counts + 1);
    toast.success(data);
    setModalpaymentmode(false);
    setModalpaymentmode1(false);
  };
  const [checkNameStatus, setCheckStatus] = useState({});
  const [msg, setMsg] = useState("");
  const [modal, setModal] = useState(false);
  const Close = () => {
    setModal(false);
    props.setModalStates();
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);
  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const { http, https, user } = AuthUser();
  const [Paymentmode, setPaymentmode] = useState([]);
  const [PaymentType, setPaymentType] = useState([]);
  const [Employee, setEmployee] = useState([]);
  const [Payment, setPayment] = useState(props.edit_data);


  useEffect(() => {
    if (Payment.payment_type === 1) {
      setPayment({ ...Payment, user_type: 1 });
    } else if (Payment.payment_type === 2) {
      setPayment({ ...Payment, user_type: 2 });
    }
  }, [Payment.payment_type]);

  const getpaymentmode = async () => {
    http
      .get("/payment_mode/list")
      .then((response) => {
        if (response.data.length > 0) {
          setPaymentmode(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    http
      .get("/payment_type/list")
      .then((response) => {
        if (response.data.length > 0) {
          setPaymentType(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getEmployee = async () => {
    http
      .get("/franchisebusiness/list")
      .then((response) => {
        if (response.data.length > 0) {
          setEmployee(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePayment = (e) => {
    setCheckStatus({});
    setMsg("");
    // setPayment({ ...Payment, payment_type: e.value });
  };
  useEffect(() => {
    getpaymentmode();
    getEmployee();
  }, []);

  const SubmitData = () => {
    if (Payment.payment_type === "") {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsg("Please Fill The Data !");
    } else if (Payment.payment_mode === "") {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsg("Please Fill The Data !");
    } else if (Payment.payment_amount === "") {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsg("Please Fill The Data !");
    } else if (Payment.payment_remark === "") {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsg("Please Fill The Data !");
    } else if (Payment.payment_receiver === "") {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsg("Please Fill The Data !");
    } else {
      http
        .put("/payment/update", Payment)
        .then(function (response) {
          props.checkchang(response.data, 1);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  return (
    <div>
      <Modal id="showModal" size="xl" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Payment Edit
        </ModalHeader>
        <div className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              SubmitData();
              return false;
            }}
            action="#"
          >
            <ModalBody>
              <Card className="border card-border-success  p-3 shadow-lg">
                <div className="mb-3 row">
                  <div className="col-4">
                    <Label
                      htmlFor="Paymentname-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      <div>
                        Date<span style={{ color: "red" }}> *</span>
                      </div>
                    </Label>
                    <Flatpickr
                      className="form-control fw-bold"
                      options={{
                        dateFormat: "d/m/Y",
                        defaultDate: "today", // Set default date to today
                      }}
                      name="payment_date"
                      placeholder="Enter start date"
                      onChange={(selectedDates) => {
                        const selectedDate = selectedDates[0];
                        if (selectedDate) {
                          // Convert to YYYY-MM-DD format
                          const year = selectedDate.getFullYear();
                          const month = (selectedDate.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const day = selectedDate
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const formattedDate = `${year}-${month}-${day} 00:00:00`;
                          setPayment({
                            ...Payment,
                            payment_date: formattedDate,
                          });
                        }
                      }}
                    />
                  </div>

                  <div className="col-4">
                    <Label
                      htmlFor="Paymentname-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      Payment Mode
                      <a
                        className="btn btn-success btn-sm px-1"
                        style={{ padding: "0px" }}
                        onClick={() => setModalpaymentmode(!false)}
                      >
                        <i className="ri-add-line align-bottom"></i>
                      </a>
                    </Label>

                    <Select
                      options={Paymentmode.map((e) => ({
                        value: e.payment_mode_id,
                        label: e.payment_mode_name,
                      }))}
                      name="payment_mode"
                      id="payment_mode"
                      className=" fw-bold"
                      placeholder={Payment.payment_mode_name}
                      onChange={(e) =>
                        setPayment({ ...Payment, payment_mode: e.value })
                      }
                    />
                  </div>
                  <div className="col-4">
                    <Label
                      htmlFor="Paymentname-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      Payment Type <a
                        className="btn btn-success btn-sm px-1"
                        style={{ padding: "0px" }}
                        onClick={() => setModalpaymentmode1(!false)}
                      >
                        <i className="ri-add-line align-bottom"></i>
                      </a>
                    </Label>

                    <Select
                      options={PaymentType.map((e) => ({
                        value: e.payment_type_id,
                        label: e.payment_type_name,

                      }))}
                      name="payment_type"
                      id="payment_type"
                      className=" fw-bold"
                      placeholder={Payment.payment_type_name}

                      // value={Payment.payment_type}
                      // onChange={(selectedOption) => setPayment({ ...Payment, payment_type: selectedOption.value })}
                      onChange={(e) =>
                        setPayment({ ...Payment, payment_type: e.value })
                      }
                    />
                  </div>
                  {Payment.payment_type === 1 ? (
                    <div className="col-4">
                      <Label
                        htmlFor="Paymentname-field"
                        className="form-label mt-2 fw-bold d-flex justify-content-between"
                      >
                        Employee
                        <a
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </a>
                      </Label>

                      <Select
                        options={Employee.filter((fil) => fil.u_type !== 1).map(
                          (e) => ({
                            value: e.uid,
                            label: e.name,
                          })
                        )}
                        name="Payment_city"
                        id="Payment_city"
                        className=" fw-bold"
                        placeholder={Payment.name}
                        onChange={(e) =>
                          setPayment({ ...Payment, payment_receiver: e.value })
                        }
                      />
                    </div>
                  ) : Payment.payment_type !== 1 && Payment.payment_type > 0 ? (
                    <>
                      <div className="col-4">
                        <Label
                          htmlFor="Paymentname-field"
                          className="form-label mt-2 fw-bold"
                        >
                          Receiver Name
                        </Label>
                        <Input
                          onChange={(e) =>
                            setPayment({
                              ...Payment,
                              payment_receiver: e.target.value,
                            })
                          }
                          name="payment_receiver"
                          id="payment_receiver"
                          className="form-control fw-bold"
                          placeholder={Payment.payment_receiver}
                          type="text"
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <div className="col-4">
                    <Label
                      htmlFor="Paymentname-field"
                      className="form-label mt-2 fw-bold"
                    >
                      Amount
                    </Label>
                    <Input
                      onChange={(e) =>
                        setPayment({
                          ...Payment,
                          payment_amount: e.target.value,
                        })
                      }
                      name="payment_amount"
                      id="payment_amount"
                      className="form-control fw-bold"
                      placeholder={Payment.payment_amount}
                      type="text"
                    />
                  </div>
                  <div className="col-3">
                    <Label
                      htmlFor="Paymentname-field"
                      className="form-label mt-2 fw-bold"
                    >
                      Remark
                    </Label>
                    <Input
                      onChange={(e) =>
                        setPayment({
                          ...Payment,
                          payment_remark: e.target.value,
                        })
                      }
                      name="payment_remark"
                      id="payment remark"
                      className="form-control fw-bold"
                      placeholder={Payment.payment_remark}
                      type="text"
                    />
                  </div>
                </div>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <div
                style={{ color: "red", fontSize: "20px" }}
                className="fw-bold"
              >
                {msg}
              </div>

              <div className="hstack gap-2 justify-content-end">
                <button
                  type="submit"
                  name="sumbit"
                  id="submit"
                  className="btn btn-primary"
                  // onClick={() => OnSubmited()}
                  ref={submitButtonRef}
                >
                  <i className="ri-save-3-line align-bottom me-1"></i>
                  Update
                </button>
                <button
                  name="close"
                  id="close"
                  type="button"
                  className="btn btn-danger"
                  onClick={() => Close()}
                >
                  <i className="ri-close-line me-1 align-middle" />
                  Close
                </button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
      {modalpaymentmode === true ? (
        <PaymentModeAdd
          modalStates={modalpaymentmode}
          setModalStates={() => {
            setModalpaymentmode(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {modalpaymentmode1 === true ? (
        <PaymentTypeAdd
          modalStates={modalpaymentmode1}
          setModalStates={() => {
            setModalpaymentmode1(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default PaymentUpdate;
