import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Button,
  Form,
} from "reactstrap";

import Flatpickr from "react-flatpickr";

import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
import CustomInput from "../Unit/Input";
import Select from "react-select";
import LeadStatusAdd from "../LeadStatus/LeadStatusAdd";
import SourceAdd from "../Source/SourceAdd";
import CustomerAdd from "../Customers/CustomerAdd";
import RefrencesAdd from "../Refrences/RefrencesAdd";
import ProductAdd from "../Product/ProductAdd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import D_img from "../D_img";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { API_URL } from "../../helpers/url_helper";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { KeyRounded } from "@mui/icons-material";

const TaskAdd = (props) => {
  const [assignMoreTasks, setAssignMoreTasks] = useState(false); // Track switch state
  const [manageRepeat, setManageRepeat] = useState(false);
  const [frequency, setFrequency] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [daysOfWeek, setDaysOfWeek] = useState([]);

  const handleFrequencyChange = (selectedOption) => {
    setFrequency(selectedOption.value);
    setTask({
      ...taskData,
      repeat_category: selectedOption.value,
      repeat_every_day: selectedOption.value === "Weekly" ? daysOfWeek : [], // Set daysOfWeek for Weekly
    });
  };
  const handleDateChange = (selectedDates) => {
    setTask({
      ...taskData,
      repeat_startDate: selectedDates[0],
      repeat_every_day: frequency === "Monthly"
        ? selectedDates.map((date) => date.getDate()) // Store day numbers for Monthly frequency
        : taskData.repeat_every_day, // For other frequencies, keep existing days
    });
  };

  const handleDayChange = (day) => {
    const updatedDays = daysOfWeek.includes(day)
      ? daysOfWeek.filter((d) => d !== day)
      : [...daysOfWeek, day];

    setDaysOfWeek(updatedDays);

    // Update taskData to store the selected days for the repeat_every_day field
    setTask({
      ...taskData,
      repeat_every_day: `[${updatedDays.join(", ")}]`, // Join the days array as a string for consistent format
    });
  };






  const { http, https, user } = AuthUser();
  const [statusData, setstatusData] = useState([]);
  const getstatusData = async () => {
    http
      .get("/lead/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageLeadStatus === 1) {
            setTask({
              ...taskData,
              status: res.data[res.data.length - 1].lead_status_id,
            });
          } else {
            setTask({
              ...taskData,
              status: res.data[0].lead_status_id,
            });
          }
          setstatusData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(statusData[0]);


  const [tempValid, settempValid] = useState(0);
  const [modal, setModal] = useState(false);

  const [tc, settc] = useState(false);
  const [tempRe, settempRe] = useState({
    temp_num: "0",
    temp_label: "Daily",
  });
  const [audioBlob, setAudioBlob] = useState(null); // State for audio blob
  const recorderControls = useAudioRecorder();

  const [taskData, setTask] = useState({
    department: user.user.u_type != 1 || user.user.u_type != 3 || user.user.u_type != 5 ? user.user.user_role_id : 0,
    customer: "",
    task_title: "",
    product: 1,
    value: 0,
    today_date: new Date(),
    minimum_due_date: new Date(),
    ref_by: 1,
    maximum_due_date: new Date(),
    next_follow_up: new Date(),
    source: 1,
    priority: 1,
    description: "",
    assigned_by: user.user.u_type === 1 ? "[]" : `[${user.user.uid}]`,
    tags: "[]",
    status: 1,
    image: [],
    repeat_startDate: new Date(),
    repeat_every_day: '[]',
    total_cycles: "",
    task_created_by: user.user.uid,
  });
  console.log(taskData);

  const addAudioElement = (blob) => {
    const uniqueFileName = `recording_${Date.now()}_${Math.floor(Math.random() * 10000)}.mp3`;

    // Create a new File object from the blob with the random file name
    const mp3File = new File([blob], uniqueFileName, { type: 'audio/mp3' });

    // Update the audio blob state and leadsData with the mp3 file
    setAudioBlob(mp3File);
    setTask({
      ...taskData,
      audio_file: mp3File // Add the mp3 file to leadsData
    });
  };

  const [selectedOptions, setSelectedOptions] = useState([]);

  const options = [
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Yearly", label: "Yearly" },
    // { value: "Custom", label: "Custom" },
  ];


  const [weekDays, setWeekDays] = useState([]);
  const handleWeekdayChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setWeekDays([...weekDays, value]); // Add selected day to the list
    } else {
      setWeekDays(weekDays.filter((day) => day !== value)); // Remove unselected day
    }

    // Update your taskData or tempRe as needed based on the weekDays array
  };



  const [selectedImageNames, setSelectedImageNames] = useState([]); // State for storing selected image names
  const [selectedDocName, setSelectedDocName] = useState('');
  const [selectedVideoName, setSelectedVideoName] = useState('');
  const [selectedVideo, setSelectedVideo] = useState(null);


  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    // Update the taskData with the selected images
    setTask({
      ...taskData,
      image: [...taskData.image, ...selectedFiles],
    });

    // Store the selected images for preview display
    setSelectedImages([...selectedImages, ...selectedFiles]);
  };


  const handleRemoveImage = (index) => {
    const updatedImages = [...taskData.image];
    updatedImages.splice(index, 1);

    setTask({
      ...taskData,
      image: updatedImages,
    });
  };
  const [EmployeeDoc, SetEmployeeDoc] = useState("");
  const [EmployeeVideo, SetEmployeeVideo] = useState("");
  const [manageSource, setmanageSource] = useState(0);
  const [manageProduct, setmanageProduct] = useState(0);
  const [manageCustomer, setmanageCustomer] = useState(0);
  const [manageLeadStatus, setmanageLeadStatus] = useState(0);
  const [manageReferBy, setmanageReferBy] = useState(0);

  const [statusModal, setstatusModal] = useState(false);
  const [productModal, setproductModal] = useState(false);
  const [sourceModal, setsourceModal] = useState(false);
  const [customerModal, setcustomerModal] = useState(false);
  const [referBy, setreferBy] = useState(false);

  const priorityData = [
    { label: "Low", value: "1" },
    { label: "Medium", value: "2" },
    { label: "High", value: "3" },
    { label: "Urgent", value: "4" },
  ];

  const [count, setCount] = useState(0);
  const handleCallback = (data, status) => {
    if (status == 1) {
      toast.success(data);
      setCount(count + 1);
    } else {
      toast.warn(data);
      setCount(count + 1);
    }
    getcustomerData();
    getproductData();
    getreferByData();
    getstatusData();
    gettagData();
    getAssignedBy();
    getcustomerData();
    getsourceData();
  };

  const [tagData, settagData] = useState([]);
  const gettagData = async () => {
    http
      .get("/tags/list")
      .then((res) => {
        if (res.data.length > 0) {
          settagData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [referByData, setreferByData] = useState([]);
  const getreferByData = async () => {
    http
      .get("/reference/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageReferBy === 1) {
            setTask({
              ...taskData,
              ref_by: res.data[res.data.length - 1].reference_id,
            });
          }
          setreferByData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [productData, setproductData] = useState([]);
  const getproductData = async () => {
    http
      .get("/product/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageProduct === 1) {
            setTask({
              ...taskData,
              product: res.data[res.data.length - 1].product_id,
            });
          }
          setproductData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [sourceData, setsourceData] = useState([]);
  const getsourceData = async () => {
    http
      .get("/source/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageSource === 1) {
            setTask({
              ...taskData,
              source: res.data[res.data.length - 1].source_id,
            });
          }
          setsourceData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [customerData, setcustomerData] = useState([]);
  const getcustomerData = async () => {
    http
      .get("/customer/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageCustomer === 1) {
            setTask({
              ...taskData,
              customer: res.data[res.data.length - 1].customer_id,
            });
          }

          setcustomerData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [selectedRoleIds, setSelectedRoleIds] = useState((user.user.u_type === 2 && user.user.user_behave == 1) ? user.user.user_role_id : null);
  const [selectedRoleUserIds, setSelectedRoleUserIds] = useState([]);
  const [statuscheckforAssignedBy, setstatuscheckforAssignedBy] = useState(0);
  const [assignedBy, setassignedBy] = useState([]);
  const getAssignedBy = async () => {
    try {

      const res = await http.get("/user/list");
      const filteredEmployee = res.data.filter(
        (e) => e.u_type !== 3 && e.u_type !== 5
      );
      if (selectedRoleIds !== null) {
        console.log("Checing Data: ", res.data);

        const filteredEmployee = res.data.filter(
          (e) => e.u_type !== 3 && e.u_type !== 5 && (e.user_role_id === selectedRoleIds || e.role_created_for == selectedRoleIds)

        );
        setassignedBy(filteredEmployee);
      } else {
        setassignedBy(filteredEmployee);

      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAssignedBy();
  }, [statuscheckforAssignedBy])
  const [role, setRole] = useState([]);
  const getRole = async () => {
    http.get("/role/list")
      .then(function (response) {

        const filteredData = user && user.user.u_type === 1 || user.user.u_type === 2
          ? response.data.filter(
            (role) => parseInt(role.role_created_user_id) === user.user.user_user_id || parseInt(role.role_created_user_id) === user.user.uid
          ) :

          response.data.filter(
            (role) => parseInt(role.role_created_user_id) === user.user.uid
          );
        console.log(filteredData);
        setRole(filteredData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getRole();
  }, []);

  const [message, setmessage] = useState([]);
  const getMessageList = async () => {
    http
      .get("/message/list")
      .then((res) => {
        if (res.data.length > 0) {
          setmessage(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getcustomerData();
    getproductData();
    getreferByData();
    getstatusData();
    gettagData();
    // getassignedBy();
    getMessageList();
    getsourceData();
  }, [count]);

  const [Selecteduser, SetSelectedUser] = useState([]);
  useEffect(() => {
    // Parse the string representation of the array into an actual array of IDs
    const assignedByArray = JSON.parse(taskData.assigned_by);

    // Use map to find the corresponding user for each ID
    const users = assignedByArray.map((userId) =>
      assignedBy.find((user) => user.uid === userId)
    );

    // Set the state with the array of selected users
    SetSelectedUser(users.filter(Boolean)); // Filter out undefined values
  }, [taskData.assigned_by, assignedBy]);

  const Close = () => {
    setModal(false);
    props.setModalStates();
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);
  const [smsStatus, SetsmsStatus] = useState(false);


  const [loading, setLoading] = useState(false);
  const SubmitData = () => {
    if (loading) return;
    if (taskData.task_title == "") {
      settempValid(2);
    }
    else if (taskData.assigned_by === "[]") {
      settempValid(1);
    } else {
      // setLoading(true);

      console.log(taskData);

      https
        .post("/task/store", taskData)
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
          if (smsStatus === true) {
            sendSMS();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });

      if (assignMoreTasks == false) {
        props.setModalStates(false);
      } else {
        setTask({
          ...taskData,
          department:
            user.user.u_type !== 1 || user.user.u_type !== 3 || user.user.u_type !== 5
              ? user.user.user_role_id
              : 0,
          // repeat_every_day: `[${taskData.repeat_every_day}]`, // Ensure repeat_every_day is sent as an array
          assigned_by: user.user.u_type === 1 ? "[]" : `[${user.user.uid}]`,
          task_created_by: user.user.uid,
        });
      }
    }
  };



  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        props.setModalStates(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const [tempCustomer, settempCustomer] = useState(0);

  const sendSMS = async () => {
    // Ensure that Selecteduser is not an empty array
    if (Selecteduser.length === 0) {
      console.error("No selected user to send SMS");
      return;
    }

    const instance = "XtZCQYa7U4M1vpD";
    const media =
      "https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg";
    const apikey = "1e02603b909287d419e289997344ca8769fc00cb";

    const url = `${API_URL}/send-msg`;
    try {
      // Iterate over each user in Selecteduser array
      for (const user of Selecteduser) {
        const number = user.mobile_no;
        let msg = "";
        message.forEach((message) => {
          // Check if message_status is equal to 1
          if (message.message_status === 1) {
            msg = message.message_description;
          } else {
            msg = "";
          }
        });

        const data = new URLSearchParams();
        data.append("number", number);
        data.append("media", media);
        data.append("msg", msg);
        data.append("instance", instance);
        data.append("apikey", apikey);

        const response = await axios.post(url, data, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          timeout: 100000,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => { }, []);



  const [isDescriptionListening, setIsDescriptionListening] = useState(false);


  const {
    transcript,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  // Mapping spoken numbers and symbols to digits and symbols
  const numberAndSymbolMapping = {
    zero: "0",
    one: "1",
    two: "2",
    three: "3",
    four: "4",
    five: "5",
    six: "6",
    seven: "7",
    eight: "8",
    nine: "9",
    dot: ".",
    comma: ",",
    dash: "-",
    plus: "+",
    at: "@",
    hashtag: "#",
    dollar: "$",
    percent: "%",
    ampersand: "&",
    asterisk: "*",
    exclamation: "!",
    question: "?",
    slash: "/",
    backslash: "\\",
  };

  // Convert spoken numbers and symbols to digits/symbols
  const convertSpokenNumbersToDigits = (text) => {
    return text
      .split(" ")
      .map((word) => numberAndSymbolMapping[word.toLowerCase()] || word)
      .join(" ");
  };

  // Update description field based on voice input
  useEffect(() => {
    if (isDescriptionListening && transcript) {
      const convertedTranscript = convertSpokenNumbersToDigits(transcript);
      setTask((prevData) => ({
        ...prevData,
        description: prevData.description + " " + convertedTranscript, // Append voice input to description
      }));
      resetTranscript(); // Clear transcript after appending
    }
  }, [transcript, isDescriptionListening]);

  // Toggle microphone button
  useEffect(() => {
    if (isDescriptionListening) {
      SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
    } else {
      SpeechRecognition.stopListening();
    }
  }, [isDescriptionListening]);

  // Handle microphone button click
  const handleDescriptionMicClick = () => {
    setIsDescriptionListening(!isDescriptionListening); // Toggle mic on/off
  };

  if (!browserSupportsSpeechRecognition) {
    return <div>Your browser does not support speech recognition.</div>;
  }

  return (
    <div>
      <Modal
        className="modal-md" // Change size class to smaller one
        id="fullscreeexampleModal"
        isOpen={modal}
        size={"lg"} // Change size from "xl" to "md" for medium size
        toggle={toggle}
        centered
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            SubmitData();
            return false;
          }}
          action="#"
        >
          <ModalHeader className="bg-light p-3" toggle={toggle}>
            Create Task
          </ModalHeader>
          <span className="tablelist-form">
            <ModalBody style={{ maxHeight: "80vh", overflowY: "auto" }}>
              <Card className="border card-border-success p-3 shadow-lg">
                <div className="mb-3">
                  <Row>

                    <Col lg={12}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between "
                      >
                        <div>Task Title <span className="text-danger fw-bold"> *</span> </div>
                        <div className="fw-bold text-danger"> {tempValid == 2 ? "Task Title Cannot be empty! " : ""} </div>
                      </Label>
                      <CustomInput
                        name="task_title"
                        id="task_title"
                        className="form-control fw-bold"
                        placeholder="TITLE"
                        value={taskData.task_title} // Bind the input to taskData.task_title
                        onChange={(e) => {
                          setTask({
                            ...taskData,
                            task_title: e.target.value, // Update task_title field
                          });
                        }}
                        type="text"
                      />
                    </Col>
                    <Col lg={12}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between mt-3"
                      >
                        <div>
                          Description
                        </div>
                        <div className="text-danger">
                          {tempValid === 3 ? "Description cannot be empty!" : ""}
                        </div>
                      </Label>

                      {/* Microphone Button */}
                      <Button
                        type="button"
                        color="none"
                        style={{
                          position: "absolute",
                          top: "70%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          width: "50px",
                          height: "50px",
                          padding: "0",
                          borderRadius: "50%",
                          zIndex: "10",
                          background: "none", // No background
                          border: "none", // No border
                          boxShadow: "none", // No shadow
                        }}
                        onClick={handleDescriptionMicClick} // Toggle microphone on click
                      >
                        <i
                          className={
                            isDescriptionListening
                              ? "ri-stop-circle-fill align-bottom" // Icon when listening
                              : "ri-mic-fill align-bottom" // Icon when not listening
                          }
                        ></i>
                      </Button>

                      {/* Simple Textarea for description */}
                      <textarea
                        id="description"
                        name="description"
                        className="form-control"
                        placeholder="Short description of the task"
                        value={taskData.description} // Controlled value from taskData
                        onChange={(e) => {
                          settempValid(0); // Reset validation
                          setTask({
                            ...taskData,
                            description: e.target.value, // Update taskData with new description
                          });
                        }}
                        style={{ height: "130px", resize: "none" }} // Adjust height and disable resize
                      />
                    </Col>

                    {user.user.u_type === 1 || user.user.u_type === 3 ? (
                      <Col lg={6}>
                        <Label
                          htmlFor="categoryname-field"
                          className="form-label fw-bold d-flex justify-content-between mt-3 "
                        >
                          <div>
                            Department<span className="text-danger"> *</span>
                          </div>
                          <div style={{ color: "red" }}>
                            {tempValid === 1 ? "Please select the assigned!" : ""}
                          </div>
                        </Label>
                        <Select
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused ? 'white' : 'white',
                            }),
                             
                          }}
                          className={tempValid === 1 ? "border border-danger" : ""}
                          options={role.map((e) => ({
                            value: e.role_id,
                            label: e.role_name,
                          }))} // Map the role data to options
                          onChange={(e) => {
                            setTask({
                              ...taskData,
                              department: e.value
                            })
                            setSelectedRoleIds(e.value); // Update the selected role ID(s)
                            setstatuscheckforAssignedBy(statuscheckforAssignedBy + 1)
                          }}
                        />
                      </Col>
                    ) : (<></>)}
                    <Col lg={6}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        <div>
                          Team Member<span className="text-danger"> *</span>
                        </div>
                        <div style={{ color: "red" }}>
                          {tempValid === 1 ? "Please select the assigned!" : ""}
                        </div>
                      </Label>
                      {user.user.u_type === 1 || (user.user.u_type === 2 && user.user.user_behave == 1) || (user.user.u_type === 4 && user.user.user_behave == 1) || user.user.u_type === 3 ? (
                        <Select
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused ? 'white' : 'white',

                            }),
                          }}
                          options={[
                            {
                              label: 'Managers', // Group heading for Managers
                              options: assignedBy
                                .filter(e => e.user_behave === 1 && (selectedRoleUserIds.length === 0 || selectedRoleUserIds.includes(e.uid.toString())))
                                .map((e, i) => ({
                                  value: e.uid,
                                  label: e.name, // Show the name under Managers group
                                  index: i,
                                })),
                            },
                            {
                              label: 'Employees', // Group heading for Employees
                              options: assignedBy
                                .filter(e => e.user_behave === 0 && (selectedRoleUserIds.length === 0 || selectedRoleUserIds.includes(e.uid.toString())))
                                .map((e, i) => ({
                                  value: e.uid,
                                  label: e.name, // Show the name under Employees group
                                  index: i,
                                })),
                            },
                          ]}
                          isMulti
                          onChange={(e) => {
                            if (e) {
                              // Extract values from selected options and store in an array
                              const selectedValues = e.map(
                                (option) => option.value
                              );
                              setTask({
                                ...taskData,
                                assigned_by: `[${selectedValues.join(",")}]`,
                              });
                            }
                          }}
                        />
                      ) : (
                        <Input
                          readonly
                          disabled
                          className="fw-bold"
                          value={user.user.name}
                        />
                      )}
                    </Col>
                    {/* <Col lg={6}> */}
                    {/* <Label htmlFor="categoryname-field"className="form-label fw-bold d-flex justify-content-between mt-3 "> */}
                    {/* <div>Status</div> */}
                    {/* <button
                          type="button"
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => {
                            setmanageLeadStatus(1);
                            setstatusModal(!statusModal);
                          }}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </button> */}
                    {/* </Label> */}
                    {/* <Input readOnly value={"Pending"} className="fw-bold"/> */}
                    {/* <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        options={statusData.filter((temp)=>temp.lead_status_id != 2).map((e, i) => ({
                          value: e.lead_status_id,
                          label: e.lead_status_name,
                        }))}
                        className="fw-bold"
                        name="task_status"
                        id="task_status"
                        onChange={(e) => {
                          setTask({
                            ...taskData,
                            status: e.value,
                          });
                        }}
                        placeholder={
                          statusData.length != 0
                            ? manageLeadStatus == 0
                              ? statusData[0].lead_status_name
                              : statusData[statusData.length - 1]
                                .lead_status_name
                            : ""
                        }
                      /> */}
                    {/* </Col> */}

                    {/* <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Source</div>
                        <button
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => {
                            setmanageSource(1);
                            setsourceModal(!sourceModal);
                          }}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </button>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        placeholder={
                          sourceData.length !== 0
                            ? manageSource == 0
                              ? sourceData[0].source_name
                              : sourceData[sourceData.length - 1].source_name
                            : ""
                        }
                        options={sourceData.map((e, i) => ({
                          value: e.source_id,
                          label: e.source_name,
                        }))}
                        onChange={(e) => {
                          setTask({
                            ...taskData,
                            source: e.value,
                          });
                        }}
                        className="fw-bold"
                        name="source"
                        id="source"
                      />
                    </Col> */}

                    {/* <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>
                          Customer
                        </div>
                        <div style={{ color: "red" }}>
                          {tempValid === 2 ? "Select the customer!" : ""}
                          <button
                            className="btn btn-success btn-sm px-1"
                            style={{ padding: "0px" }}
                            onClick={() => {
                              setcustomerModal(!customerModal);
                            }}
                          >
                            <i className="ri-add-line align-bottom"></i>
                          </button>
                        </div>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        className={
                          tempValid === 2
                            ? "fw-bold border border-danger"
                            : "fw-bold"
                        }
                        name="customer"
                        id="customer"
                        placeholder={"Customer "}
                        onChange={(e) => {
                          settempValid(0);
                          setmanageCustomer(1);
                          settempCustomer(e.index);
                          setTask({
                            ...taskData,
                            customer: e.value,
                          });
                        }}
                        options={customerData.map((e, i) => ({
                          value: e.customer_id,
                          label: e.customer_name,
                          index: i,
                        }))}
                      />
                    </Col> */}
                    {/* <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Phone</div>
                      </Label>
                      <Input
                        readOnly
                        name="phone"
                        id="phone"
                        className="form-control fw-bold"
                        value={
                          manageCustomer !== 0
                            ? customerData[tempCustomer].customer_whatsapp_no
                            : ""
                        }
                        type="text"
                        disabled
                      />
                    </Col> */}
                    {/* <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Email</div>
                      </Label>
                      <Input
                        readOnly
                        name="email"
                        id="email"
                        className="form-control fw-bold"
                        placeholder="Email"
                        type="text"
                        value={
                          manageCustomer !== 0
                            ? customerData[tempCustomer].customer_email
                            : ""
                        }
                        disabled
                      />
                    </Col> */}
                    {/* <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>City</div>
                      </Label>
                      <Input
                        readOnly
                        name="city"
                        id="city"
                        className=" fw-bold "
                        placeholder="City"
                        type="text"
                        value={
                          manageCustomer !== 0
                            ? customerData[tempCustomer].city_name
                            : ""
                        }
                        disabled
                      />
                    </Col> */}
                    {/* <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Refer By</div>
                        <button
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => {
                            setmanageReferBy(1);
                            setreferBy(!referBy);
                          }}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </button>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        className="fw-bold"
                        name="ref_by"
                        id="ref_by"
                        placeholder={
                          referByData.length != 0
                            ? manageReferBy == 0
                              ? referByData[0].reference_name
                              : referByData[referByData.length - 1]
                                .reference_name
                            : ""
                        }
                        onChange={(e) => {
                          setTask({
                            ...taskData,
                            ref_by: e.value,
                          });
                        }}
                        options={referByData.map((e, i) => ({
                          value: e.reference_id,
                          label: e.reference_name,
                        }))}
                      />
                    </Col> */}
                    {/* <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Product</div>
                        <button
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => {
                            setmanageProduct(1);
                            setproductModal(!productModal);
                          }}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </button>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        className="fw-bold"
                        name="product"
                        id="product"
                        placeholder={
                          productData != 0
                            ? manageProduct == 0
                              ? productData[0].product_name
                              : productData[productData.length - 1].product_name
                            : ""
                        }
                        onChange={(e) => {
                          setTask({
                            ...taskData,
                            product: e.value,
                          });
                        }}
                        options={productData.map((e, i) => ({
                          value: e.product_id,
                          label: e.product_name,
                        }))}
                      />
                    </Col> */}
                    {/* <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Value</div>
                      </Label>
                      <Input
                        name="value"
                        id="value"
                        className="form-control fw-bold"
                        placeholder="0"
                        onChange={(e) => {
                          setTask({
                            ...taskData,
                            value: e.target.value,
                          });
                        }}
                        type="text"
                      />
                    </Col> */}
                    {/* <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Tags</div>
                      </Label>
                      {tagData.length !== 0 ? (
                        <Select
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused ? 'white' : 'white',

                            }),
                          }}
                          options={tagData.map((e, i) => ({
                            label: e.tag_name,
                            value: e.tag_id,
                            index: i,
                          }))}
                          isMulti
                          // value={selectedOptions}
                          onChange={(e) => {
                            if (e) {
                              // Extract values from selected options and store in an array
                              const selectedValues = e.map(
                                (option) => option.value
                              );
                              setTask({
                                ...taskData,
                                tags: `[${selectedValues.join(",")}]`,
                              });
                            }
                          }}
                        />
                      ) : (
                        <span className="fw-bold text-danger">
                          No tags added
                        </span>
                      )}
                    </Col> */}

                    {/* <Col lg={2}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Total Cycles</div>
                      </Label>

                      <div className="d-flex   align-items-center">
                        {" "}
                        <Col lg={2} className="text-center ">
                          <Input
                            type="checkbox"
                            onClick={(e) => {
                              if (e.target.checked) {
                                settc(!tc);
                                setTask({
                                  ...taskData,
                                  total_cycles: "Infinity",
                                });
                              } else {
                                settc(!tc);
                                setTask({
                                  ...taskData,
                                  total_cycles: 0,
                                });
                              }
                            }}
                          ></Input>{" "}
                        </Col>
                        <Col lg={10}>
                          <Input
                            disabled={tc}
                            className="fw-bold"
                            placeholder="0"
                            value={taskData.total_cycles}
                            onChange={(e) => {
                              setTask({
                                ...taskData,
                                total_cycles: e.target.value,
                              });
                            }}
                          />
                        </Col>
                      </div>
                    </Col> */}
                    <Col lg={6}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between mt-3"
                      >
                        <div>Start Date</div>
                      </Label>
                      <Flatpickr
                        data-enable-time
                        className="form-control fw-bold"
                        options={{
                          dateFormat: "d/m/Y h:i K",  // Use 'h:i K' for 12-hour time format with AM/PM
                          enableTime: true,           // Enable time selection
                          time_12hr: true,            // Enable 12-hour time with AM/PM
                          defaultDate: (() => {
                            // Get the current date and time in UTC
                            const currentDate = new Date();

                            // Convert to Indian Standard Time (UTC+5:30)
                            const utcOffset = currentDate.getTimezoneOffset() * 60 * 1000; // Device timezone offset in milliseconds
                            const ISTOffset = 5.5 * 60 * 60 * 1000;  // IST offset in milliseconds (5 hours 30 minutes ahead of UTC)

                            // Adjust the current date to IST
                            const istDate = new Date(currentDate.getTime() + ISTOffset + utcOffset);

                            return istDate; // Return IST date as the default
                          })(),
                        }}
                        onChange={(selectedDates) => {
                          if (selectedDates.length > 0) {
                            const selectedDate = selectedDates[0];

                            // Convert the selected date to IST
                            const utcOffset = selectedDate.getTimezoneOffset() * 60 * 1000; // Timezone offset in milliseconds
                            const ISTOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds (5 hours 30 minutes ahead of UTC)
                            const istDate = new Date(selectedDate.getTime() + ISTOffset + utcOffset);

                            // Extract date components
                            const day = istDate.getDate().toString().padStart(2, "0");
                            const month = (istDate.getMonth() + 1).toString().padStart(2, "0");
                            const year = istDate.getFullYear();

                            // Extract time components for 12-hour format with AM/PM
                            const hours = istDate.getHours() % 12 || 12;  // 12-hour format
                            const minutes = istDate.getMinutes().toString().padStart(2, "0");
                            const ampm = istDate.getHours() >= 12 ? "PM" : "AM";  // AM/PM indicator

                            // Format the date and time as "YYYY-MM-DD h:i AM/PM"
                            const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;

                            // Set the task data with the formatted date and time
                            setTask({
                              ...taskData,
                              minimum_due_date: formattedDateTime,
                            });
                          } else {
                            console.log("Date not found.");
                          }
                        }}
                        name="date"
                      />

                    </Col>
                    {/* <Col lg={6}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>End Date</div>
                      </Label>
                      <Flatpickr
                        className="form-control fw-bold  shadow-sm "
                        options={{
                          dateFormat: "d/m/Y h:i K", // Use 'h:i K' for 12-hour time with AM/PM
                          enableTime: true,          // Enable time selection
                          time_12hr: true,           // Enable 12-hour time format with AM/PM
                          defaultDate: taskData.next_follow_up,
                        }}
                        onChange={(selectedDates) => {
                          const selectedDate = selectedDates[0];

                          // Extract date components
                          const day = selectedDate.getDate().toString().padStart(2, "0");
                          const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
                          const year = selectedDate.getFullYear();

                          // Extract time components
                          const hours = selectedDate.getHours() % 12 || 12; // 12-hour format
                          const minutes = selectedDate.getMinutes().toString().padStart(2, "0");
                          const ampm = selectedDate.getHours() >= 12 ? "PM" : "AM"; // AM/PM indicator

                          // Format the date and time as "YYYY-MM-DD h:i AM/PM"
                          const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;

                          // Set the task data with both date and time
                          setTask({
                            ...taskData,
                            next_follow_up: formattedDateTime,
                          });
                        }}
                        name="date"
                      />
                    </Col> */}

                    <Col lg={6}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>End Date</div>
                      </Label>
                      <Flatpickr
                        data-enable-time
                        className="form-control fw-bold"
                        options={{
                          dateFormat: "d/m/Y h:i K", // Use 'h:i K' for 12-hour time with AM/PM
                          enableTime: true,          // Enable time selection
                          time_12hr: true,           // Use 12-hour format
                          defaultDate: (() => {
                            // Get the current date and time
                            const currentDate = new Date();

                            // Convert to Indian Standard Time (UTC+5:30)
                            const utcOffset = currentDate.getTimezoneOffset() * 60 * 1000; // Device timezone offset in milliseconds
                            const ISTOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds (5 hours 30 minutes ahead of UTC)

                            // Adjust the current date to IST
                            const istDate = new Date(currentDate.getTime() + ISTOffset + utcOffset);

                            return istDate; // Return the date adjusted to IST
                          })(),
                        }}
                        onChange={(selectedDates) => {
                          if (selectedDates.length > 0) {
                            const selectedDate = selectedDates[0];

                            // Convert the selected date to IST
                            const utcOffset = selectedDate.getTimezoneOffset() * 60 * 1000; // Timezone offset in milliseconds
                            const ISTOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds (5 hours 30 minutes ahead of UTC)
                            const istDate = new Date(selectedDate.getTime() + ISTOffset + utcOffset);

                            // Extract date components
                            const day = istDate.getDate().toString().padStart(2, "0");
                            const month = (istDate.getMonth() + 1).toString().padStart(2, "0");
                            const year = istDate.getFullYear();

                            // Extract time components for 12-hour format with AM/PM
                            const hours = istDate.getHours() % 12 || 12; // 12-hour format
                            const minutes = istDate.getMinutes().toString().padStart(2, "0");
                            const ampm = istDate.getHours() >= 12 ? "PM" : "AM"; // AM/PM indicator

                            // Format the date and time as "YYYY-MM-DD h:i AM/PM"
                            const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;

                            // Set the task data with the formatted date and time
                            setTask({
                              ...taskData,
                              maximum_due_date: formattedDateTime,
                            });
                          } else {
                            console.log("Date not found.");
                          }
                        }}
                        name="date"
                      />

                    </Col>
                    <Col lg={6}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Next Follow Up Date</div>
                      </Label>
                      <Flatpickr
                        data-enable-time
                        className="form-control fw-bold shadow-sm"
                        options={{
                          dateFormat: "d/m/Y h:i K", // Use 'h:i K' for 12-hour time with AM/PM
                          enableTime: true,          // Enable time selection
                          time_12hr: true,           // Enable 12-hour time format with AM/PM
                          defaultDate: (() => {
                            // Get the current date and time
                            const currentDate = new Date();

                            // Convert to Indian Standard Time (UTC+5:30)
                            const utcOffset = currentDate.getTimezoneOffset() * 60 * 1000; // Timezone offset in milliseconds
                            const ISTOffset = 5.5 * 60 * 60 * 1000;  // IST offset in milliseconds (5 hours 30 minutes ahead of UTC)

                            // Adjust the current date to IST
                            const istDate = new Date(currentDate.getTime() + ISTOffset + utcOffset);

                            return istDate;
                          })(),
                        }}
                        onChange={(selectedDates) => {
                          if (selectedDates.length > 0) {
                            const selectedDate = selectedDates[0];

                            // Convert the selected date to IST
                            const utcOffset = selectedDate.getTimezoneOffset() * 60 * 1000; // Timezone offset in milliseconds
                            const ISTOffset = 5.5 * 60 * 60 * 1000;  // IST offset in milliseconds (5 hours 30 minutes ahead of UTC)
                            const istDate = new Date(selectedDate.getTime() + ISTOffset + utcOffset);

                            // Extract date components
                            const day = istDate.getDate().toString().padStart(2, "0");
                            const month = (istDate.getMonth() + 1).toString().padStart(2, "0");
                            const year = istDate.getFullYear();

                            // Extract time components
                            const hours = istDate.getHours() % 12 || 12; // 12-hour format
                            const minutes = istDate.getMinutes().toString().padStart(2, "0");
                            const ampm = istDate.getHours() >= 12 ? "PM" : "AM"; // AM/PM indicator

                            // Format the date and time as "YYYY-MM-DD h:i AM/PM"
                            const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;

                            // Set the task data with both date and time
                            setTask({
                              ...taskData,
                              next_follow_up: formattedDateTime,
                            });
                          }
                        }}
                        name="date"
                      />

                    </Col>
                    <Col lg={6}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        <div>Priority</div>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        className="fw-bold"
                        name="priority"
                        id="priority"
                        options={priorityData.map((e, i) => ({
                          value: e.value,
                          label: e.label,
                        }))}
                        onChange={(e) => {
                          setTask({
                            ...taskData,
                            priority: e.value,
                          });
                        }}
                        placeholder={priorityData[0].label}
                      />
                    </Col>
                    <Col lg={6}>
                      <Label htmlFor="categoryname-field" className="w-100 form-label fw-bold mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-repeat me-2"></i>
                            <span>Repeat</span>
                          </div>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="repeatCheckbox"
                            checked={manageRepeat}
                            onChange={() => setManageRepeat(!manageRepeat)}
                          />
                        </div>

                        {/* Show Select Dropdown if Repeat is Enabled */}
                        {manageRepeat && (
                          <div className="mt-3">
                            <Select
                              className="me-2 shadow-sm"
                              options={options}
                              onChange={handleFrequencyChange}
                              placeholder="Select Frequency"
                            />
                          </div>
                        )}

                        {/* Frequency-based Inputs */}
                        {manageRepeat && (
                          <div className="mt-4 bg-light p-3 rounded">
                            {/* Weekly Frequency */}
                            {frequency === "Weekly" && (
                              <>
                                <div className="d-flex justify-content-evenly mb-3">
                                  {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
                                    <div key={day} className="text-center">
                                      <label>{day}</label>
                                      <br />
                                      <input
                                        type="checkbox"
                                        value={day}
                                        className="form-input-check"
                                        checked={daysOfWeek.includes(day)}  // This will reflect the current state
                                        onChange={() => handleDayChange(day)}  // Handle change when the checkbox is toggled
                                      />
                                    </div>
                                  ))}
                                </div>


                                <Label
                                  htmlFor="categoryname-field"
                                  className="form-label fw-bold d-flex justify-content-between mt-3"
                                >
                                  <div>Start Date</div>
                                </Label>
                                <Flatpickr
                                  className="form-control fw-bold shadow-sm"
                                  options={{
                                    dateFormat: "d/m/Y h:i K",
                                    enableTime: true,
                                    time_12hr: true,
                                    defaultDate: taskData.repeat_startDate,
                                  }}
                                  onChange={handleDateChange}
                                  name="date"
                                />
                              </>
                            )}

                            {/* Monthly Frequency */}
                            {frequency === "Monthly" && (
                              <>
                                <Label
                                  htmlFor="categoryname-field"
                                  className="form-label fw-bold d-flex justify-content-between mt-3"
                                >
                                  <div>Select Dates</div>
                                </Label>
                                <Flatpickr
                                  className="form-control fw-bold shadow-sm mb-3"
                                  options={{
                                    mode: "multiple",
                                    dateFormat: "d",
                                    inline: true,
                                    maxDate: "2024-01-28",
                                    defaultDate: "2024-01-01",
                                  }}
                                  onChange={(selectedDates) => {
                                    setTask({
                                      ...taskData,
                                      repeat_every_day: `[${selectedDates.map((date) => date.getDate()).join(", ")}]`,
                                    });
                                  }}
                                />
                                <Label
                                  htmlFor="categoryname-field"
                                  className="form-label fw-bold d-flex justify-content-between mt-3"
                                >
                                  <div>Start Date</div>
                                </Label>

                                <Flatpickr
                                  className="form-control fw-bold shadow-sm"
                                  options={{
                                    dateFormat: "d/m/Y h:i K",
                                    enableTime: true,
                                    time_12hr: true,
                                    defaultDate: taskData.repeat_startDate,
                                  }}
                                  onChange={handleDateChange}
                                  name="date"
                                />
                              </>
                            )}

                            {/* Daily and Yearly Frequencies */}
                            {frequency === "Daily" || frequency === "Yearly" ? (
                              <>
                                <Label
                                  htmlFor="categoryname-field"
                                  className="form-label fw-bold d-flex justify-content-between mt-3"
                                >
                                  <div>Start Date</div>
                                </Label>
                                <Flatpickr
                                  className="form-control fw-bold shadow-sm mb-3"
                                  options={{
                                    dateFormat: "d/m/Y h:i K",
                                    enableTime: true,
                                    time_12hr: true,
                                    defaultDate: taskData.repeat_startDate,
                                  }}
                                  onChange={handleDateChange}
                                  name="date"
                                />


                              </>







                            ) : null}


                            {/* {frequency === 'Custom' && (
                              <div className="mb-3">
                                <Input
                                  type="number"
                                  className="form-control shadow-sm mb-2"
                                  placeholder="How Many Times?"
                                />
                                <Input
                                  type="number"
                                  className="form-control shadow-sm"
                                  placeholder="Interval (in days, weeks, etc.)"
                                />
                              </div>
                            )} */}
                          </div>

                        )}
                      </Label>
                    </Col>





                    {/* <Col lg={2}>
                      <div className=" mt-3 ">
                        <h5 className="fs-15 mb-1">Upload Image</h5>
                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute top-100 start-100 translate-middle">
                              <label
                                htmlFor="taskImage"
                                className="mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title=""
                                data-bs-original-title="Select Image"
                              >
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i className="ri-image-fill"></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="taskImage"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={(e) => {
                                  setTask({
                                    ...taskData,
                                    image: e.target.files[0],
                                  });
                                }}
                              />
                            </div>

                            <div className="avatar-lg">
                              <div className="avatar-title bg-light rounded">
                                {taskData.image != null ? (
                                  <img
                                    src={URL.createObjectURL(taskData.image)}
                                    id="product-img"
                                    alt="product-img"
                                    className="h-auto"
                                    width={"100px"}
                                  />
                                ) : (
                                  <D_img />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col> */}



                    <Col lg={12}>
                      <div className="mt-5 text-center">
                        <div className="d-flex justify-content-center align-items-center">

                          {/* Image Upload */}
                          <Col lg={3}>
                            <div className="position-relative mx-2">
                              {/* Display selected image  */}
                              <div className="image-preview d-flex mb-2">
                                {selectedImages.map((image, index) => (
                                  <div key={index} className="me-2">
                                    <img
                                      src={URL.createObjectURL(image)}
                                      alt={`Selected ${index}`}
                                      style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                                    />
                                  </div>
                                ))}
                              </div>

                              <label
                                htmlFor="taskImages"
                                className="mb-0 cursor-pointer"
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title="Select Images"
                              >
                                <div className="avatar-xs">
                                  <div
                                    className="avatar-title border rounded-circle text-light"
                                    style={{ width: '40px', height: '40px', backgroundColor: 'transparent' }}
                                  >
                                    <i className="ri-image-fill" style={{ fontSize: '20px', color: '#000' }}></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="taskImages"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                multiple
                                onChange={handleImageChange}
                              />
                            </div>
                          </Col>


                          {/* Video Upload */}
                          <Col lg={3}>
                            <div className="position-relative mx-2">
                              <div className="avatar-title bg-light rounded">
                                {selectedVideo ? (
                                  <video
                                    src={URL.createObjectURL(selectedVideo)}
                                    id="employee-video"
                                    height={"100px"}
                                    width={"100px"}
                                    controls
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <label
                                htmlFor="employeeVideo"
                                className="mb-0 cursor-pointer"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Select Video"
                              >
                                <div className="avatar-xs">
                                  <div
                                    className="avatar-title border rounded-circle text-light"
                                    style={{ width: "40px", height: "40px", marginTop: "20px", backgroundColor: "transparent" }}
                                  >
                                    <i
                                      className="ri-video-fill"
                                      style={{ fontSize: "20px", color: "#000" }}
                                    ></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="employeeVideo"
                                type="file"
                                accept="video/mp4, video/avi, video/mkv"
                                onChange={(e) => {
                                  const selectedFile = e.target.files[0];
                                  setTask({ ...taskData, video: selectedFile });
                                  setSelectedVideo(selectedFile);
                                  setSelectedVideoName(selectedFile ? selectedFile.name : ''); // Update video name
                                }}
                              />
                              {/* Display selected video name */}
                              {selectedVideoName && (
                                <span className="ml-2">{selectedVideoName}</span>
                              )}
                            </div>
                          </Col>


                          {/* Document Upload */}
                          <Col lg={3}>
                            <div className="position-relative mx-2">
                              {/* Display selected document name above the button */}
                              {selectedDocName && (
                                <div className="avatar-title bg-light rounded d-flex align-items-center" style={{ marginTop: "20px" }}>
                                  <i
                                    className="ri-file-fill"
                                    style={{ fontSize: "30px", marginRight: "10px", color: "#000" }}
                                  ></i>
                                  <span style={{ color: "#000" }}>{selectedDocName.split("/").pop().substring(0, 15) + "..."}</span>
                                </div>
                              )}

                              <label
                                htmlFor="employeeDocument"
                                className="mb-0 cursor-pointer"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Select Document"
                              >
                                <div className="avatar-xs" style={{ marginTop: selectedDocName ? "10px" : "50px" }}>
                                  <div
                                    className="avatar-title border rounded-circle text-light"
                                    style={{ width: '40px', marginTop: "-20px", height: '40px', backgroundColor: 'transparent' }}
                                  >
                                    {/* Transparent background */}
                                    <i className="ri-file-upload-fill" style={{ fontSize: '20px', color: '#000' }}></i> {/* Icon color black */}
                                  </div>
                                </div>
                              </label>

                              <input
                                className="form-control d-none"
                                id="employeeDocument"
                                type="file"
                                accept=".pdf,.doc,.docx,.txt,.xlsx"
                                onChange={(e) => {
                                  const selectedFile = e.target.files[0];
                                  setTask({ ...taskData, document: selectedFile });
                                  setSelectedDocName(selectedFile ? selectedFile.name : ''); // Update document name
                                }}
                              />
                            </div>
                          </Col>



                          {/* Audio Recorder */}
                          <Col lg={3}>
                            <div className="position-relative mx-2">
                              {/* Display selected audio name above the button */}
                              {audioBlob && (
                                <div className="avatar-title bg-light rounded d-flex align-items-center mb-2" style={{ marginTop: "50px" }}>
                                  <audio controls style={{ marginRight: "10px" }}>
                                    <source src={URL.createObjectURL(audioBlob)} type="audio/wav" />
                                    Your browser does not support the audio element.
                                  </audio>
                                  <i className="ri-file-fill" style={{ fontSize: "30px", marginRight: "10px", color: "#000", }}
                                  ></i>
                                  <span style={{ color: "#000" }}>{audioBlob.name ? audioBlob.name.split("/").pop().substring(0, 15) + "..." : "Recorded Audio"}</span>

                                </div>
                              )}

                              <label
                                htmlFor="audioRecorder"
                                className="mb-0 cursor-pointer"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Record Audio"
                              >

                              </label>
                              <AudioRecorder onRecordingComplete={addAudioElement} recorderControls={recorderControls} />
                            </div>
                          </Col>


                        </div>
                      </div>
                    </Col>




                  </Row>
                  <Row>
                    <Col lg={5} className="mt-5">
                      <div className="mt-3">
                        <Input
                          type="checkbox"
                          onClick={(e) => SetsmsStatus(true)}
                        ></Input>{" "}
                        Send Notification To Whatsapp
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="assignMoreTasksSwitch"
                  checked={assignMoreTasks}
                  onChange={() => {
                    setAssignMoreTasks(!assignMoreTasks);
                  }} // Toggle switch when clicked
                />
                <label className="form-check-label" htmlFor="assignMoreTasksSwitch">
                  Assign More Tasks
                </label>
              </div>


              <button
                ref={submitButtonRef}
                name="close"
                id="close"
                type="submit"
                className="btn btn-primary"
                disabled={loading} // Disable button if loading
              >
                <i className="ri-save-3-line align-bottom me-1"></i>
                {loading ? "Saving..." : "Save"}
              </button>

              <button
                name="close"
                id="close"
                type="button"
                className="btn btn-danger"
                onClick={() => Close()}
              >
                <i className="ri-close-line me-1 align-middle" />
                Close
              </button>
            </div>
          </span>
        </Form>
      </Modal>

      {
        statusModal === true ? (
          <LeadStatusAdd
            modalStates={statusModal}
            setModalStates={() => {
              setstatusModal(false);
            }}
            checkchang={handleCallback}
          />
        ) : (
          ""
        )
      }
      {
        productModal === true ? (
          <ProductAdd
            modalStates={productModal}
            setModalStates={() => {
              setproductModal(false);
            }}
            checkchang={handleCallback}
          />
        ) : (
          ""
        )
      }
      {
        referBy === true ? (
          <RefrencesAdd
            modalStates={referBy}
            setModalStates={() => {
              setreferBy(false);
            }}
            checkchang={handleCallback}
          />
        ) : (
          ""
        )
      }
      {
        customerModal === true ? (
          <CustomerAdd
            modalStates={customerModal}
            setModalStates={() => {
              setcustomerModal(false);
            }}
            checkchang={handleCallback}
          />
        ) : (
          ""
        )
      }
      {
        sourceModal === true ? (
          <SourceAdd
            modalStates={sourceModal}
            setModalStates={() => {
              setsourceModal(false);
            }}
            checkchang={handleCallback}
          />
        ) : (
          ""
        )
      }
    </div >
  );
};

export default TaskAdd;
