import React, { useEffect, useState } from 'react';
import { Card, Col, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import CustomInput from '../Unit/Input';
import { toast } from 'react-toastify';
import AuthUser from '../../helpers/Authuser';
import Select from "react-select";

const SubscriptionPlanUpdate = (props) => {

    const { http } = AuthUser();
    const [formData, setFormData] = useState(props.edit_data);
    const [SubscriptionCategory, setSubscriptionCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

   
    const getCategory = () => {
        http
            .get("/subscription/list")
            .then(response => {
                setSubscriptionCategory(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        getCategory();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        
            http.put('/subscriptionplan/update', formData)
                .then(response => {
                    if (response.data) {
                        toast.success('Subscription plan Update successfully');
                        props.setModalStates(false);
                        props.checkchang();
                    } else {
                        toast.error('Failed to Update subscription plan');
                    }
                })
                .catch(error => {
                    console.error(error);
                    toast.error(error.response?.data?.error || 'Failed to Update subscription plan');
                    props.setModalStates(false);
                });
      
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        console.log(formData);
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedCategory(selectedOption);
        setFormData({
            ...formData,
            subscriptionplan_title: selectedOption ? selectedOption.value : ''
        });
    };

    return (
        <div>
            <Modal id="showModal" size="xl" isOpen={props.modalStates} centered>
                <ModalHeader className="bg-light p-3">
                    Update Subscription Plan
                </ModalHeader>
                <span className="tablelist-form">
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <Card className="border card-border-success p-3 shadow-lg">
                                <div className="mb-3">
                                    <Row>
                                        <Col lg={4}>
                                            <Label
                                                htmlFor="subscriptionplan_title"
                                                className="form-label fw-bold d-flex justify-content-between"
                                            >
                                                <div>
                                                    Plan Title<span style={{ color: "red" }}> *</span>
                                                </div>

                                            </Label>
                                            <Select
                                                className="fw-bold"
                                                placeholder={formData.subscription_title
                                                }
                                                options={SubscriptionCategory.map(e => ({
                                                    value: e.subscription_id,
                                                    label: e.subscription_title,
                                                }))}
                                                value={selectedCategory}
                                                onChange={handleSelectChange}
                                            />
                                        </Col>
                                        <Col lg={4}>
                                            <Label
                                                htmlFor="subscriptionplan_duration"
                                                className="form-label fw-bold d-flex justify-content-between"
                                            >
                                                <div>
                                                    Plan Duration<span style={{ color: "red" }}> *</span>
                                                </div>
                                               
                                            </Label>
                                            <CustomInput
                                                id="subscriptionplan_duration"
                                                name="subscriptionplan_duration"
                                                className="form-control fw-bold"
                                           
                                                type="text"
                                                value={formData.subscriptionplan_duration}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                        <Col lg={4}>
                                            <Label
                                                htmlFor="subscriptionplan_price"
                                                className="form-label fw-bold d-flex justify-content-between"
                                            >
                                                <div>
                                                    Plan Price<span style={{ color: "red" }}> *</span>
                                                </div>
                                            </Label>
                                            <CustomInput
                                                id="subscriptionplan_price"
                                                name="subscriptionplan_price"
                                                className="form-control fw-bold"
                                             
                                                type="number"
                                                value={formData.subscriptionplan_price}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='mt-2'>
                                        <Col lg={12}>
                                            <Label
                                                htmlFor="subscriptionplan_future"
                                                className="form-label fw-bold d-flex justify-content-between"
                                            >
                                                <div>
                                                    Plan Features<span style={{ color: "red" }}> *</span>
                                                </div>
                                            </Label>
                                            <CustomInput
                                                id="subscriptionplan_future"
                                                name="subscriptionplan_future"
                                                className="form-control fw-bold"
                                               
                                                type="text"
                                                value={formData.subscriptionplan_future}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </ModalBody>
                        <div className="modal-footer">
                            <button
                                name="submit"
                                id="submit"
                                type="submit"
                                className="btn btn-primary"
                            >
                                <i className="ri-save-3-line align-bottom me-1"></i>
                                Save
                            </button>
                            <button
                                name="close"
                                id="close"
                                type="button"
                                className="btn btn-danger"
                                onClick={() => props.setModalStates(false)}
                            >
                                <i className="ri-close-line me-1 align-middle" />
                                Close
                            </button>
                        </div>
                    </form>
                </span>
            </Modal>
        </div>
    );
};

export default SubscriptionPlanUpdate;
