import React, { useRef, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  Row,
  Badge,
  UncontrolledPopover,
  PopoverBody,
  Button,
} from "reactstrap";
import Flatpickr from "react-flatpickr";

import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import AuthUser from "../../helpers/Authuser";
import { useEffect } from "react";
import { IMG_API_URL } from "../../helpers/url_helper";
import Select from "react-select";
import { useParams } from "react-router-dom";
import MessageAdd from "./MessageAdd";
import MessageEdit from "./MessageEdit";
import ImgCom from "../Fullscreenimg/ImgCom";
import D_img from "../D_img";
import jsPDF from "jspdf";


const MessageList = () => {
  const { type } = useParams();
  const { http, https, user } = AuthUser();

  const [shiftModal, setShiftModal] = useState(false);
  const [shiftListModal, setshiftListModal] = useState(false);
  const [filterData, setfilterData] = useState({
    start_date: new Date().toISOString().split("T")[0] + " 00:00:00",
    end_date: new Date().toISOString().split("T")[0] + " 23:59:59",
    customer_name: 0,
    lead_id: type ? type : 0,
    assigned_by: user.user.u_type === 1 ? 0 : user.user.uid,
  });
  const [modalStates, setModalStates] = useState(false);
  const [UpdatemodalStates, setUpdateModalStates] = useState(false);
  const [viewModalaStates, setviewModalaStates] = useState(false);
  const [followUpStates, setfollowUpStates] = useState(false);
  const [followUpList, setfollowUpList] = useState(false);

  const [counts, Setcounts] = useState(1);
  const [LeadData, SetLeadData] = useState([]);
  // infinity

  const [statusChange, setstatusChange] = useState([]);
  const getstatusChange = async () => {
    http
      .get("/message/list")
      .then((response) => {
        if (response.data.length > 0) {
          setstatusChange(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [tagsData, settagsData] = useState([]);
  const gettagsData = async () => {
    http
      .get("/tags/list")
      .then((response) => {
        if (response.data.length > 0) {
          settagsData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const ChangeStatus = (index) => {
    let FindArray = LeadData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
  };
  const [assignedByData, setassignedByData] = useState([]);
  const getassignedByData = async () => {
    http
      .get("/franchisebusiness/list")
      .then((response) => {
        if (response.data.length > 0) {
          setassignedByData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleFilter = async () => {
    http
      .get(`/message/list`)
      .then(function (response) {
        if (response.data.length > 0) {
          SetLeadData(response.data);
        } else {
          SetLeadData([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const [customerData, setcustomerData] = useState([]);
  const getcustomerData = async () => {
    http
      .get("/customer/list")
      .then((response) => {
        if (response.data.length > 0) {
          setcustomerData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getstatusChange();
    gettagsData();
    getassignedByData();
    getcustomerData();
    document.title = "Lead | CRM";
    handleFilter();
  }, [counts]);
  const fetchData = () => {
    Setcounts(counts + 1);
  };
  //   Delete Aleart
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, SetID] = useState();
  const onClickDelete = (data) => {
    SetID(data);
    setDeleteModal(true);
  };
  const handleDeleteOrder = (data) => {
    if (data._reactName == "onClick") {
      http
        .delete(`/message/delete/${ID}`)
        .then(function (response) {
          if (response.data.status == 1) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
          Setcounts(counts + 1);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    SetLeadData([]);
    setDeleteModal(false);
  };

  // shortcuts for opening add form
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.altKey && event.key === "a") ||
        (event.altKey && event.key === "A")
      ) {
        event.preventDefault();
        setModalStates(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  //   end Alert
  const handleCallback = (data, status) => {
    SetLeadData([]);
    if (status == 1) {
      setModalStates(false);
      setUpdateModalStates(false);
      setShiftModal(false);
      toast.success(data);
      Setcounts(counts + 1);
    } else {
      toast.warn(data);
      Setcounts(counts + 1);
    }
  };
  // Edit Data
  const [FindData, SetFind] = useState([]);
  const EditUpdate = (index) => {
    let FindArray = LeadData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setUpdateModalStates(!UpdatemodalStates);
  };
  const ViewPage = (index) => {
    let FindArray = LeadData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setviewModalaStates(!viewModalaStates);
  };
  const transeferData = (index) => {
    let FindArray = LeadData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setShiftModal(!shiftModal);
  };
  const FollowUp = (index) => {
    let FindArray = LeadData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setfollowUpStates(!followUpStates);
  };
  const FollowListed = (index) => {
    let FindArray = LeadData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setfollowUpList(!followUpList);
  };
  const tableRef = useRef(null);
  const downloadCSV = () => {
    const table = document.getElementById("tableToPrint");
    if (!table) {
      console.error("Table element not found.");
      return;
    }

    const rows = table.querySelectorAll("tr");
    let csvContent = "data:text/csv;charset=utf-8,\n";

    rows.forEach((row, rowIndex) => {
      const rowData = Array.from(row.children).map((col, colIndex) => {
        const cellContent = col.dataset.value || col.textContent;
        const encodedCellContent = encodeURIComponent(cellContent)
          .replace(/%20/g, " ") // Replace %20 with space
          .replace(/%2D/g, "-") // Replace %2D with dash
          .replace(/%3A/g, "_"); // Replace %3A with underscore for colons
        return encodedCellContent;
      });

      csvContent += rowData.join(",") + "\n";
    });

    const encodedURI = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", "leads_list.csv");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    console.log("Data downloaded successfully.");
  };

  const generatePDF = () => {
    const doc = new jsPDF('landscape');

    // Set table headers
    const headers = [
      "#",
      " MESSAGE TITLE",
      " MESSAGE DISCRIPTION",
      "MESSAGE STATUS"
    ];
    // Set table rows
    const data = LeadData.map((item, index) => [
      index + 1,
      item.message_title,
      item.message_description,
      item.message_image ? "Deactive" : "Active"
    ]);
    doc.setFontSize(12);
    doc.text("Message List", 14, 15);
    doc.autoTable({
      head: [headers],
      body: data,
      startY: 20,
    });

    // Save the PDF
    doc.save("Message.pdf");

    toast.success("PDF generated successfully!");
  };
  const convertToCSVexcel = () => {
    let csvContent = "#,MESSAGE TITLE,  MESSAGE DISCRIPTION ,MESSAGE STATUS ";

    LeadData.forEach((item, index) => {
      csvContent += `\n${index + 1},"${item.message_title}","${item.message_description},"${item.message_image ? "Deactive" : "Active"}"`;
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "MessageList.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    toast.success("CSV generated successfully!");
  };

  return (
    <div className="page-content">
      <table
        ref={tableRef}
        style={{ display: "none" }}
        role="table"
        id="tableToPrint"
        className="text-center bg-white table"
      >
        <thead className="table-light text-muted text-uppercase">
          <tr>
            <th title="Toggle SortBy" style={{ cursor: "pointer" }}>
              #
            </th>
            <th title="Toggle SortBy" style={{ cursor: "pointer" }}>
              Date and time
            </th>
            <th title="Toggle SortBy" style={{ cursor: "pointer" }}>
              Customer Name
            </th>
            <th>Phone</th>
            <th>City Name</th>
            <th>Product </th>
            <th>Value</th>
            <th>Tags</th>
            <th>Assigned</th>
            <th>Status</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          {LeadData.length > 0
            ? LeadData.map((item, index) => (
              <tr key={index}>
                <td>
                  <a
                    className="fw-medium link-primary"
                    href="/apps-ecommerce-order-details"
                  >
                    {index + 1}
                  </a>
                </td>
                <td>
                  {new Date(item.today_date).toLocaleDateString()}{" "}
                  {new Date(item.today_date).toLocaleTimeString()}
                </td>
                <td>{item.customer_name}</td>
                <td>{item.customer_whatsapp_no}</td>
                <td>{item.city_name}</td>
                <td>{item.product_name}</td>
                <td>{item.value}</td>
                <td>
                  {tagsData != null && item.tags
                    ? tagsData.map((e) =>
                      JSON.parse(item.tags).includes(e.tag_id) ? (
                        <div key={e.tag_id} className="p-1">
                          <button>{e.tag_name}</button>
                        </div>
                      ) : null
                    )
                    : "Loading..."}
                </td>
                <td>
                  {assignedByData != null && item.assigned_by
                    ? assignedByData.map((e) =>
                      JSON.parse(item.assigned_by).includes(e.uid) ? (
                        <div key={e.uid} className="p-1">
                          <button className="bg-primary fw-bold text-white border border-primary">
                            {e.name}
                          </button>
                        </div>
                      ) : null
                    )
                    : "Loading..."}
                </td>
                <td>
                  <div onClick={() => ChangeStatus(index)}>
                    <Select
                      className="fw-bold"
                      placeholder={item.lead_status_name}
                      options={statusChange.map((e) => ({
                        label: e.lead_status_name,
                        value: e.lead_status_id,
                      }))}
                      onChange={(e) => {
                        https
                          .put("/leads/update", {
                            lead_id: FindData.lead_id,
                            status: e.value,
                          })
                          .then(function (response) {

                            toast.success(
                              "Lead status updated successfully!"
                            );
                          })
                          .catch(function (err) {
                            console.log(err);
                          });
                      }}
                    />
                  </div>
                </td>
                <td>{item.source_name}</td>
              </tr>
            ))
            : ""}
        </tbody>
      </table>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">






                      <div>

                      </div>
                      <div>

                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn fw-bold btn-success add-btn mt-3"
                          id="create-btn"
                          onClick={() => setModalStates(!modalStates)}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Message
                        </button>
                        <button
                          type="button"
                          className="btn  btn-info mt-3 ms-1"
                          onClick={convertToCSVexcel}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          Export
                        </button>

                        <button
                          type="button"
                          className="btn  btn-danger mt-3 ms-1"
                          onClick={generatePDF}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          PDF
                        </button>
                      </div>


                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  ></Nav>
                  <div className="table-responsive">
                    <table
                      role="table"
                      className="align-middle table-nowrap table table-hover"
                    >
                      <thead className="table-light text-muted text-uppercase">
                        <tr>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            #
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            Message Title
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            Message Description
                          </th>
                          <th>Message Image</th>
                          <th>Status</th>
                          <th>Action</th>

                        </tr>
                      </thead>
                      <tbody>
                        {LeadData.length > 0
                          ? LeadData.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <a
                                  className="fw-medium link-primary"
                                  href="/apps-ecommerce-order-details"
                                >
                                  {index + 1}
                                </a>
                              </td>
                              <td>{item.message_title}</td>
                              <td>{item.message_description}</td>
                              <td>
                                {item.message_image ? (<ImgCom
                                  imageUrl={IMG_API_URL + "message/" + item.message_image}
                                  style={{ height: "100px", width: "100px" }}
                                />) : (
                                  <D_img />
                                )}
                              </td>
                              <td>      <Badge
                                id="PopoverDismissible"
                                color="success"
                                style={{ cursor: "pointer" }}
                                className="p-2 badge-label"
                              >
                                Active
                              </Badge></td>
                              <td>            <button
                                className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                onClick={() => EditUpdate(index)}
                              >
                                <i className="ri-pencil-fill fs-16" />
                              </button>

                                <button
                                  onClick={() =>
                                    onClickDelete(item.message_id)
                                  }
                                  className="text-danger d-inline-block remove-item-btn  border-0 bg-transparent"
                                >
                                  <i className="ri-delete-bin-5-fill fs-16" />
                                </button></td>




                            </tr>
                          ))
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>

                {modalStates === true ? (
                  <MessageAdd
                    modalStates={modalStates}
                    setModalStates={() => {
                      setModalStates(false);
                    }}
                    checkchang={handleCallback}
                  />
                ) : (
                  ""
                )}
                {UpdatemodalStates === true ? (
                  <MessageEdit
                    modalStates={UpdatemodalStates}
                    setModalStates={() => {
                      setUpdateModalStates(false);
                    }}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}



                {/* {shiftListModal === true ? (
                  <ShiftLeadList
                    modalStates={shiftListModal}
                    setModalStates={() => {
                      setshiftListModal(false);
                    }}
                    status={0}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )} */}

                <ToastContainer closeButton={false} limit={1} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MessageList;
