import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
} from "reactstrap";
import "./sync.css";
//import images
import logoSm from "../assets/images/oneclickCRM1.png";
import logoDark from "../assets/images/oneclickCRM1.png";
import logoLight from "../assets/images/oneclickCRM1.png";

//import Components
import NotificationDropdown from "../Components/Common/NotificationDropdown";
import NotificationTableView from "../Components/Common/NotificationTableView";
import ProfileDropdown from "../Components/Common/ProfileDropdown";
import LightDark from "../Components/Common/LightDark";

import { changeSidebarVisibility } from "../store/actions";
import { createSelector } from "reselect";
import AuthUser from "../helpers/Authuser";
import { useDispatch, useSelector } from "react-redux";
import { storePermissions } from "../store/permissions/PermissionsSlice";
import IconBxSync from "./Sync";
import { ToastContainer, toast } from "react-toastify";
import MenuDropdown from "../Components/Common/MenuDropdown";
import MessageBox from "../pages/DashboardEcommerce/MessageBox";
import Chat from "../pages/Chat";
import MessageNotification from "../Components/Common/MessageNotification";
import MyCartDropdown from "../Components/Common/TODODropdown";
import TODODropdown from "../Components/Common/TODODropdown";
import AttendenceDropdown from "../Components/Common/AttendenceDropdown";
import LeadsAdd from '../../src/pages/Leads/LeadsAdd'
import TaskAdd from '../../src/pages/Task/TaskAdd'

const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {
  const { user } = AuthUser();
  const navigate = useNavigate();
  const location = useLocation();
  const handleDoubleClick = () => {
    if (location.pathname !== '/login') {
      if (user.user.u_type === 1 && location.pathname !== '/dashboard') {
        navigate('/dashboard'); // Navigate to '/dashboard' for user type 1
      } else if (user.user.u_type === 2 && location.pathname !== '/user-dashboard') {
        navigate('/user-dashboard'); // Navigate to '/user-dashboard' for user type 2
      } else {
        navigate(-1); // Navigate back to the previous route for other cases
      }
    }
    // navigate(-1); // Navigate back to the previous route
  };


  const [search, setSearch] = useState(false);
  const toogleSearch = () => {
    setSearch(!search);
  };

  const dispatch = useDispatch();
  const sidebarVisibilityData = createSelector(
    (state) => state.Layout.sidebarVisibilitytype,
    (sidebarVisibilitytype) => sidebarVisibilitytype
  );
  const [greeting, setGreeting] = useState("");
  const { http, checkPermission } = AuthUser();

  useEffect(() => {
    // Get the current hour of the day
    const currentHour = new Date().getHours();

    // Function to determine the appropriate greeting based on the time
    function getGreeting() {
      if (currentHour >= 5 && currentHour < 12) {
        return "Good Morning";
      } else if (currentHour >= 12 && currentHour < 17) {
        return "Good Afternoon";
      } else {
        return "Good Evening";
      }
    }

    // Set the greeting message
    setGreeting(getGreeting());
    // getUserPermissions();
  }, []);

  const getUserPermissions = async () => {
    const roleIDD = JSON.parse(sessionStorage.getItem("authUser")).user.role;
    const apiResponse = await http.get(`/get/permissions/${roleIDD}`);
    dispatch(storePermissions(apiResponse.data));
  };

  // Inside your component
  const sidebarVisibilitytype = useSelector(sidebarVisibilityData);

  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;
    dispatch(changeSidebarVisibility("show"));
    if (windowSize > 767)
      document.querySelector(".hamburger-icon").classList.toggle("open");
    //For collapse horizontal menu
    if (document.documentElement.getAttribute("data-layout") === "horizontal") {
      document.body.classList.contains("menu")
        ? document.body.classList.remove("menu")
        : document.body.classList.add("menu");
    }
    //For collapse vertical and semibox menu
    if (
      sidebarVisibilitytype === "show" &&
      (document.documentElement.getAttribute("data-layout") === "vertical" ||
        document.documentElement.getAttribute("data-layout") === "semibox")
    ) {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "sm"
          ? document.documentElement.setAttribute("data-sidebar-size", "")
          : document.documentElement.setAttribute("data-sidebar-size", "sm");
      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "lg"
          ? document.documentElement.setAttribute("data-sidebar-size", "sm")
          : document.documentElement.setAttribute("data-sidebar-size", "lg");
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
    }
    //Two column menu
    if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
      document.body.classList.contains("twocolumn-panel")
        ? document.body.classList.remove("twocolumn-panel")
        : document.body.classList.add("twocolumn-panel");
    }
  };

  const [modalStates, setModalStates] = useState(false);
  const [modalStates1, setModalStates1] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleCallback = (data, status) => {
      if (!modalOpen) {
          // Toggle the modal state
          setModalStates(false);
          setModalStates1(false);
      }
  };



  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass}>
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box horizontal-logo">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="17" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="17" />
                  </span>
                </Link>
              </div>

              <button
                onClick={toogleMenuBtn}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>

              {/* <Link className={"btn mt-3 btn-success"} onClick={handleDoubleClick} >
                BACK

              </Link> */}
              <div style={{ display: 'flex', gap: '10px' }}>
                <button
                  className="btn mt-3 btn-success custom-margin py-1" // Use py-1 for vertical padding
                  style={{ height:"35px" }}
                  onClick={handleDoubleClick}
                >
                  BACK
                </button>
                <button 
                className="btn mt-3 btn-success custom-margin py-1"
                style={{ height:"35px" }}
                onClick={() => setModalStates1(!modalStates1)}
                
                  >
                <i className="ri-add-line align-bottom me-1"></i>
                TASK
                </button>
                <button 
                className="btn mt-3 btn-success custom-margin py-1"
                style={{ height:"35px" }}
                onClick={() => setModalStates(!modalStates)} 
                
                >
                <i className="ri-add-line align-bottom me-1"></i>
                LEAD
                </button>
              </div>



              {/* <MenuDropdown /> */}
            </div>

            <div className="d-flex align-items-center">
              <Dropdown
                isOpen={search}
                toggle={toogleSearch}
                className="d-md-none topbar-head-dropdown header-item"
              >
                <DropdownToggle
                  type="button"
                  tag="button"
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                >
                  <i className="bx bx-search fs-22"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                  <Form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                        />
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </button>
                      </div>
                    </div>
                  </Form>
                </DropdownMenu>
              </Dropdown>
              <div
                className="text-center mt-2"
                style={{ marginRight: "120px" }}
              >
                <h5 className="text-primary">
                  Hello <span className="text-success"> {greeting}</span> 😊,
                  Welcome Back !
                </h5>
              </div>
              {/* <MessageBox/> */}

              {/* {checkPermission("POS Create") ? ( */}
              {/* <Link to={"/pos/create"}>
                <Button color="danger" className="btn-label rounded-pill">
                  {" "}
                  <i className="mdi mdi-calculator label-icon align-middle fs-16 me-2"></i>{" "}
                  POS{" "}
                </Button>
              </Link> */}
              {/* ) : (
                ""
              )} */}
              {/* Dark/Light Mode set */}

              <LightDark
                layoutMode={layoutModeType}
                onChangeLayoutMode={onChangeLayoutMode}
              />

              {/* NotificationDropdown */}
              <TODODropdown />
              {user.user.u_type !== 5 && (<><AttendenceDropdown /></>)}


              <NotificationDropdown />
              <MessageNotification />



              {/* ProfileDropdown */}
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </header>
      <ToastContainer closeButton={false} limit={1} />
      {modalStates === true ? (
                <LeadsAdd
                    modalStates={modalStates}
                    setModalStates={() => {
                        setModalStates(false);
                    }}
                    checkchang={handleCallback}
                />
            ) : (
                ""
            )}
            {modalStates1 === true ? (
                <TaskAdd
                    modalStates={modalStates1}
                    setModalStates={() => {
                        setModalStates1(false);
                    }}
                    checkchang={handleCallback}
                />
            ) : (
                ""
            )}
    </React.Fragment>
  );
};

export default Header;