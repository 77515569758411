import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  Row,
} from "reactstrap";
import AuthUser from "../../helpers/Authuser";
import { ToastContainer, toast } from "react-toastify";
import { SalaryAdd } from "./SalaryAdd";
import SalaryPay from "./SalaryPay";
import { IMG_API_URL } from "../../helpers/url_helper";
import numberToWords from "number-to-words";
import jsPDF from "jspdf";
export const SalaryGeneration = () => {
  const { http } = AuthUser();




  const [modalStates, setModalStates] = useState(false);
  const [modalStates1, setModalStates1] = useState(false);

  const month_options = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "Octomber" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
    // Add more options as needed
  ];


  const months = Array.from({ length: 12 }, (_, index) => index + 1);

  // Generate options for months
  const monthOptions = months.map((month) => ({
    value: month,
    label: month.toString(),
  }));

  // Generate an array of years (from the current year to 5 years into the future, for example)
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 5 }, (_, index) => currentYear + index);

  // Generate options for years
  const yearOptions = years.map((year) => ({
    value: year,
    label: year.toString(),
  }));

  // Set default values
  const defaultMonthOption = { value: new Date().getMonth() + 1, label: (new Date().getMonth() + 1).toString() };
  const defaultYearOption = { value: currentYear, label: currentYear.toString() };
  const [counts, setcounts] = useState(0);

  const handlechangeofcounts = (e) => {
    setcounts(counts + 1)
  }

  const currentMonth = new Date().getMonth() + 1; // JavaScript months are zero-based

  const [selectedMonth, setSelectedMonth] = useState({
    value: currentMonth.toString(),
    label: month_options[currentMonth - 1].label,
  });
  const handleMonthSelect = (selectedOption) => {
    setSelectedMonth(selectedOption);
  };
  const [month, setmonth] = useState({
    month: selectedMonth
  });
  const [salaryData, setsalaryData] = useState([]);
  console.log(salaryData);
  const getMainData = async () => {
    http.post('/salary/filter', { month: selectedMonth.value }).then((res) => {
      setsalaryData(res.data)
    }).catch((e) => {
      console.log(e);
    })
  }
  useEffect(() => {
    getMainData();
    document.title = "Salary Generation";
  }, [selectedMonth, modalStates, counts]);

  const [Find, setFind] = useState([]);

  const paySalary = (index) => {
    const staticMonth = 1; // Set your desired static month value here
    let FindArray = salaryData.filter((_, i) => i === index);
    let FindObject = FindArray[0];
    FindObject.month = staticMonth; // Assign the static month value
    setFind(FindObject);
    setModalStates1(!modalStates1);
  };
  const handleCallback = (data, status, salary_id) => {

    if (status == 1) {
      if (salary_id) {
        receiptprint(salary_id);
      }
      toast.success(data);
      setModalStates1(false);
      setcounts(counts + 1)
    } else {
      toast.warn(data);
      setcounts(counts + 1)
    }

  };
  const [modal_large, setModalLarge] = useState(false); // State for large modal
  console.log(modal_large);
  // Function to toggle the large modal
  const tog_large = () => setModalLarge(!modal_large);
  const [ReceiptPrint, setReceiptPrint] = useState([]);
  console.log(ReceiptPrint);
  const receiptprint = (id) => {

    http
      .get(`/slip/view/${id}`)
      .then(function (response) {
        // console.log(response.data);/
        setReceiptPrint(response.data);
      }).catch(function (error) {
        console.log(error);
      });
  };
  // console.log(ReceiptPrint);
  useEffect(() => {
    // console.log("ReceiptPrint");

    if (ReceiptPrint.receipts && ReceiptPrint.receipts[0]

    ) {
      // console.log("hello");
      tog_large();
    }
  }, [ReceiptPrint]);
  const [businessProfileData, setBusinessProgilrData] = useState([]);
  console.log(businessProfileData);
  useEffect(() => {
    http
      .get("/business-setting/list")
      .then(function (response) {
        if (response.data.length > 0) {
          // console.log(response.data);
          // setBusinessStatus(true);
          setBusinessProgilrData(response.data[0]);
          // console.log(response.data[0]);
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  }, [counts + 1]);
  const handlePrint = () => {
    const printableArea = document.getElementById("printable-area");
    const clonedContent = printableArea.cloneNode(true);

    // Apply styles to the cloned content
    const styleElement = document.createElement("style");
    styleElement.textContent = `
      @import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css');

      /* Define A4 size */
      @page {
          size: A4;
      }

      /* Set margins to fit A4 size */
      body {
          margin: 0;
      }


  `;
    clonedContent.appendChild(styleElement);

    // Open a new window and print the content
    const printWindow = window.open("", "_blank");
    printWindow.document.body.appendChild(clonedContent);
    printWindow.print();
    printWindow.close();
  };

  const generatePDF = () => {
    const doc = new jsPDF('landscape');

    // Set table headers
    const headers = [
      "#",
      " NAME",
      "SALARY",
      "PRESENT",
      "ABSENT",
      "SALARY DATE",
      "CALCULAT SALARY",
      "INCENTIVE SALARY",
      "TOTAL SALARY",
      "SALARY STATUS"


    ];
    // Set table rows
    const data = salaryData.map((item, index) => [
      index + 1,
      item.name,
      item.salary,
      item.salary_present_days,
      item.salary_absent_day,
      item.salary_date,
      item.calculated_salary,
      item.salary_incentive,
      item.total_salary_amount,
      item.salary_status && item.salary_status === 1 ? (
        "UNPAID"
      ) : (
        "PAID"
      )
    ]);

    // Set table style
    doc.setFontSize(12);
    doc.text("Salary List", 14, 15);
    doc.autoTable({
      head: [headers],
      body: data,
      startY: 20,
    });

    // Save the PDF
    doc.save("SalaryList.pdf");

    toast.success("PDF generated successfully!");
  };
  const convertToCSVexcel = () => {
    let csvContent = "#,NAME,SALARY,PRESENT,ABSENT,SALARY DATE,CALCULAT SALARY,INCENTIVE SALARY,TOTAL SALARY,SALARY STATUS";

    salaryData.forEach((item, index) => {
      csvContent += `\n${index + 1},"${item.name}","${item.salary}","${item.salary_present_days}","${item.salary_absent_day}","${item.salary_date}","${item.calculated_salary}","${item.salary_incentive}","${item.total_salary_amount}","${item.salary_status && item.salary_status === 1 ? "UNPAID" : "PAID"}"`;
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "SalaryList.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    toast.success("CSV generated successfully!");
  };
  return (
    <div className="page-content">
      <React.Fragment>

        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="card-header border-0">
                  <Row className="align-items-center gy-3">
                    <div className="col-sm">
                      <h5 className="card-title mb-0">Salary List</h5>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-1 flex-wrap">

                        <div>
                          {/* <div className="fw-bold">Assigned to</div> */}




                        </div>

                        <div>
                          <div className="fw-bold" style={{ width: "150px" }}>Select Month</div>
                          <Select
                            className="fw-bold"
                            options={month_options}
                            onChange={handleMonthSelect} // This will trigger when a month is selected
                            value={selectedMonth} // Set the selected value
                            placeholder="Select Month"
                          ></Select>
                        </div>
                        <div>
                          <div className="fw-bold">Select Year</div>
                          <Input className="form-control w-50" value={currentYear}></Input>
                        </div>
                        <div>
                          {/* <div className="fw-bold">Start Date</div> */}
                          {/* <Flatpickr
                          className="form-control fw-bold"
                          options={{
                            dateFormat: "d/m/Y",
                            defaultDate: "today",
                          }}
                          name="payment_date"
                          placeholder="Enter start date"
                          onChange={(selectedDates) => {
                            const selectedDate = selectedDates[0];
                            // Convert to YYYY-MM-DD format
                            const year = selectedDate.getFullYear();
                            const month = (selectedDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0");
                            const day = selectedDate
                              .getDate()
                              .toString()
                              .padStart(2, "0");
                            const formattedDate = `${year}-${month}-${day} 00:00:00`;

                            setfilterData({
                              ...filterData,
                              start_date: formattedDate,
                            });
                          }} */}
                        </div>

                        <div>
                          {/* <button
                                className="btn mt-3 btn-success"
                                onClick={() => setModalStates(!modalStates)}
                              >
                                Generate Salary
                              </button> */}
                          {selectedMonth && selectedMonth ? (
                            salaryData.length > 0 ? (
                              <button
                                className="btn mt-3 btn-success"

                              >
                                Search
                              </button>
                            ) : (
                              selectedMonth.value <= currentMonth ?
                                <button
                                  className="btn mt-3 btn-success"
                                  onClick={() => setModalStates(!modalStates)}
                                >
                                  Generate Salary
                                </button> :
                                <span className="text-danger fw-bold mt-2">
                                  "This Month Not come yet"
                                </span>
                            )
                          ) : (
                            <></>
                          )}
                          <button
                            type="button"
                            className="btn  btn-info mt-3 ms-1"
                          onClick={convertToCSVexcel}
                          >
                            <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                            Export
                          </button>

                          <button
                            type="button"
                            className="btn  btn-danger mt-3 ms-1"
                          onClick={generatePDF}
                          >
                            <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                            PDF
                          </button>
                        </div>
                        <div>

                        </div>
                        <div>
                          {/* <button
                            type="button"
                            className="btn mt-3 btn-info"
                          >
                            <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                            Export
                          </button> */}
                        </div>
                        <div>

                        </div>
                      </div>
                    </div>
                  </Row>
                </CardHeader>

                <CardBody className="pt-0">
                  <div>
                    <Nav
                      className="nav-tabs nav-tabs-custom nav-success"
                      role="tablist"
                    ></Nav>
                    <div className="table-responsive">
                      <table
                        role="table"
                        className="align-middle table-nowrap table table-hover"
                      >
                        <thead className="table-light text-muted text-uppercase">
                          <tr>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              #
                            </th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              Name
                            </th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              Salary
                            </th>
                            <th>Present</th>
                            <th>Absent</th>
                            <th>Salary Date</th>
                            <th>Calculate Salary</th>
                            <th>Incentive</th>
                            <th>Total Salary</th>
                            <th>Status</th>
                            <th>Action</th>

                          </tr>
                        </thead>
                        <tbody>
                          {
                            salaryData.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.salary}</td>
                                <td>{item.salary_present_days}</td>
                                <td>{item.salary_absent_days}</td>
                                <td>
                                  {/* {item.salary_date} */}
                                  {new Date(item.salary_date).toLocaleDateString("en-GB")}
                                </td>
                                <td>{parseFloat(item.calculated_salary).toFixed(2)}</td>

                                <td>{item.salary_incentive}</td>
                                <td>{item.total_salary_amount}</td>
                                <td>{item.salary_status && item.salary_status === 1 ? (
                                  <><span className="badge bg-info text-white text-uppercase">
                                    <i className="ri--line align-bottom me-1"></i>UnPaid
                                  </span></>
                                ) : (
                                  <><span className="badge bg-success text-white text-uppercase">
                                    <i className="ri--line align-bottom me-1"></i>Paid
                                  </span></>
                                )}</td>
                                <td>
                                  {item.salary_status && item.salary_status === 1 ? (
                                    <button className="btn btn-primary btn-sm" onClick={() => paySalary(index)}>Pay</button>
                                  ) : (
                                    <>
                                      <button className="btn btn-success btn-sm" onClick={() => {
                                        receiptprint(item.salary_id)
                                      }}>Print</button>
                                      {/* <PrintableRow key={index} item={item} /> */}
                                    </>
                                  )}</td>

                              </tr>
                            ))
                          }

                        </tbody>
                      </table>
                    </div>
                  </div>


                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {modalStates === true ? (
            <SalaryAdd
              counts={handlechangeofcounts}
              day={selectedMonth}
              modalStates={modalStates}
              setModalStates={() => {
                setModalStates(false);
              }}
            />
          ) : (
            ""
          )}

          {modalStates1 === true ? (
            <SalaryPay
              modalStates={modalStates1}
              setModalStates={() => {
                setModalStates1(false);
              }}
              checkchang={handleCallback}
              edit_data={Find}
            />
          ) : (
            ""
          )}
        </Container>

        <Modal
          size="lg"
          isOpen={modal_large}
          toggle={() => {
            tog_large();
          }}
        >
          <ModalHeader
            className="modal-title"
            id="myLargeModalLabel"
            toggle={() => {
              tog_large();
            }}
          >
          </ModalHeader>
          <ModalBody>
            <div
              id="printable-area"
              style={{
                width: "97%",
                padding: "10px",
                border: "1px solid black",

              }}
            >
              {/* <div style={{ padding: "10px" }}>

            </div> */}
              <div>
                <p className="text-center" style={{ fontSize: "10px", fontWeight: "bold", textAlign: "center" }}><img
                  src={
                    businessProfileData && businessProfileData.business_logo !== null
                      ? `${IMG_API_URL}/${businessProfileData && businessProfileData.business_logo}`
                      : ""
                  }
                  width="110"
                  height="50"
                  alt="logo"
                /></p>
                <h3 className="text-center" style={{ textAlign: "center" }}>{businessProfileData && businessProfileData.business_name}</h3>
                <p className="text-center" style={{ textAlign: "center" }}>
                  {businessProfileData && businessProfileData.business_billing_address}<br></br>
                  {businessProfileData && businessProfileData.business_company_phone_no}
                </p>
                <p className="text-center fw-bold" style={{ textAlign: "center", marginTop: '-10px' }}>
                  Salary Slip
                </p>
                <hr></hr>
              </div>
              <table style={{ width: "100%", margin: "0 auto", marginBottom: "60px" }}>
                <tr
                  style={{
                    width: "100%",
                    alignItems: "center",
                    // marginLeft: "30%",
                  }}
                >
                  <td style={{ fontWeight: "bold", width: "100px", textAlign: "center" }} className="text-center">
                    Employee Name
                  </td>
                  <td style={{ fontWeight: "bold", width: "10px", textAlign: "center" }} className="text-center">:</td>
                  <td style={{ width: "100px", textAlign: "center" }} className="text-center">{ReceiptPrint && ReceiptPrint.receipts && ReceiptPrint.receipts[0].name}</td>

                  <td style={{ fontWeight: "bold", width: "100px", textAlign: "center" }} className="text-center">
                    Salary Slip No
                  </td>
                  <td style={{ fontWeight: "bold", width: "10px", textAlign: "center" }} className="text-center">:</td>
                  <td style={{ width: "100px", textAlign: "center" }} className="text-center">{ReceiptPrint && ReceiptPrint.receipts && ReceiptPrint.receipts[0].pay_id
                  }</td>
                </tr>
                <tr style={{ width: "100%", alignItems: "center" }}>
                  <td style={{ fontWeight: "bold", width: "100px", textAlign: "center" }} className="text-center">Address</td>
                  <td style={{ fontWeight: "bold", width: "10px", textAlign: "center" }} className="text-center">:</td>
                  <td style={{ width: "100px", textAlign: "center" }} className="text-center"> {ReceiptPrint && ReceiptPrint.receipts && ReceiptPrint.receipts[0].address}</td>

                  <td style={{ fontWeight: "bold", width: "100px", textAlign: "center" }} className="text-center">Date</td>
                  <td style={{ fontWeight: "bold", width: "10px", textAlign: "center" }} className="text-center">:</td>
                  <td style={{ width: "100px", textAlign: "center" }} className="text-center">
                    {ReceiptPrint && ReceiptPrint.receipts && new Date(ReceiptPrint.receipts[0].salary_date).toLocaleDateString()}
                  </td>

                </tr>
                <tr style={{ width: "100%", alignItems: "center" }}>
                  <td style={{ fontWeight: "bold", width: "100px", textAlign: "center" }} className="text-center">Month</td>
                  <td style={{ fontWeight: "bold", width: "10px", textAlign: "center" }} className="text-center">:</td>
                  <td style={{ width: "100px", textAlign: "center" }} className="text-center"> {
                    ReceiptPrint && ReceiptPrint.receipts && ReceiptPrint.receipts[0].pay_month
                  }</td>

                  <td style={{ fontWeight: "bold", width: "100px", textAlign: "center" }} className="text-center">Present Days</td>
                  <td style={{ fontWeight: "bold", width: "10px", textAlign: "center" }} className="text-center">:</td>
                  <td style={{ width: "100px", textAlign: "center" }} className="text-center">
                    {
                      ReceiptPrint && ReceiptPrint.receipts && ReceiptPrint.receipts[0].salary_present_days
                    }
                  </td>

                </tr>
                <tr style={{ width: "100%", alignItems: "center" }}>
                  <td style={{ fontWeight: "bold", width: "100px", textAlign: "center" }} className="text-center"></td>
                  <td style={{ fontWeight: "bold", width: "10px", textAlign: "center" }} className="text-center"></td>
                  <td style={{ width: "100px", textAlign: "center" }} className="text-center">
                    {/* {
                    ReceiptPrint && ReceiptPrint.receipts && ReceiptPrint.receipts[0].payment_mode_name
                  } */}
                  </td>

                  <td style={{ fontWeight: "bold", width: "100px", textAlign: "center" }} className="text-center">
                    {/* Payment Description */}
                  </td>
                  <td style={{ fontWeight: "bold", width: "10px", textAlign: "center" }} className="text-center">
                    {/* : */}
                  </td>
                  <td style={{ width: "100px", textAlign: "center" }} className="text-center">
                    {/* {
                      ReceiptPrint && ReceiptPrint.receipts && ReceiptPrint.receipts[0].pay_description
                    } */}
                  </td>

                </tr>

              </table>
              <hr></hr>
              <tr>


                <td style={{ fontWeight: "bold", marginLeft: "100px", textAlign: "center" }} className="text-center">
                  Salary In words:

                  {

                    ReceiptPrint && ReceiptPrint.receipts && ReceiptPrint.receipts[0].pay_remaining_salary == 0 ?
                      ReceiptPrint && ReceiptPrint.receipts && numberToWords.toWords(


                        ReceiptPrint.receipts[0].total_salary_amount


                      ) : ReceiptPrint && ReceiptPrint.receipts && numberToWords.toWords(ReceiptPrint.receipts[0].pay_remaining_salary)


                  } only
                </td>

              </tr>
              <hr></hr>

              <table style={{ width: "100%", margin: "0 " }}>
                <tr
                  style={{
                    width: "100%",
                    alignItems: "center",

                  }}
                >
                  <td style={{ fontWeight: "bold", width: "100px" }}>Total Salary

                  </td>
                  <td style={{ fontWeight: "bold", width: "10px" }}>:</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>{ReceiptPrint && ReceiptPrint.receipts && ReceiptPrint.receipts[0].salary}/-</td>
                  <td style={{ width: "80px", fontSize: 13, fontWeight: "bold" }}>

                  </td>
                </tr>
                <tr
                  style={{
                    width: "100%",
                    alignItems: "center",

                  }}
                >
                  <td style={{ fontWeight: "bold", width: "100px" }}>
                    Month Calculate Salary
                  </td>
                  <td style={{ fontWeight: "bold", width: "10px" }}>:</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>{ReceiptPrint && ReceiptPrint.receipts && parseFloat(ReceiptPrint.receipts[0].calculated_salary
                  ).toFixed(2)} /-</td>
                  <td style={{ width: "80px", fontSize: 13, fontWeight: "bold" }}>

                  </td>
                </tr>
                <tr
                  style={{
                    width: "100%",
                    alignItems: "center",

                  }}
                >
                  <td style={{ fontWeight: "bold", width: "100px" }}>
                    Incentive
                  </td>
                  <td style={{ fontWeight: "bold", width: "10px" }}>:</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>{ReceiptPrint && ReceiptPrint.receipts && ReceiptPrint.receipts[0].salary_incentive}/-</td>
                  <td style={{ width: "80px", fontSize: 13, fontWeight: "bold" }}>{ReceiptPrint && ReceiptPrint.receipts && ReceiptPrint.receipts[0].pay_remaining_salary != 0 && (<>
                    <span className="fw-bold">Advanced Payment :</span> {ReceiptPrint && ReceiptPrint.receipts && parseInt(ReceiptPrint.receipts[0].pay_advance_paid) + parseInt(ReceiptPrint.receipts[0].pay_remaining_paid)}
                  </>)
                  }
                  </td>
                </tr>
                <tr
                  style={{
                    width: "100%",
                    alignItems: "center",

                  }}
                >{ReceiptPrint && ReceiptPrint.receipts && ReceiptPrint.receipts[0].pay_remaining_salary != 0 ? (<>
                  <td style={{ fontWeight: "bold", width: "100px" }}>

                    <span className="fw-bold">Current Paid Advanced Amt  </span>

                  </td>
                  <td style={{ fontWeight: "bold", width: "10px" }}>:</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>{ReceiptPrint && ReceiptPrint.receipts && parseInt(ReceiptPrint.receipts[0].pay_advance_paid)}/-</td>
                </>) : (<><td style={{ fontWeight: "bold", width: "100px" }}>



                </td>
                  <td style={{ fontWeight: "bold", width: "10px" }}></td>
                  <td style={{ width: "100px", fontWeight: "bold" }}></td>
                </>)
                  }
                  <td style={{ width: "80px", fontSize: 13, fontWeight: "bold" }}>
                    For {businessProfileData && businessProfileData.business_name}
                  </td>
                </tr>
                <tr
                  style={{
                    width: "100%",
                    alignItems: "center",
                    marginLeft: "30%",
                  }}
                >
                  <td style={{ fontWeight: "bold", width: "100px" }}>Calculate Total Salary</td>
                  <td style={{ fontWeight: "bold", width: "10px" }}>:</td>
                  <td style={{ width: "100px", fontWeight: "bold", fontSize: "20px" }}>


                    {/* {ReceiptPrint && ReceiptPrint.receipts && ReceiptPrint.receipts[0].total_salary_amount} */}

                    {

                      ReceiptPrint && ReceiptPrint.receipts && ReceiptPrint.receipts[0].pay_remaining_salary == 0 ?
                        ReceiptPrint && ReceiptPrint.receipts &&


                        ReceiptPrint.receipts[0].total_salary_amount


                        : ReceiptPrint && ReceiptPrint.receipts && ReceiptPrint.receipts[0].pay_remaining_salary


                    }

                    /-</td>

                  <td style={{ textAlign: "end", width: "100px", fontWeight: "bold" }}>
                    Auth. Signatory
                  </td>
                </tr>
              </table>
            </div>
          </ModalBody>
          <div className="hstack gap-2 justify-content-center my-2">
            <button
              type="button"
              onClick={() => {
                tog_large();
              }}
              className="btn btn-danger"
            >
              <i className="ri-close-line me-1 align-middle" />
              Close
            </button>
            <button type="submit" className="btn btn-primary" onClick={() => handlePrint()}>
              <i className="ri-save-3-line align-bottom me-1"></i>

              Print
            </button>
          </div>
        </Modal>
      </React.Fragment>
    </div>
  );
};
