import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Row,
  Col,
  Button,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../../helpers/Authuser";

import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import D_img from "../../D_img";
import { IMG_API_URL } from "../../../helpers/url_helper";
import Flatpickr from "react-flatpickr";
const FolllowUpDetails = (props) => {
  const [modal, setModal] = useState(false);
  const [count, setCount] = useState(0);
  const [leadsData, setLeadsData] = useState(props.edit_data);

  useEffect(() => {
    // console.log(leadsData);
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  // shortcuts to save and close modal
  const submitButtonRef = useRef();
  useEffect(() => {
    getFollowUpListData();
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(true);
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        props.setModalStates();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [count]);

  const { http, https, user } = AuthUser();
  const [FollowUpListData, setFollowUpListData] = useState([]);
  const [shiftModal, setShiftModal] = useState(false);
  const getFollowUpListData = async () => {
    http
      .post("/follow_up/list", {
        leadOrTask: props.status,
        follow_up_send_by:
          props.status === 0
            ? props.edit_data[0].lead_id
            : props.edit_data[0].task_id,
      })
      .then(function (response) {
        setfollowUpData({
          ...followUpData,
          follow_up_description: ""
        })
        setFollowUpListData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [followUpData, setfollowUpData] = useState({
    follow_up_send_by: user.user.uid,
    follow_up_lead_id: props.edit_data[0].lead_id
      ? props.edit_data[0].lead_id
      : "",
    follow_up_description: "",
    follow_up_task_id: props.edit_data[0].task_id
      ? props.edit_data[0].task_id
      : "",
    follow_up_image: "",
    follow_up_next: "",
    follow_up_today: new Date().toLocaleDateString('en-GB').replace(/[/]/g, '/'),
  });

  const [manageStatus, setmanageStatus] = useState(0);
  const SubmitFollowUp = () => {
    if (followUpData.follow_up_description === "") {
      setmanageStatus(2);
    } else if (followUpData.follow_up_next == "") {
      setmanageStatus(3);
    } else {
      https
        .post("/follow_up/lead", followUpData)
        .then((res) => {
          if (res.data.status === 1) {
            setCount(count + 1);
            setfollowUpData({
              ...followUpData,
              follow_up_image: "",
            });
            props.checkchang(res.data.message, res.data.status);
          } else {
            toast.warn(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div>
      <Modal
        className="modal-xl"
        id="fullscreeexampleModal"
        isOpen={modal}
        size={"xl"}
        toggle={toggle}
        centered
      >
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Follow Up Details
        </ModalHeader>
        <span className="tablelist-form">
          <ModalBody style={{ maxHeight: "100vh", overflowY: "auto" }}>
            <Card className="border card-border-success p-3 shadow-lg">
              <Row className="">
                <Col lg={5}>
                  <div className="mt-3">
                    <h5 className="fs-15 mb-1">Upload Image</h5>
                    <div className="text-center">
                      <div className="position-relative d-inline-block">
                        <div className="position-absolute top-100 start-100 translate-middle">
                          <label
                            htmlFor="taskImage"
                            className="mb-0"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            title=""
                            data-bs-original-title="Select Image"
                          >
                            <div className="avatar-xs">
                              <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                <i className="ri-image-fill"></i>
                              </div>
                            </div>
                          </label>
                          <input
                            className="form-control d-none"
                            id="taskImage"
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={(e) => {
                              setfollowUpData({
                                ...followUpData,
                                follow_up_image: e.target.files[0],
                              });
                            }}
                          />
                        </div>

                        <div className="avatar-lg">
                          <div className="avatar-title bg-light rounded">
                            {followUpData.follow_up_image === "" ? (
                              <D_img />
                            ) : (
                              <div className="position-relative">
                                <img
                                  src={URL.createObjectURL(
                                    followUpData.follow_up_image
                                  )}
                                  alt={`product-img`}
                                  className="h-auto m-2"
                                  width="100px"
                                />
                                <button
                                  type="button"
                                  className="position-absolute top-0 end-0 btn-close"
                                  onClick={() =>
                                    setfollowUpData({
                                      ...followUpData,
                                      follow_up_image: "",
                                    })
                                  }
                                ></button>
                              </div>

                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Label
                    htmlFor="Leadname-field"
                    className="form-label fw-bold d-flex justify-content-between"
                  >
                    <div>Follow Up</div>
                    <div style={{ color: "red" }}>
                      {manageStatus === 2 ? "Follow up cannot be empty!" : ""}
                    </div>
                  </Label>
                  <CKEditor
                    rows={1}
                    editor={ClassicEditor}
                    onChange={(e, editor) => {
                      setmanageStatus(0);
                      setfollowUpData({
                        ...followUpData,
                        follow_up_description: editor.getData(),
                      });
                    }}
                    data={followUpData.follow_up_description}
                  />
                  <Label
                    htmlFor="Leadname-field"
                    className="form-label fw-bold d-flex justify-content-between"
                  >
                    <div>Next Follow Up</div>
                    <div style={{ color: "red" }}>
                      {manageStatus === 3 ? "Next Follow up date cannot be empty!" : ""}
                    </div>
                  </Label>
                  <Flatpickr
                    className="form-control fw-bold"
                    options={{
                      dateFormat: "Y-m-d h:i K", // Use 'h:i K' for 12-hour time with AM/PM
                      enableTime: true,          // Enable time selection
                      time_12hr: true,           // Enable 12-hour time format with AM/PM

                    }}

                    onChange={(selectedDates) => {
                      const selectedDate = selectedDates[0];

                      // Extract date components
                      const day = selectedDate.getDate().toString().padStart(2, "0");
                      const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
                      const year = selectedDate.getFullYear();

                      // Extract time components
                      const hours = selectedDate.getHours() % 12 || 12; // 12-hour format
                      const minutes = selectedDate.getMinutes().toString().padStart(2, "0");
                      const ampm = selectedDate.getHours() >= 12 ? "PM" : "AM"; // AM/PM indicator

                      // Format the date and time as "YYYY-MM-DD h:i AM/PM"
                      const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;

                      // Set the task data with both date and time
                      setfollowUpData({
                        ...followUpData,
                        follow_up_next: formattedDateTime,
                      });
                    }}
                    name="date"
                  />
                  <Col className="mt-3 text-center" lg={12}>
                    <Button
                      name="close"
                      id="close"
                      type="button"
                      className="btn btn-primary"
                      onClick={() => SubmitFollowUp()}
                    >
                      <i className="ri-save-3-line align-bottom me-1"></i>
                      Add Follow Up
                    </Button>
                  </Col>
                </Col>
                <Col lg={7}>
                  <div className="table-responsive">
                    <table
                      role="table"
                      className="align-middle table-nowrap text-center table table-hover"
                    >
                      <thead>
                        <tr>
                          <td colSpan={7}>
                            <div className="d-flex justify-content-between">
                              <h5>Working / Follow Ups </h5>
                            </div>
                          </td>
                        </tr>
                      </thead>
                      <thead className="table-light text-muted text-uppercase">
                        <tr>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            #
                          </th>
                          <th>Image</th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            Date & Time
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            Next FollowUp Date
                          </th>


                          <th>Follow Ups</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr>
                          <td>0</td>
                          <td>
                            {leadsData[0].image ?
                              <img
                                src={
                                  IMG_API_URL +
                                  "/leads/" +
                                  leadsData[0].image
                                    .split(",")[0].replace(
                                      /[\[\]]/g,
                                      ""
                                    )
                                }
                                height={"50px"}
                                width={"80px"}
                                alt="profile-user"
                              /> : ""}</td>
                          <td>{leadsData[0].today_date.split("T")[0]}</td>
                          <td>{leadsData[0].today_date.split("T")[1].split(".")[0]}</td>
                          <td>{leadsData[0].description.replace(
                            /<[^>]*>/g,
                            ""
                          )}</td>
                        </tr> */}
                        {FollowUpListData.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>

                              {item.follow_up_image ?
                                <img
                                  height={"50px"}
                                  width={"80px"}
                                  src={
                                    IMG_API_URL +
                                    "/follow-ups/" +
                                    item.follow_up_image
                                  }
                                  alt={
                                    IMG_API_URL +
                                    "/follow-ups/" +
                                    item.follow_up_image
                                  }
                                />
                                :

                                <D_img />
                              }
                            </td>
                            <td>
                              {/* {item.created_date.split("T")[0]}{" "} */}
                              {new Date(item.created_date).toLocaleDateString("en-GB")}
                              {" "}
                              {item.created_date.split("T")[1].split(".")[0]}
                            </td>

                            <td>
                              {item.follow_up_next}
                            </td>
                            <td>
                              {item.follow_up_description.replace(/<\/?[^>]+(>|$)|&nbsp;/g,
                                ""
                              )}
                            </td>
                          </tr>
                        ))}
                        {FollowUpListData.length === 0 ? (
                          <tr>
                            <td colSpan={8} align="center">
                              <div className="fw-bold text-danger">
                                No Follow up added.
                              </div>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* <FollowUpListData
                    count={count}
                    status={lort}
                    edit_data={leadsData}
                  /> */}
                </Col>
              </Row>
            </Card>
          </ModalBody>
        </span>
      </Modal>
    </div>
  );
};

export default FolllowUpDetails;
