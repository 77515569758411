import React, { useRef, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    CardHeader,
    Nav,
    Row,
    Badge,
    UncontrolledPopover,
    PopoverHeader,
    PopoverBody,
    Button,
} from "reactstrap";
import Flatpickr from "react-flatpickr";

import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import AuthUser from "../../helpers/Authuser";
import { useEffect } from "react";
import Select from "react-select";
import { useParams } from "react-router-dom";
import jsPDF from "jspdf";


const LeadsReport = () => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [selectedLeadIndex, setSelectedLeadIndex] = useState(null);
    const [followUpDetails, setfollowUpDetails] = useState(false);
    const togglePopover = (index) => {
        setPopoverOpen(!popoverOpen);
        setSelectedLeadIndex(index);
    };

    const { type, status } = useParams();
    const { http, https, user, permission } = AuthUser();
    const [shiftModal, setShiftModal] = useState(false);
    const [shiftListModal, setshiftListModal] = useState(false);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const [filterData, setfilterData] = useState({
        start_date:
            status
                ? new Date(currentYear, 0, 1).toISOString().split("T")[0] + " 00:00:00"
                : currentDate.toISOString().split("T")[0] + " 00:00:00",
        end_date:
            status
                ? new Date(currentYear, 11, 31).toISOString().split("T")[0] + " 23:59:59"
                : currentDate.toISOString().split("T")[0] + " 23:59:59",
        customer_name: 0,
        status_name: status ? status : 0,
        lead_id: type ? type : 0,
        assigned_by: user.user.u_type === 1 ? 0 : user.user.uid,
    });
    const [modalStates, setModalStates] = useState(false);
    const [UpdatemodalStates, setUpdateModalStates] = useState(false);
    const [viewModalaStates, setviewModalaStates] = useState(false);
    const [followUpStates, setfollowUpStates] = useState(false);
    const [followUpList, setfollowUpList] = useState(false);

    const [counts, Setcounts] = useState(1);
    const [LeadData, SetLeadData] = useState([]);
    // infinity

    const [statusChange, setstatusChange] = useState([]);
    const getstatusChange = async () => {
        http
            .get("/lead/list")
            .then((response) => {
                if (response.data.length > 0) {
                    setstatusChange(response.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const [tagsData, settagsData] = useState([]);
    const gettagsData = async () => {
        http
            .get("/tags/list")
            .then((response) => {
                if (response.data.length > 0) {
                    settagsData(response.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const ChangeStatus = (index) => {
        let FindArray = LeadData.filter((_, i) => i == index);
        SetFind(FindArray[0]);
    };
    const [assignedByData, setassignedByData] = useState([]);
    const getassignedByData = async () => {
        http
            .get("/franchisebusiness/list")
            .then((response) => {
                if (response.data.length > 0) {
                    setassignedByData(response.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleFilter = async () => {
        http
            .post(`/leads/filter`, filterData)
            .then(function (response) {
                if (response.data.length > 0) {
                    SetLeadData(response.data);
                } else {
                    SetLeadData([]);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const [customerData, setcustomerData] = useState([]);
    const getcustomerData = async () => {
        http
            .get("/customer/list")
            .then((response) => {
                if (response.data.length > 0) {
                    setcustomerData(response.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        getstatusChange();
        gettagsData();
        getassignedByData();
        getcustomerData();
        document.title = "Lead | CRM";
        handleFilter();
    }, [counts && filterData]);
    const fetchData = () => {
        Setcounts(counts + 1);
    };
    //   Delete Aleart
    const [deleteModal, setDeleteModal] = useState(false);
    const [ID, SetID] = useState();
    const onClickDelete = (data) => {
        SetID(data);
        setDeleteModal(true);
    };
    const handleDeleteOrder = (data) => {
        if (data._reactName == "onClick") {
            http
                .delete(`/lead/delete/${ID}`)
                .then(function (response) {
                    if (response.data.status == 0) {
                        toast.success(response.data.message);
                    } else {
                        toast.error(response.data.message);
                    }
                    Setcounts(counts + 1);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        SetLeadData([]);
        setDeleteModal(false);
    };

    // shortcuts for opening add form
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (
                (event.altKey && event.key === "a") ||
                (event.altKey && event.key === "A")
            ) {
                event.preventDefault();
                setModalStates(true);
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);
    //   end Alert
    const handleCallback = (data, status) => {
        SetLeadData([]);
        if (status == 1) {
            Setcounts(counts + 1);
            setModalStates(false);
            setUpdateModalStates(false);
            setShiftModal(false);
            toast.success(data);
        } else {
            toast.warn(data);
            Setcounts(counts + 1);
        }
        handleFilter();
    };
    // Edit Data
    const [FindData, SetFind] = useState([]);
    const EditUpdate = (index) => {
        let FindArray = LeadData.filter((_, i) => i == index);
        SetFind(FindArray[0]);
        setUpdateModalStates(!UpdatemodalStates);
    };
    const ViewPage = (index) => {
        let FindArray = LeadData.filter((_, i) => i == index);
        SetFind(FindArray[0]);
        setviewModalaStates(!viewModalaStates);
    };
    const transeferData = (index) => {
        let FindArray = LeadData.filter((_, i) => i == index);
        SetFind(FindArray[0]);
        setShiftModal(!shiftModal);
    };
    const FollowUp = (index) => {
        let FindArray = LeadData.filter((_, i) => i == index);
        SetFind(FindArray[0]);
        setfollowUpStates(!followUpStates);
    };
    const FollowListed = (index) => {
        let FindArray = LeadData.filter((_, i) => i == index);
        SetFind(FindArray[0]);
        setfollowUpList(!followUpList);
    };
    const tableRef = useRef(null);
    const downloadPDF = () => {
        const table = tableRef.current;
        if (!table) {
            console.error("Table element not found.");
            return;
        }

        // Create a new jsPDF instance with landscape orientation
        const pdf = new jsPDF({
            orientation: "landscape"
        });

        // Add the table to the PDF using autoTable
        pdf.autoTable({ html: table });

        // Output the PDF as a blob
        const blob = pdf.output("blob");
        const url = URL.createObjectURL(blob);

        // Create a link element to download the PDF
        const link = document.createElement("a");
        link.href = url;
        link.download = "leads_list.pdf";

        // Append the link to the document, trigger the download, and then remove the link
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const downloadCSV = () => {
        const table = tableRef.current;
        if (!table) {
            console.error("Table element not found.");
            return;
        }

        const csvContent = Array.from(table.querySelectorAll("tr"))
            .map((row) =>
                Array.from(row.children)
                    .map((cell) => `"${cell.innerText.trim()}"`)
                    .join(",")
            )
            .join("\n");

        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = "lead_report.csv";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="page-content">
            <table
                ref={tableRef}
                style={{ display: "none" }}
                role="table"
                id="tableToPrint"
                className="text-center bg-white table"
            >
                <thead className="table-light text-muted text-uppercase">
                    <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Customer</th>
                        <th>Phone</th>
                        <th>City</th>
                        <th>Product </th>
                        <th>Source</th>
                        <th>Value</th>
                        <th>Tags</th>
                        <th>Assigned By</th>
                        <th>Assigned To</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {LeadData.length > 0
                        ? LeadData.map((item, index) => (
                            <tr title="Open Lead" style={{ cursor: "pointer" }} key={index}>
                                <td>
                                    <a
                                        className="fw-medium link-primary"
                                        href="/apps-ecommerce-order-details"
                                    >
                                        {index + 1}
                                    </a>
                                </td>
                                <td>
                                    {new Date(item.today_date).toLocaleDateString("en-GB")}{" "}
                                </td>
                                <td>{new Date(item.today_date).toLocaleTimeString()}</td>
                                <td>{item.customer_name}</td>
                                <td>{item.customer_whatsapp_no}</td>
                                <td>{item.city_name}</td>
                                <td>{item.product_name}</td>

                                <td>{item.source_name}</td>
                                <td>{item.value}</td>
                                <td>
                                    {tagsData != null && item.tags
                                        ? tagsData.map((e) =>
                                            JSON.parse(item.tags).includes(e.tag_id) ? (
                                                <Col key={e.tag_id} lg={6}>
                                                    <Badge className="badge-gradient-info ">
                                                        {e.tag_name}
                                                    </Badge>
                                                </Col>
                                            ) : null
                                        )
                                        : "Loading..."}
                                </td>
                                <td>{item.name}</td>
                                <td>
                                    {assignedByData != null && item.assigned_by
                                        ? assignedByData.map((e) =>
                                            JSON.parse(item.assigned_by).includes(e.uid) ? (
                                                <div key={e.uid} className="p-1">
                                                    <button className="bg-primary fw-bold text-white border border-primary">
                                                        {e.name}
                                                    </button>
                                                </div>
                                            ) : null
                                        )
                                        : "Loading..."}
                                </td>
                                <td>
                                    <div>
                                        <div className="hstack flex-wrap gap-2">
                                            <Badge
                                                color={
                                                    item.lead_status_id === 1
                                                        ? "info"
                                                        : item.lead_status_id === 2
                                                            ? "primary"
                                                            : item.lead_status_id === 3
                                                                ? "warning"
                                                                : item.lead_status_id === 4
                                                                    ? "success"
                                                                    : item.lead_status_id === 5
                                                                        ? "danger"
                                                                        : ""
                                                }
                                            >
                                                {item.lead_status_name}
                                            </Badge>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))
                        : ""}
                </tbody>
            </table>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteOrder}
                onCloseClick={() => setDeleteModal(false)}
            />
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader className="card-header border-0">
                                <Row className="align-items-center gy-3">
                                    <div className="text-center pt-2 pb-4">
                                        <h2 className="fw-bold mb-0">
                                            {filterData.assigned_by > 0
                                                ? (assignedByData && assignedByData.length > 0 && assignedByData.filter((data) => data.uid === filterData.user_id).length > 0
                                                ? `${assignedByData.find((data) => data.uid === filterData.assigned_by)?.name || ''} `
                                                : "User not found")
                                                : "All "}
                                            Leads Reports
                                        </h2>
                                      


                                    </div>
                                </Row>
                                <Row className="align-items-center gy-3">
                                    <div className="col-sm">
                                        {/* <h5 className="card-title mb-0"> Leads Reports </h5> */}
                                    </div>
                                    <div className="col-sm-auto">
                                        <div className="d-flex gap-1 flex-wrap">
                                            {user.user.u_type === 1 ? (
                                                <div>
                                                    <div className="fw-bold" style={{ width: "150px" }}>Assigned to</div>
                                                    <Select
                                                        className="fw-bold"
                                                        placeholder="Assigned"
                                                        options={[
                                                            { value: 0, label: "Select All" },
                                                            ...assignedByData.map((e) => ({
                                                                value: e.uid,
                                                                label: e.name,
                                                            })),
                                                        ]}
                                                        onChange={(e) => {
                                                            setfilterData({
                                                                ...filterData,
                                                                assigned_by: e.value,
                                                            });
                                                        }}
                                                    ></Select>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            <div>
                                                <div className="fw-bold" style={{ width: "150px" }}>By Customer</div>
                                                <Select
                                                    className="fw-bold"
                                                    placeholder="Customer"
                                                    options={[
                                                        { value: 0, label: "Select All" },
                                                        ...customerData.map((e) => ({
                                                            value: e.customer_id,
                                                            label: e.customer_name,
                                                        })),
                                                    ]}
                                                    onChange={(e) => {
                                                        setfilterData({
                                                            ...filterData,
                                                            customer_name: e.value,
                                                        });
                                                    }}
                                                ></Select>
                                            </div>
                                            <div>
                                                <div className="fw-bold" style={{ width: "150px" }}>By Status</div>
                                                <Select
                                                    className="fw-bold"
                                                    placeholder="Status"
                                                    options={[
                                                        { value: 0, label: "Select All" },
                                                        ...statusChange.map((e) => ({
                                                            value: e.lead_status_id,
                                                            label: e.lead_status_name,
                                                        })),
                                                    ]}
                                                    onChange={(e) => {
                                                        setfilterData({
                                                            ...filterData,
                                                            status_name: e.value,
                                                        });
                                                    }}
                                                ></Select>
                                            </div>
                                            <div>
                                                <div className="fw-bold">Start Date</div>
                                                <Flatpickr
                                                    className="form-control fw-bold"
                                                    options={{
                                                        dateFormat: "d/m/Y",
                                                        defaultDate: "today",
                                                    }}
                                                    style={{
                                                        width: "110px",
                                                    }}
                                                    name="payment_date"
                                                    placeholder="Enter start date"
                                                    onChange={(selectedDates) => {
                                                        const selectedDate = selectedDates[0];
                                                        // Convert to YYYY-MM-DD format
                                                        const year = selectedDate.getFullYear();
                                                        const month = (selectedDate.getMonth() + 1)
                                                            .toString()
                                                            .padStart(2, "0");
                                                        const day = selectedDate
                                                            .getDate()
                                                            .toString()
                                                            .padStart(2, "0");
                                                        const formattedDate = `${year}-${month}-${day} 00:00:00`;

                                                        setfilterData({
                                                            ...filterData,
                                                            start_date: formattedDate,
                                                        });
                                                        Setcounts(counts + 1);
                                                    }}
                                                />
                                            </div>

                                            <div>
                                                <div className="fw-bold">End Date</div>
                                                <Flatpickr
                                                    className="form-control fw-bold"
                                                    options={{
                                                        dateFormat: "d/m/Y",
                                                        defaultDate: "today",
                                                    }}
                                                    style={{
                                                        width: "110px",
                                                    }}
                                                    name="payment_date"
                                                    placeholder="Enter start date"
                                                    onChange={(selectedDates) => {
                                                        const selectedDate = selectedDates[0];
                                                        // Convert to YYYY-MM-DD format
                                                        const year = selectedDate.getFullYear();
                                                        const month = (selectedDate.getMonth() + 1)
                                                            .toString()
                                                            .padStart(2, "0");
                                                        const day = selectedDate
                                                            .getDate()
                                                            .toString()
                                                            .padStart(2, "0");
                                                        const formattedDate = `${year}-${month}-${day} 23:59:59`;

                                                        setfilterData({
                                                            ...filterData,
                                                            end_date: formattedDate,
                                                        });
                                                        Setcounts(counts + 1);
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <button
                                                    className="btn mt-3 btn-success "
                                                    onClick={(e) => {
                                                        handleFilter();
                                                    }}
                                                >
                                                    Search
                                                </button>
                                            </div>

                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn mt-3 btn-info"
                                                    onClick={() => downloadCSV()}
                                                >
                                                    <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                                                    Export
                                                </button>
                                            </div>
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn mt-3 btn-danger"
                                                    onClick={() => downloadPDF()}
                                                >
                                                    <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                                                    Pdf
                                                </button>
                                            </div>


                                        </div>
                                    </div>
                                </Row>
                            </CardHeader>

                            <CardBody className="pt-0">
                                <div>
                                    <Nav
                                        className="nav-tabs nav-tabs-custom nav-success"
                                        role="tablist"
                                    ></Nav>
                                    {permission.find(permission => permission.permission_category === "LEAD" && permission.permission_path === "1") && (
                                        <div className="table-responsive">
                                            <table
                                                role="table"
                                                className="align-middle table-nowrap table table-hover"
                                            >
                                                <thead className="table-light text-muted text-uppercase">
                                                    <tr>
                                                        <th style={{ cursor: "pointer" }}>#</th>
                                                        <th
                                                            className="sort desc"
                                                            onClick={() => {
                                                                SetLeadData(LeadData.slice().reverse());
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Date & time
                                                        </th>
                                                        <th
                                                            className="sort desc"
                                                            onClick={() => {
                                                                SetLeadData(LeadData.slice().reverse());
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Customer
                                                        </th>
                                                        <th
                                                            className="sort desc"
                                                            onClick={() => {
                                                                SetLeadData(LeadData.slice().reverse());
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Phone
                                                        </th>
                                                        <th
                                                            className="sort desc"
                                                            onClick={() => {
                                                                SetLeadData(LeadData.slice().reverse());
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            City
                                                        </th>
                                                        <th
                                                            className="sort desc"
                                                            onClick={() => {
                                                                SetLeadData(LeadData.slice().reverse());
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Product{" "}
                                                        </th>
                                                        <th
                                                            className="sort desc"
                                                            onClick={() => {
                                                                LeadData.slice().reverse();
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Source
                                                        </th>
                                                        <th
                                                            className="sort desc"
                                                            onClick={() => {
                                                                SetLeadData(LeadData.slice().reverse());
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Value
                                                        </th>
                                                        <th
                                                            className="sort desc"
                                                            onClick={() => {
                                                                SetLeadData(LeadData.slice().reverse());
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Tags
                                                        </th>
                                                        <th
                                                            className="sort desc"
                                                            onClick={() => {
                                                                SetLeadData(LeadData.slice().reverse());
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Assigned BY
                                                        </th>
                                                        <th
                                                            className="sort desc"
                                                            onClick={() => {
                                                                SetLeadData(LeadData.slice().reverse());
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Assigned TO
                                                        </th>
                                                        <th
                                                            className="sort desc"
                                                            onClick={() => {
                                                                SetLeadData(LeadData.slice().reverse());
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Follow Up
                                                        </th>
                                                        <th
                                                            className="sort desc"
                                                            onClick={() => {
                                                                SetLeadData(LeadData.slice().reverse());
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Status
                                                        </th>
                                                        <th
                                                            className="sort desc"
                                                            onClick={() => {
                                                                SetLeadData(LeadData.slice().reverse());
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Shift
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {LeadData.length > 0
                                                        ? LeadData.map((item, index) => (
                                                            <tr
                                                                title="Open Lead"
                                                                style={{ cursor: "pointer" }}
                                                                key={index}
                                                            >
                                                                <td>

                                                                    {index + 1}

                                                                </td>
                                                                <td >
                                                                    {/* {item.today_date.split("T")[0]} */}
                                                                    {new Date(item.today_date).toLocaleDateString("en-GB")}
                                                                    <br />

                                                                    {item.today_date.substring(11, 16)}
                                                                </td>
                                                                <td >
                                                                    {item.customer_name}
                                                                </td>
                                                                <td >
                                                                    {item.customer_whatsapp_no}
                                                                </td>
                                                                <td >
                                                                    {item.city_name}
                                                                </td>
                                                                <td >
                                                                    {item.product_name}
                                                                </td>

                                                                <td >
                                                                    {item.source_name}
                                                                </td>
                                                                <td >
                                                                    {item.value}
                                                                </td>
                                                                <td >
                                                                    {tagsData != null && item.tags
                                                                        ? tagsData.map((e) =>
                                                                            JSON.parse(item.tags).includes(
                                                                                e.tag_id
                                                                            ) ? (
                                                                                <Col key={e.tag_id} lg={6}>
                                                                                    <Badge className="badge-gradient-info ">
                                                                                        {e.tag_name}
                                                                                    </Badge>
                                                                                </Col>
                                                                            ) : null
                                                                        )
                                                                        : "Loading..."}
                                                                </td>
                                                                <td>{item.name}</td>
                                                                <td >
                                                                    {assignedByData != null && item.assigned_by
                                                                        ? assignedByData.map((e) =>
                                                                            JSON.parse(item.assigned_by).includes(
                                                                                e.uid
                                                                            ) ? (
                                                                                <div key={e.uid} className="p-1">
                                                                                    <button className="bg-primary fw-bold text-white border border-primary">
                                                                                        {e.name}
                                                                                    </button>
                                                                                </div>
                                                                            ) : null
                                                                        )
                                                                        : "Loading..."}
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        className="btn border-0"
                                                                        style={{
                                                                            background:
                                                                                "linear-gradient(to right, #FF69B4, #FF1493)", // Adjust colors as needed
                                                                            color: "white", // Set text color
                                                                            cursor: "pointer", // Add pointer cursor on hover
                                                                        }}

                                                                    >
                                                                        Follow Up
                                                                    </button>
                                                                </td>
                                                                <td>
                                                                    <div >
                                                                        <div className="hstack flex-wrap gap-2">
                                                                            <Button
                                                                                color={
                                                                                    item.lead_status_id === 1
                                                                                        ? "info"
                                                                                        : item.lead_status_id === 2
                                                                                            ? "primary"
                                                                                            : item.lead_status_id === 3
                                                                                                ? "warning"
                                                                                                : item.lead_status_id === 4
                                                                                                    ? "success"
                                                                                                    : item.lead_status_id === 5
                                                                                                        ? "danger"
                                                                                                        : ""
                                                                                }
                                                                                className="btn-label  right "
                                                                            >

                                                                                {item.lead_status_name}
                                                                            </Button>


                                                                        </div>
                                                                    </div>
                                                                </td>



                                                                <td>

                                                                    <ul className="list-inline hstack gap-2 mb-0">
                                                                        <li className="list-inline-item edit">
                                                                            <button
                                                                                className="btn btn-info  border-0"

                                                                                style={{
                                                                                    background:
                                                                                        "linear-gradient(to right, #ff7e5f, #feb47b)", // Adjust colors as needed
                                                                                }}
                                                                            >
                                                                                Shift
                                                                            </button>
                                                                        </li>
                                                                    </ul>

                                                                </td>

                                                            </tr>
                                                        ))
                                                        : ""}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>


                                <ToastContainer closeButton={false} limit={1} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default LeadsReport;
