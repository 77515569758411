import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Button,
  Form,
  Container,
  CardHeader,
  CardBody,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";

import { toast } from "react-toastify";
import CustomInput from "../Unit/Input";
import { Link } from "react-router-dom";
import { IMG_API_URL } from "../../helpers/url_helper";

const AttendenceAdd = (props) => {
  const { https, http, user, token } = AuthUser();
  // const{IMG_API_URL}=url_
  const [modal, setModal] = useState(false);
  const [ChotaBheem, setChotaBheem] = useState(false);
  const [TodayAttendence, setTodayAttendence] = useState([]);
  const [ID, setID] = useState("");
  //in presenti
  const [Attendence, SetAttendence] = useState({
    user_id: user.user.uid,
    attendance_date: "",
    in_time: "",
    attendance_in_latitude: "",
    attendance_in_longitude: "",
    in_location: "",
    in_photo: "",
  });

  // console.log(IMG_API_URL);

  // console.log(user.user.uid);
  // const [address, setAddress] = useState('');
  const [msg, setMsg] = useState("");
  //image capture start
  const [photoData, setPhotoData] = useState(null);
  const [photoDatas, setPhotoDatas] = useState(null);
  // console.log(photoDatas);
  const videoRef = useRef(null);
  const handleStartCapture = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
    } catch (error) {
      console.error("Error accessing the camera:", error);
    }
  };

  // Function to convert base64 string to File object
  function dataURLtoBlob(dataURL) {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }
  const generateRandomName = () => {
    const characters =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const length = 10; // Adjust the length of the random name as needed
    let randomName = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomName += characters.charAt(randomIndex);
    }

    return randomName + ".jpg";
  };
  const handleCapturePhoto = () => {
    const video = videoRef.current;
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
    const capturedPhoto = canvas.toDataURL("image/png");
    setPhotoData(capturedPhoto);
    // SetAttendence({ ...Attendence, in_photo: capturedPhoto });
    // Convert the data URL to a Blob
    const blob = dataURLtoBlob(capturedPhoto);
    // Generate a random file name for the captured image
    const randomFileName = generateRandomName();
    // Create a File object from the Blob
    // Create a File object from the Blob with the random name and appropriate type
    const file = new File([blob], randomFileName, { type: "image/jpeg" });

    SetAttendence((prevState) => ({
      ...prevState,
      in_photo: file,
    }));

    video.srcObject.getVideoTracks().forEach((track) => track.stop());
  };
  //image capture end

  //location trace start
  useEffect(() => {
    // Date fetch
    const fetchTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = `${today.getMonth() + 1}`.padStart(2, "0"); // Adding leading zero if needed
      const day = `${today.getDate()}`.padStart(2, "0"); // Adding leading zero if needed
      const todayFormatted = `${year}-${month}-${day}`;

      SetAttendence((prevState) => ({
        ...prevState,
        attendance_date: todayFormatted,
      }));
    };
    // Date fetch
    const fetchLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            const { latitude, longitude } = position.coords;

            // Reverse geocode the latitude and longitude into an address
            const apiUrl = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`;
            // console.log(latitude, longitude);
            SetAttendence((prevState) => ({
              ...prevState,
              attendance_in_latitude: latitude,
              attendance_in_longitude: longitude,
            }));
            fetch(apiUrl)
              .then(function (response) {
                return response.json();
              })
              .then(function (data) {
                SetAttendence((prevState) => ({
                  ...prevState,
                  in_location: `${data.locality}, ${data.city}, ${data.principalSubdivision}, ${data.countryName}`,
                }));
                // console.log(`Locality: ${data.locality}, City: ${data.city}, ${data.principalSubdivision}, ${data.countryName}`);
              })
              .catch(function (error) {
                console.error("Error fetching address", error);
                setMsg("Error fetching address");
              });
          },
          function (error) {
            console.error("Error getting location", error);
            setMsg("Error getting location");
          }
        );
      } else {
        console.error("Geolocation not supported");
        setMsg("Geolocation not supported");
      }
    };
    fetchTodayDate();
    fetchLocation();
  }, []);

  // Function to update the current time
  const updateCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");

    const currentTimeString = `${hours}:${minutes}:${seconds}`;
    // SetAttendence({ ...Attendence, in_time: currentTimeString });
    // setCurrentTime(currentTimeString);
    SetAttendence((prevState) => ({
      ...prevState,
      in_time: currentTimeString,
    }));
  };

  useEffect(() => {
    const timer = setInterval(() => {
      updateCurrentTime();
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  //location trace end
  const Close = () => {
    setModal(false);
    // props.setModalStates();
  };

  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      // props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [checkNameStatus, setCheckStatus] = useState({});
  const SubmitData = () => {
    if (Attendence == "") {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsg("Attendence connot be empty!");
    } else {
      https
        .post("/attendance/intime/store/", Attendence)
        .then(function (response) {
          // window.location.reload();
          setChotaBheem(true);
          toast.success(response.data.message);
          // props.checkchang(response.data.message, response.data.status);
        })
        .catch(function (error) {
          console.log(error);
        });
      // window.location.reload();
    }
  };

  const handleAttendence = (e) => {
    setCheckStatus({});
    setMsg("");
    SetAttendence(e.target.value);
  };

  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        // props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    http
      .get(`/today/attendance/${user.user.uid}`)
      .then(function (response) {
        setTodayAttendence(response.data);
        setID(response.data[0].attendance_id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [ChotaBheem]);

  const [OutAttendence, SetOutAttendence] = useState([]);
  useEffect(() => {
    // This block will execute after TodayAttendence is updated
    const newOutAttendence = {
      attendance_id: ID,
      user_id: user.user.uid,
      out_time: "",
      attendance_out_latitude: "",
      attendance_out_longitude: "",
      out_location: "",
      out_photo: "",
    };

    SetOutAttendence(newOutAttendence);
  }, [TodayAttendence]);
  // const [address, setAddress] = useState('');
  const [msg1, setMsg1] = useState("");
  //image capture start
  const [photoData1, setPhotoData1] = useState(null);
  const videoRef1 = useRef(null);
  const handleStartCapture1 = async () => {
    try {
      const stream1 = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      videoRef1.current.srcObject = stream1;
      videoRef1.current.srcObject = stream1;
    } catch (error) {
      console.error("Error accessing the camera:", error);
    }
  };

  // Function to convert base64 string to File object
  function dataURLtoBlob(dataURL) {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }
  const generateRandomNames = () => {
    const characters =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const length = 10; // Adjust the length of the random name as needed
    let randomName = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomName += characters.charAt(randomIndex);
    }

    return randomName + ".jpg";
  };
  const handleCapturePhoto1 = () => {
    const video = videoRef1.current;
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
    const capturedPhotos = canvas.toDataURL("image/png");
    setPhotoDatas(capturedPhotos);
    // SetAttendence({ ...Attendence, in_photo: capturedPhoto });
    // Convert the data URL to a Blob
    const blob = dataURLtoBlob(capturedPhotos);
    // Generate a random file name for the captured image
    const randomFileNames = generateRandomNames();

    // Create a File object from the Blob with the random name and appropriate type
    const files = new File([blob], randomFileNames, { type: "image/jpeg" });
    SetOutAttendence((prevState) => ({
      ...prevState,
      out_photo: files,
    }));

    video.srcObject.getVideoTracks().forEach((track) => track.stop());
  };
  //image capture end
  const [Date1, Setdate1] = useState({
    attendence_date: "",
  });
  //location trace start
  useEffect(() => {
    // Date fetch
    const fetchTodayDate1 = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = `${today.getMonth() + 1}`.padStart(2, "0"); // Adding leading zero if needed
      const day = `${today.getDate()}`.padStart(2, "0"); // Adding leading zero if needed
      const todayFormatted = `${year}-${month}-${day}`;
      Setdate1((prevState) => ({
        ...prevState,
        attendence_date: todayFormatted,
      }));
      // Setdate(todayFormatted);
    };

    // Date fetch
    const fetchLocation1 = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            const { latitude, longitude } = position.coords;

            // console.log(latitude,longitude/);
            // Reverse geocode the latitude and longitude into an address
            const apiUrl = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`;
            // console.log(latitude, longitude);
            SetOutAttendence((prevState) => ({
              ...prevState,
              attendance_out_latitude: latitude,
              attendance_out_longitude: longitude,
            }));
            fetch(apiUrl)
              .then(function (response) {
                return response.json();
              })
              .then(function (data) {
                // SetAttendence({ ...Attendence, in_location: `Locality: ${data.locality}, City: ${data.city}, ${data.principalSubdivision}, ${data.countryName}` }

                // );
                SetOutAttendence((prevState) => ({
                  ...prevState,
                  out_location: `${data.locality}, ${data.city}, ${data.principalSubdivision}, ${data.countryName}`,
                }));
              })
              .catch(function (error) {
                console.error("Error fetching address", error);
                setMsg("Error fetching address");
              });
          },
          function (error) {
            console.error("Error getting location", error);
            setMsg("Error getting location");
          }
        );
      } else {
        console.error("Geolocation not supported");
        setMsg("Geolocation not supported");
      }
    };
    fetchTodayDate1();

    fetchLocation1();
  }, []);
  // Function to update the current time
  const updateCurrentTime1 = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");

    const currentTimeString = `${hours}:${minutes}:${seconds}`;
    // SetAttendence({ ...Attendence, in_time: currentTimeString });
    // setCurrentTime(currentTimeString);
    SetOutAttendence((prevState) => ({
      ...prevState,
      out_time: currentTimeString,
    }));
  };
  useEffect(() => {
    const timer = setInterval(() => {
      updateCurrentTime1();
    }, 1000);

    return () => clearInterval(timer);
  }, []);
  //location trace end
  const Close1 = () => {
    setModal(false);
    // props.setModalStates();
  };
  useEffect(() => {
    setModal(false);
    toggle1();
  }, [props.modalStates]);
  const toggle1 = useCallback(() => {
    if (modal) {
      setModal(false);
      // props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [checkNameStatus1, setCheckStatus1] = useState({});
  const SubmitData1 = () => {
    https
      .post("/attendance/outime/store", OutAttendence)
      .then(function (response) {
        // props.checkchang(response.data.message, response.data.status);
        toast.error(response.data.message);
        // window.location.reload();
        setChotaBheem(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              {/* {
                TodayAttendence
              } */}

              <Card>
                <CardHeader className="card-header border-0">
                  <Row className="align-items-center gy-3">
                    <div className="col-sm">
                      <h5 className="card-title mb-0">Today Attendence</h5>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-1 flex-wrap">
                        <Link
                          to={"/attendence-list"}
                          className="btn fw-bold btn-success add-btn"
                          id="create-btn"
                          // onClick={() => setModalStates(!modalStates)}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> View
                          Attendence
                        </Link>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
              </Card>

              {TodayAttendence.length !== 0 ? (
                <Card>
                  <CardHeader className="card-header border-0">
                    <Row className="align-items-center gy-3 mt-3">
                      <div className="col-sm">
                        <h5 className="card-title mb-0">
                          Today OutTime Attendence
                        </h5>
                      </div>
                      <div className="col-sm-auto"></div>
                    </Row>
                  </CardHeader>

                  <CardBody className="pt-0">
                    <Card className="border card-border-success p-3 shadow-lg">
                      <Row>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label
                              htmlFor="cityname-field"
                              className="form-label fw-bold d-flex justify-content-between"
                            >
                              <div>
                                Date<span style={{ color: "red" }}> *</span>
                              </div>
                              {/* <div style={{ color: "red" }}>{msg}</div> */}
                            </Label>
                            {TodayAttendence != null ? (
                              <Input
                                checkNameStatus={checkNameStatus1}
                                style={{ backgroundColor: "lightblue" }}
                                name="attendance_date"
                                id="date-field"
                                className="form-control fw-bold"
                                placeholder="Attendence Name"
                                type="date"
                                value={Date1.attendence_date}
                                readonly
                              />
                            ) : (
                              <Input
                                // checkNameStatus={checkNameStatus1}
                                style={{ backgroundColor: "lightblue" }}
                                name="attendance_date"
                                id="date-field"
                                className="form-control fw-bold"
                                placeholder="Attendence Name"
                                type="date"
                                value={Date1.attendence_date}
                                readonly
                              />
                            )}
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label
                              htmlFor="cityname-field"
                              className="form-label fw-bold d-flex justify-content-between"
                            >
                              <div>
                                Out Time<span style={{ color: "red" }}> *</span>
                              </div>
                            </Label>
                            {TodayAttendence != null &&
                            TodayAttendence[0].out_time === null ? (
                              <Input
                                checkNameStatus={checkNameStatus}
                                name="out_time"
                                id="date-field"
                                className="form-control fw-bold"
                                style={{ backgroundColor: "lightblue" }}
                                placeholder="Attendence Name"
                                type="time"
                                value={OutAttendence.out_time}
                                readonly
                              />
                            ) : (
                              <Input
                                checkNameStatus={checkNameStatus}
                                name="out_time"
                                id="date-field"
                                className="form-control fw-bold"
                                style={{ backgroundColor: "lightblue" }}
                                placeholder="Attendence Name"
                                type="time"
                                value={TodayAttendence[0].out_time}
                                readonly
                              />
                            )}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <label
                              htmlFor="cityname-field"
                              className="form-label fw-bold d-flex justify-content-between"
                            >
                              <div>
                                Address<span style={{ color: "red" }}> *</span>
                              </div>
                              <div style={{ color: "red" }}>{msg}</div>
                            </label>
                            {TodayAttendence.length > 0 &&
                            TodayAttendence[0].out_location === null ? (
                              <input
                                name="out_location"
                                style={{ backgroundColor: "lightblue" }}
                                id="city-field"
                                className="form-control fw-bold"
                                placeholder="Attendance Name"
                                type="text"
                                value={OutAttendence.out_location}
                              />
                            ) : (
                              <input
                                name="out_location"
                                style={{ backgroundColor: "lightblue" }}
                                id="city-field"
                                className="form-control fw-bold"
                                placeholder="Attendance Name"
                                type="text"
                                value={TodayAttendence[0].out_location}
                              />
                            )}
                          </div>
                        </Col>

                        {TodayAttendence.length > 0 &&
                        TodayAttendence[0].out_location === null ? (
                          <Col lg={6}>
                            <div className="mb-3">
                              {!photoDatas ? (
                                <>
                                  <Label
                                    htmlFor="cityname-field"
                                    className="form-label fw-bold d-flex justify-content-between"
                                  >
                                    <div>
                                      Capture live photo
                                      <span style={{ color: "red" }}> *</span>
                                    </div>
                                  </Label>
                                  <video
                                    ref={videoRef1}
                                    className="form-control"
                                    style={{
                                      width: "100%",
                                      maxWidth: "400px",
                                      marginBottom: "10px",
                                    }}
                                    autoPlay
                                  ></video>
                                  <Button
                                    className="btn fw-bold btn-danger add-btn"
                                    onClick={handleStartCapture1}
                                  >
                                    Start Capture
                                  </Button>
                                  <span className="mx-2"></span>
                                  <Button
                                    className="btn fw-bold btn-info add-btn"
                                    onClick={handleCapturePhoto1}
                                  >
                                    Capture Photo
                                  </Button>
                                </>
                              ) : (
                                <div>
                                  <h2>Captured Photo</h2>
                                  <img
                                    src={photoDatas}
                                    alt="Captured"
                                    style={{ maxWidth: "300px" }}
                                  />
                                </div>
                              )}
                            </div>
                          </Col>
                        ) : (
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="cityname-field"
                                className="form-label fw-bold d-flex justify-content-between"
                              >
                                <div>
                                  Capture Photo
                                  <span style={{ color: "red" }}> *</span>
                                </div>
                              </Label>
                              <div>
                                <img
                                  src={
                                    IMG_API_URL + TodayAttendence[0].out_photo
                                  }
                                  alt="Captured"
                                  style={{ maxWidth: "300px" }}
                                />
                              </div>
                            </div>
                            {/* Additional code if needed when TodayAttendance is not null */}
                          </Col>
                        )}
                      </Row>
                    </Card>
                  </CardBody>
                  <div className="modal-footer mb-3 justify-content-center">
                    {TodayAttendence != null &&
                    TodayAttendence[0].out_location === null ? (
                      <button
                        ref={submitButtonRef}
                        name="save"
                        id="save"
                        type="button"
                        className="btn btn-danger"
                        onClick={() => SubmitData1()}
                      >
                        <i className="ri-save-3-line align-bottom me-1"></i>
                        Out Save
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </Card>
              ) : (
                ""
              )}

              <Card>
                <CardHeader className="card-header border-0">
                  <Row className="align-items-center gy-3 mt-3">
                    <div className="col-sm">
                      <h5 className="card-title mb-0">
                        Today InTime Attendence
                      </h5>
                    </div>
                    <div className="col-sm-auto"></div>
                  </Row>
                </CardHeader>

                <CardBody className="pt-0">
                  <Card className="border card-border-success p-3 shadow-lg">
                    <Row>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label
                            htmlFor="cityname-field"
                            className="form-label fw-bold d-flex justify-content-between"
                          >
                            <div>
                              Date<span style={{ color: "red" }}> *</span>
                            </div>
                            {/* <div style={{ color: "red" }}>{msg}</div> */}
                          </Label>
                          {TodayAttendence.length === 0 ? (
                            <Input
                              checkNameStatus={checkNameStatus}
                              style={{ backgroundColor: "lightblue" }}
                              name="attendance_date"
                              id="date-field"
                              className="form-control fw-bold"
                              placeholder="Attendence Name"
                              type="date"
                              value={Attendence.attendance_date}
                              readonly
                            />
                          ) : (
                            <Input
                              checkNameStatus={checkNameStatus}
                              style={{ backgroundColor: "lightblue" }}
                              name="attendance_date"
                              id="date-field"
                              className="form-control fw-bold"
                              placeholder="Attendence Name"
                              type="text"
                              value={TodayAttendence[0].attendance_date}
                              readonly
                            />
                          )}
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label
                            htmlFor="cityname-field"
                            className="form-label fw-bold d-flex justify-content-between"
                          >
                            <div>
                              Time<span style={{ color: "red" }}> *</span>
                            </div>
                            {/* <div style={{ color: "red" }}>{msg}</div> */}
                          </Label>
                          {TodayAttendence.length === 0 ? (
                            <Input
                              checkNameStatus={checkNameStatus}
                              name="in_time"
                              id="date-field"
                              className="form-control fw-bold"
                              style={{ backgroundColor: "lightblue" }}
                              placeholder="Attendence Name"
                              type="time"
                              value={Attendence.in_time}
                              readonly
                            />
                          ) : (
                            <Input
                              checkNameStatus={checkNameStatus}
                              name="in_time"
                              id="date-field"
                              className="form-control fw-bold"
                              style={{ backgroundColor: "lightblue" }}
                              placeholder="Attendence Name"
                              type="text"
                              value={TodayAttendence[0].in_time}
                              readonly
                            />
                          )}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <label
                            htmlFor="cityname-field"
                            className="form-label fw-bold d-flex justify-content-between"
                          >
                            <div>
                              Address<span style={{ color: "red" }}> *</span>
                            </div>
                            <div style={{ color: "red" }}>{msg}</div>
                          </label>
                          {TodayAttendence.length === 0 ? (
                            <input
                              name="in_location"
                              style={{ backgroundColor: "lightblue" }}
                              id="city-field"
                              className="form-control fw-bold"
                              placeholder="Attendance Name"
                              type="text"
                              value={Attendence.in_location}
                            />
                          ) : (
                            <input
                              name="in_location"
                              style={{ backgroundColor: "lightblue" }}
                              id="city-field"
                              className="form-control fw-bold"
                              placeholder="Attendance Name"
                              type="text"
                              value={TodayAttendence[0].in_location}
                            />
                          )}
                        </div>
                      </Col>
                      {TodayAttendence.length === 0 ? (
                        <Col lg={6}>
                          <div className="mb-3">
                            {!photoData ? (
                              <>
                                <Label
                                  htmlFor="cityname-field"
                                  className="form-label fw-bold d-flex justify-content-between"
                                >
                                  <div>
                                    Capture live photo
                                    <span style={{ color: "red" }}> *</span>
                                  </div>
                                </Label>

                                <video
                                  ref={videoRef}
                                  className="form-control"
                                  style={{
                                    width: "100%",
                                    maxWidth: "400px",
                                    marginBottom: "10px",
                                  }}
                                  autoPlay
                                ></video>

                                <Button
                                  className="btn fw-bold btn-danger add-btn mr-2"
                                  onClick={handleStartCapture}
                                >
                                  Start Capture
                                </Button>
                                <span className="mx-2"></span>
                                <Button
                                  className="btn fw-bold danger add-btn"
                                  onClick={handleCapturePhoto}
                                >
                                  Capture Photo
                                </Button>
                              </>
                            ) : (
                              <div>
                                <h2>Captured Photo</h2>
                                <img
                                  src={photoData}
                                  alt="Captured"
                                  style={{ maxWidth: "300px" }}
                                />
                              </div>
                            )}
                          </div>
                        </Col>
                      ) : (
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="cityname-field"
                              className="form-label fw-bold d-flex justify-content-between"
                            >
                              <div>
                                Capture Photo
                                <span style={{ color: "red" }}> *</span>
                              </div>
                            </Label>
                            <div>
                              <img
                                src={IMG_API_URL + TodayAttendence[0].in_photo}
                                alt="Captured"
                                style={{ maxWidth: "300px" }}
                              />
                            </div>
                          </div>
                          {/* Additional code if needed when TodayAttendance is not null */}
                        </Col>
                      )}
                    </Row>
                  </Card>
                </CardBody>
                <div className="modal-footer mb-3 justify-content-center">
                  {TodayAttendence.length === 0 ? (
                    <button
                      ref={submitButtonRef}
                      name="save"
                      id="close"
                      type="button"
                      className="btn btn-primary"
                      onClick={() => SubmitData()}
                    >
                      <i className="ri-save-3-line align-bottom me-1"></i>
                      In Save
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AttendenceAdd;
