
import React, { useEffect, useState, useCallback, useRef } from "react";
import {
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Row,
    Col,
    Button,
    Form,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import CustomInput from "../Unit/Input";
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';

import Select from "react-select";

const Feachers_Update = (props) => {
    const { https } = AuthUser();
    const [modal, setModal] = useState(false);
    const [Subscription, SetSubscription] = useState(props.edit_data);
    const submitButtonRef = useRef();

    const [audioBlob, setAudioBlob] = useState(null); // State for audio blob
    const recorderControls = useAudioRecorder();
    const [SubscriptionData, SetSubscriptionData] = useState([]);
    const [errors, setErrors] = useState({}); // State to store validation errors

    useEffect(() => {
        document.title = "Subscription | CRM";

        https
            .get('/subscriptiontype/list')
            .then(function (response) {
                SetSubscriptionData([...SubscriptionData, ...response.data]);
                console.log("Use Effect is working");
            })
            .catch(function (error) {
                console.log(error);

            });
    }, []);

    const addAudioElement = (blob) => {
        setAudioBlob(blob); // Update the audio blob state
    };

    const Close = () => {
        setModal(false);
        props.setModalStates();
    };

    useEffect(() => {
        setModal(false);
        toggle();
    }, [props.modalStates]);

    const toggle = useCallback(() => {
        setModal(!modal);
    }, [modal]);

    const SubmitData = () => {
        https
            .put("/feachers/update", Subscription)
            .then(response => {
                props.checkchang(response.data.message, response.data.status);
            })
            .catch(error => {
                console.log(error);
            });
    };

    return (
        <div>
            <Modal id="showModal" isOpen={modal} size="xl" toggle={toggle} centered>
                <ModalHeader className="bg-light p-3" toggle={toggle}>
                    Create feature
                </ModalHeader>
                <span className="tablelist-form">
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            SubmitData();
                            return false;
                        }}
                        action="#"
                    >
                        <ModalBody>
                            <Card className="border card-border-success p-3 shadow-lg">
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label
                                                htmlFor="feachers-name-field"
                                                className="form-label fw-bold d-flex justify-content-between"
                                            >
                                                <div>
                                                Feature Name<span style={{ color: "red" }}> *</span>
                                                </div>
                                            </Label>
                                            <CustomInput
                                            className='form-control'
                                                name="feachers_name"
                                                id="feachers-name-field"
                                                value={Subscription.feachers_name}
                                                type="text"
                                                onChange={(e) => SetSubscription({
                                                    ...Subscription, feachers_name: e.target.value
                                                })}
                                            />
                                         
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label      
                                                htmlFor="feachers-type-field"
                                                className="form-label fw-bold d-flex justify-content-between"
                                            >
                                                <div>
                                                Feature Type<span style={{ color: "red" }}> *</span>
                                                </div>
                                            </Label>
                                            <Select
                                            placeholder={Subscription.subscription_type_name}
                                                options={SubscriptionData.map((e) => ({
                                                    value: e.subscription_type_id,
                                                    label: e.subscription_type_name
                                                }))}
                                                value={SubscriptionData.find(option => option.value === Subscription.feachers_type_id)}
                                                onChange={(selectedOption) => {
                                                    SetSubscription(prevState => ({
                                                        ...prevState,
                                                        feachers_type_id: selectedOption.value 
                                                    }));
                                                }}
                                            />
                                          
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </ModalBody>
                        <div className="modal-footer">
                            <button
                                ref={submitButtonRef}
                                name="close"
                                id="close"
                                type="submit"
                                className="btn btn-primary"
                            >
                                <i className="ri-save-3-line align-bottom me-1"></i>
                                Save
                            </button>
                            <button
                                name="close"
                                id="close"
                                type="button"
                                className="btn btn-danger"
                                onClick={Close}
                            >
                                <i className="ri-close-line me-1 align-middle" />
                                Close
                            </button>
                        </div>
                    </Form>
                </span>
            </Modal>
        </div>
    );
  };
export default Feachers_Update;
