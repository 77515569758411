import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CustomInput from "../Unit/Input";
import { useRef } from "react";
import Select from "react-select";
import AuthUser from "../../helpers/Authuser";
import ShiftAdd from "../Shift Slots/ShiftAdd";
import CityAdd from "../City/CityAdd";
import RoleCreateModal from "../UserManagement/Roles/RoleCreateModal";
import { IMG_API_URL } from "../../helpers/url_helper";
import Flatpickr from "react-flatpickr";

const BusinessEdit = (props) => {
  const [modal, setModal] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const { https, http } = AuthUser();
  const [modalStatess1, setModalStatess1] = useState(false);
  const [modalStatess, setModalStatess] = useState(false);
  const [counts, Setcounts] = useState(1);
  const [ActiveShift, setActiveShift] = useState(false);
  const [activeRole, setActiveRole] = useState(false);
  const [EmployeeShift, setEmployeeShift] = useState([]);
  const [EmployeeRole, setEmployeeRole] = useState([]);
  const [ManageShift, setManageShift] = useState(0);
  const [ManageRole, setManageRole] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const handleCallback = (data) => {
    Setcounts(counts);
    setManageRole(1);
    setManageShift(1);
    toast.success(data);
    setModalStatess(false);
    setModalStatess1(false);
  };
  const [EmployeeAdhar, SetEmployeeAdhar] = useState("");
  const [EmployeeProfile, SetEmployeeProfile] = useState("");
  const [EmployeePan, SetEmployeePan] = useState("");
  const [EmployeeQrCode, SetEmployeeQrCode] = useState("");
  const [employeeData, setemployeeData] = useState(props.edit_data);
  const [EmployeeDoc, SetEmployeeDoc] = useState("");
  const [EmployeeAudio, SetEmployeeAudio] = useState("");
  const [EmployeeVideo, SetEmployeeVideo] = useState("");

  console.log(props.edit_data);
  useEffect(() => {

    http
      .get("/role/list")
      .then(function (response) {
        if (response.data.length == 0) {
          setActiveRole(false);
        } else {
          setEmployeeRole(response.data);
          // setActiveRole(true);
          setemployeeData(() => ({


            ...employeeData,
            user_role_id: ManageRole != 0 ? response.data[response.data.length - 1].role_id : employeeData.role_name

          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });


    http
      .get("/shift/list")
      .then(function (response) {
        // console.log(response.data);
        setEmployeeShift(response.data);
        // setActiveShift(true);
        setemployeeData(() => ({
          ...employeeData,
          shift_id: ManageShift != 0 ? response.data[response.data.length - 1].shift_id : employeeData.shift_id

        }));

      })
      .catch(function (error) {
        console.log(error);
      });
  }, [counts]);
  console.log(employeeData);
  const getSelectedCityValue = (e) => {
    setemployeeData({ ...employeeData, shift_id: e.value });
  };
  const getSelectedRoleValue = (e) => {
    setemployeeData({ ...employeeData, user_role_id: e.value });
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStatess1]);
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStatess]);

  // console.log(employeeData);
  const Close = () => {
    setModal(false);
    props.setModalStates();
  };



  // GETTING ROLES LIST
  const getRolesList = async () => {
    try {
      const apiResponse = await https.get("/role/list");
      setRolesList(apiResponse.data);
    } catch (error) {
      console.log(error);
    }
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [checkNameStatus, setCheckStatus] = useState({});


  const [msg, setMsg] = useState("");


  const SubmitData = () => {
    if (employeeData.employee_name === "") {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsg("Employee cannot be empty!");
    } else {
      // console.log(employeeData);
      https
        .put("/user/update", employeeData)
        .then(function (response) {

          props.checkchang(response.data.message, response.data.status);

        })
        .catch(function (error) {
          console.log("Error", error);
        });
    }
  };


  const handleEmployee = (e) => {
    setCheckStatus({});
    setMsg("");
    setemployeeData({ ...employeeData, [e.target.name]: e.target.value });
  };

  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <Modal id="showModal" size="xl" isOpen={modal} toggle={toggle} style={{ marginTop: '5px', paddingTop: '10px' }} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Update Business
        </ModalHeader>
        <span className="tablelist-form">
          <ModalBody>
            <Card className="border card-border-success p-3 shadow-lg">
              <div className="mb-3">
                <Row>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                        Business Name <span className="text-danger">*</span>{" "}
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <CustomInput
                      checkNameStatus={checkNameStatus}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder="Business Name"
                      type="text"
                      name="name"
                      value={employeeData.name}
                      onChange={handleEmployee}
                    />
                  </Col>

                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>Mobile Number<span className="text-danger fw-bold"> *</span></div>
                    </Label>
                    <Input
                      value={employeeData.mobile_no}
                      id="mobile_no"
                      name="mobile_no"
                      className="form-control fw-bold"
                      placeholder="Mobile Number"
                      type="number"
                      onChange={handleEmployee}
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>Emergency Mobile Number</div>
                    </Label>
                    <Input
                      value={employeeData.emergency_contact}
                      name="emergency_contact"
                      id="emergency_contact"
                      onChange={(e) => handleEmployee(e)}
                      className="form-control fw-bold"
                      placeholder="Emergency Mobile Number "
                      type="text"
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>Email Address<span className="text-danger fw-bold"> *</span></div>
                    </Label>
                    <Input
                      value={employeeData.email}
                      name="email"
                      id="email"
                      onChange={(e) => handleEmployee(e)}
                      className="form-control fw-bold"
                      placeholder="Email Address"
                      type="text"
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>Password <span className="text-danger fw-bold"> *</span></div>
                    </Label>
                    <Input
                      value={employeeData.password}
                      name="password"
                      id="password"
                      onChange={(e) => handleEmployee(e)}
                      className="form-control fw-bold"
                      placeholder="Password"
                      type={showPassword ? "text" : "password"} // Toggle between text and password

                    />
                    <i
                      className={`ri-eye${showPassword ? "-off" : ""}-fill`}
                      style={{
                        position: "absolute",
                        top: "80%",
                        right: "20px",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                    ></i>
                  </Col>

                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>Business Created Date</div>
                    </Label>
                    <Flatpickr
                      className="form-control fw-bold"
                      options={{
                        dateFormat: "d/m/Y",
                        defaultDate: employeeData.date_of_joining,
                      }}
                      name="payment_date"
                      placeholder="Date of joining"
                      onChange={(selectedDates) => {
                        const selectedDate = selectedDates[0];

                        const year = selectedDate.getFullYear();
                        const month = (selectedDate.getMonth() + 1)
                          .toString()
                          .padStart(2, "0");
                        const day = selectedDate
                          .getDate()
                          .toString()
                          .padStart(2, "0");
                        const formattedDate = `${year}-${month}-${day} 00:00:00`;

                        setemployeeData({
                          ...employeeData,
                          date_of_joining: formattedDate,
                        });
                      }}
                    />


                  </Col>

                  <Col lg={12}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      Address
                    </Label>
                    <textarea
                      value={employeeData.address}
                      name="address"
                      id="address"
                      placeholder="Address"
                      className="form-control fw-bold"
                      onChange={handleEmployee}
                    ></textarea>
                  </Col>

                  <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Profile</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="empoyeeProfile"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title=""
                              data-bs-original-title="Select Image"
                            >
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                  <i className="ri-image-fill"></i>
                                </div>
                              </div>
                            </label>
                            <input
                              // value={IMG_API_URL + employeeData.profile_photo}
                              className="form-control d-none"
                              id="empoyeeProfile"
                              type="file"
                              accept="image/png, image/gif, image/jpeg"
                              onChange={(e) => {
                                setemployeeData({
                                  ...employeeData,
                                  profile_photo: e.target.files[0],
                                });
                                SetEmployeeProfile(e.target.files[0]);
                              }}
                            />
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {EmployeeProfile ? (
                                <img
                                  src={URL.createObjectURL(EmployeeProfile)}
                                  id="product-img"
                                  alt="product-img"
                                  height={"100px"}
                                  width={"100px"}
                                />
                              ) : employeeData.profile_photo !== "/user/null" ? (
                                <img
                                  src={IMG_API_URL + employeeData.profile_photo}
                                  id="product-img"
                                  alt="product-img"
                                  height={"100px"}
                                  width={"100px"}
                                />
                              ) : ("")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Document</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="employeeProfile"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title=""
                              data-bs-original-title="Select Document"
                            >
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                  <i className="ri-file-upload-fill"></i>
                                </div>
                              </div>
                            </label>
                            <input
                              className="form-control d-none"
                              id="employeeProfile"
                              type="file"
                              accept=".pdf,.doc,.docx,.txt,.xlsx"
                              onChange={(e) => {
                                setemployeeData({
                                  ...employeeData,
                                  document: e.target.files[0],
                                });
                                SetEmployeeDoc(e.target.files[0]);
                              }}
                            />
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {EmployeeDoc ? (
                                EmployeeDoc.type.startsWith("image/") ? (
                                  <img
                                    src={URL.createObjectURL(EmployeeDoc)}
                                    alt="Selected file preview"
                                    height="100px"
                                    width="100px"
                                  />
                                ) : (
                                  <div>
                                    <p>{EmployeeDoc.name}</p>
                                    <p>{EmployeeDoc.type}</p>
                                  </div>
                                )
                              ) : employeeData.document && employeeData.document !== "/user/null" ? (
                                <img
                                  src={IMG_API_URL + employeeData.document}
                                  alt="Document preview"
                                  height="100px"
                                  width="100px"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col> */}
                  {/* <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Audio</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="employeeAudio"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title=""
                              data-bs-original-title="Select Document"
                            >
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                  <i className="ri-file-upload-fill"></i>
                                </div>
                              </div>
                            </label>
                            <input
                              className="form-control d-none"
                              id="employeeAudio"
                              type="file"
                              accept=".mp3,.aac,.wav,.flac,.mpeg"
                              onChange={(e) => {
                                  setemployeeData({
                                    ...employeeData,
                                    audio: e.target.files[0],
                                  });
                                  SetEmployeeAudio(e.target.files[0]);
                                }}
                            />
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {EmployeeAudio ? (
                                EmployeeAudio.type.startsWith("image/") ? (
                                  <img
                                    src={URL.createObjectURL(EmployeeAudio)}
                                    alt="Selected file preview"
                                    height="100px"
                                    width="100px"
                                  />
                                ) : (
                                  <div>
                                    <p>{EmployeeAudio.name}</p>
                                    <p>{EmployeeAudio.type}</p>
                                  </div>
                                )
                              ) : employeeData.audio && employeeData.audio !== "/user/null" ? (
                                <img
                                  src={IMG_API_URL + employeeData.audio}
                                  alt="Document preview"
                                  height="100px"
                                  width="100px"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col> */}
                  {/* <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Video</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="employeeVideo"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title=""
                              data-bs-original-title="Select Document"
                            >
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                  <i className="ri-file-upload-fill"></i>
                                </div>
                              </div>
                            </label>
                            <input
                              className="form-control d-none"
                              id="employeeVideo"
                              type="file"
                              accept="video/mp4, video/avi, video/mkv"
                                onChange={(e) => {
                                  setemployeeData({
                                    ...employeeData,
                                    video: e.target.files[0],
                                  });
                                  SetEmployeeVideo(e.target.files[0]);
                                }}
                            />
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {EmployeeVideo ? (
                                EmployeeVideo.type.startsWith("image/") ? (
                                  <img
                                    src={URL.createObjectURL(EmployeeVideo)}
                                    alt="Selected file preview"
                                    height="100px"
                                    width="100px"
                                  />
                                ) : (
                                  <div>
                                    <p>{EmployeeVideo.name}</p>
                                    <p>{EmployeeVideo.type}</p>
                                  </div>
                                )
                              ) : employeeData.video && employeeData.video !== "/user/null" ? (
                                <img
                                  src={IMG_API_URL + employeeData.video}
                                  alt="Document preview"
                                  height="100px"
                                  width="100px"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col> */}
                </Row>
              </div>
            </Card>
          </ModalBody>
          <div className="modal-footer">
            <button
              ref={submitButtonRef}
              name="close"
              id="close"
              type="button"
              className="btn btn-primary"
              onClick={() => SubmitData()}
            >
              <i className="ri-save-3-line align-bottom me-1"></i>
              Update
            </button>
            <button
              name="close"
              id="close"
              type="button"
              className="btn btn-danger"
              onClick={() => Close()}
            >
              <i className="ri-close-line me-1 align-middle" />
              Close
            </button>

          </div>
        </span>
      </Modal>

      {modalStatess1 === true ? (
        <ShiftAdd
          modalStates={modalStatess1}
          setModalStates={() => {
            setModalStatess1(false);
          }}
          checkchang={handleCallback}
        />
      ) : modalStatess === true ? (
        <RoleCreateModal
          modalStates={modalStatess}
          setModalStates={() => {
            setModalStatess(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default BusinessEdit;
