import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Button,
  Form,
} from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";

import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
import CustomInput from "../Unit/Input";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const LeaveAdd = (props) => {
  const { https, http, user } = AuthUser();
  const [modal, setModal] = useState(false);
  const [leaveType, setLeaveType] = useState([]);
  const [selectedleaveType, setSelectedleaveType] = useState([]);
  const [dayCount, setDayCount] = useState(0);

  const calculateDayCount = (fromDate, toDate) => {
    if (fromDate && toDate) {
      const [fromDay, fromMonth, fromYear] = fromDate.split('-').map(Number);
      const [toDay, toMonth, toYear] = toDate.split('-').map(Number);

      const fromDateObj = new Date(fromYear, fromMonth - 1, fromDay);
      const toDateObj = new Date(toYear, toMonth - 1, toDay);

      const differenceInTime = toDateObj.getTime() - fromDateObj.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24) + 1; // Adding 1 to include both dates
      setDayCount(differenceInDays);
    }
  };
  const [Leave, SetLeave] = useState({
    leave_user_id: user.user.uid,
    to_date: '',
    from_date: '',
    leave_reason: '',
    leavetype_id: '',
    days: '',
  });




  const handleLeaveSelection = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.value);
    
    // Find the remaining days for selected leave types
    const selectedLeaveTypes = leaveType.filter((leavetype) => selectedIds.includes(leavetype.leavetype_id));
    let warningMessage = '';
  
    selectedLeaveTypes.forEach((leavetype) => {
      // Create a mapping of LeaveData by leavetype_id to find remaining days
      const leaveDataMap = LeaveData.filter((item) => item.leave_status === '2').reduce((acc, leave) => {
        if (!acc[leave.leavetype_id]) {
          acc[leave.leavetype_id] = { ...leave, leave_days: 0 };
        }
        acc[leave.leavetype_id].leave_days += leave.leave_days;
        return acc;
      }, {});
  
      const matchingLeave = leaveDataMap[leavetype.leavetype_id];
      const remainingDays = matchingLeave ? (leavetype.days - matchingLeave.leave_days) : leavetype.days;
  
      // Check if the remaining days are less than the day count
      if (remainingDays < dayCount) {
        warningMessage = `You have less leave available for ${leavetype.leavetype_name}. Remaining days: ${remainingDays}`;
      }
    });
  
    setSelectedleaveType(selectedIds);
  
    // Update the Leave state with selected leave types and day count
    SetLeave((prevLeave) => ({
      ...prevLeave,
      leavetype_id: selectedIds,
      days: dayCount,
    }));
  
    // Show warning message if exists
    if (warningMessage) {
      alert(warningMessage); // You can replace this with a more sophisticated alert mechanism or a state variable to display the warning in your UI
    }
  };
  
  

  const [LeaveData, SetLeaveData] = useState([]);

  const [filterData, setfilterData] = useState({
    start_date: new Date().toISOString().split("T")[0] + " 00:00:00",
    end_date: new Date().toISOString().split("T")[0] + " 23:59:59",
    leave_status: 0,

    user_id: 0,
  });
  const fetchLeaveType = () => {
    http
      .post(`/leave/list`, filterData)
      .then(function (response) {
        console.log(response.data);
        if (response.data.length > 0) {
          SetLeaveData(response.data);
          // SetPages(Pages + 1);
        }
        else {
          SetLeaveData([]);
        }

      })
      .catch(function (error) {
        console.log(error);

      });
    http
      .get(`leaveassign/list`)
      .then((response) => {
        setLeaveType(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error Fetching Table Data:", error);
      });
  };

  useEffect(() => {
    fetchLeaveType();
  }, []);




  const Close = () => {
    setModal(false);
    props.setModalStates();
  };

  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [checkNameStatus, setCheckStatus] = useState({});
  const [msg, setMsg] = useState("");
  const [msg1, setMsg1] = useState("");
  const SubmitData = () => {
    if (Leave.to_date === "") {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsg("Leave connot be empty!");
    } else {
      https
        .post("/leave/store", Leave)
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const handleLeave = (e) => {
    setCheckStatus({});
    setMsg("");
    SetLeave(e.target.value);
  };

  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <Modal
        className="modal-lg"
        id="showModal"
        isOpen={modal}
        toggle={toggle}
        centered
      >
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Create Leave
        </ModalHeader>
        <span className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              SubmitData();
              return false;
            }}
            action="#"
          >
            <ModalBody>
              <Card className="border card-border-success p-3 shadow-lg">
                <div style={{ color: "red" }}>{msg}</div>
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      {/* <Label
                        htmlFor="cityname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Current Date<span style={{ color: "red" }}> *</span>
                        </div>
                      </Label> */}
                      <Flatpickr
                        className="form-control fw-bold"
                        options={{
                          dateFormat: "d/m/Y",
                          defaultDate: new Date(), // Default date for Flatpickr
                        }}
                        value={Leave.current_date} // Display the current date
                        onChange={(selectedDates) => {
                          const selectedDate = selectedDates[0];
                          const day = selectedDate
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const month = (selectedDate.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const year = selectedDate.getFullYear();
                          const formattedDate = `${day}-${month}-${year}`;
                          SetLeave({
                            ...Leave,
                            current_date: formattedDate,
                          });
                        }}
                        name="current_date"
                        style={{ display: "none" }} // Hide the Flatpickr
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label
                        htmlFor="cityname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          From Date<span style={{ color: "red" }}> *</span>
                        </div>
                      </Label>

                      <Flatpickr
                        className="form-control fw-bold"
                        options={{
                          dateFormat: "d/m/Y",
                          defaultDate: new Date(),
                        }}
                        onChange={(selectedDates) => {
                          const selectedDate = selectedDates[0];
                          const day = selectedDate
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const month = (selectedDate.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const year = selectedDate.getFullYear();
                          const formattedDate = `${day}-${month}-${year}`;

                          SetLeave((prev) => {
                            const updatedLeave = {
                              ...prev,
                              from_date: formattedDate,
                            };
                            // Calculate day count after updating the state
                            if (
                              updatedLeave.from_date &&
                              updatedLeave.to_date
                            ) {
                              calculateDayCount(
                                formattedDate,
                                updatedLeave.to_date
                              );
                            }
                            return updatedLeave;
                          });
                        }}
                        name="from_date"
                      />
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label
                        htmlFor="cityname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          To Date<span style={{ color: "red" }}> *</span>
                        </div>
                      </Label>

                      <Flatpickr
                        className="form-control fw-bold"
                        options={{
                          dateFormat: "d/m/Y",
                          defaultDate: new Date(),
                        }}
                        onChange={(selectedDates) => {
                          const selectedDate = selectedDates[0];
                          const day = selectedDate
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const month = (selectedDate.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const year = selectedDate.getFullYear();
                          const formattedDate = `${day}-${month}-${year}`;

                          SetLeave((prev) => {
                            const updatedLeave = {
                              ...prev,
                              to_date: formattedDate,
                            };
                            // Calculate day count after updating the state
                            if (
                              updatedLeave.from_date &&
                              updatedLeave.to_date
                            ) {
                              calculateDayCount(
                                updatedLeave.from_date,
                                formattedDate
                              );
                            }
                            return updatedLeave;
                          });
                        }}
                        name="to_date"
                      />
                    </div>
                  </Col>

                  <Col lg={6}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between mt-3"
                    >
                      <div>Days Count: {dayCount}</div>
                    </Label>
                  </Col>

                  <Col lg={6}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between mt-3"
                    >
                      <div>Leave-Type</div>
                    </Label>

                    {/* Render the multi-select box for leave types using react-select */}
                    <Select
                      isMulti
                      className="fw-bold"
                      placeholder="Select Leave Types"
                      value={leaveType
                        .filter((leavetype) => selectedleaveType.includes(leavetype.leavetype_id))
                        .map((leavetype) => ({
                          label: `${leavetype.leavetype_name} - ${leavetype.days}`,
                          value: leavetype.leavetype_id,
                        }))
                      }
                      onChange={handleLeaveSelection}
                      options={leaveType.map((leavetype) => {
                        const leaveDataMap = LeaveData.filter((item) => item.leave_status === '2').reduce((acc, leave) => {
                          if (!acc[leave.leavetype_id]) {
                            acc[leave.leavetype_id] = { ...leave, leave_days: 0 };
                          }
                          acc[leave.leavetype_id].leave_days += leave.leave_days;
                          return acc;
                        }, {});
                        const matchingLeave = leaveDataMap[leavetype.leavetype_id];
                        const remainingDays = matchingLeave ? (leavetype.days - matchingLeave.leave_days) : leavetype.days;

                        return {
                          label: `${leavetype.leavetype_name} - Remaining Days: ${remainingDays}`,
                          value: leavetype.leavetype_id,
                        };
                      })}
                    />
                  </Col>


                  {/* <Col lg={6}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between mt-3"
                    >
                      <div>Leave-Type</div>
                    </Label>

                    {(leaveType || []).map((leavetype) => (
                      <div key={leavetype.leavetype_id} className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`leaveType-${leavetype.leavetype_id}`}
                          value={leavetype.leavetype_id}
                          checked={selectedleaveType.includes(
                            leavetype.leavetype_id
                          )}
                          onChange={(e) =>
                            handleLeaveTypeChange(e, leavetype.leavetype_id)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`leaveType-${leavetype.leavetype_id}`}
                        >
                          {leavetype.leavetype_name} {leavetype.days}
                        </label>

                        
                        {selectedleaveType.includes(leavetype.leavetype_id) && (
                          <input
                            type="number"
                            className="form-control mt-2"
                            placeholder="Add Day's"
                            value={daysInput[leavetype.leavetype_id] || ""}
                            onChange={(e) =>
                              handleDaysInputChange(e, leavetype.leavetype_id)
                            }
                          />
                        )}
                      </div>
                    ))}
                  </Col> */}

                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="cityname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Leave Application Reason
                          <span style={{ color: "red" }}> *</span>
                        </div>
                        {/* <div style={{ color: "red" }}>{msg}</div> */}
                      </Label>
                      <CKEditor
                        rows={1}
                        editor={ClassicEditor}
                        onChange={(e, editor) => {
                          // settempValid(0);
                          SetLeave({
                            ...Leave,
                            leave_reason: editor.getData(),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <Button
                ref={submitButtonRef}
                name="close"
                id="close"
                type="submit"
                className="btn btn-primary"
              //onClick={() => SubmitData()}
              >
                <i className="ri-save-3-line align-bottom me-1"></i>
                Save
              </Button>
              <button
                name="close"
                id="close"
                type="button"
                className="btn btn-danger"
                onClick={() => Close()}
              >
                <i className="ri-close-line me-1 align-middle" />
                Close
              </button>
            </div>
          </Form>
        </span>
      </Modal>
    </div>
  );
};

export default LeaveAdd;
