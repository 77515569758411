import React, { useState } from 'react';
import { Card, Col, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import CustomInput from '../Unit/Input';

import { toast } from 'react-toastify';
import AuthUser from '../../helpers/Authuser';

const SubscriptionUpdate = (props) => {
    // console.log(props);
    const [categoryName, setCategoryName] = useState(props.edit_data);

    const { http, user } = AuthUser();
   
    const handleSubmit = (e) => {
        e.preventDefault();
        
            http.put("/subscription/update", {
                subscription_id: props.edit_data.subscription_id,
                subscription_title: categoryName,
            })
                .then(response => {
                    if (response.data) {
                        props.setModalStates(false);
                        props.checkchang('Category update successfully!!');
                    } else {
                        toast.error('Failed to update category');
                    }
                })
                .catch(error => {
                    console.error(error);
                    toast.error(error.response?.data?.error || 'Failed to update category');
                    props.setModalStates(false);
                });
       
    };

    return (
        <div>
            <Modal id="showModal" size="md" isOpen={props.modalStates} centered>
                <ModalHeader className="bg-light p-3">
                Update Category
                </ModalHeader>
                <span className="tablelist-form">
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <Card className="border card-border-success p-3 shadow-lg">
                                <div className="mb-3">
                                    <Row>
                                        <Col lg={12}>
                                            <Label
                                                htmlFor="categoryname-field"
                                                className="form-label fw-bold d-flex justify-content-between"
                                            >
                                                <div>
                                                    Category Name<span style={{ color: "red" }}> *</span>
                                                </div>
                                               
                                            </Label>
                                            <CustomInput
                                                id="category-name-field"
                                                className="form-control fw-bold"
                                                placeholder="Category Name"
                                                type="text"
                                                value={categoryName.subscription_title}
                                                onChange={(e) => setCategoryName(e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </ModalBody>
                        <div className="modal-footer">
                            <button
                                name="submit"
                                id="submit"
                                type="submit"
                                className="btn btn-primary"
                            >
                                <i className="ri-save-3-line align-bottom me-1"></i>
                                Save
                            </button>
                            <button
                                name="close"
                                id="close"
                                type="button"
                                className="btn btn-danger"
                                onClick={() => props.setModalStates(false)}
                            >
                                <i className="ri-close-line me-1 align-middle" />
                                Close
                            </button>
                        </div>
                    </form>
                </span>
            </Modal>
        </div>
    );
};

export default SubscriptionUpdate;
