import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Form,
  Button,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import CustomInput from "../Unit/Input";

const ShiftEdit = (props) => {
  // console.log(props);
  const [modal, setModal] = useState(false);
  const [Shift_name, SetShift_Name] = useState(props.edit_data.shift_name);
  const [Shift_Intime, SetShift_Intime] = useState(
    props.edit_data.shift_intime
  );
  const [Shift_Outtime, SetShift_Outtime] = useState(
    props.edit_data.shift_outime
  );
  const [shiftId] = useState(props.edit_data.shift_id);
  // console.log(Shift_name);
  const { https } = AuthUser();
  const [checkNameStatus, setCheckStatus] = useState({});
  const [msg, setMsg] = useState("");
  const Close = () => {
    setModal(false);
    props.setModalStates();
  };

  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalState]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const SubmitData = () => {
    if (Shift_name && Shift_Intime && Shift_Outtime == "") {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsg("Shift cannot be empty!");
    } else {
      https
        .put("/shift/update", {
          shift_id: shiftId,

          shift_name: Shift_name,
          shift_intime: Shift_Intime,
          shift_outime: Shift_Outtime,
        })
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const handleShift = (e) => {
    setCheckStatus({});
    setMsg("");
    SetShift_Name(e.target.value);
  };
  const handleRelatedToDEs = (e) => {
    setCheckStatus({});
    setMsg("");
    SetShift_Intime(e.target.value);
  };
  const handleRelatedToDEss = (e) => {
    setCheckStatus({});
    setMsg("");
    SetShift_Outtime(e.target.value);
  };
  return (
    <div>
      <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Shift Edit
        </ModalHeader>
        <span className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              SubmitData();
              return false;
            }}
            action="#"
          >
            <ModalBody>
              <Card className="border card-border-success p-3 shadow-lg">
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Shiftname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Shift Name<span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "red" }}>{msg}</div>
                      </Label>
                      <CustomInput
                        checkNameStatus={checkNameStatus}
                        name="shift_name"
                        id="shift-field"
                        className="form-control fw-bold"
                        placeholder="Shift Name"
                        type="text"
                        value={Shift_name}
                        onChange={handleShift}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Shiftname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Shift Intime<span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "red" }}>{msg}</div>
                      </Label>
                      <Input
                        name="shift_intime"
                        id="Shift-field"
                        className="form-control fw-bold"
                        placeholder="Shift Description"
                        type="time"
                        value={Shift_Intime}
                        onChange={handleRelatedToDEs}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Shiftname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Shift Outtime<span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "red" }}>{msg}</div>
                      </Label>
                      <Input
                        name="shift_Outtime"
                        id="Shift-field"
                        className="form-control fw-bold"
                        type="time"
                        value={Shift_Outtime}
                        onChange={handleRelatedToDEss}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <button
                name="close"
                id="close"
                type="submit"
                className="btn btn-primary"
              // onClick={() => SubmitData()}
              >
                <i className="ri-save-3-line align-bottom me-1"></i>
                Update
              </button>
              <button
                name="close"
                id="close"
                type="button"
                className="btn btn-danger"
                onClick={() => Close()}
              >
                <i className="ri-close-line me-1 align-middle" />
                Close
              </button>
            </div>
          </Form>
        </span>
      </Modal>
    </div >
  );
};

export default ShiftEdit;
