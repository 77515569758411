import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Button,
  Form,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import "react-toastify/dist/ReactToastify.css";
// import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
// import CustomInput from "../Unit/Input";
import Select from "react-select";
import AuthUser from "../../helpers/Authuser";

const SalaryPay = (props) => {
  const { https, http } = AuthUser();
  const [Salary, Setsalary] = useState([]);
  const [modal, setModal] = useState(false);
  const [validation, setValidation] = useState(0)
  useEffect(() => {
    http
      .post(`/salary/show/`, { id: props.edit_data.salary_id, month: props.edit_data.salary_month })
      .then(function (response) {
        Setsalary(response.data)
      })
      .catch(function (error) {
        console.log(error);
        // SetNoMore(false);
      });
  }, [])

  const Close = () => {
    setModal(false);
    props.setModalStates();
  };

  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [totalSalary, setTotalSalary] = useState(0);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [advancedPayment, setAdvancedPayment] = useState(0);
  const [remainingPaid, setRemainingPaid] = useState(0);
  const [remainingsalary, setRemainingsalary] = useState(0);

  const calculateValues = () => {
    const total = parseFloat(Salary.total_salary_amount);
    const payment = parseFloat(Salary.payment_amount);
    // console.log(payment);
    // console.log(total);

    if (payment < total) {
      setAdvancedPayment(payment);
      setRemainingPaid(0);
      setRemainingsalary(total - payment);
    }

    else if (payment > total) {
      setAdvancedPayment(total);
      setRemainingPaid(payment - total);
      setRemainingsalary(0);
    }

  };
  const [totalCalculate, setTotalCalculate] = useState({
    pay_salary_id: 0,
    total_advance_paid: 0,
    total_remaining_paid: 0,
    total_remaining_salary: 0
  })
  const getPaidData = async () => {
    http
      .get(`/pay/calculation/${Salary.uid}`)
      .then((res) => {
        if (res.data.length > 0) {
          setTotalCalculate(res.data[0])
        }
      }).catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    getPaidData();
    calculateValues();
  }, [Salary.total_salary_amount, Salary.payment_amount]);


  const handleValue = (e) => {
    // console.log(e.target.value);
    const payment = parseFloat(e.target.value);
    const totalSalary = parseFloat(Salary.total_salary_amount);
    const advancedPayment = parseFloat(Salary.payment_amount);

    if (!isNaN(payment)) {
      if (payment <= totalSalary && payment <= advancedPayment) {
        const remainingAdvance = advancedPayment - payment;
        const remainingSalary = totalSalary - payment;

        setAdvancedPayment(payment);
        setRemainingPaid(remainingAdvance);
        setRemainingsalary(remainingSalary);
        // first if

        // Print the updated values
        // console.log("Remaining Advance:", remainingAdvance);
        // console.log("Remaining Salary:", remainingSalary);
      } else {
        // If the entered payment is greater than either the total salary or the advanced payment, 
        // set advanced payment to the smaller of the two
        const smallerPayment = Math.min(totalSalary, advancedPayment);
        setAdvancedPayment(smallerPayment);
        setRemainingPaid(0);
        setRemainingsalary(0);
        //  console.log("ifssss",smallerPayment);
        // Print the updated values
        // console.log("Remaining Advance:", 0);
        // console.log("Remaining Salary:", 0);
      }
    } else {
      // If input is not a number, reset values
      setAdvancedPayment(0);
      setRemainingPaid(0);
      // setRemainingsalary/(totalSalary);

      // Print the updated values
      // console.log("Remaining Advance:", 0);
      // console.log("Remaining Salary:", totalSalary);
    }
  };

  const SubmitData = () => {

    http
      .post("/pay/store", {
        pay_salary_id: props.edit_data.salary_id,
        pay_advance_paid: advancedPayment,
        pay_remaining_paid: remainingPaid,
        pay_remaining_salary: remainingsalary,
        pay_employee_id: Salary.uid,
        pay_payment_id: Salary.payment_id,
        pay_month: props.edit_data.salary_month
      }).then((res) => {
        console.log(res.data);
        props.checkchang(res.data.message, res.data.status, props.edit_data.salary_id);
      })
      .catch((err) => {
        console.log(err);
      })
  }
  return (
    <div><div>
      <Modal id="showModal" size="lg" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Salary Create
        </ModalHeader>
        <span className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              SubmitData();
              return false;
            }}
            action="#"
          >
            <ModalBody>
              <Card className="border card-border-success p-3 shadow-lg">
                <Row>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Productsname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Name<span style={{ color: "red" }}> *</span>
                        </div>
                        {/* {/ <div style={{ color: "red" }}>{msg}</div> /} */}
                        <div style={{ color: "red" }}>{validation === 1 ? "Please Enter Product Name!" : ""}</div>
                      </Label>
                      <Input

                        name="item_name"
                        id="item_name"
                        className="form-control fw-bold"
                        value={Salary.name}
                        type="text"
                        disabled
                      />
                    </div>
                  </Col>


                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Productsname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>Salary Month</div>
                      </Label>
                      <Input
                        className="form-control fw-bold"
                        value={props.edit_data.salary_month}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Productsname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>Salary Date</div>
                      </Label>
                      <Input type="text"
                        className="form-control fw-bold"
                        disabled
                        value={Salary.salary_date} // Set the default value to today's date
                      />
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Productsname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div> Present days</div>
                      </Label>
                      <Input
                        name="item_sale_price"
                        id="item_sale_price"
                        className="form-control fw-bold"
                        value={Salary.salary_present_days}
                        type="text"
                        disabled
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Productsname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>Absent days </div>
                      </Label>
                      <Input
                        name="item_wholesale_price"
                        id="item_wholesale_price"
                        className="form-control fw-bold"
                        value={Salary.salary_absent_days}
                        type="text"
                        disabled
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Productsname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>Salary Amount </div>
                      </Label>
                      <Input
                        name=" item_opening_qty"
                        id=" item_opening_qty"
                        className="form-control fw-bold"
                        placeholder=" Opening Quantity "
                        type="text"
                        value={parseFloat(Salary.calculated_salary).toFixed(2)}
                        disabled

                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Productsname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>Salary Incentive</div>
                      </Label>
                      <Input
                        name=" item_opening_value"
                        id=" item_opening_value"
                        className="form-control fw-bold"
                        placeholder="Opening Quantity "
                        type="text"
                        disabled
                        value={Salary.salary_incentive}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Productsname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div> Total Salary </div>
                      </Label>
                      <Input
                        name=" item_opening_value"
                        id=" item_opening_value"
                        className="form-control fw-bold"
                        placeholder="Opening Quantity "
                        type="text"
                        value={Salary.total_salary_amount}
                        disabled

                      />
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Productsname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>Advanced Payment</div>
                      </Label>
                      <Input
                        name=" item_opening_value"
                        id=" item_opening_value"
                        className="form-control fw-bold"
                        placeholder="Opening Quantity "
                        type="text"
                        value={Salary && Salary.payment_amount ? Salary.payment_amount : 0}

                        disabled

                      />
                    </div>
                  </Col>

                  {/* <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Productsname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>Advance Paid</div>
                      </Label>
                      <Input
                        // name=" item_opening_value"
                        // id=" item_opening_value"
                        className="form-control fw-bold"
                        placeholder="Opening Quantity "
                        type="text"
                        value={advancedPayment}
                        onChange={handleProduct}

                      />
                    </div>
                  </Col> */}
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Productsname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>Advance Paid</div>
                      </Label>
                      <Input
                        className="form-control fw-bold"
                        placeholder="Opening Quantity "
                        type="text"
                        value={advancedPayment}
                        onChange={handleValue}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Productsname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>Remaining Paid</div>
                      </Label>
                      <Input
                        name=" item_opening_value"
                        id=" item_opening_value"
                        className="form-control fw-bold"
                        placeholder="Opening Quantity "
                        value={parseFloat(Salary.payment_amount) - parseFloat(advancedPayment)}
                        type="text"

                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Productsname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div> remainingsalary</div>
                      </Label>
                      <Input
                        name=" item_opening_value"
                        id=" item_opening_value"
                        className="form-control fw-bold"
                        placeholder="Opening Quantity "
                        type="text"
                        value={Salary && Salary.payment_amount ? remainingsalary : Salary.total_salary_amount}

                      />
                    </div>
                  </Col>

                </Row>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <button
                // ref={submitButtonRef}
                name="close"
                id="close"
                type="submit"
                className="btn btn-primary"
              // onClick={() => SubmitData()}
              >
                <i className="ri-save-3-line align-bottom me-1"></i>
                Save
              </button>
              <button
                name="close"
                id="close"
                type="button"
                className="btn btn-danger"
                onClick={() => Close()}
              >
                <i className="ri-close-line me-1 align-middle" />
                Close
              </button>

            </div>
          </Form>
        </span>
      </Modal>
    </div></div>
  )
}

export default SalaryPay