  import React, { useEffect, useState, useCallback, useRef } from "react";
  import { Card, Row, Col, CardBody, Nav } from "reactstrap";
  import "react-toastify/dist/ReactToastify.css";
  import AuthUser from "../../../helpers/Authuser";
  import { ToastContainer } from "react-toastify";
  import ShiftLeadAdd from "../ShiftLead/ShiftLeadAdd";

  const FollowUpList = (props) => {
    const { http, user } = AuthUser();
    const [FollowUpList, setFollowUpList] = useState([]);
    const [shiftModal, setShiftModal] = useState(false);

    useEffect(() => {
      getFollowUpList();
    }, [props.count]);

    const getFollowUpList = async () => {
      http
        .post("/follow_up/list", {
          leadOrTask: props.status,
          follow_up_send_by:
            props.status === 0
              ? props.edit_data.lead_id
              : props.edit_data.task_id,
        })
        .then(function (response) {
          // console.log(response.data);
          // setFollowUpList(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return (
      <div>
        <Card className=" p-3">
          <Row>
            <Col lg={12}>
              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  ></Nav>
                  <div className="table-responsive">
                    <table
                      role="table"
                      className="align-middle table-nowrap text-center table table-hover"
                    >
                      <thead>
                        <tr>
                          <td colSpan={7}>
                            <div className="d-flex justify-content-between">
                              <h5>Working / Follow Ups </h5>

                              {/* {user.user.uid !== 1 ? (
                                <h5
                                  className="btn btn-primary mb-0"
                                  onClick={() => setShiftModal(!shiftModal)}
                                >
                                  Shift Lead
                                </h5>
                              ) : (
                                ""
                              )} */}
                            </div>
                          </td>
                        </tr>
                      </thead>
                      <thead className="table-light text-muted text-uppercase">
                        <tr>
                          <th title="Toggle SortBy" style={{ cursor: "pointer" }}>
                            #
                          </th>
                          <th title="Toggle SortBy" style={{ cursor: "pointer" }}>
                            Date
                          </th>

                          <th>Time</th>
                          <th>Follow Ups</th>
                        </tr>
                      </thead>
                      <tbody>
                        {FollowUpList.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {new Date(item.created_date).toLocaleDateString()}{" "}
                            </td>
                            <td>
                              {" "}
                              {new Date(item.created_date).toLocaleTimeString()}
                            </td>

                            <td>
                              {item.follow_up_description.replace(/<[^>]*>/g, "")}
                            </td>
                          </tr>
                        ))}
                        {FollowUpList.length === 0 ? (
                          <tr>
                            <td colSpan={8} align="center">
                              <div className="fw-bold text-danger">
                                No Follow up added.
                              </div>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {shiftModal === true ? (
                  <ShiftLeadAdd
                    modalStates={shiftModal}
                    setModalStates={() => {
                      setShiftModal(false);
                    }}
                    // checkchang={handleCallback}
                    edit_data={props.edit_data}
                    status={0}
                  />
                ) : (
                  ""
                )}
                <ToastContainer closeButton={false} limit={1} />
              </CardBody>
            </Col>
          </Row>
        </Card>
      </div>
    );
  };

  export default FollowUpList;
