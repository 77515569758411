import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  Row,
} from "reactstrap"; import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import PaymentAdd from "./PaymentAdd";
import PaymentUpdate from "./PaymentUpdate";
import AuthUser from "../../helpers/Authuser";
import PaymentView from "./PaymentView";
import InfiniteScroll from "react-infinite-scroll-component";
import Flatpickr from "react-flatpickr";
const Payment = () => {
  const [modalStates, setModalStates] = useState(false);
  const [UpdatemodalStates, setUpdateModalStates] = useState(false);
  const [ViewmodalStates, setViewModalStates] = useState(false);

  const [filterData, setfilterData] = useState({
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
    payment_type: ''

  });
  // console.log(filterData);
  //   Delete Aleart
  const { checkPermission, http, permission } = AuthUser();
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, setID] = useState();
  const onClickDelete = (data) => {
    setID(data);
    setDeleteModal(true);
  };

  // Editable data
  const [findData, setFindData] = useState();
  const onClickEdit = (index) => {
    let FindArray = getPaymentData.filter((item) => item.payment_id == index);
    setFindData(FindArray[0]);
    // console.log(FindArray[0]);
    setUpdateModalStates(!UpdatemodalStates);
  };
  const onClickView = (index) => {
    let FindArray = getPaymentData.filter((_, i) => i == index);
    setFindData(FindArray[0]);
    setViewModalStates(!ViewmodalStates);
  };
  const [counts, setCounts] = useState(1);
  const handleDeleteOrder = (data) => {
    if (data._reactName == "onClick") {
      http
        .delete(`/payment/delete/${ID}`)
        .then(function (response) {
          if (response.data.status == 1) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
          setCounts(counts + 1);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    setPaymentData([]);
    SetPages(1);
    setDeleteModal(false);
  };

  // shortcuts for opening add form
  useEffect(() => {
    document.title = "Payment CRM";

    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "a" || event.altKey && event.key === "A") {
        event.preventDefault();
        setModalStates(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  //   end Alert
  const handleCallback = (data, status) => {
    setPaymentData([]);
    SetPages(1);
    if (status == 1) {
      toast.success(data);
      setModalStates(false);

      setUpdateModalStates(false);
    } else {
      toast.warn(data);
    }

    setCounts(counts + 1);
  };
  // Get data
  const [getPaymentData, setPaymentData] = useState([]);
  // console.log(getPaymentData);
  const [PaymentType, setPaymentType] = useState([]);
  // infinity
  const [Pages, SetPages] = useState(1);
  const [NoMore, SetNoMore] = useState(true);
  useEffect(() => {
    http
      .get("/payment_type/list")
      .then((response) => {
        if (response.data.length > 0) {
          setPaymentType(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    http
      .get(`/payment/list?page=${Pages}&limit=30`)
      .then(function (response) {
        setPaymentData([...getPaymentData, ...response.data]);
        SetPages(Pages + 1);
        if (response.data.length === 0) {
          SetNoMore(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        SetNoMore(false);
      });
  }, [counts]);
  const fetchData = () => {
    setCounts(counts + 1);
  };

  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="card-header border-0">
              <Row className="align-items-center gy-3">
                  <div className="text-center pt-2 pb-4">
                    <h2 className="fw-bold mb-0">
                    Payment
                    </h2>
                  </div>
                </Row>
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    {/* <h5 className="card-title mb-0">Payment </h5> */}
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">

                      <div >
                        <div className="fw-bold">Start Date</div>
                        <Flatpickr

                          className="form-control fw-bold "
                          options={{
                            dateFormat: "d/m/Y",
                            defaultDate: filterData.startDate,
                          }}
                          name="payment_date"
                          placeholder="Start date"
                          onChange={(selectedDates) => {
                            const selectedDate = selectedDates[0];

                            const year = selectedDate.getFullYear();
                            const month = (selectedDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0");
                            const day = selectedDate
                              .getDate()
                              .toString()
                              .padStart(2, "0");
                            const formattedDate = `${year}-${month}-${day}`;

                            setfilterData({
                              ...filterData,
                              startDate: formattedDate,
                            });
                          }}
                        />
                      </div>
                      <div className="" >
                        <div className="fw-bold">End Date</div>
                        <Flatpickr
                          className="form-control fw-bold"
                          options={{
                            dateFormat: "d/m/Y",
                            defaultDate: filterData.endDate,
                          }}
                          name="payment_date"
                          placeholder="End date"
                          onChange={(selectedDates) => {
                            const selectedDate = selectedDates[0];

                            const year = selectedDate.getFullYear();
                            const month = (selectedDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0");
                            const day = selectedDate
                              .getDate()
                              .toString()
                              .padStart(2, "0");
                            const formattedDate = `${year}-${month}-${day}`;

                            setfilterData({
                              ...filterData,
                              endDate: formattedDate,
                            });
                          }}
                        />
                      </div>
                      <div>
                        <div className="fw-bold">Payment Type</div>
                        <Select
                          className="fw-bold"
                          placeholder="Select Employee"
                          options={[
                            { value: 0, label: "All" },
                            ...PaymentType.map((e) => ({
                              value: e.payment_type_id,
                              label: e.payment_type_name,
                            })),
                          ]}
                          onChange={(e) => {
                            setfilterData({
                              ...filterData,
                              payment_type: e.value,
                            });
                          }}
                        ></Select>
                      </div>
                      {permission.find(permission => permission.permission_category === "PAYMENT" && permission.permission_path === "2") && (
                        <button
                          type="button"
                          className="btn fw-bold btn-success add-btn"
                          id="create-btn"
                          onClick={() => setModalStates(!modalStates)}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Payment
                        </button>)}
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  ></Nav>
                  <InfiniteScroll
                    dataLength={getPaymentData.length}
                    next={fetchData}
                    hasMore={NoMore}
                  >
                    {permission.find(permission => permission.permission_category === "PAYMENT" && permission.permission_path === "1") && (
                      <table
                        role="table"
                        className="align-middle table-nowrap table table-hover"
                      >
                        <thead className="table-light text-muted text-uppercase">
                          <tr>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >#</th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              Date
                            </th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              Name
                            </th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              Payment Type
                            </th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              Amount
                            </th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              Remark
                            </th>

                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getPaymentData.filter((item) => (filterData.payment_type > 0 ? item.payment_type_id == filterData.payment_type : true) &&
                            (item.payment_date.split("T")[0] >= filterData.startDate &&
                              item.payment_date.split("T")[0] <= filterData.endDate)).map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    {/* {item.payment_date.split("T")[0]} */}
                                    {new Date(item.payment_date).toLocaleDateString("en-GB")}
                                  </td>
                                  <td>{item.name === null ? item.payment_receiver : item.name}</td>
                                  <td>{item.payment_type_name}</td>
                                  <td>{item.payment_amount}</td>
                                  <td>{item.payment_remark}</td>

                                  <td>
                                    <ul className="list-inline hstack gap-2 mb-0">
                                      {/* {
                                  checkPermission('Payment Create') ?
                                    <li className="list-inline-item edit">
                                      <button
                                        className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                        onClick={() => onClickView(index)}
                                      >
                                        <i className="ri-eye-fill fs-16" />
                                      </button>
                                    </li> : ""
                                } */}
                                      {permission.find(permission => permission.permission_category === "PAYMENT" && permission.permission_path === "3") && (
                                        <li className="list-inline-item edit">
                                          <button
                                            className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                            onClick={() => onClickEdit(item.payment_id)}
                                          >
                                            <i className="ri-pencil-fill fs-16" />
                                          </button>
                                        </li>)}
                                      {permission.find(permission => permission.permission_category === "PAYMENT" && permission.permission_path === "4") && (
                                        <li className="list-inline-item">
                                          {item.payment_id != 1 ? (
                                            <button
                                              onClick={() =>
                                                onClickDelete(item.payment_id)
                                              }
                                              className="text-danger d-inline-block remove-item-btn  border-0 bg-transparent"
                                            >
                                              <i className="ri-delete-bin-5-fill fs-16" />
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </li>)}
                                    </ul>
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>)}
                  </InfiniteScroll>
                </div>

                {modalStates === true ? (
                  <PaymentAdd
                    modalStates={modalStates}
                    setModalStates={() => {
                      setModalStates(false);
                    }}
                    checkchang={handleCallback}
                  />
                ) : (
                  ""
                )}
                {UpdatemodalStates === true ? (
                  <PaymentUpdate
                    modalStates={UpdatemodalStates}
                    setModalStates={() => {
                      setUpdateModalStates(false);
                    }}
                    checkchang={handleCallback}
                    edit_data={findData}
                  />
                ) : (
                  ""
                )}
                {ViewmodalStates === true ? (
                  <PaymentView
                    modalStates={ViewmodalStates}
                    setModalStates={() => {
                      setViewModalStates(false);
                    }}
                    checkchang={handleCallback}
                    edit_data={findData}
                  />
                ) : (
                  ""
                )}
                <ToastContainer closeButton={false} limit={1} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div >
  );
};

export default Payment;
