import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Button,
  Form,
} from "reactstrap";



import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";



const NotificationView = (props) => {
  const [tempValid, settempValid] = useState(0);
  const [modal, setModal] = useState(false);
const {http} = AuthUser();

  const [manageSource, setmanageSource] = useState(0);
  const [manageProduct, setmanageProduct] = useState(0);
  const [manageCustomer, setmanageCustomer] = useState(0);
  const [manageLeadStatus, setmanageLeadStatus] = useState(0);
  const [manageReferBy, setmanageReferBy] = useState(0);


  const [referBy, setreferBy] = useState(false);

  const priorityData = [
    { label: "Low", value: "1" },
    { label: "Medium", value: "2" },
    { label: "High", value: "3" },
    { label: "Urgent", value: "4" },
  ];

  const Close = () => {
    setModal(false);
    props.setModalStates();
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [checkNameStatus, setCheckStatus] = useState({});
  const [msg, setMsg] = useState("");
  const SubmitData = () => {
   
  };

  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [notification,SetNotification]= useState([]);
  useEffect(()=>{
http.get(`/notification/single/show/${props.data}`).then((res)=>{
    const firstNotification = res.data.length > 0 ? res.data[0] : {};
console.log(firstNotification);
    // Set the object as state
    SetNotification(firstNotification);
}).catch((e)=>{
    console.log(e);
})
http.get(`/notification/status/change/${props.data}`).then((res)=>{
console.log(res);
props.callbackk()
}).catch((e)=>{
    console.log(e);
})




  },[])
  const [tempCustomer, settempCustomer] = useState(0);
  return (
    <div>
         <Modal
        className="modal"
        id="fullscreeexampleModal"
        isOpen={modal}
        toggle={toggle}
        centered
            
      >
        <ModalHeader className="bg-light p-3" toggle={toggle}>

   </ModalHeader>
        <span className="tablelist-form">
          <ModalBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                SubmitData();
                return false;
              }}
              action="#"
            >
        <div className="card mb-3">
  <div className="card-body">
    <div className="mb-4">
      
      
    </div>
    <div className="table-card">
      <table className="table mb-0">
        <tbody>
          <tr>
            <td className="fw-medium">Tasks/Lead No.</td>
            <td>{notification.task_id ? notification.task_id : notification.lead_id}</td>
          </tr>
          <tr>
            <td className="fw-medium">Tasks Title</td>
            <td>Profile Page Structure</td>
          </tr>
          <tr>
            <td className="fw-medium">Project Name</td>
            <td>Velzon - Admin Dashboard</td>
          </tr>
          <tr>
            <td className="fw-medium">Priority</td>
            <td><span className={`badge ${
  notification.priority === 1
    ? "bg-warning-subtle text-dark"
    : notification.priority === 2
    ? "bg-primary-subtle text-dark"
    : notification.priority === 3
    ? "bg-secondary-subtle text-dark"
    : notification.priority === 4
    ? "bg-danger-subtle "
    : ""
  }`
            
            }>  {notification.priority === 1
                              ? "Low"
                              : notification.priority === 2
                              ? "Medium"
                              : notification.priority === 3
                              ? "High"
                              : notification.priority === 4
                              ? "Urgent"
                              : ""}</span></td>
          </tr>
          <tr>
            <td className="fw-medium">Status</td>
            <td><span className="badge bg-secondary-subtle text-secondary">Inprogress</span></td>
          </tr>
          <tr>
            <td className="fw-medium">End Date</td>
            <td>{new Date(notification.maximum_due_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })}</td>
          </tr>
        </tbody>
      </table>
      {/*end table*/}
    </div>
  </div>
</div>

            </Form>
          </ModalBody>
          <div className="modal-footer">
            <button
              name="close"
              id="close"
              type="button"
              className="btn btn-danger"
              onClick={() => Close()}
            >
              <i className="ri-close-line me-1 align-middle" />
              Close
            </button>

          </div>
        </span>
      </Modal>

     
     

    </div>
  );
};

export default NotificationView;
