import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Button,
  Form,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import CustomInput from "../../Unit/Input";
import AuthUser from "../../../helpers/Authuser";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";

const ShiftLeadAdd = (props) => {
  const [MainData, setMainData] = useState(props.edit_data);


  const { http, user } = AuthUser();
  const [modal, setModal] = useState(false);
  const [shiftLead, setshiftLead] = useState({
    slt_send_to: "",
    slt_send_by: user.user.u_type === 1 ? "" : user.user.uid,
    slt_lead_id: props.status === 0 ? props.edit_data.lead_id : "",
    slt_reason: "",
    slt_task_id: props.status === 1 ? props.edit_data.task_id : "",
    temp_employee: props.edit_data.assigned_by,
  });

  const [employeeData, setemployeeData] = useState([]);
  const getemployeeData = async () => {
    http
      .get("/franchisebusiness/list")
      .then(function (response) {
        if (response.data.length > 0) {
          const filteredemployee = response.data.filter((e) => e.u_type !== 3 && e.u_type !== 5);
          setemployeeData(filteredemployee);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Close = () => {
    setModal(false);
    props.setModalStates();
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);


  const [manageStatus, setmanageStatus] = useState(0);
  const SubmitData = () => {

    if (shiftLead.slt_send_by == "") {
      setmanageStatus(1);
    } else if (shiftLead.slt_send_to == "") {
      setmanageStatus(2);
    } else if (shiftLead.slt_reason == "") {
      setmanageStatus(3);
    } else {
      http
        .post("/transfer/lead", shiftLead)
        .then((res) => {
          props.checkchang(res.data.message, res.data.status);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    getemployeeData();
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <Modal id="showModal" size="l" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          {props.status === 0 ? "Shift Lead Add" : "Shift Task List"}
        </ModalHeader>
        <span className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              SubmitData();
              return false;
            }}
            action="#"
          >
            <ModalBody>
              <Card className="border card-border-success p-3 shadow-lg">
                <Row>
                  {user.user.u_type === 1 || user.user.u_type === 3 ?
                    <Col lg={12}>
                      <div className="fw-bold p-1 d-flex justify-content-between">
                        <div>
                          Select Employee<span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "red" }}>
                          {manageStatus === 1
                            ? "Select employee to replace!"
                            : ""}
                        </div>
                      </div>
                      <div className="d-flex">
                        {employeeData.length > 0
                          ? employeeData.map((e) =>
                            JSON.parse(MainData.assigned_by).includes(e.uid) ? (
                              <div key={e.uid}>
                                <label
                                  className={e.uid == shiftLead.slt_send_by ? "btn btn-success mx-2" : "btn btn-outline-success mx-2"}
                                  htmlFor={`pass-${e.uid}`}
                                >
                                  {e.name}
                                </label>
                                <input

                                  type="radio"
                                  className="btn-check shadow"
                                  value={e.uid}
                                  name="pass"
                                  onClick={(e) => {
                                    setshiftLead({
                                      ...shiftLead,
                                      slt_send_by: e.target.value,
                                    });
                                  }}
                                  id={`pass-${e.uid}`}
                                  autoComplete="off"
                                />
                              </div>
                            ) : null
                          )
                          : "Loading..."}
                      </div>
                    </Col>
                    : ""}
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Leadname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Employee Name<span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "red" }}>
                          {manageStatus === 2
                            ? "Employee cannot be empty!"
                            : ""}
                        </div>
                      </Label>
                      <Select
                        className="fw-bold"
                        options={[
                          {
                            label: 'Managers', // Group heading for Managers
                            options: employeeData
                              .filter(e => e.user_behave === 1 && !JSON.parse(props.edit_data.assigned_by).includes(e.uid) && (MainData.department == e.user_role_id || MainData.role_created_for == e.user_role))
                              .map((e, i) => ({
                                value: e.uid,
                                label: e.name,
                              }))
                          },
                          {
                            label: 'Employees', // Group heading for Employees
                            options: employeeData
                              .filter(e => e.user_behave === 0 && !JSON.parse(props.edit_data.assigned_by).includes(e.uid) && (MainData.department == e.user_role_id || MainData.role_created_for == e.user_role))
                              .map((e, i) => ({
                                value: e.uid,
                                label: e.name,
                              }))
                          }
                        ]}

                        onChange={(e) => {
                          setmanageStatus(0);
                          setshiftLead({
                            ...shiftLead,
                            slt_send_to: e.value,
                          });
                        }}
                      ></Select>
                    </div>
                  </Col>

                  <Col lg={12}>
                    <Label
                      htmlFor="Leadname-field"
                      className="form-label fw-bold d-flex justify-content-between"
                    >
                      <div>
                        Reason<span style={{ color: "red" }}> *</span>
                      </div>
                      <div style={{ color: "red" }}>
                        {manageStatus === 3 ? "Reason cannot be empty!" : ""}
                      </div>
                    </Label>
                    <CKEditor
                      rows={1}
                      editor={ClassicEditor}
                      onChange={(e, editor) => {
                        setmanageStatus(0);
                        setshiftLead({
                          ...shiftLead,
                          slt_reason: editor.getData(),
                        });
                      }}
                    // data={leadsData.description}
                    />
                  </Col>
                </Row>
              </Card>
            </ModalBody>
            <div className="modal-footer">

              <Button
                ref={submitButtonRef}
                name="close"
                id="close"
                type="submit"
                className="btn btn-primary"
              // onClick={() => SubmitData()}
              >
                <i className="ri-save-3-line align-bottom me-1"></i>
                Save
              </Button>
              <button
                name="close"
                id="close"
                type="button"
                className="btn btn-danger"
                onClick={() => Close()}
              >
                <i className="ri-close-line me-1 align-middle" />
                Close
              </button>

            </div>
          </Form>
        </span>
      </Modal>
    </div>
  );
};

export default ShiftLeadAdd;
