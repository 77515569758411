import React, { useEffect, useState } from 'react';
import { Card, Col, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import CustomInput from '../Unit/Input';
import { toast } from 'react-toastify';
import AuthUser from '../../helpers/Authuser';
import Select from "react-select";

const SubscriptionfeachersUpdatee = (props) => {

    const { http } = AuthUser();
    const [formData, setFormData] = useState(props.edit_data);
    const [SubscriptionCategory, setSubscriptionCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);


    const getCategory = () => {
        http
            .get("/subscription/list")
            .then(response => {
                setSubscriptionCategory(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        getCategory();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        http.put('/subscriptionfeachers/update', formData)
            .then(response => {
                if (response.data) {
                    toast.success('Subscription feachers Update successfully');
                    props.setModalStates(false);
                    props.checkchang();
                } else {
                    toast.error('Failed to Update subscription feachers');
                }
            })
            .catch(error => {
                console.error(error);
                toast.error(error.response?.data?.error || 'Failed to Update subscription feachers');
                props.setModalStates(false);
            });

    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        // console.log(formData);
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedCategory(selectedOption);
        setFormData({
            ...formData,
            subscriptionfuture_category: selectedOption ? selectedOption.value : ''
        });
    };

    return (
        <div>
            <Modal id="showModal" size="xl" isOpen={props.modalStates} centered>
                <ModalHeader className="bg-light p-3">
                    Update Subscription feachers
                </ModalHeader>
                <span className="tablelist-form">
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <Card className="border card-border-success p-3 shadow-lg">
                                <div className="mb-3">
                                    <Row>
                                        <Col lg={6}>
                                            <Label
                                                htmlFor="subscriptionfuture_category"
                                                className="form-label fw-bold d-flex justify-content-between"
                                            >
                                                <div>
                                                    feachers Category<span style={{ color: "red" }}> *</span>
                                                </div>

                                            </Label>
                                            <Select
                                                className="fw-bold"
                                                placeholder={formData.subscriptionfuture_category
                                                }
                                                options={SubscriptionCategory.map(e => ({
                                                    value: e.subscription_id,
                                                    label: e.subscription_title,
                                                }))}
                                                value={selectedCategory}
                                                onChange={handleSelectChange}
                                            />
                                        </Col>
                                        <Col lg={6}>
                                            <Label
                                                htmlFor="subscriptionplan_duration"
                                                className="form-label fw-bold d-flex justify-content-between"
                                            >
                                                <div>
                                                    feachers Name<span style={{ color: "red" }}> *</span>
                                                </div>

                                            </Label>
                                            <CustomInput
                                                id="subscriptionfuture_name"
                                                name="subscriptionfuture_name"
                                                className="form-control fw-bold"
                                                type="text"
                                                value={formData.subscriptionfuture_name}
                                                onChange={handleChange}
                                            />
                                        </Col>

                                    </Row>

                                </div>
                            </Card>
                        </ModalBody>
                        <div className="modal-footer">
                            <button
                                name="submit"
                                id="submit"
                                type="submit"
                                className="btn btn-primary"
                            >
                                <i className="ri-save-3-line align-bottom me-1"></i>
                                Save
                            </button>
                            <button
                                name="close"
                                id="close"
                                type="button"
                                className="btn btn-danger"
                                onClick={() => props.setModalStates(false)}
                            >
                                <i className="ri-close-line me-1 align-middle" />
                                Close
                            </button>
                        </div>
                    </form>
                </span>
            </Modal>
        </div>
    );
};

export default SubscriptionfeachersUpdatee;
