import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

//import images
import progileBg from "../../assets/images/profile-bg.jpg";
import Select from "react-select";
import AuthUser from "../../helpers/Authuser";
import { IMG_API_URL } from "../../helpers/url_helper";
import { ToastContainer, toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const BusinessProfile = () => {
  const { https, http } = AuthUser();
  const [activeTab, setActiveTab] = useState("1");
  const [businessLogo, setBusinessLogo] = useState(null);
  const [counts, setCounts] = useState(1);
  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [industryType, setIndustryType] = useState([]);


  const [businessStatus, setBusinessStatus] = useState(false);
  // const [Businesslogo, SetBusinesslogo] = useState("");
  const [businessProfileData, setBusinessProgilrData] = useState({
    business_name: '',
    business_company_phone_no: '',
    business_company_email: '',
    business_billing_address: '',
    business_state: '',
    business_state_code: '',
    business_pincode: '',
    business_city: '',
    business_gst_no: '',
    business_pan_number: '',
    business_type: '',
    business_industry_type: '',
    business_registration_type: '',
    business_logo: '',
  });
  // console.log(businessProfileData);
  useEffect(() => {
    http
      .get("/business-setting/list")
      .then(function (response) {
        if (response.data.length > 0) {
          // console.log(response.data);
          setBusinessStatus(true);
          setBusinessProgilrData(response.data[0]);
          // console.log(response.data[0]);
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  }, [counts + 1]);
  const businessType = [
    { name: "business_type", label: "Retailer", value: "Retailer" },
    { name: "business_type", label: "Wholesaler", value: "Wholesaler" },
    { name: "business_type", label: "Distributor", value: "Distributor" },
    { name: "business_type", label: "Manufacturer", value: "Manufacturer" },
    { name: "business_type", label: "Services", value: "Services" },
  ];
  const handleIndustryType = (e) => {
    setBusinessProgilrData({
      ...businessProfileData,
      business_industry_type: e.value,
    });
  };
  const handleSelectOptions = (e) => {
    setBusinessProgilrData({ ...businessProfileData, [e.name]: e.value });
  };

  document.title = "CRM | Business Profile Settings";
  const stateOption = [
    { value: "1", label: "Jammu and Kashmir" },
    { value: "2", label: "Himachal Pradesh" },
    { value: "3", label: "Punjab" },
    { value: "4", label: "Chandigarh" },
    { value: "5", label: "Uttarakhand" },
    { value: "6", label: "Haryana" },
    { value: "7", label: "Delhi" },
    { value: "8", label: "Rajasthan" },
    { value: "9", label: "Uttar Pradesh" },
    { value: "10", label: "Bihar" },
    { value: "11", label: "Sikkim" },
    { value: "12", label: "Arunanchal Pradesh" },
    { value: "13", label: "Nagaland" },
    { value: "14", label: "Manipur" },
    { value: "15", label: "Mizoram" },
    { value: "16", label: "Tripura" },
    { value: "17", label: "Meghalaya" },
    { value: "18", label: "Assam" },
    { value: "19", label: "West Bengal" },
    { value: "20", label: "Jharkhand" },
    { value: "21", label: "Odisha" },
    { value: "22", label: "Chattisgarh" },
    { value: "23", label: "Madhya Pradesh" },
    { value: "24", label: "Gujarat" },
    { value: "26", label: "Dadra And Nagar Haveli And Daman And  Diu " },
    { value: "27", label: "Maharashtra" },
    { value: "28", label: "Andhra Pradesh" },
    { value: "29", label: "Karnataka" },
    { value: "30", label: "Goa" },
    { value: "31", label: "Lakshadweep" },
    { value: "32", label: "Kerela" },
    { value: "33", label: "Tamil Nadu" },
    { value: "34", label: "Puducherry" },
    { value: "35", label: "Andaman and Nicobar Islands" },
    { value: "36", label: "Telangana" },
    { value: "37", label: "Andhra Pradesh" },
    { value: "38", label: "Ladakh" },
    { value: "97", label: "Other Territory" },
  ];
  const [stateCode, setStateCode] = useState(27);
  const handleState = (e) => {
    setBusinessProgilrData({
      ...businessProfileData,
      business_state: e.label,
      business_state_code: e.value,
    });
    setStateCode(e.value);
  };

  const businessRegistrationType = [
    {
      name: "business_registration_type",
      value: "Sole Proprietorship",
      label: "Sole Proprietorship",
    },
    {
      name: "business_registration_type",
      value: "Private Limited Company",
      label: "Private Limited Company",
    },
    {
      name: "business_registration_type",
      value: "Public Limited Company",
      label: "Public Limited Company",
    },
    {
      name: "business_registration_type",
      value: "Partnerships Firm",
      label: "Partnerships Firm",
    },
    {
      name: "business_registration_type",
      value: "Limited Liability Partnership",
      label: "Limited Liability Partnership",
    },
    {
      name: "business_registration_type",
      value: "One Person Company",
      label: "One Person Company",
    },
    {
      name: "business_registration_type",
      value: "Section 8 Company",
      label: "Section 8 Company",
    },
    {
      name: "business_registration_type",
      value: "Business Not Registered",
      label: "Business Not Registered",
    },
  ];

  const handleBusinessProfileData = (e) => {
    setBusinessProgilrData({
      ...businessProfileData,
      [e.target.name]: e.target.value,
    });
  };


  const [validImage, setValidImage] = useState({});
  const [validBusinessName, setValidBusinessName] = useState({});

  const OnSubmited = () => {
    if (businessStatus === false) {
      if (businessProfileData.business_name == "") {
        toast.error("Business name cannot be empty");
        setValidBusinessName({
          borderColor: "red",
        });
      } else {
        // console.log(businessProfileData);
        https
          .post("/business-setting/store", businessProfileData)
          .then(function (response) {
            // console.log(response.data);
            toast.success(response.data.message);

          })
          .catch(function (err) {
            console.log(err);
          });
      }
    } else if (businessStatus === true) {
      // console.log(businessProfileData);

      https
        .put("/business-setting/update", businessProfileData)
        .then(function (response) {

          toast.success(response.data.message);

          window.location.reload();
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  };
  const onClickDelete = (id) => {
    alert(id);
  };
  return (
    <React.Fragment>
      <div className="page-content ">
        <Container fluid>
          <div className="mt-5">
            {/* <img src={progileBg} className="profile-wid-img" alt="" /> */}
          </div>
          <Row>
            <Col xxl={12}>
              <Card className="mt-xxl-n5 ">
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <form>
                        <Row>
                          <Col lg={3}>
                            <div className="text-center">
                              <div className="profile-user position-relative d-inline-block fw-bold" style={{ textAlign: "center" }}>
                                Buisiness Logo <span style={{ color: "red" }}>*</span>
                                <br /><br />
                                {businessLogo ? (
                                  <img
                                    id="business_logo"
                                    accept="image/png, image/gif, image/jpeg"
                                    src={businessLogo ? URL.createObjectURL(businessLogo) : ""}
                                    className="rounded-sqaure avatar-xl img-thumbnail user-profile-image"
                                    alt="business_profile_images"
                                  // style={{  width: "200px", height: "200px", }} // Example CSS styles
                                  />
                                ) : businessStatus === true ? (
                                  <img
                                    accept="image/png, image/gif, image/jpeg"
                                    src={IMG_API_URL + businessProfileData.business_logo}
                                    className="rounded-sqaure avatar-xl img-thumbnail user-profile-image"
                                    alt="business_profile_images"
                                  // style={{ width: "200px", height: "200px",  }} // Example CSS styles
                                  />
                                ) : (
                                  <b style={{ color: "red", fontSize: "18px" }}>
                                    Upload Business Profile Here
                                  </b>
                                )}
                                <div className="avatar-xs p-0  profile-photo-edit">
                                  <label htmlFor="business_logo" className="profile-photo-edit avatar-xs">
                                    <span className="avatar-title bg-light text-body">
                                      <i className="ri-camera-fill"></i>
                                    </span>
                                  </label>
                                  <input
                                    onChange={(e) => {
                                      setBusinessProgilrData({
                                        ...businessProfileData,
                                        business_logo: e.target.files[0],
                                      });
                                      setBusinessLogo(e.target.files[0]);
                                    }}
                                    id="business_logo"
                                    defaultValue=""
                                    accept="image/png, image/gif, image/jpeg"
                                    type="file"
                                    className="form-control d-none"
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col lg={4} className="mt-3">
                            <div className="mb-3">
                              <Label
                                htmlFor="firstnameInput"
                                className="form-label fw-bold"
                              >
                                Business Name
                                <span style={{ color: "red" }}>*</span>
                              </Label>
                              <Input
                                type="text"
                                style={validBusinessName}
                                onKeyUp={() => {
                                  setValidBusinessName({});
                                }}
                                onChange={handleBusinessProfileData}
                                value={businessProfileData.business_name}
                                name="business_name"
                                className="form-control fw-bold"
                                id="lastnameInput"
                                placeholder="Business Name"
                              />
                            </div>
                          </Col>
                          <Col lg={4} className="mt-3">
                            <div className="mb-3">
                              <Label
                                htmlFor="lastnameInput"
                                className="form-label fw-bold"
                              >
                                Business Type
                              </Label>
                              <Select
                                name="business_type"
                                className="fw-bold"
                                placeholder={businessProfileData.business_type}
                                onChange={handleSelectOptions}
                                options={businessType}
                              />
                            </div>
                          </Col>

                          <Col lg={4} className="mt-3">
                            <div className="mb-3">
                              <Label
                                htmlFor="cityInput"
                                className="form-label fw-bold"
                              >
                                Registration Type
                              </Label>
                              <Select
                                className="fw-bold"
                                onChange={handleSelectOptions}
                                placeholder={
                                  businessProfileData.business_registration_type
                                }
                                options={businessRegistrationType}
                              />
                            </div>
                          </Col>
                          <Col lg={4} className="mt-3">
                            <div className="mb-3">
                              <Label
                                htmlFor="emailInput"
                                className="form-label fw-bold"
                              >
                                Buisiness Mobile Number
                              </Label>
                              <Input
                                type="text"
                                className="fw-bold"
                                placeholder="Whatsapp Number"
                                value={businessProfileData ? businessProfileData.business_company_phone_no : ""}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  // Check if the input value matches the pattern of exactly ten digits or is empty
                                  if (/^\d{0,10}$/.test(inputValue)) {
                                    setBusinessProgilrData({
                                      ...businessProfileData,
                                      business_company_phone_no: inputValue,
                                    });
                                  }
                                }}
                              />
                            </div>
                          </Col>
                          <Col lg={4} className="mt-3">
                            <div className="mb-3">
                              <Label
                                htmlFor="emailInput"
                                className="form-label fw-bold"
                              >
                                Buisiness  Email
                              </Label>
                              <Input
                                type="email"
                                onChange={handleBusinessProfileData}
                                value={
                                  businessProfileData.business_company_email
                                }
                                name="business_company_email"
                                className="form-control fw-bold"
                                id="emailInput"
                                placeholder="Email Address"
                              />
                            </div>
                          </Col>
                          <Col lg={4} className="mt-3">
                            <div className="mb-3">
                              <Label
                                htmlFor="cityInput"
                                className="form-label fw-bold"
                              >
                                GST No
                              </Label>
                              <Input
                                onChange={handleBusinessProfileData}
                                name="business_gst_no"
                                value={businessProfileData.business_gst_no}
                                type="text"
                                className="form-control fw-bold"
                                id="cityInput"
                                placeholder="GST No"
                              />
                            </div>
                          </Col>
                          <Col lg={4} className="mt-3">
                            <div className="mb-3">
                              <Label
                                htmlFor="cityInput"
                                className="form-label fw-bold"
                              >
                                PAN No
                              </Label>
                              <Input
                                type="text"
                                onChange={handleBusinessProfileData}
                                value={businessProfileData.business_pan_number}
                                name="business_pan_number"
                                className="form-control fw-bold"
                                id="cityInput"
                                placeholder="PAN No"
                              />
                            </div>
                          </Col>

                          <Col lg={4} className="mt-3">
                            <div className="mb-3">
                              <Label
                                htmlFor="cityInput"
                                className="form-label fw-bold"
                              >
                                Pin code
                              </Label>
                              <Input
                                type="number"
                                value={businessProfileData.business_pincode}
                                onChange={handleBusinessProfileData}
                                name="business_pincode"
                                className="form-control fw-bold"
                                id="cityInput"
                                placeholder="Enter the pin code"
                              />
                            </div>
                          </Col>
                          <Col lg={4} className="mt-3">
                            <div className="mb-3">
                              <Label
                                htmlFor="cityInput"
                                className="form-label fw-bold"
                              >
                                State{" "}
                              </Label>
                              <Select
                                className="fw-bold"
                                placeholder={businessProfileData.business_state}
                                onChange={handleState}
                                options={stateOption}
                              />
                            </div>
                          </Col>
                          <Col lg={4} className="mt-3">
                            <div className="mb-3">
                              <Label
                                htmlFor="cityInput"
                                className="form-label fw-bold"
                              >
                                State Code
                              </Label>
                              <Input
                                type="text"
                                className="form-control "
                                id="cityInput"
                                value={businessProfileData.business_state_code}
                                readOnly
                                placeholder="State Code"
                              />
                            </div>
                          </Col>
                          <Col lg={4} className="mt-3">
                            <div className="mb-3">
                              <Label
                                htmlFor="emailInput"
                                className="form-label fw-bold"
                              >
                                City
                              </Label>
                              <Input
                                value={businessProfileData.business_city}
                                onChange={handleBusinessProfileData}
                                name="business_city"
                                type="text"
                                className="form-control fw-bold"
                                id="textInput"
                                placeholder="Enter your City"
                              />
                            </div>
                          </Col>

                          <Col lg={12} className="mt-3">
                            <div className="mb-3">
                              <Label
                                htmlFor="countryInput"
                                className="form-label fw-bold"
                              >
                                Buisiness  Address
                              </Label>
                              <textarea
                                onChange={handleBusinessProfileData}
                                value={
                                  businessProfileData.business_billing_address
                                }
                                name="business_billing_address"
                                className="form-control fw-bold h-20"
                                rows={4}
                                placeholder="Enter the billing address"
                              />
                            </div>
                          </Col>



                          <Col lg={12} className="mt-3">
                            <div className="hstack gap-2 justify-content-center">
                              <button
                                ref={submitButtonRef}
                                type="button"
                                className="btn btn-primary"
                                onClick={() => OnSubmited()}
                              >
                                {businessStatus == false ? "Save" : "Update"}
                              </button>
                              {/* {businessStatus == true ? (
                                <button
                                  className="btn btn-danger"
                                  onClick={() =>
                                    onClickDelete(
                                      businessProfileData.business_id
                                    )
                                  }
                                >
                                  Delete
                                </button>
                              ) : (
                                ""
                              )} */}
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </TabPane>
                    <ToastContainer limit={1} />
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BusinessProfile;
