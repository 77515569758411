import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Button,
  Form,
  CardHeader,
  CardBody,
} from "reactstrap";

import Flatpickr from "react-flatpickr";

import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
import CustomInput from "../Unit/Input";
import Select from "react-select";
import LeadStatusAdd from "../LeadStatus/LeadStatusAdd";
import SourceAdd from "../Source/SourceAdd";
import CustomerAdd from "../Customers/CustomerAdd";
import RefrencesAdd from "../Refrences/RefrencesAdd";
import ProductAdd from "../Product/ProductAdd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import D_img from "../D_img";
import { IMG_API_URL } from "../../helpers/url_helper";

const LeadsView = (props) => {
  const { http, https, user } = AuthUser();
  const [tempValid, settempValid] = useState(0);
  const [modal, setModal] = useState(false);
  const [leadsData, setLeads] = useState(props.edit_data);
  console.log(leadsData);
  const [manageSource, setmanageSource] = useState(0);
  const [manageCustomer, setmanageCustomer] = useState(0);

  const [statusModal, setstatusModal] = useState(false);
  const [productModal, setproductModal] = useState(false);
  const [sourceModal, setsourceModal] = useState(false);
  const [customerModal, setcustomerModal] = useState(false);
  const [referBy, setreferBy] = useState(false);
  const [FollowUpListData, setFollowUpListData] = useState([]);
  const getFollowUpListData = async () => {
    http
      .post("/follow_up/list", {
        leadOrTask: 0,
        follow_up_send_by:
          leadsData.lead_id != undefined ? leadsData.lead_id : "",
      })
      .then(function (response) {
        if (response.data.length > 0) {
          setFollowUpListData(response.data);
        } else {
          setFollowUpListData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [count, setCount] = useState(0);
  const handleCallback = (data, status) => {
    if (status === 1) {
      toast.success(data);
      setCount(count + 1);
    } else {
      toast.warn(data);
      setCount(count + 1);
    }
    getcustomerData();
    getstatusData();
    gettagData();
    getassignedBy();
    getcustomerData();
    getsourceData();
  };

  const [tagData, settagData] = useState([]);
  const gettagData = async () => {
    http
      .get("/tags/list")
      .then((res) => {
        if (res.data.length > 0) {
          settagData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [statusData, setstatusData] = useState([]);
  const getstatusData = async () => {
    http
      .get("/lead/list")
      .then((res) => {
        if (res.data.length > 0) {
          setstatusData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [sourceData, setsourceData] = useState([]);
  const getsourceData = async () => {
    http
      .get("/source/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageSource === 1) {
            setLeads({
              ...leadsData,
              source: res.data[res.data.length - 1].source_id,
            });
          }
          setsourceData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [customerData, setcustomerData] = useState([]);
  const getcustomerData = async () => {
    http
      .get("/customer/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageCustomer === 1) {
            setLeads({
              ...leadsData,
              customer: res.data[res.data.length - 1].customer_id,
            });
          }
          setcustomerData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [assignedBy, setassignedBy] = useState([]);
  const getassignedBy = async () => {
    http
      .get("/franchisebusiness/list")
      .then((res) => {
        if (res.data.length > 0) {
          setassignedBy(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getcustomerData();
    getstatusData();
    gettagData();
    getassignedBy();
    getsourceData();
    getFollowUpListData();
  }, [count]);
  const Close = () => {
    setModal(false);
    props.setModalStates();
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const SubmitData = () => {
    if (leadsData.assigned_by === "[]") {
      settempValid(1);
    } else if (leadsData.customer === "") {
      settempValid(2);
    } else if (leadsData.description === "") {
      settempValid(3);
    } else {
      https
        .put("/leads/update", leadsData)
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    document.title = "View Leads | CRM";
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        props.setModalStates(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const [tempCustomer, settempCustomer] = useState(0);
  return (
    <div>
      <Modal
        className="modal-xl"
        id="fullscreeexampleModal"
        isOpen={modal}
        size={"xl"}
        toggle={toggle}
        centered
      >
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          View Leads
        </ModalHeader>
        <span className="tablelist-form">
          <ModalBody style={{ maxHeight: "80vh", overflowY: "auto" }}>
            <Card className="border card-border-success p-3 shadow-lg">
              <Row>
                <Col lg={4} className="shadow-lg">
                  <div className=" m-2">
                    <div className="">
                      <div className="text-center align-content-center ">
                        <img
                          src="https://previews.123rf.com/images/happyvector071/happyvector0711904/happyvector071190415714/121105442-creative-illustration-of-default-avatar-profile-placeholder-isolated-on-background-art-design-grey-p.jpg"
                          alt="Admin"
                          width="150"
                          height={"150px"}
                          style={{ borderRadius: "50%" }}
                        />
                        <div style={{ marginTop: "10px" }}>
                          <h4>{leadsData.customer_name}</h4>
                          <p>Mobile No: {leadsData.customer_whatsapp_no}</p>
                          <p>Email: {leadsData.customer_email}</p>
                          <p>City: {leadsData.city_name}</p>
                          <p>Address: {leadsData.customer_address}</p>
                          <p>
                            Alternate Mobile:{" "}
                            {leadsData.customer_alternative_no}
                          </p>
                          <img
                            src={
                              IMG_API_URL +
                              "/leads/" +
                              leadsData.image
                                .split(",")[0].replace(
                                  /[\[\]]/g,
                                  ""
                                )
                            }
                            height={"300px"}
                            width={"300px"}
                            alt="profile-user"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={8}>
                  <Card className="">
                    <CardHeader className="d-flex mt-2 mx-4 justify-content-between">
                      <div className="fw-bold">Lead Status</div>
                      <div className="fw-bold">Map</div>
                    </CardHeader>
                    <CardBody className="row">
                      <div className="col-12">
                        {statusData.map((e, i) => (
                          <div
                            key={i}
                            className="p-3 mx-4 d-flex justify-content-between "
                          >
                            <div className="d-flex">
                              <i
                                className={`text-${e.lead_status_color} ri-checkbox-blank-circle-fill fs-16 ms-2 mx-2`}
                              ></i>
                              <div className="mt-1">{e.lead_status_name}</div>
                            </div>
                            <div>
                              {leadsData.lead_status_id >= e.lead_status_id ? (
                                <i className="text-white bg-success p-1 ri-checkbox-circle-fill fs-16 ms-2"></i>
                              ) : (
                                <i className="text-white bg-danger p-1 ri-close-circle-fill fs-16 ms-2"></i>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="">
                        <div className="col-12 d-flex p-3 justify-content-evenly mt-5">
                          <div>Start Date</div>
                          <div>
                            {/* {leadsData.minimum_due_date.split("T")[0]} */}
                            {new Date(leadsData.minimum_due_date).toLocaleDateString("en-GB")}
                          </div>
                        </div>
                        <div className="col-12 d-flex p-3 justify-content-evenly">
                          <div>Maximum Date</div>
                          <div>
                            {/* {leadsData.maximum_due_date.split("T")[0]} */}
                            {new Date(leadsData.maximum_due_date).toLocaleDateString("en-GB")}


                          </div>
                        </div>
                        <div className="col-12 d-flex p-3 justify-content-evenly">
                          <div>Source</div>
                          <div>{leadsData.source_name}</div>
                        </div>
                        <div className="col-12 d-flex p-3 justify-content-evenly">
                          <div>Refer By</div>
                          <div>{leadsData.reference_name}</div>
                        </div>
                        <div className="col-12 d-flex p-3 justify-content-evenly">
                          <div>Priority</div>
                          <div>
                            {" "}
                            {leadsData.priority === 1
                              ? "Low"
                              : leadsData.priority === 2
                                ? "Medium"
                                : leadsData.priority === 3
                                  ? "High"
                                  : leadsData.priority === 4
                                    ? "Urgent"
                                    : ""}
                          </div>
                        </div>
                        <div className="col-12 d-flex p-3 justify-content-evenly">
                          <div>Product</div>
                          <div>{leadsData.product_name}</div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg={4}>
                  <Card className="">
                    <CardHeader className="d-flex mt-2 mx-4 justify-content-between">
                      <div className="fw-bold">Video</div>
                    </CardHeader>
                    <CardBody className="row">
                      {leadsData.lead_video &&
                        <video controls width="600">
                          <source
                            src={
                              IMG_API_URL +
                              "/leads/video/" +
                              leadsData.lead_video.split(",")[0].replace(/[\[\]]/g, "")
                            }
                            type="video/mp4"
                          />
                          Your browser does not support the video element.
                        </video>
                      }

                    </CardBody>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card className="">
                    <CardHeader className="d-flex mt-2 mx-4 justify-content-between">
                      <div className="fw-bold">Audio</div>

                    </CardHeader>
                    <CardBody className="row">
                      {leadsData.audio_file &&
                        <audio controls>
                          <source
                            src={
                              IMG_API_URL +
                              "/leads/audio/" +
                              leadsData.audio_file
                                .split(",")[0].replace(
                                  /[\[\]]/g,
                                  ""
                                )
                            } type="audio/mpeg" />
                          Your browser does not support the audio element.
                        </audio>}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card className="">
                    <CardHeader className="d-flex mt-2 mx-4 justify-content-between">
                      <div className="fw-bold">Document</div>
                    </CardHeader>
                    <CardBody className="row">


                    </CardBody>
                  </Card>
                </Col>

                <Col lg={12} className="mt-3">
                  <div className="table-responsive">
                    <table
                      role="table"
                      className="align-middle table-nowrap text-center table table-hover"
                    >
                      <thead>
                        <tr>
                          <td colSpan={7}>
                            <div className="d-flex justify-content-between">
                              <h5>Working / Follow Ups </h5>
                            </div>
                          </td>
                        </tr>
                      </thead>
                      <thead className="table-light text-muted text-uppercase">
                        <tr>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            #
                          </th>
                          <th>Image</th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            Date
                          </th>

                          <th>Time</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {FollowUpListData.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {item.follow_up_image ? <img
                                height={"50px"}
                                width={"80px"}
                                src={
                                  IMG_API_URL +
                                  "/follow-ups/" +
                                  item.follow_up_image
                                }
                                alt={
                                  IMG_API_URL +
                                  "/follow-ups/" +
                                  item.follow_up_image
                                }
                              /> : <D_img />}
                            </td>
                            <td>
                              {/* {item.created_date.split("T")[0]}{" "} */}
                              {new Date(item.created_date).toLocaleDateString("en-GB")}

                            </td>
                            <td>
                              {" "}
                              {item.created_date.split("T")[1].split(".")[0]}
                            </td>

                            <td>
                              {item.follow_up_description.replace(
                                /<[^>]*>/g,
                                ""
                              )}
                            </td>
                          </tr>
                        ))}
                        {FollowUpListData.length === 0 ? (
                          <tr>
                            <td colSpan={8} align="center">
                              <div className="fw-bold text-danger">
                                No Follow up added.
                              </div>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* <FollowUpListData
                    count={count}
                    status={lort}
                    edit_data={leadsData}
                  /> */}
                </Col>
              </Row>
            </Card>
          </ModalBody>
          <div className="modal-footer">
            <button
              name="close"
              id="close"
              type="button"
              className="btn btn-danger"
              onClick={() => Close()}
            >
              <i className="ri-close-line me-1 align-middle" />
              Close
            </button>
          </div>
        </span>
      </Modal>

      {statusModal === true ? (
        <LeadStatusAdd
          modalStates={statusModal}
          setModalStates={() => {
            setstatusModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {productModal === true ? (
        <ProductAdd
          modalStates={productModal}
          setModalStates={() => {
            setproductModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {referBy === true ? (
        <RefrencesAdd
          modalStates={referBy}
          setModalStates={() => {
            setreferBy(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {customerModal === true ? (
        <CustomerAdd
          modalStates={customerModal}
          setModalStates={() => {
            setcustomerModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {sourceModal === true ? (
        <SourceAdd
          modalStates={sourceModal}
          setModalStates={() => {
            setsourceModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default LeadsView;
