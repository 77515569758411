import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Button,
  Form,
} from "reactstrap";

import Flatpickr from "react-flatpickr";

import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
import Select from "react-select";
import LeadStatusAdd from "../LeadStatus/LeadStatusAdd";
import SourceAdd from "../Source/SourceAdd";
import CustomerAdd from "../Customers/CustomerAdd";
import RefrencesAdd from "../Refrences/RefrencesAdd";
import ProductAdd from "../Product/ProductAdd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import D_img from "../D_img";
import { IMG_API_URL } from "../../helpers/url_helper";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Status } from "filepond";

const TaskUpdate = (props) => {
  const { http, https, user } = AuthUser();

  const recorderControls = useAudioRecorder();
  const [tempValid, settempValid] = useState(0);
  const [modal, setModal] = useState(false);
  const [taskData, setTask] = useState(props.edit_data);
  const [TaskStatus, setTaskStatus] = useState(props.edit_data.lead_status_id);
  const [manageRepeat, setManageRepeat] = useState((taskData.repeat_category != "") && (taskData.repeat_category != null));
  const [frequency, setFrequency] = useState(taskData.repeat_category || "");
  const [startDate, setStartDate] = useState(taskData.repeat_startDate || "");
  const [daysOfWeek, setDaysOfWeek] = useState(() => {
    if (taskData.repeat_category === "Weekly") {
      if (Array.isArray(taskData.repeat_every_day)) {
        return taskData.repeat_every_day;
      } else if (typeof taskData.repeat_every_day === "string") {
        // Remove brackets and split by commas, then trim spaces
        return taskData.repeat_every_day
          .replace(/[\[\]]/g, "") // Remove square brackets
          .split(",") // Split by commas
          .map((day) => day.trim()); // Trim whitespace from each day
      }
    }
    return [];
  });

  const handleFrequencyChange = (selectedOption) => {
    setFrequency(selectedOption.value);
    setTask({
      ...taskData,
      repeat_category: selectedOption.value,
      repeat_every_day: selectedOption.value === "Weekly" ? daysOfWeek : [], // Set daysOfWeek for Weekly
    });
  };
  const handleDateChange = (selectedDates) => {
    setTask({
      ...taskData,
      repeat_startDate: selectedDates[0],
      repeat_every_day:
        frequency === "Monthly"
          ? selectedDates.map((date) => date.getDate()) // Store day numbers for Monthly frequency
          : taskData.repeat_every_day, // For other frequencies, keep existing days
    });
  };

  const handleDayChange = (day) => {
    const updatedDays = daysOfWeek.includes(day)
      ? daysOfWeek.filter((d) => d !== day) // Remove the day if already selected
      : [...daysOfWeek, day]; // Add the day if not selected

    setDaysOfWeek(updatedDays);

    // Update taskData to store the selected days for the repeat_every_day field
    setTask({
      ...taskData,
      repeat_every_day: `[${updatedDays.join(", ")}]`, // Save as a string in the desired format
    });

    // Log for debugging
    console.log("Updated Days:", updatedDays);
  };

  const options = [
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Yearly", label: "Yearly" },
    // { value: 'Custom', label: 'Custom' },
  ];

  const [OldImages, setOldImages] = useState(
    props.edit_data.image ? props.edit_data.image : []
  );
  const [image, setImage] = useState("");
  const [newImages, setNewImages] = useState([]);

  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);

    // If a new image is selected, clear the old image and store only the new one
    setImage(selectedImages[0]); // Store only the first selected image
    setNewImages(selectedImages); // Replace the old images with new ones
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...newImages];
    updatedImages.splice(index, 1); // Remove image by index
    setNewImages(updatedImages);

    if (updatedImages.length === 0) {
      setImage(""); // Clear the image if no images are left
    }
  };

  const addAudioElement = (blob) => {
    const uniqueFileName = `recording_${Date.now()}_${Math.floor(
      Math.random() * 10000
    )}.mp3`;

    // Create a new File object from the blob with the random file name
    const mp3File = new File([blob], uniqueFileName, { type: "audio/mp3" });

    // Update the audio blob state and taskData with the mp3 file
    setEmployeeBlob(mp3File);
    setTask({
      ...taskData,
      task_audio_file: uniqueFileName, // Add the file name to taskData
      audio_file: mp3File, // Add the actual file to taskData (if needed)
    });
  };

  const backendDateString = taskData.repeat_every_day;
  // Parse the backend date string into a JavaScript Date object
  // const parts = backendDateString.split("/");
  // const day = parseInt(parts[0], 10);
  // const month = parseInt(parts[1] - 1, 10); // Months are 0-based in JavaScript
  // const year = parseInt(parts[2], 10);
  const [EmployeeDoc, SetEmployeeDoc] = useState("");
  const [EmployeeVideo, SetEmployeeVideo] = useState("");
  const [EmployeeBlob, setEmployeeBlob] = useState("");
  const [manageSource, setmanageSource] = useState(0);
  const [manageProduct, setmanageProduct] = useState(0);
  const [manageCustomer, setmanageCustomer] = useState(0);
  const [manageLeadStatus, setmanageLeadStatus] = useState(0);
  const [manageReferBy, setmanageReferBy] = useState(0);

  const [statusModal, setstatusModal] = useState(false);
  const [productModal, setproductModal] = useState(false);
  const [sourceModal, setsourceModal] = useState(false);
  const [customerModal, setcustomerModal] = useState(false);
  const [referBy, setreferBy] = useState(false);
  const [selectedRoleIds, setSelectedRoleIds] = useState(
    user.user.u_type === 2 && user.user.user_behave == 1
      ? user.user.user_role_id
      : null
  );
  const [selectedRoleUserIds, setSelectedRoleUserIds] = useState([]);
  const [statuscheckforAssignedBy, setstatuscheckforAssignedBy] = useState(0);
  const [role, setRole] = useState([]);
  const getRole = async () => {
    http
      .get("/role/list")
      .then(function (response) {
        const filteredData =
          (user && user.user.u_type === 1) || user.user.u_type === 2
            ? response.data.filter(
                (role) =>
                  parseInt(role.role_created_user_id) ===
                    user.user.user_user_id ||
                  parseInt(role.role_created_user_id) === user.user.uid
              )
            : response.data.filter(
                (role) => parseInt(role.role_created_user_id) === user.user.uid
              );
        console.log(filteredData);
        setRole(filteredData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getRole();
  }, []);

  const priorityData = [
    { label: "Low", value: "1" },
    { label: "Medium", value: "2" },
    { label: "High", value: "3" },
    { label: "Urgent", value: "4" },
  ];

  const [count, setCount] = useState(0);
  const handleCallback = (data, status) => {
    if (status === 1) {
      toast.success(data);
      setCount(count + 1);
    } else {
      toast.warn(data);
      setCount(count + 1);
    }
    getcustomerData();
    getproductData();
    getreferByData();
    getstatusData();
    gettagData();
    getassignedBy();
    getcustomerData();
    getsourceData();
  };

  const [tagData, settagData] = useState([]);
  const gettagData = async () => {
    http
      .get("/tags/list")
      .then((res) => {
        if (res.data.length > 0) {
          settagData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [statusData, setstatusData] = useState([]);
  const getstatusData = async () => {
    http
      .get("/lead/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageLeadStatus === 1) {
            setTask({
              ...taskData,
              status: res.data[res.data.length - 1].lead_status_id,
            });
          }
          setstatusData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [referByData, setreferByData] = useState([]);
  const getreferByData = async () => {
    http
      .get("/reference/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageReferBy === 1) {
            setTask({
              ...taskData,
              ref_by: res.data[res.data.length - 1].reference_id,
            });
          }
          setreferByData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [productData, setproductData] = useState([]);
  const getproductData = async () => {
    http
      .get("/product/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageProduct === 1) {
            setTask({
              ...taskData,
              product: res.data[res.data.length - 1].product_id,
            });
          }
          setproductData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [sourceData, setsourceData] = useState([]);
  const getsourceData = async () => {
    http
      .get("/source/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageSource === 1) {
            setTask({
              ...taskData,
              source: res.data[res.data.length - 1].source_id,
            });
          }
          setsourceData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [customerData, setcustomerData] = useState([]);
  const getcustomerData = async () => {
    http
      .get("/customer/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageCustomer === 1) {
            setTask({
              ...taskData,
              customer: res.data[res.data.length - 1].customer_id,
            });
          }

          setcustomerData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [assignedBy, setassignedBy] = useState([]);
  const getassignedBy = async () => {
    https
      .get("/user/list")
      .then((res) => {
        if (res.data.length > 0) {
          const filteredemployee = res.data.filter(
            (e) => e.u_type !== 3 && e.u_type !== 5
          );

          setassignedBy(filteredemployee);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getcustomerData();
    getproductData();
    getreferByData();
    getstatusData();
    gettagData();
    getassignedBy();
    getsourceData();
  }, [count]);

  const Close = () => {
    setModal(false);
    props.setModalStates();
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const SubmitData = () => {
    let updatedTaskData = { ...taskData };

    if (newImages.length > 0) {
      updatedTaskData.image = newImages;
    } else {
      updatedTaskData.image = taskData.image;
    }

    const updatedObject = {
      ...updatedTaskData,
      department: selectedRoleIds,
    };

    const lastupdatedObject = {
      ...updatedObject,
      status: props.edit_data.lead_status_id,
    };

    if (taskData.assigned_by === "[]") {
      settempValid(1);
    } else if (taskData.customer === "") {
      settempValid(2);
    } else if (taskData.description === "") {
      settempValid(3);
    } else {
      // console.log("updatedObject",updatedObject);
console.log("lastupdatedObject",lastupdatedObject);

      https
        .put("/task/update", lastupdatedObject)
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const [tempCustomer, settempCustomer] = useState(0);
  const assignedByIds = taskData.assigned_by
    ? JSON.parse(taskData.assigned_by)
    : [];

  const defaultAssignedOptions = assignedByIds.map((tagId) => {
    const tag = assignedBy.find((e) => e.uid === tagId);
    return tag ? { value: tag.uid, label: tag.name } : null;
  });
  const selectedTagIds = taskData.tags ? JSON.parse(taskData.tags) : [];

  // Convert tag IDs to options
  const defaultSelectedOptions = selectedTagIds.map((tagId) => {
    const tag = tagData.find((e) => e.tag_id === tagId);
    return tag ? { value: tag.tag_id, label: tag.tag_name } : null;
  });

  const [isDescriptionListening, setIsDescriptionListening] = useState(false);

  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  // Mapping spoken numbers and symbols to digits and symbols
  const numberAndSymbolMapping = {
    zero: "0",
    one: "1",
    two: "2",
    three: "3",
    four: "4",
    five: "5",
    six: "6",
    seven: "7",
    eight: "8",
    nine: "9",
    dot: ".",
    comma: ",",
    dash: "-",
    plus: "+",
    at: "@",
    hashtag: "#",
    dollar: "$",
    percent: "%",
    ampersand: "&",
    asterisk: "*",
    exclamation: "!",
    question: "?",
    slash: "/",
    backslash: "\\",
  };

  // Convert spoken numbers and symbols to digits/symbols
  const convertSpokenNumbersToDigits = (text) => {
    return text
      .split(" ")
      .map((word) => numberAndSymbolMapping[word.toLowerCase()] || word)
      .join(" ");
  };

  // Update description field based on voice input
  useEffect(() => {
    if (isDescriptionListening && transcript) {
      const convertedTranscript = convertSpokenNumbersToDigits(transcript);
      setTask((prevData) => ({
        ...prevData,
        description: prevData.description + " " + convertedTranscript, // Append voice input to description
      }));
      resetTranscript(); // Clear transcript after appending
    }
  }, [transcript, isDescriptionListening]);

  // Toggle microphone button
  useEffect(() => {
    if (isDescriptionListening) {
      SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
    } else {
      SpeechRecognition.stopListening();
    }
  }, [isDescriptionListening]);

  // Handle microphone button click
  const handleDescriptionMicClick = () => {
    setIsDescriptionListening(!isDescriptionListening); // Toggle mic on/off
  };

  if (!browserSupportsSpeechRecognition) {
    return <div>Your browser does not support speech recognition.</div>;
  }

  return (
    <div>
      <Modal
        className="modal-md" // Change size class to smaller one
        id="fullscreeexampleModal"
        isOpen={modal}
        size={"lg"} // Change size from "xl" to "md" for medium size
        toggle={toggle}
        centered
      >
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Task Details{" "}
        </ModalHeader>
        <span className="tablelist-form">
          <ModalBody style={{ maxHeight: "80vh", overflowY: "auto" }}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                SubmitData();
                return false;
              }}
              action="#"
            >
              <Card className="border card-border-success p-3 shadow-lg">
                <div className="mb-3">
                  <Row>
                    <Col lg={12}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between mt-3"
                      >
                        <div>Task Title</div>
                      </Label>
                      <Input
                        name="task_title"
                        id="task_title"
                        className="form-control fw-bold"
                        placeholder="TITLE"
                        value={taskData.task_title} // Bind the input to taskData.task_title
                        onChange={(e) => {
                          setTask({
                            ...taskData,
                            task_title: e.target.value, // Update task_title field
                          });
                        }}
                        type="text"
                      />
                    </Col>
                    <Col lg={12}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>
                          Description<span className="text-danger"> *</span>
                        </div>
                        <div className="text-danger">
                          {tempValid === 3
                            ? "Description cannot be empty!"
                            : ""}
                        </div>
                      </Label>
                      {/* Microphone Button */}
                      <Button
                        type="button"
                        color="none"
                        style={{
                          position: "absolute",
                          top: "70%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          width: "50px",
                          height: "50px",
                          padding: "0",
                          borderRadius: "50%",
                          zIndex: "10",
                          background: "none", // No background
                          border: "none", // No border
                          boxShadow: "none", // No shadow
                        }}
                        onClick={handleDescriptionMicClick} // Toggle microphone on click
                      >
                        <i
                          className={
                            isDescriptionListening
                              ? "ri-stop-circle-fill align-bottom" // Icon when listening
                              : "ri-mic-fill align-bottom" // Icon when not listening
                          }
                        ></i>
                      </Button>

                      <textarea
                        rows={1}
                        className="form-control"
                        value={taskData.description}
                        onChange={(e) => {
                          settempValid(0);
                          setTask({
                            ...taskData,
                            description: e.target.value,
                          });
                        }}
                        style={{ height: "130px", resize: "none" }}
                      />
                    </Col>
                    {user.user.u_type === 1 || user.user.u_type === 3 ? (
                      <Col lg={6}>
                        <Label
                          htmlFor="categoryname-field"
                          className="form-label fw-bold d-flex justify-content-between mt-3 "
                        >
                          <div>
                            Department<span className="text-danger"> *</span>
                          </div>
                          <div style={{ color: "red" }}>
                            {tempValid === 1
                              ? "Please select the assigned!"
                              : ""}
                          </div>
                        </Label>
                        <Select
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused
                                ? "white"
                                : "white",
                            }),
                          }}
                          className={
                            tempValid === 1 ? "border border-danger" : ""
                          }
                          // Pre-select the department based on the selected task's department
                          value={role
                            .map((e) => ({
                              value: e.role_id,
                              label: e.role_name,
                            }))
                            .find(
                              (option) => option.value === taskData.department
                            )} // Show selected department
                          options={role.map((e) => ({
                            value: e.role_id,
                            label: e.role_name,
                          }))} // Map the role data to options for the select box
                          onChange={(e) => {
                            setTask({
                              ...taskData,
                              department: e.value, // Update the selected department in taskData
                            });
                            setSelectedRoleIds(e.value); // Optionally update the selected role ID
                            setstatuscheckforAssignedBy(
                              statuscheckforAssignedBy + 1
                            );
                          }}
                        />
                      </Col>
                    ) : (
                      <></>
                    )}

                    <Col lg={6}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between mt-3 "
                      >
                        <div>
                          Team Member<span className="text-danger"> *</span>
                        </div>
                        <div style={{ color: "red" }}>
                          {tempValid === 1 ? "Please select the assigned!" : ""}
                        </div>
                      </Label>
                      {/* {user.user.u_type === 1 ||
                        (user.user.u_type == 2 && user.user.user_behave == 1) ? ( */}
                      {user.user.u_type === 1 ||
                      (user.user.u_type === 2 && user.user.user_behave == 1) ||
                      (user.user.u_type === 4 && user.user.user_behave == 1) ||
                      user.user.u_type === 3 ? (
                        <Select
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused
                                ? "white"
                                : "white",
                            }),
                          }}
                          options={[
                            {
                              label: "Managers", // Group heading for Managers
                              options: assignedBy
                                .filter(
                                  (e) =>
                                    e.user_behave === 1 &&
                                    (selectedRoleUserIds.length === 0 ||
                                      selectedRoleUserIds.includes(
                                        e.uid.toString()
                                      ))
                                )
                                .map((e, i) => ({
                                  value: e.uid,
                                  label: e.name, // Show the name under Managers group
                                  index: i,
                                })),
                            },
                            {
                              label: "Employees", // Group heading for Employees
                              options: assignedBy
                                .filter(
                                  (e) =>
                                    e.user_behave === 0 &&
                                    (selectedRoleUserIds.length === 0 ||
                                      selectedRoleUserIds.includes(
                                        e.uid.toString()
                                      ))
                                )
                                .map((e, i) => ({
                                  value: e.uid,
                                  label: e.name, // Show the name under Employees group
                                  index: i,
                                })),
                            },
                          ]}
                          isMulti
                          value={defaultAssignedOptions}
                          onChange={(e) => {
                            if (e) {
                              // Extract values from selected options and store in an array
                              const selectedValues = e.map(
                                (option) => option.value
                              );
                              setTask({
                                ...taskData,
                                assigned_by: `[${selectedValues.join(",")}]`,
                              });
                            }
                          }}
                        />
                      ) : (
                        <Input
                          value={user.user.name}
                          className="fw-bold"
                          disabled
                        />
                      )}
                    </Col>
                    {/* <Col lg={6}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between mt-3 "
                      >
                        <div>Status</div>
                        <button
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => {
                            setmanageLeadStatus(1);
                            setstatusModal(!statusModal);
                          }}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </button>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        placeholder={
                          statusData.find(
                            (e) => e.lead_status_id === taskData.lead_status_id
                          )?.lead_status_name || "Select Status" // Default placeholder if not found
                        }
                        options={statusData.filter((temp) => temp.lead_status_id != 2).map((e, i) => ({
                          value: e.lead_status_id,
                          label: e.lead_status_name,
                        }))}
                        className="fw-bold"
                        name="task_status"
                        id="task_status"
                        onChange={(e) => {
                          setTask({
                            ...taskData,
                            status: e.value,
                          });
                        }}
                      // placeholder={
                      //   statusData.length != 0
                      //     ? manageLeadStatus == 0
                      //       ? statusData[0].lead_status_name
                      //       : statusData[statusData.length - 1]
                      //         .lead_status_name
                      //     : ""
                      // }
                      />
                    </Col> */}

                    {/* <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  "
                      >
                        <div>Source</div>
                        <button
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => {
                            setmanageSource(1);
                            setsourceModal(!sourceModal);
                          }}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </button>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        placeholder={
                          sourceData.length !== 0
                            ? manageSource == 0
                              ? sourceData[0].source_name
                              : sourceData[sourceData.length - 1].source_name
                            : ""
                        }
                        options={sourceData.map((e, i) => ({
                          value: e.source_id,
                          label: e.source_name,
                        }))}
                        onChange={(e) => {
                          setTask({
                            ...taskData,
                            source: e.value,
                          });
                        }}
                        className="fw-bold"
                        name="source"
                        id="source"
                      />
                    </Col> */}

                    {/* <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>
                          Customer<span className="text-danger"> *</span>
                        </div>
                        <div style={{ color: "red" }}>
                          {tempValid === 2 ? "Select the customer!" : ""}
                          <button
                            className="btn btn-success btn-sm px-1"
                            style={{ padding: "0px" }}
                            onClick={() => {
                              setcustomerModal(!customerModal);
                            }}
                          >
                            <i className="ri-add-line align-bottom"></i>
                          </button>
                        </div>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        className={
                          tempValid === 2
                            ? "fw-bold border border-danger"
                            : "fw-bold"
                        }
                        name="customer"
                        id="customer"
                        placeholder={taskData.customer_name}
                        onChange={(e) => {
                          settempValid(0);
                          setmanageCustomer(1);
                          settempCustomer(e.index);
                          setTask({
                            ...taskData,
                            customer: e.value,
                          });
                        }}
                        options={customerData.map((e, i) => ({
                          value: e.customer_id,
                          label: e.customer_name,
                          index: i,
                        }))}
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Phone</div>
                      </Label>
                      <Input
                        readOnly
                        name="phone"
                        id="phone"
                        className="form-control fw-bold"
                        value={
                          manageCustomer !== 0
                            ? customerData[tempCustomer].customer_whatsapp_no
                            : taskData.customer_whatsapp_no
                        }
                        type="text"
                        disabled
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Email</div>
                      </Label>
                      <Input
                        readOnly
                        name="email"
                        id="email"
                        className="form-control fw-bold"
                        placeholder="Email"
                        type="text"
                        value={
                          manageCustomer !== 0
                            ? customerData[tempCustomer].customer_email
                            : taskData.customer_email
                        }
                        disabled
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>City</div>
                      </Label>
                      <Input
                        readOnly
                        name="city"
                        id="city"
                        className=" fw-bold "
                        placeholder="City"
                        type="text"
                        value={
                          manageCustomer !== 0
                            ? customerData[tempCustomer].city_name
                            : taskData.city_name
                        }
                        disabled
                      />
                    </Col> */}
                    {/* <Col lg={6}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Refer By</div>
                        <button
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => {
                            setmanageReferBy(1);
                            setreferBy(!referBy);
                          }}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </button>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        className="fw-bold"
                        name="ref_by"
                        id="ref_by"
                        placeholder={
                          manageReferBy === 0
                            ? taskData.reference_name
                            : referByData[referByData.length - 1].reference_name
                        }
                        onChange={(e) => {
                          setTask({
                            ...taskData,
                            ref_by: e.value,
                          });
                        }}
                        options={referByData.map((e, i) => ({
                          value: e.reference_id,
                          label: e.reference_name,
                        }))}
                      />
                    </Col> */}
                    {/* <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Product</div>
                        <button
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => {
                            setmanageProduct(1);
                            setproductModal(!productModal);
                          }}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </button>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        className="fw-bold"
                        name="product"
                        id="product"
                        placeholder={
                          manageProduct === 0
                            ? taskData.product_name
                            : productData[productData.length - 1].product_name
                        }
                        onChange={(e) => {
                          setTask({
                            ...taskData,
                            product: e.value,
                          });
                        }}
                        options={productData.map((e, i) => ({
                          value: e.product_id,
                          label: e.product_name,
                        }))}
                      />
                    </Col> */}
                    {/* <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Value</div>
                      </Label>
                      <Input
                        required
                        name="value"
                        id="value"
                        className="form-control fw-bold"
                        placeholder={taskData.value}
                        onChange={(e) => {
                          setTask({
                            ...taskData,
                            value: e.target.value,
                          });
                        }}
                        type="text"
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Tags</div>
                      </Label>
                      {tagData.length !== 0 ? (
                        <Select
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused ? 'white' : 'white',

                            }),
                          }}
                          options={tagData.map((e, i) => ({
                            label: e.tag_name,
                            value: e.tag_id,
                            index: i,
                          }))}
                          isMulti
                          value={defaultSelectedOptions}
                          onChange={(e) => {
                            if (e) {
                              const selectedValues = e.map(
                                (option) => option.value
                              );
                              setTask({
                                ...taskData,
                                tags: `[${selectedValues.join(",")}]`,
                              });
                            }
                          }}
                        />
                      ) : (
                        <span className="fw-bold text-danger">
                          No tags added
                        </span>
                      )}
                    </Col> */}

                    {/* <Col lg={2}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Total Cycles</div>
                      </Label>

                      <div className="d-flex   align-items-center">
                        {" "}
                        <Col lg={2} className="text-center ">
                          <Input
                            type="checkbox"
                            onClick={(e) => {
                              if (e.target.checked) {
                                settc(!tc);
                                setTask({
                                  ...taskData,
                                  total_cycles: "Infinity",
                                });
                              } else {
                                settc(!tc);
                                setTask({
                                  ...taskData,
                                  total_cycles: taskData.total_cycles,
                                });
                              }
                            }}
                          ></Input>{" "}
                        </Col>
                        <Col lg={10}>
                          <Input
                            disabled={tc}
                            className="fw-bold"
                            placeholder="0"
                            value={taskData.total_cycles}
                            onChange={(e) => {
                              setTask({
                                ...taskData,
                                total_cycles: e.target.value,
                              });
                            }}
                          />
                        </Col>
                      </div>
                    </Col> */}
                    <Col lg={6}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div> Start Date</div>
                      </Label>
                      <Flatpickr
                        className="form-control fw-bold"
                        options={{
                          dateFormat: "d/m/Y h:i K",
                          enableTime: true, // Enable time selection
                          time_12hr: true,
                          defaultDate: taskData.minimum_due_date,
                        }}
                        onChange={(selectedDates) => {
                          const selectedDate = selectedDates[0];

                          // Extract date components
                          const day = selectedDate
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const month = (selectedDate.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const year = selectedDate.getFullYear();

                          // Extract time components for 12-hour format with AM/PM
                          const hours = selectedDate.getHours() % 12 || 12; // 12-hour format
                          const minutes = selectedDate
                            .getMinutes()
                            .toString()
                            .padStart(2, "0");
                          const ampm =
                            selectedDate.getHours() >= 12 ? "PM" : "AM"; // AM/PM indicator

                          // Format the date and time as "YYYY-MM-DD h:i AM/PM"
                          const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;

                          // Set the task data with the formatted date and time
                          setTask({
                            ...taskData,
                            minimum_due_date: formattedDateTime,
                          });
                        }}
                        name="date"
                      />
                    </Col>

                    <Col lg={6}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>End Date</div>
                      </Label>
                      <Flatpickr
                        className="form-control fw-bold"
                        options={{
                          dateFormat: "d/m/Y h:i K", // Specify the desired date format as "yyyy-mm-dd"
                          enableTime: true, // Enable time selection
                          time_12hr: true,
                          defaultDate: taskData.maximum_due_date, // Set the default date to an empty string if taskData.maximum_due_date is falsy
                        }}
                        onChange={(selectedDates) => {
                          const selectedDate = selectedDates[0];

                          // Extract date components
                          const day = selectedDate
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const month = (selectedDate.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const year = selectedDate.getFullYear();

                          // Extract time components for 12-hour format with AM/PM
                          const hours = selectedDate.getHours() % 12 || 12; // 12-hour format
                          const minutes = selectedDate
                            .getMinutes()
                            .toString()
                            .padStart(2, "0");
                          const ampm =
                            selectedDate.getHours() >= 12 ? "PM" : "AM"; // AM/PM indicator

                          // Format the date and time as "YYYY-MM-DD h:i AM/PM"
                          const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;

                          // Set the task data with the formatted date and time
                          setTask({
                            ...taskData,
                            maximum_due_date: formattedDateTime,
                          });
                        }}
                        name="date"
                      />
                    </Col>
                    <Col lg={6}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Next Follow Up Date</div>
                      </Label>
                      <Flatpickr
                        className="form-control fw-bold"
                        options={{
                          dateFormat: "d/m/Y h:i K", // Use 'h:i K' for 12-hour time with AM/PM
                          enableTime: true, // Enable time selection
                          time_12hr: true,
                          defaultDate: taskData.next_follow_up,
                        }}
                        onChange={(selectedDates) => {
                          const selectedDate = selectedDates[0];

                          // Extract date components
                          const day = selectedDate
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const month = (selectedDate.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const year = selectedDate.getFullYear();

                          // Extract time components
                          const hours = selectedDate.getHours() % 12 || 12; // 12-hour format
                          const minutes = selectedDate
                            .getMinutes()
                            .toString()
                            .padStart(2, "0");
                          const ampm =
                            selectedDate.getHours() >= 12 ? "PM" : "AM"; // AM/PM indicator

                          // Format the date and time as "YYYY-MM-DD h:i AM/PM"
                          const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;

                          // Set the task data with both date and time
                          setTask({
                            ...taskData,
                            next_follow_up: formattedDateTime,
                          });
                        }}
                        name="date"
                      />
                    </Col>
                    <Col lg={6}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        <div>Priority</div>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused
                              ? "white"
                              : "white",
                          }),
                        }}
                        className="fw-bold"
                        name="priority"
                        id="priority"
                        options={priorityData.map((e, i) => ({
                          value: e.value,
                          label: e.label,
                        }))}
                        onChange={(e) => {
                          setTask({
                            ...taskData,
                            priority: e.value,
                          });
                        }}
                        placeholder={
                          priorityData.find(
                            (e) => e.value === String(taskData.priority) // Ensure both are strings for comparison
                          )?.label || "Select Priority" // Default placeholder if not found
                        }

                        // placeholder={priorityData[0].label}
                      />
                    </Col>
                    <Col lg={6}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label w-100 fw-bold mt-3"
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-repeat me-2"></i>
                            <span>Repeat</span>
                          </div>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="repeatCheckbox"
                            checked={manageRepeat}
                            onChange={() => {
                              setManageRepeat(!manageRepeat);

                              // Clear repeat fields when unchecked
                              if (manageRepeat) {
                                setTask({
                                  ...taskData,
                                  repeat_category: '',
                                  repeat_every_day: "[]",
                                  repeat_startDate: null,
                                });
                                setFrequency("");
                                setDaysOfWeek([]);
                              }
                            }}
                          />
                        </div>

                        {/* Show Select Dropdown if Repeat is Enabled */}
                        {manageRepeat && (
                          <div className="mt-3">
                            <Select
                              className="me-2 shadow-sm"
                              options={options}
                              onChange={handleFrequencyChange}
                              value={options.find(
                                (option) => option.value === frequency
                              )} // Show taskData frequency as selected option
                              placeholder="Select Frequency"
                            />
                          </div>
                        )}

                        {/* Frequency-based Inputs */}
                        {manageRepeat && frequency && (
                          <div className="mt-4 bg-light p-3 rounded">
                            {/* Weekly Frequency */}
                            {frequency === "Weekly" && (
                              <>
                                <div className="d-flex justify-content-evenly mb-3">
                                  {[
                                    "Sun",
                                    "Mon",
                                    "Tue",
                                    "Wed",
                                    "Thu",
                                    "Fri",
                                    "Sat",
                                  ].map((day) => (
                                    <div key={day} className="text-center">
                                      <label>{day}</label>
                                      <br />
                                      <input
                                        type="checkbox"
                                        value={day}
                                        className="form-input-check"
                                        checked={daysOfWeek.includes(day)} // Check the box if the day is in daysOfWeek
                                        onChange={() => handleDayChange(day)}
                                      />
                                    </div>
                                  ))}
                                </div>
                                <Label
                                  htmlFor="categoryname-field"
                                  className="form-label fw-bold d-flex justify-content-between mt-3"
                                >
                                  <div>Start Date</div>
                                </Label>
                                <Flatpickr
                                  className="form-control fw-bold shadow-sm"
                                  options={{
                                    dateFormat: "d/m/Y h:i K",
                                    enableTime: true,
                                    time_12hr: true,
                                    defaultDate: taskData.repeat_startDate, // Placeholder from taskData
                                  }}
                                  onChange={handleDateChange}
                                  name="date"
                                />
                              </>
                            )}

                            {/* Monthly Frequency */}
                            {frequency === "Monthly" && (
                              <>
                                <Label
                                  htmlFor="categoryname-field"
                                  className="form-label fw-bold d-flex justify-content-between mt-3"
                                >
                                  <div>Select Dates</div>
                                </Label>
                                <Flatpickr
                                  className="form-control fw-bold shadow-sm mb-3"
                                  options={{
                                    mode: "multiple",
                                    dateFormat: "d",
                                    inline: true,
                                    defaultDate:
                                      typeof taskData.repeat_every_day ===
                                      "string"
                                        ? taskData.repeat_every_day.split(",") // If it's a string, split by comma to create an array
                                        : [], // Default to an empty array if it's not an array
                                  }}
                                  onChange={(selectedDates) => {
                                    setTask({
                                      ...taskData,
                                      repeat_every_day: `[${selectedDates
                                        .map((date) => date.getDate())
                                        .join(", ")}]`,
                                    });
                                  }}
                                />
                                <Label
                                  htmlFor="categoryname-field"
                                  className="form-label fw-bold d-flex justify-content-between mt-3"
                                >
                                  <div>Start Date</div>
                                </Label>
                                <Flatpickr
                                  className="form-control fw-bold shadow-sm"
                                  options={{
                                    dateFormat: "d/m/Y h:i K",
                                    enableTime: true,
                                    time_12hr: true,
                                    defaultDate: taskData.repeat_startDate, // Placeholder from taskData
                                  }}
                                  onChange={handleDateChange}
                                />
                              </>
                            )}

                            {/* Daily and Yearly Frequencies */}
                            {frequency === "Daily" || frequency === "Yearly" ? (
                              <>
                                <Label
                                  htmlFor="categoryname-field"
                                  className="form-label fw-bold d-flex justify-content-between mt-3"
                                >
                                  <div>Start Date</div>
                                </Label>
                                <Flatpickr
                                  className="form-control fw-bold shadow-sm mb-3"
                                  options={{
                                    dateFormat: "d/m/y h:i K",
                                    enableTime: true,
                                    time_12hr: true,
                                    defaultDate: taskData.repeat_startDate, // Placeholder from taskData
                                  }}
                                  onChange={handleDateChange}
                                  name="date"
                                />
                              </>
                            ) : null}
                          </div>
                        )}
                      </Label>
                    </Col>

                    {/* 
                    <Col lg={2}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Next Follow Up Date</div>
                      </Label>
                      <Flatpickr
                        className="form-control fw-bold"
                        options={{
                          dateFormat: "d/m/Y h:i K", // Use 'h:i K' for 12-hour time with AM/PM
                          enableTime: true,          // Enable time selection
                          time_12hr: true,
                          defaultDate: taskData.next_follow_up,
                        }}
                        onChange={(selectedDates) => {
                          const selectedDate = selectedDates[0];

                          // Extract date components
                          const day = selectedDate.getDate().toString().padStart(2, "0");
                          const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
                          const year = selectedDate.getFullYear();

                          // Extract time components
                          const hours = selectedDate.getHours() % 12 || 12; // 12-hour format
                          const minutes = selectedDate.getMinutes().toString().padStart(2, "0");
                          const ampm = selectedDate.getHours() >= 12 ? "PM" : "AM"; // AM/PM indicator

                          // Format the date and time as "YYYY-MM-DD h:i AM/PM"
                          const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;

                          // Set the task data with both date and time
                          setTask({
                            ...taskData,
                            next_follow_up: formattedDateTime,
                          });
                        }}
                        name="date"
                      />
                    </Col> 
                    {/* <Col lg={2}>
                      <div className=" mt-3 ">
                        <h5 className="fs-15 mb-1">Upload Image</h5>
                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute top-100 start-100 translate-middle">
                              <label
                                htmlFor="taskImage"
                                className="mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title=""
                                data-bs-original-title="Select Image"
                              >
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i className="ri-image-fill"></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="taskImage"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={(e) => {
                                  setTask({
                                    ...taskData,
                                    image: e.target.files[0],
                                  });
                                }}
                              />
                            </div>

                            <div className="avatar-lg">
                              <div className="avatar-title bg-light rounded">
                                {taskData.image != null ? (
                                  <img
                                    src={
                                      taskData.image.name === undefined
                                        ? IMG_API_URL +
                                          "/task/" +
                                          taskData.image
                                        : URL.createObjectURL(taskData.image)
                                    }
                                    id="product-img"
                                    alt={taskData.image}
                                    className="h-auto"
                                    width={"100px"}
                                  />
                                ) : (
                                  <D_img />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                  <Row>
                    {/* <Col lg={6}>
                      <div className="mt-3">
                        <h5 className="fs-15 mb-1">Past Images</h5>
                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="avatar-lg">
                              <div className="avatar-title bg-light rounded">
                                {OldImages !== "[]" ? (
                                  OldImages.split(",")
                                    .slice(0, 7)
                                    .map((image, index) => (
                                      <div
                                        key={index}
                                        className="position-relative"
                                      >
                                        <img
                                          src={
                                            image
                                              ? `${IMG_API_URL}task/${image.replace(
                                                /^\[|\]$/g,
                                                ""
                                              )}`
                                              : URL.createObjectURL(image)
                                          }
                                          alt={`product-img-${index}`}
                                          className="m-2"
                                          width={"100px"}
                                          height={"100px"}
                                        />
                                      </div>
                                    ))
                                ) : (
                                  <D_img />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col> */}

                    {/* Upload section with updated design */}

                    <Row>
                      <div className="mt-5 text-center">
                        <div className="d-flex justify-content-center align-items-center flex-wrap">
                          {/* Image Upload */}
                          <Col lg="3" sm="12" className="col-12 mb-3">
                            <div className="position-relative mx-3">
                              <div className="avatar-title bg-light rounded">
                                {image ? (
                                  <img
                                    src={URL.createObjectURL(image)}
                                    id="product-img"
                                    alt="product-img"
                                    height={"100px"}
                                    width={"100px"}
                                  />
                                ) : taskData && taskData.image != "[]" ? (
                                  <img
                                    src={`${IMG_API_URL}/task/${
                                      taskData.image
                                        ? taskData.image
                                            .replace(/[\[\]']+/g, "")
                                            .trim()
                                        : ""
                                    }`}
                                    id="product-img"
                                    alt="product-img"
                                    height={"100px"}
                                    width={"100px"}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <label
                                htmlFor="taskImages"
                                className="mb-0 cursor-pointer"
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title="Select Images"
                              >
                                <div className="avatar-xs">
                                  <div
                                    className="avatar-title border rounded-circle text-light"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    <i
                                      className="ri-image-fill"
                                      style={{
                                        fontSize: "20px",
                                        color: "#000",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="taskImages"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                multiple
                                onChange={handleImageChange}
                              />
                              {newImages.length > 0 && (
                                <span className="ml-2 d-block mt-1">
                                  {newImages
                                    .map((file) => file.name)
                                    .join(", ")}
                                </span>
                              )}
                            </div>
                          </Col>

                          {/* Video Upload */}
                          <Col lg="3" sm="12" className="col-12 mb-3">
                            <div className="position-relative mx-3">
                              <div className="avatar-title bg-light rounded">
                                {EmployeeVideo ? (
                                  <video
                                    src={URL.createObjectURL(EmployeeVideo)}
                                    id="employee-video"
                                    height={"100px"}
                                    width={"100px"}
                                    controls
                                  />
                                ) : taskData.task_video !== null ? (
                                  <video
                                    src={`${IMG_API_URL}/task/${
                                      taskData.task_video
                                        ? taskData.task_video
                                            .replace(/[\[\]']+/g, "")
                                            .trim()
                                        : ""
                                    }`}
                                    id="employee-video"
                                    height={"100px"}
                                    width={"100px"}
                                    controls
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <label
                                htmlFor="employeeVideo"
                                className="mb-0 cursor-pointer"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Select Video"
                              >
                                <div className="avatar-xs">
                                  <div
                                    className="avatar-title border rounded-circle text-light"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    <i
                                      className="ri-video-fill"
                                      style={{
                                        fontSize: "20px",
                                        color: "#000",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="employeeVideo"
                                type="file"
                                accept="video/mp4, video/avi, video/mkv"
                                onChange={(e) => {
                                  setTask({
                                    ...taskData,
                                    video: e.target.files[0],
                                  });
                                  SetEmployeeVideo(e.target.files[0]);
                                }}
                              />
                              {EmployeeVideo && (
                                <span className="ml-2">
                                  {EmployeeVideo.name}
                                </span>
                              )}
                            </div>
                          </Col>

                          {/* Document Upload */}
                          <Col lg="3" sm="12" className="col-12 mb-3">
                            <div className="position-relative mx-3">
                              <div
                                className="avatar-title bg-light rounded"
                                style={{ marginTop: "20px" }}
                              >
                                {EmployeeDoc ? (
                                  <div className="d-flex align-items-center">
                                    <i
                                      className="ri-file-fill"
                                      style={{
                                        fontSize: "30px",
                                        marginRight: "10px",
                                        color: "#000",
                                      }}
                                    ></i>
                                    <span>{EmployeeDoc.name}</span>
                                  </div>
                                ) : taskData.task_document &&
                                  taskData.task_document !== null ? (
                                  <div className="d-flex align-items-center">
                                    <i
                                      className="ri-file-fill"
                                      style={{
                                        fontSize: "30px",
                                        marginRight: "10px",
                                        color: "#000",
                                      }}
                                    ></i>
                                    <span style={{ color: "#000" }}>
                                      {taskData.task_document
                                        ? taskData.task_document
                                            .split("/")
                                            .pop()
                                            .substring(0, 15) + "..."
                                        : ""}
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <label
                                htmlFor="employeeDocument"
                                className="mb-0 cursor-pointer"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Select Document"
                              >
                                <div
                                  className="avatar-xs"
                                  style={{ marginTop: "50px" }}
                                >
                                  <div
                                    className="avatar-title border rounded-circle text-light"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    <i
                                      className="ri-file-upload-fill"
                                      style={{
                                        fontSize: "20px",
                                        color: "#000",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="employeeDocument"
                                type="file"
                                accept=".pdf,.doc,.docx,.txt,.xlsx"
                                onChange={(e) => {
                                  setTask({
                                    ...taskData,
                                    document: e.target.files[0],
                                  });
                                  SetEmployeeDoc(e.target.files[0]);
                                }}
                              />
                              {EmployeeDoc && (
                                <span className="ml-2 d-block mt-1">
                                  {EmployeeDoc.name}
                                </span>
                              )}
                            </div>
                          </Col>

                          {/* Audio Upload */}
                          <Col lg="3" sm="12" className="col-12 mb-3">
                            <div className="position-relative mx-3">
                              <div
                                className="avatar-title bg-light rounded"
                                style={{ marginTop: "20px" }}
                              >
                                {EmployeeBlob ? (
                                  <div className="d-flex align-items-center mb-2">
                                    <audio
                                      controls
                                      style={{ marginRight: "10px" }}
                                    >
                                      <source
                                        src={URL.createObjectURL(EmployeeBlob)}
                                        type="audio/wav"
                                      />
                                      Your browser does not support the audio
                                      element.
                                    </audio>
                                    <span>{EmployeeBlob.name}</span>
                                  </div>
                                ) : taskData.task_audio_file &&
                                  taskData.task_audio_file !== null ? (
                                  <div className="d-flex align-items-center">
                                    <i
                                      className="ri-file-fill"
                                      style={{
                                        fontSize: "30px",
                                        marginRight: "10px",
                                        color: "#000",
                                      }}
                                    ></i>
                                    <span style={{ color: "#000" }}>
                                      {taskData.task_audio_file
                                        ? taskData.task_audio_file
                                            .split("/")
                                            .pop()
                                            .substring(0, 15) + "..."
                                        : ""}
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <label
                                htmlFor="audioRecorder"
                                className="mb-0 cursor-pointer"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Record Audio"
                              >
                                <div
                                  className="avatar-xs"
                                  style={{ marginTop: "20px" }}
                                >
                                  <i
                                    className="ri-audio-fill"
                                    style={{ fontSize: "20px", color: "#000" }}
                                  ></i>
                                </div>
                              </label>
                              <AudioRecorder
                                onRecordingComplete={addAudioElement}
                                recorderControls={recorderControls}
                              />
                            </div>
                          </Col>
                        </div>
                      </div>
                    </Row>
                  </Row>
                </div>
              </Card>
            </Form>
          </ModalBody>
          <div className="modal-footer">
            <button
              ref={submitButtonRef}
              name="close"
              id="close"
              type="submit"
              className="btn btn-primary"
              onClick={() => SubmitData()}
            >
              <i className="ri-save-3-line align-bottom me-1"></i>
              Update
            </button>
            <button
              name="close"
              id="close"
              type="button"
              className="btn btn-danger"
              onClick={() => Close()}
            >
              <i className="ri-close-line me-1 align-middle" />
              Close
            </button>
          </div>
        </span>
      </Modal>

      {statusModal === true ? (
        <LeadStatusAdd
          modalStates={statusModal}
          setModalStates={() => {
            setstatusModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {productModal === true ? (
        <ProductAdd
          modalStates={productModal}
          setModalStates={() => {
            setproductModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {referBy === true ? (
        <RefrencesAdd
          modalStates={referBy}
          setModalStates={() => {
            setreferBy(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {customerModal === true ? (
        <CustomerAdd
          modalStates={customerModal}
          setModalStates={() => {
            setcustomerModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {sourceModal === true ? (
        <SourceAdd
          modalStates={sourceModal}
          setModalStates={() => {
            setsourceModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default TaskUpdate;
