import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import Section from "./Section";
import AuthUser from "../../helpers/Authuser";
import Widgets from "./Widgets";
import RecentOrders from "./RecentOrders";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { SimpleDonut, SimpleDonut1 } from "../Charts/ApexCharts/PieCharts/PieCharts";
import LeadsView from "../Leads/LeadsView";
import TaskView from "../Task/TaskView";

const FranchiseDashboard = () => {
  const { http, user, token } = AuthUser();
  const { type } = useParams();
  document.title = "Franchise Dashboard | CRM";
  const [rightColumn, setRightColumn] = useState(true);
  const toggleRightColumn = () => {
    setRightColumn(!rightColumn);
  };
  const currentYear = new Date().getFullYear();

  const [formData, setformData] = useState({
    start_date: `${currentYear}-01-01` + " 00:00:00",
    end_date: `${currentYear}-12-31` + " 23:59:59",
    leave_status: 0,
    leave_id: type,
    user_id: user != null ? (user.user.u_type === 1 ? 0 : user.user.uid) : "",
    customer_name: 0,
    lead_id: type ? type : 0,
    assigned_by:
      user != null ? (user.user.u_type === 1 ? 0 : user.user.uid) : "",
  });

  const [shiftedData, setshiftedData] = useState({
    leads: [],
    task: [],
  });
  const getshiftedData = async () => {
    http
      .post("/slt/details", formData)
      .then((response) => {
        if (response.data.leads.length > 0) {
          setshiftedData({
            leads: response.data.leads,
            task: response.data.task,
          });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const [LeadData, SetLeadData] = useState([]);
  const [TaskData, SetTaskData] = useState([]);
  const [LeaveData, SetLeaveData] = useState([]);

  const [tagsData, settagsData] = useState([]);
  const gettagsData = async () => {
    http
      .get("/tags/list")
      .then((response) => {
        if (response.data.length > 0) {
          settagsData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [assignedByData, setassignedByData] = useState([]);
  const getassignedByData = async () => {
    http
      .get("/franchisebusiness/list")
      .then((response) => {
        if (response.data.length > 0) {
          setassignedByData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [totalData, settotalData] = useState({
    taskCount: 0,
    taskStatusCount: [],
    leadCount: 0,
    leadStatusCount: [],
    customerCount: 0,
    attendanceCount: 0,
  });
  // console.log(totalData);

  const handleFilter = async () => {
    //dash board total counts and statuswise report
    http.post("/dashboard/total", formData).then((res) => {
      settotalData({
        taskCount: res.data.taskCount,
        leadStatusCount: res.data.leadStatusCounts,
        taskStatusCount: res.data.taskStatusCounts,
        leadCount: res.data.leadCount,
        customerCount: res.data.customerCount,
        attendanceCount: res.data.attendanceCount,
      });
    });
    http
      .post(`/leads/filter`, formData)
      .then(function (response) {
        if (response.data.length > 0) {
          SetLeadData(response.data);
        } else {
          SetLeadData([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    http
      .post(`/leave/list`, formData)
      .then(function (response) {
        if (response.data.length > 0) {
          SetLeaveData(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    http
      .post(`/task/filter`, formData)
      .then(function (response) {
        if (response.data.length > 0) {
          SetTaskData(response.data);
        } else {
          SetTaskData([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    gettagsData();
    getassignedByData();
    document.title = "Lead | CRM";
    // handleTaskFilter();
    // getAllData();
    getshiftedData();
    handleFilter();
  }, []);
  const [Data, SetData] = useState({});
  const navigate = useNavigate();
  const [filterData, setfilterData] = useState({
    startdate: new Date().toISOString().split("T")[0] + " 00:00:00",
    enddate: new Date().toISOString().split("T")[0] + " 23:59:59",
    user_id:
      user != null && user != null
        ? user.user.u_type === 1
          ? 0
          : user.user.uid
        : 0,
  });
  const [filterChartData, setfilterChartData] = useState({
    startdate: new Date().toISOString().split("T")[0] + " 00:00:00",
    enddate: new Date().toISOString().split("T")[0] + " 23:59:59",
    user_id:
      user != null && user != null
        ? user.user.u_type === 1
          ? 0
          : user.user.uid
        : 0,
  });
  const [Attendance, SetAttendance] = useState([]);
  useEffect(() => {
    document.title = "User Dashboard CRM";
    http
      .post(`/attendence/filter`, filterData)
      .then(function (response) {
        if (response.data.length > 0) {
          SetAttendance(response.data);
          // console.log(response.data);
        } else {
          // SetAttendance([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const [counts, Setcounts] = useState(1);
  const handleCallback = (data, status) => {
    SetLeadData([]);
    if (status == 1) {
      Setcounts(counts + 1);

    } else {

      Setcounts(counts + 1);
    }
    handleFilter();
  };
  const [FindData, SetFind] = useState([]);
  const [viewModalaStates, setviewModalaStates] = useState(false);
  const ViewPage = (index) => {
    let FindArray = LeadData.filter((item, i) => item.lead_id == index);
    SetFind(FindArray[0]);
    setviewModalaStates(!viewModalaStates);
  };


  const [FindData1, SetFind1] = useState([]);
  const [viewModalaStates1, setviewModalaStates1] = useState(false);
  const ViewPage1 = (index) => {
    let FindArray = TaskData.filter((item, i) => item.task_id == index);
    SetFind1(FindArray[0]);
    setviewModalaStates1(!viewModalaStates1);
  };



  
  
  const [role, setRole] = useState([]);
  const [selectedRoleIds, setSelectedRoleIds] = useState(
    user && user.user.u_type === 2 && user && user.user.user_behave === 1
      ? user.user.user_role_id
      : null
  );
  const [statuscheckforAssignedBy, setstatuscheckforAssignedBy] = useState(0);

  const getRole = async () => {
    try {
      const response = await http.get("/role/list");


      const filteredData = user && (user.user.u_type === 1 || user.user.u_type === 2)
        ? response.data.filter(
          (role) =>
            parseInt(role.role_created_user_id) === user.user.user_user_id ||
            parseInt(role.role_created_user_id) === user.user.uid
        )
        : response.data.filter(
          (role) => parseInt(role.role_created_user_id) === user.user.uid
        );
      setRole(filteredData);

    } catch (error) {
      console.log(error);
    }
  };


  // Call getRole() when the component mounts
  useEffect(() => {
    getRole();
  }, []);
   // Fetch lead data
   const fetchLeadData = async () => {
    try {
      const response = await http.post(`/leads/filter`, filterData);
      SetLeadData(response.data); // Update the leads based on the filter criteria
    } catch (error) {
      console.error("Failed to fetch lead data", error);
    }
  };
  const fetchChartData = async () => {
    try {
      const filterPayload = {
        ...filterChartData,
        department: filterChartData.department || 0, // Send department 0 when "Select All" or no selection
      };

      const response = await http.post(`/leads/filter`, filterPayload);
      SetLeadData(response.data); // Update the leads based on the filter criteria
    } catch (error) {
      console.error("Failed to fetch lead data", error);
    }
  };
  useEffect(() => {
    // Fetch chart data when filterChartData changes
    fetchChartData();
  }, [filterChartData]);
  const fetchTaskData = () => {
    http
      .post(`/task/filter`, formData) // Use the formData state for the API call
      .then(function (response) {
        if (response.data.length > 0) {
          SetTaskData(response.data); // Update the task data with the filtered results
        } else {
          SetTaskData([]); // Clear the task data if no results
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleRoleChange = (e) => {
    const selectedDepartment = e.value === 0 ? null : e.value; // Handle "Select All" as null

    setSelectedRoleIds(selectedDepartment); // Update the selected role ID(s)
    setstatuscheckforAssignedBy(statuscheckforAssignedBy + 1);

    // Update filterData for leads
    setfilterData({
      ...filterData,
      department: selectedDepartment, // Set department or null if "Select All"
    });

    // Fetch the leads based on the updated filter
    fetchLeadData();

    // Update chart filter
    setfilterChartData({
      ...filterChartData,
      department: selectedDepartment, // Set department or null if "Select All"
    });

    // Update form data
    setformData({
      ...formData,
      department: selectedDepartment, // Update the department value in formData
    });

    // Fetch the tasks based on the updated filter
    fetchTaskData();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section rightClickBtn={toggleRightColumn} />
                <Row>
                <Row className="align-items-center gy-3">
                    <div className="text-center pt-2 pb-4">
                      <h2 className="fw-bold mb-0">
                        
                        {filterData && filterData.department > 0
                          ? `${role.filter((data) => data.role_id == filterData.department)[0].role_name} `   
                          : "All "}
                        Dashboard's
                      </h2>
                    </div>
                  </Row>
                  <Col xl={12}>
                    <Row className="align-items-center gy-3">

                      <Col xl={4}>

                        <div>
                          <div className="fw-bold">Department</div>
                          <Select
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused ? "white" : "white",
                              }),
                            }}
                            className="fw-bold"
                            options={[
                              { value: 0, label: "Select All" }, // Option to select all roles
                              ...role.map((e) => ({
                                value: e.role_id,
                                label: e.role_name, // Display the role name as the label
                              })),
                            ]}
                            onChange={handleRoleChange}
                          />


                        </div>

                      </Col>
                      <Col xl={4}>
                        <div>
                          <div className="fw-bold">Start Date</div>
                          <Flatpickr
                            className="form-control fw-bold"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            name="payment_date"
                            placeholder="Enter start date"
                            onChange={(selectedDates) => {
                              const selectedDate = selectedDates[0];
                              // Convert to YYYY-MM-DD format
                              const year = selectedDate.getFullYear();
                              const month = (selectedDate.getMonth() + 1)
                                .toString()
                                .padStart(2, "0");
                              const day = selectedDate
                                .getDate()
                                .toString()
                                .padStart(2, "0");
                              const formattedDate = `${year}-${month}-${day} 00:00:00`;

                              setfilterData({
                                ...filterData,
                                start_date: formattedDate,
                              });
                            }}
                          />
                        </div>
                      </Col>
                      <Col xl={4}>
                        <div>
                          <div className="fw-bold">End Date</div>
                          <Flatpickr
                            className="form-control fw-bold"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            name="payment_date"
                            placeholder="Enter start date"
                            onChange={(selectedDates) => {
                              const selectedDate = selectedDates[0];
                              // Convert to YYYY-MM-DD format
                              const year = selectedDate.getFullYear();
                              const month = (selectedDate.getMonth() + 1)
                                .toString()
                                .padStart(2, "0");
                              const day = selectedDate
                                .getDate()
                                .toString()
                                .padStart(2, "0");
                              const formattedDate = `${year}-${month}-${day} 23:59:59`;

                              setfilterData({
                                ...filterData,
                                end_date: formattedDate,
                              });
                            }}
                          />
                        </div>
                      </Col>

                      <div></div>

                    </Row>
                  </Col>
                  <Col lg={12}>
                    <Row>



                      <Col xl={4}>
                        <Card >
                          <CardHeader>
                            <h4 className="card-title mb-0">Leads</h4>
                          </CardHeader>
                          <CardBody>
                            <SimpleDonut additionalData={totalData.leadStatusCount} dataColors='["--vz-info","--vz-primary","--vz-warning", "--vz-success",  "--vz-danger"]' />
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                              <Link to="/leads-list/0/1" style={{ marginRight: '10px', height: '50px', linHeight: '50px', marginBottom: '-10px', marginTop: '-7px' }}>
                                <span style={{ color: 'var(--vz-info)', fontSize: '3.5em', verticalAlign: 'middle' }}>•</span>
                                <span style={{ fontWeight: 'bold', color: 'black' }}>
                                  New - {
                                    totalData.leadStatusCount
                                      .leadStatus1
                                  }
                                </span>
                              </Link>
                              <Link to="/leads-list/0/2" style={{ marginRight: '10px', height: '50px', linHeight: '50px', marginBottom: '-10px', marginTop: '-7px' }}>
                                <span style={{ fontWeight: 'bold', color: 'var(--vz-primary)', fontSize: '3.5em', verticalAlign: 'middle' }}>•</span> {/* Second bullet with color --vz-primary and larger size */}
                                <span style={{ fontWeight: 'bold', color: 'black' }}>Accept -  {
                                  totalData.leadStatusCount
                                    .leadStatus2
                                }</span>
                              </Link>
                              <Link to="/leads-list/0/3" style={{ marginRight: '10px', height: '50px', linHeight: '50px', marginBottom: '-10px', marginTop: '-7px' }}>
                                <span style={{ fontWeight: 'bold', color: 'var(--vz-warning)', fontSize: '3.5em', verticalAlign: 'middle' }}>•</span> {/* Third bullet with color --vz-warning and larger size */}
                                <span style={{ fontWeight: 'bold', color: 'black' }}>In-Progress -  {
                                  totalData.leadStatusCount
                                    .leadStatus3
                                }</span>
                              </Link>
                              <Link to="/leads-list/0/4" style={{ marginRight: '10px', height: '50px', linHeight: '50px', marginBottom: '-10px', marginTop: '-7px' }}>
                                <span style={{ fontWeight: 'bold', color: 'var(--vz-success)', fontSize: '3.5em', verticalAlign: 'middle' }}>•</span> {/* Fourth bullet with color --vz-success and larger size */}
                                <span style={{ fontWeight: 'bold', color: 'black' }}>Completed - {
                                  totalData.leadStatusCount
                                    .leadStatus4
                                }</span>
                              </Link>
                              <Link to="/leads-list/0/5" style={{ marginRight: '10px', height: '50px', linHeight: '50px', marginBottom: '-10px', marginTop: '-7px' }}>
                                <span style={{ fontWeight: 'bold', color: 'var(--vz-danger)', fontSize: '3.5em', verticalAlign: 'middle' }}>•</span> {/* Fifth bullet with color --vz-danger and larger size */}
                                <span style={{ fontWeight: 'bold', color: 'black' }}>Rejected - {
                                  totalData.leadStatusCount
                                    .leadStatus5
                                }</span>
                              </Link>
                            </div>

                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={8}>
                        <Card lg={12}>
                          <CardHeader className="card-header border-0">
                            <Row className="align-items-center gy-3">
                              <div className="col-sm">
                                <h5 className="card-title mb-0">Leads </h5>
                              </div>
                              <div className="col-sm-auto">
                                <div className="d-flex gap-1 flex-wrap">
                                  <Link to="/leads-list" className="btn btn-soft-info btn-sm shadow-none">View All</Link>
                                </div>
                              </div>
                            </Row>
                          </CardHeader>
                          <CardBody className="pt-0" style={{ height: '400px', overflowY: 'auto' }}>
                            <div className="table-responsive">
                              <table
                                role="table"
                                className="text-center bg-white table table-responsive"
                                style={{ width: '100%' }} // Set table width to 100%
                              >
                                 <thead className=" table-light text-muted text-uppercase">
                                  <tr>
                                    <th
                                      title="Toggle SortBy"
                                      style={{ cursor: "pointer" }}
                                    >
                                      #
                                    </th>
                                    <th>DATE & TIME</th>
                                    <th
                                      title="Toggle SortBy"
                                      style={{ cursor: "pointer" }}
                                    >
                                      Customer
                                    </th>
                                    <th>Phone</th>
                                    <th>City </th>
                                    <th>Product </th>
                                    <th>Value</th>
                                    <th>Tags</th>
                                    <th>Assigned</th>
                                    <th>Status</th>
                                    <th>Source</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {LeadData.slice(0, 10).map((item, index) => (
                                    <tr key={index} onClick={() => ViewPage(item.lead_id)}>
                                      <td>{index + 1}</td>
                                      <td>
                                        {new Date(item.today_date).toLocaleDateString("en-GB")}
                                        <br />
                                        {new Date(item.today_date).toLocaleTimeString()}
                                      </td>
                                      <td>{item.customer_name}</td>
                                      <td>{item.customer_whatsapp_no}</td>
                                      <td>{item.city_name}</td>
                                      <td>{item.product_name}</td>
                                      <td>{item.value}</td>
                                      <td>
                                        {tagsData != null && item.tags
                                          ? tagsData.map((e) =>
                                            JSON.parse(item.tags).includes(e.tag_id) ? (
                                              <div key={e.tag_id} className="p-1">
                                                <button>{e.tag_name}</button>
                                              </div>
                                            ) : null
                                          )
                                          : "Loading..."}
                                      </td>
                                      <td>
                                        {assignedByData != null && item.assigned_by
                                          ? assignedByData.map((e) =>
                                            JSON.parse(item.assigned_by).includes(e.uid) ? (
                                              <div key={e.uid} className="p-1">
                                                <button className="bg-primary fw-bold text-white border border-primary">
                                                  {e.name}
                                                </button>
                                              </div>
                                            ) : null
                                          )
                                          : "Loading..."}
                                      </td>
                                      <td>
                                        <div>
                                          <span
                                            className={`badge bg-info-subtle text-${item.lead_status_id === 1
                                              ? "info"
                                              : item.lead_status_id === 2
                                                ? "primary"
                                                : item.lead_status_id === 3
                                                  ? "warning"
                                                  : item.lead_status_id === 4
                                                    ? "success"
                                                    : item.lead_status_id === 5
                                                      ? "danger"
                                                      : ""
                                              } text-uppercase`}
                                          >
                                            {item.lead_status_name}
                                          </span>
                                        </div>
                                      </td>
                                      <td>{item.source_name}</td>
                                    </tr>
                                  ))}

                                </tbody>
                              </table>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>

                    </Row>
                  </Col>
                </Row>

                <Row>
                  <RecentOrders />
                  <Col lg={4}>
                    <Card lg={12} >
                      <CardHeader className="card-header border-0">
                        <Row className="align-items-center gy-3">
                          <div className="col-sm">
                            <h5 className="card-title mb-0">Shift Leads </h5>
                          </div>
                          {/* <div className="col-sm-auto">
                            <div className="d-flex gap-1 flex-wrap">
                              <button
                                type="button"
                                className="btn fw-bold btn-info add-btn"
                                id="create-btn"
                                onClick={() => navigate("/leads-list")}
                              >
                                <i className="ri-eye-fill align-bottom me-1"></i>{" "}
                                View All
                              </button>
                            </div>
                          </div> */}
                        </Row>
                      </CardHeader>
                      <CardBody style={{ height: '400px', overflowY: 'auto' }}>
                        {shiftedData.leads.map((e, i) => (
                          <>
                            <div className="d-flex align-items-center " style={{ cursor: "pointer" }} onClick={() => navigate(`/leads-list/${e.slt_lead_id}/0`)}>
                              <div>
                                <h5 className="mb-1 fs-13 fw-normal text-dark">
                                  <b>{e.send_by_name} </b>
                                  Shifted The Lead to <b> {e.send_to_name}</b>
                                </h5>
                                <p className="mb-0 fs-12 text-muted">
                                  Reason :{" "}
                                  {e.slt_reason.replace(/<[^>]*>/g, "")}
                                </p>
                              </div>
                            </div>
                            <hr></hr>
                          </>
                        ))}
                      </CardBody>
                    </Card>
                  </Col>

                </Row>

              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {viewModalaStates === true ? (
        <LeadsView
          modalStates={viewModalaStates}
          setModalStates={() => {
            setviewModalaStates(false);
          }}
          checkchang={handleCallback}
          edit_data={FindData}
        />
      ) : (
        ""
      )}
      {viewModalaStates1 === true ? (
        <TaskView
          modalStates={viewModalaStates1}
          setModalStates={() => {
            setviewModalaStates1(false);
          }}
          checkchang={handleCallback}
          edit_data={FindData1}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default FranchiseDashboard;
