import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Row,
  Col,
  Button,
  Form,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import CustomInput from "../Unit/Input";
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';

import Select from "react-select";

const CreatePlanUpdate = (props) => {
  const { https } = AuthUser();
  const [modal, setModal] = useState(false);
  const submitButtonRef = useRef();

  const [audioBlob, setAudioBlob] = useState(null); // State for audio blob
  const recorderControls = useAudioRecorder();
  const [SubscriptionData, SetSubscriptionData] = useState([]);
  const [feachers, Setfeachers] = useState([]);
  const [errors, setErrors] = useState({}); // State to store validation errors

  useEffect(() => {
    document.title = "Subscription | CRM";

    https
      .get('/subscriptiontype/list')
      .then(function (response) {
        SetSubscriptionData([...SubscriptionData, ...response.data]);
        console.log("Use Effect is working");
      })
      .catch(function (error) {
        console.log(error);

      });
  }, []);

  useEffect(() => {
    document.title = "Subscription | CRM";

    https
      .get('/feachers/list')
      .then(function (response) {
        Setfeachers([...feachers, ...response.data]);
        console.log("Hii", response.data);

        console.log("Use Effect is working");
      })
      .catch(function (error) {
        console.log(error);

      });
  }, []);


  const addAudioElement = (blob) => {
    setAudioBlob(blob); // Update the audio blob state
  };

  const Close = () => {
    setModal(false);
    props.setModalStates();
  };

  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  const [checkNameStatus, setCheckStatus] = useState({});


  const [Subscription, SetSubscription] = useState(props.edit_data);






  const SubmitData = () => {
    // console.log(Subscription);

    

    if (audioBlob) {
      Subscription.append("audio_file", audioBlob, "recording.wav"); // Append the audio file
    }
    https
      .put("/subscription-plans/update", Subscription)
      .then(response => {
        props.checkchang(response.data.message, response.data.status);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div>
      <Modal id="showModal" isOpen={modal} size="xl" toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Create Plan
        </ModalHeader>
        <span className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              SubmitData();
            }}
            action="#"
          >
            <ModalBody>
              <Card className="border card-border-success p-3 shadow-lg">
                <Row>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label
                        htmlFor="subscription-title-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Subscription Title<span style={{ color: 'red' }}> *</span>
                        </div>
                      </Label>
                      <Select
                      placeholder={Subscription.subscription_title}
                        options={SubscriptionData.map((e) => ({
                          value: e.subscription_type_id,
                          label: e.subscription_type_name
                        }))}
                        // value={SubscriptionData.find(option => option.value === Subscription.subscription_type_id)}
                        onChange={(selectedOption) => {
                          SetSubscription(prevState => ({
                            ...prevState,
                            subscription_title: selectedOption.value
                          }));
                        }}
                        // className={"form-control fw-bold "}

                      />
                      
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label
                        htmlFor="feachers-name-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Subscription Price<span style={{ color: 'red' }}> *</span>
                        </div>
                      </Label>
                      <CustomInput
                        name="feachers_name"
                        id="feachers-name-field"
                        className={`form-control fw-bold `}
                        placeholder="Subscription Name"
                        type="text"
                        value={Subscription.subscription_price}
                        onChange={(e) => SetSubscription({
                          ...Subscription, subscription_price: e.target.value
                        })}
                      />
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label
                        htmlFor="feachers-type-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Subscription Features<span style={{ color: 'red' }}> *</span>
                        </div>
                      </Label>
                      <Select
                      placeholder={Subscription.subscription_feature}
                        options={feachers.map((e) => ({
                          value: e.feachers_id,
                          label: e.feachers_name
                        }))}
                        isMulti={true}
                        onChange={(selectedOptions) => {
                          // Convert selected options to an array of values (IDs) and then join to a string
                          const selectedIdsString = selectedOptions.map(option => option.value).join(', ');

                          // Update the Subscription state with the string
                          SetSubscription(prevState => ({
                            ...prevState,
                            subscription_feature: selectedIdsString
                          }));
                        }}
                      />
                    
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label
                        htmlFor="subscription-duration-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Subscription Duration<span style={{ color: 'red' }}> *</span>
                        </div>
                      </Label>
                      <CustomInput
                        name="subscription_duration"
                        id="subscription-duration-field"
                        className={`form-control fw-bold`}
                        placeholder="Subscription Duration"
                        type="text"
                        value={Subscription.subscription_duration}
                        onChange={(e) => SetSubscription({
                          ...Subscription, subscription_duration: e.target.value
                        })}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            </ModalBody>

            <div className="modal-footer">
              <button
                name="save"
                id="save"
                type="submit"
                className="btn btn-primary"
              >
                <i className="ri-save-3-line align-bottom me-1"></i>
                Save
              </button>
              <button
                name="close"
                id="close"
                type="button"
                className="btn btn-danger"
                onClick={Close}
              >
                <i className="ri-close-line me-1 align-middle" />
                Close
              </button>
            </div>
          </Form>
        </span>
      </Modal>
    </div>
  );
};

export default CreatePlanUpdate;
