import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardEcommerce from "../pages/DashboardEcommerce";

//AuthenticationInner pages
import BasicSignIn from "../pages/AuthenticationInner/Login/BasicSignIn";
import BasicSignUp from "../pages/AuthenticationInner/Register/BasicSignUp";
import BasicPasswReset from "../pages/AuthenticationInner/PasswordReset/BasicPasswReset";
//pages

import BasicLockScreen from "../pages/AuthenticationInner/LockScreen/BasicLockScr";
import BasicLogout from "../pages/AuthenticationInner/Logout/BasicLogout";
import BasicSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg";
import BasicTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify";
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";
import Alt404 from "../pages/AuthenticationInner/Errors/Alt404";
import Error500 from "../pages/AuthenticationInner/Errors/Error500";

import BasicPasswCreate from "../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate";
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";





// User Profile
import UserProfile from "../pages/Authentication/user-profile";
import CategoryList from "../pages/Category/CategoryList";

import CategoryAdd from "../pages/Category/CategoryAdd";

import Customers from "../pages/Customers/Customers";
import AllComponent from "../pages/AllCompoenent/AllComponent";
import CustomerGroupList from "../pages/CustomerGroup/CustomerGroupList";
import UsersList from "../pages/UserManagement/Users/UsersList";
import RolesList from "../pages/UserManagement/Roles/RolesList";
import ExpensesList from "../pages/Expenses/ExpensesList";
import BusinessProfile from "../pages/BusinessSetting/BusinessProfile";
import Unauthorized from "../pages/AllCompoenent/Unauthorized";
import Company from "../pages/CompanyAdd/Company";
import Users from "../pages/CompanyAdd/Users";
import Subscription from "../pages/AuthenticationInner/AuthenticationInner/Subscription";
import EmployeeList from "../pages/EmployeeInfo/EmployeeList";
import CityList from "../pages/City/CityList";
import ProductList from "../pages/Product/ProductList";
import SourceList from "../pages/Source/SourceList";
import RefrencesList from "../pages/Refrences/RefrencesList";
import LeadStatusList from "../pages/LeadStatus/LeadStatusList";
import RelatedToList from "../pages/RelatedTo/RelatedToList";
import TagsList from "../pages/Tags/TagsList";
import Expencess_CateList from "../pages/Expencess_Category/Expencess_CateList";
import LeadsList from "../pages/Leads/LeadsList";
import TaskList from "../pages/Task/TaskList";
import ShiftList from "../pages/Shift Slots/ShiftList";
import AttendenceList from "../pages/Attendence/AttendenceList";
import AttendenceAdd from "../pages/Attendence/AttendenceAdd";
import LeaveList from "../pages/Leave/LeaveList";
import TodoList from "../pages/ToDo/TodoList";

import AttendenceAllLists from "../pages/Attendence/AttendenceAllLists";
import AuthUser from "../helpers/Authuser";
import UserDashboard from "../pages/DashboardEcommerce/UserDashboard";
import { SalaryGeneration } from "../pages/Salary/SalaryGeneration";
import NotificationTableView from "../Components/Common/NotificationTableView";

import PaymentModeList from "../pages/PaymentMode/PaymentModeList";

import Payment from "../pages/Payment/Payment";
import PaymentTypeList from "../pages/PaymentType/PaymentTypeList";
import MessageList from "../pages/Message/MessageList";
import TaskReport from "../pages/Reports/TaskReport";
import LeadsReport from "../pages/Reports/LeadsReport";
import AttendenceReport from "../pages/Reports/AttendenceReport";
import LeaveReports from "../pages/Reports/LeaveReports";
import PaymentReport from "../pages/Reports/PaymentReport";
import Followup from "../pages/Reports/Followup";
import FranchiseList from "../pages/UserManagement/Franchises/FranchiseList";
import FranchiseDashboard from "../pages/DashboardEcommerce/FranchiseDashboard";
import AdminDashboard from "../pages/DashboardEcommerce/AdminDashboard";
import BusinessDashboard from "../pages/DashboardEcommerce/BusinessDashboard";
import BusinessList from "../pages/Business/BusinessList";

import SubscriptionList from "../pages/Subscription/SubscriptionList";
import SubscriptionPlanList from "../pages/SubscriptionPlan/SubscriptionPlanList";

import Chat from "../pages/Chat";
import SubscriptionfeachersList from "../pages/subscriptionfeachers/SubscriptionfeachersList";
import BusinessUserDashboard from "../pages/DashboardEcommerce/BusinessUserDashboard";
import Leave_TypeList from "../pages/Leave_Type/Leave_TypeList";
import SubDepartmentList from "../pages/UserManagement/Roles/SubDepartmentList";
import SubscriptionType_View from "../pages/SubscriptionType/SubscriptionType_View";
import Feachers_view from "../pages/Feachers/Feachers_view";
import CreatePlanList from "../pages/CreatePlan/CreatePlanList";


const authProtectedRoutes = [
  // Unauthorized

  // { path: "/", component: <DashboardEcommerce /> },
  { path: "/dashboard", component: <DashboardEcommerce /> },
  { path: "/admin-dashboard", component: <AdminDashboard /> },
  { path: "/business-dashboard", component: <BusinessDashboard /> },
  { path: "/businessuser-dashboard", component: <BusinessUserDashboard /> },
  { path: "/user-dashboard", component: <UserDashboard /> },
  { path: "/franchise-dashboard", component: <FranchiseDashboard /> },
  //User Profilefilter
  { path: "/profile", component: <UserProfile /> },
  {
    path: "/notification-view/:id/:type",
    component: <NotificationTableView />,
  },

  { path: "*", component: <Navigate to="/dashboard" /> },

  //Category Routes
  { path: "/category-list", component: <CategoryList /> },
  { path: "/category-add", component: <CategoryAdd /> },

  //City Routes
  { path: "/city-list", component: <CityList /> },
  //PaymentMode Routes
  { path: "/paymentmode-list", component: <PaymentModeList /> },
  //PaymentMode Routes
  { path: "/paymenttype-list", component: <PaymentTypeList /> },
  //Product Routes
  { path: "/product-list", component: <ProductList /> },
  ///shift-list Routes
  { path: "/shift-list", component: <ShiftList /> },
  //Source Routes
  { path: "/source-list", component: <SourceList /> },

  //References Routes
  { path: "/References-list", component: <RefrencesList /> },
  //LeadStatus Routes
  { path: "/leads_status-list", component: <LeadStatusList /> },
  //related to Routes
  { path: "/related_to-list", component: <RelatedToList /> },
  //tags to Routes
  { path: "/tags-list", component: <TagsList /> },

  // Customer Routes
  { path: "/customer-list", component: <Customers /> },

  // Expenses Routes
  { path: "/expenses-list", component: <Expencess_CateList /> },

  // customer Group Type Routes
  { path: "/customer-group-list", component: <CustomerGroupList /> },
  // Component
  { path: "/component-list", component: <AllComponent /> },

  // ROLES AND PERMISSIONS ROUTES | USERS
  { path: "/users-list", component: <UsersList /> },
  // Franchise ROUTES | USERS
  { path: "/franchise-list", component: <FranchiseList /> },
  // Franchise ROUTES | USERS
  { path: "/business-list", component: <BusinessList /> },

  // ROLES AND PERMISSIONS ROUTES | ROLES
  { path: "/department-list", component: <RolesList /> },
  { path: "/sub-department/:department_id", component: <SubDepartmentList /> },

  // reward points
  { path: "/employee-list", component: <EmployeeList /> },

  { path: "/message-list", component: <MessageList /> },

  // Business setting
  { path: "/business-setting", component: <BusinessProfile /> },

  // leads
  { path: "/leads-list/:type?/:status?", component: <LeadsList /> },
  { path: "/leads-report/:type?/:status?", component: <LeadsReport /> },
  // task
  { path: "/task-list/:type?/:status?", component: <TaskList /> },
  { path: "/task-report/:type?/:status?", component: <TaskReport /> },
  // { path: "/task-list/:type", component: <TaskList /> },
  // Attendence
  { path: "/attendence-list", component: <AttendenceList /> },
  { path: "/attendence-lists/:type?", component: <AttendenceAllLists /> },
  { path: "/attendence-reports/:type?", component: <AttendenceReport /> },
  // Attendence
  { path: "/attendence", component: <AttendenceAdd /> },
  // Attendence
  { path: "/leave/:type?", component: <LeaveList /> },
  { path: "/leavetype", component: <Leave_TypeList /> },
  { path: "/leave-reports/:type?", component: <LeaveReports /> },
  { path: "/followup-report/:type?", component: <Followup /> },

  // Salary Generation
  { path: "/salary-generation", component: <SalaryGeneration /> },

  // Payment Generation
  { path: "/payment", component: <Payment /> },
  { path: "/payment-reports", component: <PaymentReport /> },

  //Chat
  { path: "/chat-app", component: <Chat /> },



  { path: "/todo", component: <TodoList /> },
  { path: "/subscriptiontype-list", component: <SubscriptionType_View /> },
  { path: "/subscription-list", component: <SubscriptionList /> },
  { path: "/add-subscription", component: <SubscriptionPlanList /> },
  { path: "/add-feachers", component: <SubscriptionfeachersList /> },
  { path: "/subscription-feachers", component: <Feachers_view /> },
  { path: "/create-plan", component: <CreatePlanList /> },
  // { path: "/todo", component: <ParentComponent /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/unauthorized", component: <Unauthorized /> },
  { path: "/login", component: <Login /> },
  { path: "/company", component: <Company /> },
  { path: "/", component: <DashboardEcommerce /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/business-register", component: <Register /> },
  //AuthenticationInner pages
  { path: "/auth-signin-basic", component: <BasicSignIn /> },
  { path: "/auth-signup-basic", component: <BasicSignUp /> },
  { path: "/auth-pass-reset-basic", component: <BasicPasswReset /> },
  { path: "/auth-lockscreen-basic", component: <BasicLockScreen /> },
  { path: "/auth-logout-basic", component: <BasicLogout /> },
  { path: "/auth-success-msg-basic", component: <BasicSuccessMsg /> },
  { path: "/auth-twostep-basic", component: <BasicTwosVerify /> },
  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/auth-404-alt", component: <Alt404 /> },
  { path: "/auth-500", component: <Error500 /> },
  { path: "/auth-pass-change-basic", component: <BasicPasswCreate /> },
  { path: "/auth-offline", component: <Offlinepage /> },
  { path: "/subscription", component: <Subscription /> },
];

export { authProtectedRoutes, publicRoutes };
