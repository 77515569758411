import React, { useCallback, useEffect, useState } from 'react'
import { Card, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import AuthUser from '../../helpers/Authuser';

const Import = (props) => {
  const { http, https, user, permission } = AuthUser();
  const [tempValid, settempValid] = useState(0);
  const [modal, setModal] = useState(false);
  const Close = () => {
    setModal(false);
    props.setModalStates();
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const SubmitData = async () => {
    if (!file) {
      setMessage('Please select a file first.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {


      https
        .post("/city/upload", formData)
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
          // if (response.data.status === 1) {
          //   toast.success(response.data.message);
          // } else {
          //   toast.success(response.data.message);

          // }

        })
        .catch((error) => {
          console.log(error);
        });

    } catch (error) {
      setMessage('Error uploading file');
      console.error('Error:', error);
    }
  };
  return (<div>
    <Modal
      className="modal-sm"
      id="fullscreeexampleModal"
      isOpen={modal}
      size={"sm"}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3"
      //  toggle={toggle}
      >
        Import Database File
      </ModalHeader>
      <span className="tablelist-form">
        <ModalBody style={{ maxHeight: "80vh", overflowY: "auto" }}>

          <Form
            onSubmit={(e) => {
              // e.preventDefault();
              // SubmitData();
              return false;
            }}
            action="#"
          >
            <Card className="border card-border-success p-3 shadow-lg">
              <div className="mb-3">
                <Row>
                  <Col lg={12}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">
                        Select File <span className="text-danger">*</span><div style={{ color: "red" }}>{message}</div>

                      </h5>

                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="empoyeeProfile"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title=""
                              data-bs-original-title="Select Image"
                            >
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                  <i className="ri-image-fill"></i>
                                </div>
                              </div>
                            </label>
                            <input
                              className="form-control d-none"
                              id="empoyeeProfile"
                              type="file"
                              accept=".xlsx, .xls, .csv"
                              onChange={onFileChange}
                            />
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {file ? (
                                <p style={{ color: 'black' }}>{file.name}</p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Form>
        </ModalBody>
        <div className="modal-footer">
          <button
            // ref={submitButtonRef}
            name="close"
            id="close"
            type="submit"
            className="btn btn-primary"
            onClick={() => SubmitData()}
          >
            <i className="ri-save-3-line align-bottom me-1"></i>
            Save
          </button>
          <button
            name="close"
            id="close"
            type="button"
            className="btn btn-danger"
            onClick={() => Close()}
          >
            <i className="ri-close-line me-1 align-middle" />
            Close
          </button>
        </div>

      </span></Modal></div>
  )
}

export default Import