import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  Row,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";

import AuthUser from "../../helpers/Authuser";
import { useEffect } from "react";
import { IMG_API_URL } from "../../helpers/url_helper";
import InfiniteScroll from "react-infinite-scroll-component";
import D_img from "../D_img";

import { useParams } from "react-router-dom";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import jsPDF from "jspdf";

const LeaveReports = () => {
  const { type } = useParams();

  const [modalStates, setModalStates] = useState(false);
  const [UpdatemodalStates, setUpdateModalStates] = useState(false);
  const { http, user, permission } = AuthUser();
  const [counts, Setcounts] = useState(1);
  const [LeaveData, SetLeaveData] = useState([]);
  const [sortedLeaveData, setSortedLeaveData] = useState([]);

  const [filterData, setfilterData] = useState({
    start_date: new Date().toISOString().split("T")[0] + " 00:00:00",
    end_date: new Date().toISOString().split("T")[0] + " 23:59:59",
    leave_status: 0,
    leave_id: type,
    user_id: user.user.u_type === 1 ? 0 : user.user.uid,
  });
  // infinity
  const [Pages, SetPages] = useState(1);

  const [NoMore, SetNoMore] = useState(true);
  const getAllData = async () => {
    http
      .post(`/leave/list`, filterData)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.length > 0) {
          SetLeaveData(response.data);
          // SetPages(Pages + 1);
        } else {
          SetLeaveData([]);
        }
        if (response.data.length === 0) {
          SetNoMore(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        SetNoMore(false);
      });
  };
  const getLoginData = async () => {
    http
      .get(`/leave/user/${user.user.uid}`)
      .then(function (response) {
        SetLeaveData([...LeaveData, ...response.data]);
        SetPages(Pages + 1);
        if (response.data.length === 0) {
          SetNoMore(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        SetNoMore(false);
      });
  };

  const [assignedByData, setassignedByData] = useState([]);
  const getassignedByData = async () => {
    http
      .get("/franchisebusiness/list")
      .then((response) => {
        if (response.data.length > 0) {
          setassignedByData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    document.title = "Leave | CRM";
    // if (user.user.u_type === 1) {
    getAllData();
    getassignedByData();
    // } else if (user.user.u_type === 2) {
    // getLoginData();
    // }
    // getAllData();
  }, [counts && filterData]);

  useEffect(() => {
    setSortedLeaveData(LeaveData);
  }, [LeaveData]);

  const fetchData = () => {
    Setcounts(counts + 1);
  };
  //   Delete Aleart
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, SetID] = useState();
  const onClickDelete = (data) => {
    SetID(data);
    setDeleteModal(true);
  };
  const handleDeleteOrder = (data) => {
    if (data._reactName == "onClick") {
      http
        .delete(`/city/delete/${ID}`)
        .then(function (response) {
          if (response.data.status == 0) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
          Setcounts(counts + 1);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    SetLeaveData([]);
    SetPages(1);
    setDeleteModal(false);
  };

  // shortcuts for opening add form
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.altKey && event.key === "a") ||
        (event.altKey && event.key === "A")
      ) {
        event.preventDefault();
        setModalStates(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  //   end Alert
  const handleCallback = (data, status) => {
    // SetLeaveData([]);
    SetPages(1);
    if (status == 1) {
      toast.success(data);
      Setcounts(counts + 1);
    } else {
      toast.warn(data);
      Setcounts(counts + 1);
    }
    setfilterData({
      ...filterData,
      leave_status: 0,
      leave_id: type,
      user_id: user.user.u_type === 1 ? 0 : user.user.uid,
    });
    setModalStates(false);
    setUpdateModalStates(false);
  };
  // Edit Data
  const [FindData, SetFind] = useState([]);
  const EditUpdate = (index) => {
    let FindArray = LeaveData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setUpdateModalStates(!UpdatemodalStates);
  };
  const [FindData1, SetFind1] = useState([]);
  const EditStatus = (index) => {
    let FindArray1 = LeaveData.filter((_, i) => i == index);
    SetFind1(FindArray1[0]);
    setUpdateModalStates(!UpdatemodalStates);
  };

  const Leavestatus = [
    { value: "0", label: "All" },
    { value: "1", label: "new" },
    { value: "2", label: "Approve" },
    { value: "3", label: "Rejected" },
  ];
  const generatePDF = () => {
    const doc = new jsPDF("landscape");

    // Set table headers
    const headers = [
      "#",
      "Employee Name",
      "Reson",
      "FORM DATE",
      "TO DATE",
      "STATUS",
    ];
    // Set table rows
    const data = LeaveData.map((item, index) => [
      index + 1,
      item.name,
      item.leave_reason && item.leave_reason.replace(/<[^>]*>|&nbsp;| /g, ""),
      item.from_date,
      item.to_date,
      item.leave_status,
      item.leave_reject_reason &&
        item.leave_reject_reason.replace(/<[^>]*>|&nbsp;| /g, ""),
    ]);

    // Set table style
    doc.setFontSize(12);
    doc.text("Leave Report", 14, 15);
    doc.autoTable({
      head: [headers],
      body: data,
      startY: 20,
    });

    // Save the PDF
    doc.save("LeaveReport.pdf");

    toast.success("PDF generated successfully!");
  };

  const convertToCSVexcel = () => {
    let csvContent = "#,Employee Name,Reson,FORM DATE,TO DATE,STATUS";

    LeaveData.forEach((item, index) => {
      csvContent += `\n${index + 1},"${item.name}","${
        item.leave_reason && item.leave_reason.replace(/<[^>]*>|&nbsp;| /g, "")
      }","${item.from_date}","${item.to_date}","${item.leave_status}",`;
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "LeaveReport.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    toast.success("CSV generated successfully!");
  };
  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="text-center pt-2 pb-4">
                    <h2 className="fw-bold mb-0">
                      {filterData && filterData.user_id > 0
                        ? assignedByData &&
                          assignedByData.length > 0 &&
                          assignedByData.filter(
                            (data) => data.uid === filterData.user_id
                          ).length > 0
                          ? `${
                              assignedByData.filter(
                                (data) => data.uid === filterData.user_id
                              )[0].name
                            } `
                          : "User not found"
                        : "All "}
                      Leave
                    </h2>
                  </div>
                </Row>
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    {/* <h5 className="card-title mb-0">Leave Reports</h5> */}
                  </div>

                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      {user.user.u_type === 1 ? (
                        <div>
                          <div className="fw-bold">By employee</div>
                          <Select
                            className="fw-bold"
                            placeholder="Select Employee"
                            options={[
                              { value: 0, label: "All" },
                              ...assignedByData.map((e) => ({
                                value: e.uid,
                                label: e.name,
                              })),
                            ]}
                            onChange={(e) => {
                              setfilterData({
                                ...filterData,
                                user_id: e.value,
                              });
                            }}
                          ></Select>
                        </div>
                      ) : (
                        ""
                      )}
                      <div>
                        <div className="fw-bold">Leave Status</div>
                        <Select
                          className="fw-bold"
                          placeholder="Select Status"
                          options={Leavestatus.map((e) => ({
                            value: e.value,
                            label: e.label,
                          }))}
                          onChange={(e) => {
                            setfilterData({
                              ...filterData,
                              leave_status: e.value,
                            });
                          }}
                        ></Select>
                      </div>
                      <div>
                        <div className="fw-bold">Start Date</div>
                        <Flatpickr
                          className="form-control fw-bold"
                          options={{
                            dateFormat: "d/m/Y",
                            defaultDate: "today",
                          }}
                          name="payment_date"
                          placeholder="Enter start date"
                          onChange={(selectedDates) => {
                            const selectedDate = selectedDates[0];
                            // Convert to YYYY-MM-DD format
                            const year = selectedDate.getFullYear();
                            const month = (selectedDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0");
                            const day = selectedDate
                              .getDate()
                              .toString()
                              .padStart(2, "0");
                            const formattedDate = `${year}-${month}-${day} 00:00:00`;

                            setfilterData({
                              ...filterData,
                              start_date: formattedDate,
                            });
                          }}
                        />
                      </div>

                      <div>
                        <div className="fw-bold">End Date</div>
                        <Flatpickr
                          className="form-control fw-bold"
                          options={{
                            dateFormat: "d/m/Y",
                            defaultDate: "today",
                          }}
                          name="payment_date"
                          placeholder="Enter start date"
                          onChange={(selectedDates) => {
                            const selectedDate = selectedDates[0];
                            // Convert to YYYY-MM-DD format
                            const year = selectedDate.getFullYear();
                            const month = (selectedDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0");
                            const day = selectedDate
                              .getDate()
                              .toString()
                              .padStart(2, "0");
                            const formattedDate = `${year}-${month}-${day} 23:59:59`;

                            setfilterData({
                              ...filterData,
                              end_date: formattedDate,
                            });
                          }}
                        />
                      </div>
                      <div>
                        <button
                          className="btn mt-3 btn-success "
                          onClick={(e) => {
                            getAllData();
                          }}
                        >
                          Search
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn mt-3 btn-info"
                          onClick={convertToCSVexcel}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          Export
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn mt-3 btn-danger"
                          onClick={generatePDF}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          PDF
                        </button>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  ></Nav>
                  <InfiniteScroll
                    dataLength={LeaveData.length}
                    next={fetchData}
                    hasMore={NoMore}
                  >
                    {permission.find(
                      (permission) =>
                        permission.permission_category === "LEAVE" &&
                        permission.permission_path === "1"
                    ) && (
                      <table
                        role="table"
                        className="align-middle table-nowrap table table-hover"
                      >
                        <thead className="table-light text-muted text-uppercase">
                          <tr>
                            <th
                              title="Leave List"
                              style={{ cursor: "pointer" }}
                            >
                              #
                            </th>
                            <th
                              className="sort desc"
                              onClick={() => {
                                SetLeaveData(LeaveData.slice().reverse());
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              DATE
                            </th>
                            <th
                              className="sort desc"
                              onClick={() => {
                                SetLeaveData(LeaveData.slice().reverse());
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              EMP NAME
                            </th>
                            <th
                              className="sort desc"
                              onClick={() => {
                                SetLeaveData(LeaveData.slice().reverse());
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              REASON
                            </th>
                            <th
                              className="sort desc"
                              onClick={() => {
                                SetLeaveData(LeaveData.slice().reverse());
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              FORM DATE
                            </th>
                            <th
                              className="sort desc"
                              onClick={() => {
                                SetLeaveData(LeaveData.slice().reverse());
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              TO DATE
                            </th>
                            <th
                              className="sort desc"
                              onClick={() => {
                                SetLeaveData(LeaveData.slice().reverse());
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              STATUS
                            </th>

                            {/* <th
                                                            className="sort desc"
                                                            onClick={() => {
                                                                SetLeaveData(LeaveData.slice().reverse());
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >STATUS DES</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {LeaveData.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <a
                                  className="fw-medium link-primary"
                                  href="/apps-ecommerce-order-details"
                                >
                                  {index + 1}
                                </a>
                              </td>
                              <td>
                                {item.leaveCreatedAt != undefined
                                  ? new Date(
                                      item.leaveCreatedAt
                                    ).toLocaleDateString("en-GB")
                                  : ""}
                              </td>
                              <td>{item.name}</td>
                              <td
                                style={{
                                  maxHeight: "auto",
                                  maxWidth: "200px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "pre-wrap",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: item.leave_reason,
                                }}
                              ></td>

                              <td>
                                {" "}
                                {new Date(item.from_date).toLocaleDateString(
                                  "en-GB"
                                )}
                              </td>
                              <td>
                                {" "}
                                {new Date(item.to_date).toLocaleDateString(
                                  "en-GB"
                                )}
                              </td>

                              <td>
                                {item.leave_status === "1" ? (
                                  <span className="badge bg-info-subtle text-info text-uppercase">
                                    {/* <i className="ri-add-line align-bottom me-1"></i> */}
                                    Pending
                                  </span>
                                ) : item.leave_status === "2" ? (
                                  <span className="badge bg-success-subtle text-success text-uppercase">
                                    {/* <i className="ri-add-line align-bottom me-1"></i> */}
                                    Approved
                                  </span>
                                ) : item.leave_status === "3" ? (
                                  <span className="badge bg-danger-subtle text-danger text-uppercase">
                                    {/* <i className="ri-add-line align-bottom me-1"></i> */}
                                    Rejected
                                  </span>
                                ) : (
                                  ""
                                )}
                              </td>

                              {/* <td
                                                                style={{
                                                                    maxHeight: "5px",
                                                                    maxWidth: "200px",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    whiteSpace: "pre-wrap",
                                                                }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: item.leave_reject_reason,
                                                                }}
                                                            ></td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </InfiniteScroll>
                </div>

                <ToastContainer closeButton={false} limit={1} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LeaveReports;
