import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  Row,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import Flatpickr from "react-flatpickr";
import DeleteModal from "../../../Components/Common/DeleteModal";
import UserUpdate from "./UserUpdate";
import AuthUser from "../../../helpers/Authuser";
import { useEffect } from "react";
import UserCreateModal from "./UserCreateModal";
import UserView from "./UserView";
import D_img from "../../D_img";
import InfiniteScroll from "react-infinite-scroll-component";
import { IMG_API_URL } from "../../../helpers/url_helper";
import ImgCom from "../../Fullscreenimg/ImgCom";
import Select from "react-select"
import { Manager } from "socket.io-client";
import jsPDF from "jspdf";
const UsersList = () => {
  const [modalStates, setModalStates] = useState(false);
  const [UpdatemodalStates, setUpdateModalStates] = useState(false);
  const [viewModalState, setviewModalState] = useState(false);
  const { http, permission, user } = AuthUser();
  //   Delete Aleart
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, SetID] = useState();
  const [dstatus, Setdstatus] = useState();
  const onClickDelete = (data) => {
    // console.log(data.status);
    SetID(data.id);
    Setdstatus(data.status);
    setDeleteModal(true);
  };
  const handleDeleteOrder = (data) => {
    if (data._reactName === "onClick") {
      http
        .get(`/user/delete/${ID}/${dstatus}`)
        .then(function (response) {
          if (response.data.status === 1) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);

          }
          Setcounts(counts - 1);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    setEmployee([]);
    setDeleteModal(false);
  };

  //   end Alert
  const handleCallback = (data, status) => {
    setModalStates(false);
    setUpdateModalStates(false);
    setEmployee([]);
    if (status == 1) {
      toast.success(data);
      Setcounts(counts + 1);
    } else {
      toast.warn(data);
      // toast.error(data);
      Setcounts(counts + 1);
    }
    // Setcounts(counts + 1);

  };
  const [NoMore, SetNoMore] = useState(true);

  const [counts, Setcounts] = useState(1);
  const [Employee, setEmployee] = useState([]);
  const [selectedId, setSelectedId] = useState(0);

  const handleSelectChange = (selectedOption) => {
    setSelectedId(selectedOption ? selectedOption.value : null);
    Setcounts(counts + 1)
  };


  const [EmployeeListForFilter, setEmployeeListForFilter] = useState([]);
  useEffect(() => {
    http
      .get("/user/list")
      .then(function (response) {
        console.log(response.data);


        setEmployee(response.data);
        // setEmployeeListForFilter(filteredData);

      })
      .catch(function (error) {
        console.log(error);
        SetNoMore(false);

      });

    http
      .get("/franchisebusiness/list")
      .then(function (response) {
        // console.log(response.data);

        if (response.data.length > 0) {
          const filteredData = user.user.u_type === 1 || user.user.u_type === 2
            ? response.data
            : response.data.filter(
              (employee) => employee.user_user_id === user.user.uid
            );

          setEmployeeListForFilter(filteredData);
        }
      })
      .catch(function (error) {
        console.log(error);
        SetNoMore(false);

      });

  }, [counts]);

  const [countss, setcountss] = useState(0);
  // useEffect(() => {
  //   http
  //     .post("/user/list", filterdata)
  //     .then(function (response) {
  //       console.log(response.data);

  //       const filteredData = user.user.uid === 1 || user.user.u_type === 2
  //         ? response.data
  //         : response.data.filter(
  //           (employee) => employee.user_user_id === user.user.uid
  //         );
  //       // const filteredemployee = filteredData.filter((e) => e.u_type !== 3 && e.u_type !== 5);
  //       setEmployee(filteredData);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       SetNoMore(false);

  //     });
  // }, [countss])

  const [businesslist, setbusinesslist] = useState([]);

  // Edit Data
  const [FindData, SetFind] = useState([]);
  const EditUpdate = (index) => {
    let FindArray = Employee.filter((_, i) => i === index);
    // console.log(FindArray[0]);
    SetFind(FindArray[0]);
    setUpdateModalStates(!UpdatemodalStates);
  };
  const ViewData = (index) => {
    let FindArray = Employee.filter((_, i) => i === index);
    // console.log(FindArray[0]);
    SetFind(FindArray[0]);
    setviewModalState(!viewModalState);
  };
  // shortcut to get add form
  // shortcuts for opening add form
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "a" || event.altKey && event.key === "A") {
        event.preventDefault();
        setModalStates(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const generatePDF = () => {
    const doc = new jsPDF('landscape');
  
    // Check for valid Employee data
    if (!Array.isArray(Employee) || Employee.length === 0) {
      console.error("Employee data is not valid.");
      return;
    }
  
    // Set table headers
    const headers = [
      "#",
      "NAME",
      "EMAIL",
      "MOB NO",
      "DEPARTMENT",
      "ROLL NAME",
      
    ];
  
    // Set table rows
    const data = Employee.map((item, index) => [
      index + 1,
      item.name,
      item.email,
      item.mobile_no,
      item.role_name,
      item.user_behave === 1 ? 'MANAGER' : 'EMPLOYEE',   // Adjusted access
    ]);
  
    // Set table style
    doc.setFontSize(12);
    doc.text("Team Member List", 14, 15);
    doc.autoTable({
      head: [headers],
      body: data,
      startY: 20,
    });
  
    // Save the PDF
    doc.save("EmployeeList.pdf");
  
    toast.success("PDF generated successfully!");
  };
  
  const convertToCSVexcel = () => {
    let csvContent = "#,NAME,EMAIL,MOB NO,ROLL NAME,DEPAEMENT";

    Employee.forEach((item, index) => {
      csvContent += `\n${index + 1},"${item.name}","${item.email}","${item.mobile_no}","${item.role_name}","${item.user_behave === 1 ?'MANAGER' : 'EMPLOYEE'}"`;
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "EmployeeList.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    toast.success("CSV generated successfully!");
  };



  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">

                  <Row className="align-items-center gy-3">
                    <div className="col-sm">
                      <h5 className="card-title mb-0">Team Member</h5>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-1 flex-wrap">
                        {/* {user.user.u_type === 1 || user.user.u_type === 2 ? (
                          <div>
                            <div className="fw-bold" style={{ width: "150px" }}>By Business</div>
                            <Select
                              className="fw-bold"
                              placeholder="Select Businessssss....."
                              options={[
                                // { value: 0, label: "Select All" },
                                { value: "00", label: "Me" },
                                ...EmployeeListForFilter.filter((e) => e.u_type === 3).map((e) => ({
                                  value: e.uid,
                                  label: e.name,
                                })),
                              ]}
                              onChange={(e) => {
                                // setFilterdata({ ...filterdata, ['business_type']: e.value });
                                setcountss(countss + 1);
                              }}
                            ></Select>
                          </div>
                        ) : (
                          ""
                        )} */}
                        <div>

                        </div>
                        {/* <div>
                          <button
                            className="btn mt-3 btn-success "
                            // onClick={(e) => {
                            //   handleFilter();
                            // }}
                          >
                            Search
                          </button>
                        </div> */}

                        {/* <div>
                          <button
                            type="button"
                            className="btn mt-3 btn-info"
                          // onClick={() => downloadCSV()}
                          >
                            <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                            Export
                          </button>
                        </div> */}
                        {/* <div>
                          <button
                            type="button"
                            className="btn mt-3 btn-danger"
                          // onClick={() => downloadPDF()}
                          >
                            <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                            Pdf
                          </button>
                        </div> */}
                        <div>
                          {/* {type != 1 ? permission.find(permission => permission.permission_category === "TASK" && permission.permission_path === "1") && (
                          <button
                            type="button"
                            className="btn mt-3 fw-bold btn-success add-btn"
                            id="create-btn"
                            onClick={() => setModalStates(!modalStates)}
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add Task
                          </button>)
                          : (
                            ""
                          )} */}
                        </div>
                        {/*      */}
                        <div>

                        </div>
                        <div>
                          {permission.find(permission => permission.permission_category === "EMPLOYEE" && permission.permission_path === "2") && (
                            <button
                              type="button"
                              className="btn mt-3 fw-bold btn-success add-btn"
                              id="create-btn"
                              onClick={() => setModalStates(!modalStates)}
                            >
                              <i className="ri-add-line align-bottom me-1"></i> Add Team Member
                            </button>
                          )}
                              <button
                            type="button"
                            className="btn  btn-info mt-3 ms-1"
                          onClick={convertToCSVexcel}
                          >
                            <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                            Export
                          </button>

                          <button
                            type="button"
                            className="btn  btn-danger mt-3 ms-1"
                          onClick={generatePDF}
                          >
                            <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                            PDF
                          </button>
                        </div>
                      </div>
                    </div>
                  </Row>
                  {/* {user.user.u_type === 1 ? (

                    <div className="d-flex justify-content-end">
                      {/* {user.user.u_type === 1 && (
                        <div>
                          <div className="fw-bold" style={{ width: "150px" }}>Select User</div>
                          <Select
                            className="fw-bold"
                            placeholder="Select User"
                            options={[
                              { value: 0, label: "Select All" },
                              ...Employee
                                .filter(e => e.u_type === 3)
                                .map(e => ({
                                  value: e.uid,
                                  label: e.name,
                                }))
                            ]}
                            onChange={handleSelectChange}
                          />

                        </div>
                      )} */}
                  {/* <div className="col-sm-auto mt-3  ms-3">
                        <div className="d-flex gap-1 flex-wrap">
                          {permission.find(permission => permission.permission_category === "EMPLOYEE" && permission.permission_path === "2") && (
                            <button
                              type="button"
                              className="btn fw-bold btn-success add-btn"
                              id="create-btn"
                              onClick={() => setModalStates(!modalStates)}
                            >
                              <i className="ri-add-line align-bottom me-1"></i> Add Employee
                            </button>
                          )}
                        </div>
                      </div> */}
                  {/* </div>

                  ) : (
                    <div className="col-sm-auto">
                      <div className="d-flex gap-1 flex-wrap">
                        {permission.find(permission => permission.permission_category === "EMPLOYEE" && permission.permission_path === "2") && (
                          <button
                            type="button"
                            className="btn fw-bold btn-success add-btn"
                            id="create-btn"
                            onClick={() => setModalStates(!modalStates)}
                          >
                            <i className="ri-add-line align-bottom me-1"></i> Add
                            Employee
                          </button>)}
                      </div>
                    </div>
                  )} * */}
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  ></Nav>
                  <div className=" table-responsive">
                    <InfiniteScroll
                      dataLength={Employee.length}
                      // next={fetchData}
                      hasMore={NoMore}
                    >
                      {permission.find(permission => permission.permission_category === "EMPLOYEE" && permission.permission_path === "1") && (
                        <table
                          role="table"
                          className="align-middle  table-nowrap table table-hover"
                        >
                          <thead className="table-light text-muted text-uppercase">
                            <tr>
                              <th
                                title="Toggle SortBy"
                                style={{ cursor: "pointer" }}
                              >
                                #
                              </th>
                              <th
                                title="Toggle SortBy"
                                style={{ cursor: "pointer" }}
                              >
                                Profile
                              </th>
                              <th
                                title="Toggle SortBy"
                                style={{ cursor: "pointer" }}
                              >
                                Name
                              </th>
                              <th
                                title="Toggle SortBy"
                                style={{ cursor: "pointer" }}
                              >
                                Email
                              </th>
                              <th
                                title="Toggle SortBy"
                                style={{ cursor: "pointer" }}
                              >
                                Mobile Number
                              </th>
                              <th
                                title="Toggle SortBy"
                                style={{ cursor: "pointer" }}
                              >
                                Department
                              </th>
                              <th
                                title="Toggle SortBy"
                                style={{ cursor: "pointer" }}
                              >
                                Role
                              </th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Employee.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <a
                                    className="fw-medium link-primary"
                                    href="/apps-ecommerce-order-details"
                                  >
                                    {index + 1}
                                  </a>
                                </td>
                                <td>
                                  {item.profile_photo != "/user/null" ? (
                                    // <img src={IMG_API_URL + item.out_photo} style={{ height: '100px', width: '100px' }} />
                                    // <img

                                    //   src={IMG_API_URL + item.profile_photo}
                                    //   alt={item.profile_photo}
                                    //   width={"100px"}
                                    // />
                                    <ImgCom
                                      imageUrl={IMG_API_URL + item.profile_photo}
                                      style={{ height: "100px", width: "100px" }}
                                    />
                                  ) : (
                                    <D_img />
                                  )}
                                </td>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{item.mobile_no}</td>
                                <td>{item.role_name}</td>
                                <td>{item.user_behave === 1 ?

                                  'MANAGER' : 'Team Member'
                                }</td>
                                <td>
                                  <ul className="list-inline hstack gap-2 mb-0">
                                    <li className="list-inline-item edit">
                                      <button
                                        className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                        onClick={() => ViewData(index)}
                                      >
                                        <i className="ri-eye-fill fs-16" />
                                      </button>
                                    </li>
                                    <li className="list-inline-item edit">
                                      {permission.find(permission => permission.permission_category === "EMPLOYEE" && permission.permission_path === "3") && (
                                        <button
                                          className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                          onClick={() => EditUpdate(index)}
                                        >
                                          <i className="ri-pencil-fill fs-16" />
                                        </button>
                                      )}
                                    </li>

                                    <li className="list-inline-item">
                                      {permission.find(permission => permission.permission_category === "EMPLOYEE" && permission.permission_path === "4") && (
                                        <button
                                          onClick={() =>
                                            onClickDelete({ id: item.uid, status: 0 })
                                          }
                                          className="text-danger d-inline-block remove-item-btn  border-0 bg-transparent"
                                        >
                                          <i className="ri-delete-bin-5-fill fs-16" />
                                        </button>)}
                                    </li>
                                  </ul>
                                </td>
                              </tr>

                            ))
                            }

                          </tbody>
                        </table>
                      )}
                    </InfiniteScroll>
                  </div>
                </div>

                {modalStates === true ? (
                  <UserCreateModal
                    modalStates={modalStates}
                    setModalStates={() => {
                      setModalStates(false);
                    }}
                    checkchang={handleCallback}
                  />
                ) : (
                  ""
                )}
                {UpdatemodalStates === true ? (
                  <UserUpdate
                    modalStates={UpdatemodalStates}
                    setModalStates={() => {
                      setUpdateModalStates(false);
                    }}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                {viewModalState === true ? (
                  <UserView
                    modalStates={viewModalState}
                    setModalStates={() => {
                      setviewModalState(false);
                    }}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
        
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UsersList;
