import React from "react";
import ReactDOM from "react-dom/client";
import store from "./store"; // Update import statement
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import './LeadStatus.css';


import { BrowserRouter, HashRouter } from "react-router-dom";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {" "}
    {/* Use the 'store' directly */}
    <React.Fragment>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
