import React, { useRef, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  Row,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import AttendenceAdd from "./AttendenceAdd";
import AttendenceEdit from "./AttendenceEdit";
import AuthUser from "../../helpers/Authuser";
import { useEffect } from "react";
import { IMG_API_URL } from "../../helpers/url_helper";
import InfiniteScroll from "react-infinite-scroll-component";
import D_img from "../D_img";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import ImgCom from "../Fullscreenimg/ImgCom";
import jsPDF from "jspdf";
import "jspdf";
import "jspdf-autotable";
const AttendenceList = () => {
  const { http, https, user } = AuthUser();

  const [filterData, setfilterData] = useState({
    start_date: new Date().toISOString().split("T")[0],
    end_date: new Date().toISOString().split("T")[0],
    remark: 0,
    user_id: user.user.u_type === 1 ? 0 : user.user.uid,
  });
  const [modalStates, setModalStates] = useState(false);
  const [UpdatemodalStates, setUpdateModalStates] = useState(false);

  const [counts, Setcounts] = useState(1);
  const [Attendance, SetAttendance] = useState([]);
  console.log(Attendance);


  const [assignedByData, setassignedByData] = useState([]);
  const getassignedByData = async () => {
    http
      .get("/franchisebusiness/list")
      .then((response) => {
        if (response.data.length > 0) {
          setassignedByData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleFilter = async () => {
    http
      .post(`/attendence/filter`, filterData)
      .then(function (response) {
        if (response.data.length > 0) {
          SetAttendance(response.data);

        } else {
          SetAttendance([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // useEffect(() => {
  //   handleFilter();
  // }, [filterData])
  useEffect(() => {
    getassignedByData();
    document.title = "Attendence | CRM";
    handleFilter();
  }, [counts]);
  const fetchData = () => {
    Setcounts(counts + 1);
  };

  // shortcuts for opening add form
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.altKey && event.key === "a") ||
        (event.altKey && event.key === "A")
      ) {
        event.preventDefault();
        setModalStates(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  //   end Alert
  const handleCallback = (data, status) => {
    SetAttendance([]);
    if (status == 1) {
      setModalStates(false);
      setUpdateModalStates(false);
      toast.success(data);
      Setcounts(counts + 1);
    } else {
      toast.warn(data);
      Setcounts(counts + 1);
    }
  };
  // Edit Data
  const [FindData, SetFind] = useState([]);
  const EditUpdate = (index) => {
    let FindArray = Attendance.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setUpdateModalStates(!UpdatemodalStates);
  };

  const generatePDF = () => {
    const doc = new jsPDF('landscape');

    // Set table headers
    const headers = [
      "#",
      "Employee Name",
      "Attendance Date",
      "In Time",
      "In location",
      "In Photo",
      "Out Time",
      "Out Photo",
      "Out Location",
      "Remark",
    ];

    // Set table rows
    const data = Attendance.map((item, index) => [
      index + 1,
      item.name,
      item.attendance_date,
      item.in_time,
      item.in_location,
      item.in_photo,
      item.out_time,
      item.out_photo,
      item.out_location,
      item.remark,
    ]);

    // Set table style
    doc.setFontSize(12);
    doc.text("Attendance List", 14, 15);
    doc.autoTable({
      head: [headers],
      body: data,
      startY: 20,
    });

    // Save the PDF
    doc.save("AttendanceList.pdf");

    toast.success("PDF generated successfully!");
  };


  const tableRef = useRef(null);
  const downloadCSV = () => {
    const table = document.getElementById("tableToPrint");
    if (!table) {
      console.error("Table element not found.");
      return;
    }

    const rows = table.querySelectorAll("tr");
    let csvContent = "data:text/csv;charset=utf-8,\n";

    rows.forEach((row, rowIndex) => {
      const rowData = Array.from(row.children).map((col, colIndex) => {
        const cellContent = col.dataset.value || col.textContent;
        const encodedCellContent = encodeURIComponent(cellContent)
          .replace(/%20/g, " ") // Replace %20 with space
          .replace(/%2D/g, "-") // Replace %2D with dash
          .replace(/%3A/g, "_"); // Replace %3A with underscore for colons
        return encodedCellContent;
      });

      csvContent += rowData.join(",") + "\n";
    });

    const encodedURI = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", "attendence_list.csv");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // console.log("Data downloaded successfully.");
  };

  const AttenStatus = [
    { value: "0", label: "All" },
    { value: "1", label: "Present" },
    { value: "2", label: "Absent" },
  ];
  const calculateDuration = (inTime, outTime) => {
    const inTimeDate = new Date(`2024-01-03 ${inTime}`);
    const outTimeDate = new Date(`2024-01-03 ${outTime}`);
    const timeDiffMilliseconds = outTimeDate - inTimeDate;
    const timeDiffHours = timeDiffMilliseconds / (1000 * 60 * 60);
    return timeDiffHours.toFixed(2); // Display hours with two decimal places
  };
  // Function to format time to 12-hour format
  const formatTime = (timeString) => {
    if (!timeString) return "";

    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const [hours, minutes] = timeString.split(":");
    const date = new Date();
    date.setHours(parseInt(hours, 10), parseInt(minutes, 10));

    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="card-header border-0">

                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">Attendence List</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      {user.user.u_type === 1 ? (
                        <div>
                          <div className="fw-bold">By employee</div>
                          <Select
                            className="fw-bold"
                            placeholder="Select Employee"
                            options={assignedByData.map((e) => ({
                              value: e.uid,
                              label: e.name,
                            }))}
                            onChange={(e) => {
                              setfilterData({
                                ...filterData,
                                user_id: e.value,
                              });
                            }}
                          ></Select>
                        </div>
                      ) : (
                        ""
                      )}
                      <div>
                        <div className="fw-bold">Attendence Status</div>
                        <Select
                          className="fw-bold"
                          placeholder="Select Status"
                          options={AttenStatus.map((e) => ({
                            value: e.value,
                            label: e.label,
                          }))}
                          onChange={(e) => {
                            setfilterData({
                              ...filterData,
                              remark: e.value,
                            });
                          }}
                        ></Select>
                      </div>
                      <div>
                        <div className="fw-bold">Start Date</div>
                        <Flatpickr
                          className="form-control fw-bold"
                          options={{
                            dateFormat: "d/m/Y",
                            defaultDate: "today",
                          }}
                          name="payment_date"
                          placeholder="Enter start date"
                          onChange={(selectedDates) => {
                            const selectedDate = selectedDates[0];
                            // Convert to YYYY-MM-DD format
                            const year = selectedDate.getFullYear();
                            const month = (selectedDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0");
                            const day = selectedDate
                              .getDate()
                              .toString()
                              .padStart(2, "0");
                            const formattedDate = `${year}-${month}-${day}`;

                            setfilterData({
                              ...filterData,
                              start_date: formattedDate,
                            });
                          }}
                        />
                      </div>

                      <div>
                        <div className="fw-bold">End Date</div>
                        <Flatpickr
                          className="form-control fw-bold"
                          options={{
                            dateFormat: "d/m/Y",
                            defaultDate: "today",
                          }}
                          name="payment_date"
                          placeholder="Enter start date"
                          onChange={(selectedDates) => {
                            const selectedDate = selectedDates[0];
                            // Convert to YYYY-MM-DD format
                            const year = selectedDate.getFullYear();
                            const month = (selectedDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0");
                            const day = selectedDate
                              .getDate()
                              .toString()
                              .padStart(2, "0");
                            const formattedDate = `${year}-${month}-${day}`;

                            setfilterData({
                              ...filterData,
                              end_date: formattedDate,
                            });
                          }}
                        />
                      </div>
                      <div>
                        <button
                          className="btn mt-3 btn-success "
                          onClick={(e) => {
                            handleFilter();
                          }}
                        >
                          Search
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn mt-3 btn-info"
                          onClick={() => downloadCSV()}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          Export
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn mt-3 btn-danger"
                          onClick={generatePDF}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          PDF
                        </button>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  ></Nav>
                  <InfiniteScroll
                    dataLength={0}
                  // next={fetchData}
                  // hasMore={NoMore}
                  >
                    <table
                      role="table"
                      ref={tableRef}
                      id="tableToPrint"
                      className="align-middle table-nowrap table table-hover"
                    >
                      <thead className="table-light text-muted text-uppercase">
                        <tr>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            #
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            Date
                          </th>

                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            IN TIME
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            IN LOCATION
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            IN PHOTO
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            OUT TIME
                          </th>
                          <th>OUT LOCATION</th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            OUT PHOTO
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            WORKING TIME
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            STATUS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Attendance.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <a
                                className="fw-medium link-primary"
                                href="/apps-ecommerce-order-details"
                              >
                                {index + 1}
                              </a>
                            </td>
                            <td>
                              {/* {item.attendance_date} */}
                              {new Date(item.attendance_date).toLocaleDateString("en-GB")}
                            </td>
                            <td>{formatTime(item.in_time)}</td>
                            <td
                              style={{
                                maxWidth: "150px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.in_location}
                            </td>
                            <td>
                              {item.in_photo != "/attendance/null" ? (
                                <ImgCom
                                  imageUrl={IMG_API_URL + item.in_photo}
                                  style={{ height: "100px", width: "100px" }}
                                />
                              ) : (
                                <D_img />
                              )}
                            </td>

                            <td>{formatTime(item.out_time)}</td>
                            <td
                              style={{
                                maxWidth: "150px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.out_location}
                            </td>
                            <td>
                              {item.out_photo != "/attendance/null" ? (
                                <ImgCom
                                  imageUrl={IMG_API_URL + item.out_photo}
                                  style={{ height: "100px", width: "100px" }}
                                />
                              ) : (
                                <D_img />
                              )}
                            </td>

                            <td>
                              {calculateDuration(item.in_time, item.out_time)}{" "}
                              -Hours
                            </td>
                            <td>
                              {item.remark == 1 ? (
                                <span className="badge bg-success-subtle text-success text-uppercase">
                                  <i className="ri--line align-bottom me-1"></i>
                                  Present
                                </span>
                              ) : (
                                <span className="badge bg-danger-subtle text-success text-uppercase">
                                  <i className="ri--line align-bottom me-1"></i>
                                  Absent
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </InfiniteScroll>
                </div>

                {UpdatemodalStates === true ? (
                  <AttendenceEdit
                    modalStates={UpdatemodalStates}
                    setModalStates={() => {
                      setUpdateModalStates(false);
                    }}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                <ToastContainer closeButton={false} limit={1} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AttendenceList;
