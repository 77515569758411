import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Dropdown, DropdownMenu, DropdownToggle, Input, Row } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';

//SimpleBar
import SimpleBar from "simplebar-react";
import { toast } from "react-toastify";
import AuthUser from '../../helpers/Authuser';
import Sortable from 'sortablejs';
import Widgets from '../../pages/DashboardEcommerce/Widgets';
import FeatherIcon from 'feather-icons-react';

const AttendenceDropdown = () => {
    const cardItemTotal = useRef(null);
    const { http, user, token } = AuthUser();


    const [isCartDropdown, setIsCartDropdown] = useState(false);

    const [cartItem, setCartItem] = useState();

    const toggleCartDropdown = () => {
        setIsCartDropdown(!isCartDropdown);
        setCartItem(2);
    };
    const { type } = useParams();
    const currentYear = new Date().getFullYear();
    // console.log(formData);
    const [totalData, settotalData] = useState({
        taskCount: 0,
        taskStatusCount: [],
        leadCount: 0,
        leadStatusCount: [],
        customerCount: 0,
        attendanceCount: 0,
    });
    const [Employee, setEmployee] = useState([]); const [leavetoday, setleavetoday] = useState(0);
    const [formData, setformData] = useState({
        start_date: `${currentYear}-01-01` + " 00:00:00",
        end_date: `${currentYear}-12-31` + " 23:59:59",
        leave_status: 0,
        leave_id: type,
        user_id: 0,
        customer_name: 0,
        lead_id: type ? type : 0,
        assigned_by: 0,
        department: 0
    });
    const handleFilter = async () => {
        //dash board total counts and statuswise report
        http.post("/dashboard/total", formData).then((res) => {
            settotalData({
                taskCount: res.data.taskCount,
                leadStatusCount: res.data.leadStatusCounts,
                taskStatusCount: res.data.taskStatusCounts,
                leadCount: res.data.leadCount,
                customerCount: res.data.customerCount,
                attendanceCount: res.data.attendanceCount,
                attendanceCountout: res.data.attendanceCountout,
            });
        });
        const ct = new Date().toISOString().split('T')[0];
        //dashboard leave list
        http
            .post(`leave/list`, formData)
            .then(function (response) {
                // console.log("LeaveData:", response.data);
                if (response.data.length > 0) {
                    const filteredData = response.data.filter(item => {
                        const itemDate = ct; // Assuming item.date is in YYYY-MM-DD format
                        return itemDate >= item.from_date && itemDate <= item.to_date;
                    });
                    // console.log("jhgdcjv", filteredData);
                    setleavetoday(filteredData.length)

                }
            })
            .catch(function (error) {
                console.log(error);
            });

        http
            .get(`/user/list`)
            .then(function (response) {
                if (response.data.length > 0) {
                    setEmployee(response.data);
                } else {
                    setEmployee([]);
                }
            })
            .catch(function (error) {
                console.log(error);
            });



    };
    useEffect(() => {
        handleFilter();
    }, [])
    const [Attendance, SetAttendance] = useState([]);
    const [filterData, setfilterData] = useState({
        start_date: new Date().toISOString().split("T")[0],
        end_date: new Date().toISOString().split("T")[0],
        remark: 0,
        user_id: user.user.u_type === 1 ? 0 : user.user.uid,
    });
    useEffect(() => {
        document.title = "User Dashboard CRM";
        http
            .post(`/attendence/filter`, filterData)
            .then(function (response) {
                if (response.data.length > 0) {
                    SetAttendance(response.data);
                    // console.log(response.data);
                } else {
                    // SetAttendance([]);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <React.Fragment>
            <Dropdown isOpen={isCartDropdown} toggle={toggleCartDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none">
                    <i className='bx bx-user-check fs-22'></i>
                    {/* <span
                        className="position-absolute cartitem-badge topbar-badge fs-10 translate-middle badge rounded-pill bg-info">{cartItem}<span
                            className="visually-hidden">unread messages</span></span> */}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-xl dropdown-menu-end p-0 dropdown-menu-cart"
                    aria-labelledby="page-header-cart-dropdown">
                    <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                        <Row className="align-items-center">
                            <Col>
                                <h6 className="m-0 fs-16 fw-semibold">Attendance</h6>
                            </Col>
                            <div className="col-auto">
                                {/* <span className="badge bg-warning-subtle text-warning fs-13"><span className="cartitem-badge"> {cartItem} </span> items</span> */}
                            </div>
                        </Row>
                    </div>
                    {user && user.user.u_type === 1 || user.user.u_type === 3 ? (<>
                        <Widgets Comp={totalData}
                            emp={Employee}
                            todayleave={leavetoday}
                        />
                    </>) : (<>
                        <Col xl={12}>

                            <div className="widget-container col-md-12">
                                <div className="card dashboard-icon-widget clock-in-out-card">
                                    <div className="card-body">
                                        {Attendance && Attendance[0] && Attendance[0].in_time !== null && Attendance[0].out_time === null ? (
                                            <>
                                                <div className="widget-icon bg-coral">
                                                    <FeatherIcon size={60} color="text-danger" icon="clock" />
                                                    <Link
                                                        to="/attendence"
                                                        className="btn btn-default text-danger spinning-btn"
                                                        title="Clock In"
                                                        data-inline-loader="1"
                                                        data-closest-target="#js-clock-in-out"
                                                        data-act="ajax-request"
                                                        data-action-url="https://rise.fairsketch.com/attendance/log_time"
                                                        style={{ marginLeft: '80px' }}
                                                    >
                                                        <FeatherIcon size={24} color="text-danger" icon="log-out" />
                                                        Checked Out
                                                    </Link>
                                                </div>
                                                <div className="widget-details">
                                                    <div className="mt5 bg-transparent-white">You are currently Checked In   </div>
                                                    <div className="mt5 bg-transparent-white">In Time : {Attendance && Attendance[0] && Attendance[0] && Attendance[0].in_time
                                                    }  </div>
                                                </div>
                                            </>
                                        ) : Attendance && Attendance[0] && Attendance[0].in_time !== null && Attendance[0].out_time !== null ? (
                                            <>
                                                <div className="widget-icon bg-coral">
                                                    <FeatherIcon size={60} color="text-info" icon="clock" />

                                                </div>
                                                <div className="widget-details">
                                                    <div className="mt5 bg-transparent-white">You are currently Checked Out</div>
                                                    <div className="mt5 bg-transparent-white">In Time : {Attendance && Attendance[0] && Attendance[0].in_time
                                                    }  </div>
                                                    <div className="mt5 bg-transparent-white">Out Time : {Attendance && Attendance[0] && Attendance[0].out_time
                                                    }  </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="widget-icon bg-coral">
                                                    <FeatherIcon size={60} color="text-info" icon="clock" />
                                                    <Link
                                                        to="/attendence"
                                                        className="btn btn-default text-info spinning-btn"
                                                        title="Clock In"
                                                        data-inline-loader="1"
                                                        data-closest-target="#js-clock-in-out"
                                                        data-act="ajax-request"
                                                        data-action-url="https://rise.fairsketch.com/attendance/log_time"
                                                        style={{ marginLeft: '80px' }}
                                                    >
                                                        <FeatherIcon size={24} color="text-info" icon="log-out" />
                                                        Checked In
                                                    </Link>
                                                </div>
                                                <div className="widget-details">
                                                    <div className="mt5 bg-transparent-white text-danger">You are currently Not Checked In</div>
                                                </div></>
                                        )}

                                    </div>
                                </div>
                            </div>

                        </Col>
                    </>)}

                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default AttendenceDropdown;