import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthUser from "../helpers/Authuser";

const FranchiseLayoutMenuData = () => {
  const history = useNavigate();
  const { user, permission } = AuthUser();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isReports, setIsReports] = useState(false);
  const [isAccounts, setIsAccounts] = useState(false);
  const [isBarcodePrint, setIsBarcodePrint] = useState(false);
  const [isPaymentAndReciept, setIsPaymentAndReciept] = useState(false);
  const [isRewardPoints, setisRewardPoints] = useState(false);
  const [isHrDepartment, setisHrDepartment] = useState(false);
  const [isProfitBook, setIsProfitBook] = useState(false);
  const [isCategories, setIsCategories] = useState(false);
  const [isSettings, setIsSettings] = useState(false);
  const [isTax, setIsTax] = useState(false);
  const [isUnits, setIsUnits] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [isemployee, setIsemployee] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }
  const permissionsList = useSelector(
    (state) => state.permissionsSlice.permissionsList
  );
  const [isAdmin, setIsAdmin] = useState(false);

  // CHECKING IF THE LOGGED IN USER IS AN ADMIN OR NOT
  const checkIsAdmin = () => {
    const data = sessionStorage.getItem("authUser");
    const jsonData = JSON.parse(data);
    if (jsonData.user.email == "admin") {
      setIsAdmin(true);
    }
  };
  useEffect(() => {
    checkIsAdmin();
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "DASHBOARD") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Print Barcodes") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Categories") {
      setIsCategories(false);
    }
    if (iscurrentState !== "units") {
      setIsUnits(false);
    }
    if (iscurrentState !== "setting") {
      setIsSettings(false);
    }

    if (iscurrentState !== "Tax") {
      setIsTax(false);
    }
    if (iscurrentState !== "Profile") {
      setIsProfile(false);
    }
  }, [history, isAccounts, isReports, iscurrentState, isDashboard, isCategories, isTax, isemployee]);

  const menuItemsList = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "DASHBOARD",
      label: "Dashboard",
      icon: "mdi mdi-speedometer",
      link: "/franchise-dashboard",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("DASHBOARD");
        updateIconSidebar(e);
      },
    },

    {
      id: "LEAD",
      label: "Leads",
      icon: "bx bx-check",
      link: "/leads-list",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },


    {
      id: "CUSTOMER",
      label: "Customers",
      icon: "bx bx-user",
      link: "/customer-list",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "BUSINESS",
      label: "Business",
      icon: "mdi mdi-view-grid-plus-outline",
      link: "/business-list",
      click: function (e) {
        e.preventDefault();
        setIsemployee(!isemployee);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },

    }
    ,
    {
      id: "COMMUNICATION",
      label: "Communication",
      icon: "bx bx-user",
      link: "/chat-app",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },


  ];

  return (
    <React.Fragment>
      {menuItemsList.filter((item) => item !== null)}
    </React.Fragment>
  );
};
export default FranchiseLayoutMenuData;
