import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Row,
  Col,
  Button,
  Form,
  Input,
} from "reactstrap";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
import CustomInput from "../Unit/Input";
import { IMG_API_URL } from "../../helpers/url_helper";

const MessageEdit = (props) => {
  const { https } = AuthUser();
  const [modal, setModal] = useState(false);
  const [Category, SetCategory] = useState(props.edit_data);
  const [messageImage, setMessageImage] = useState(null)
  const [CategoryImg1, SetCategoryImg1] = useState("");
  const [Img2, SetImg2] = useState("");

  const Close = () => {
    setModal(false);
    props.setModalStates();
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const categoryOptions = [
    { label: "Task", value: "1" },
    { label: "Lead", value: "2" },
  ];


  const [checkNameStatus, setCheckStatus] = useState({});
  const [msg, setMsg] = useState(0);
  const SubmitData = () => {

    if (Category.message_title === "") {
      setMsg(1);
    } else if (Category.message_description === "") {
      setMsg(2);
    }
    else if (Category.message_category === "") {
      setMsg(3);
    }
    else {
      https
        .put("/message/update", Category)
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
          setModal(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };


  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        props.setModalStates(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  console.log();
  return (
    <div>
      <Modal id="showModal" isOpen={modal} toggle={toggle} size="lg" centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Message Edit
        </ModalHeader>
        <span className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              SubmitData();
              return false;
            }}
            action="#"
          >
            <ModalBody>
              <Card className="border card-border-success p-3 shadow-lg">
                <Row>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Message Title<span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "red" }}>{msg === 1 ? "title cannot be empty!" : ""}</div>
                      </Label>
                      <CustomInput
                        checkNameStatus={msg === 1 ? { borderColor: "red" } : {}}
                        name="category"
                        value={Category.message_title}
                        id="category-field"
                        className="form-control fw-bold"
                        placeholder="Message Title"
                        type="text"
                        onChange={(e) => {
                          setMsg(0);
                          SetCategory({ ...Category, message_title: e.target.value });
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Message Description<span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "red" }}>{msg === 2 ? "description cannot be empty!" : ""}</div>
                      </Label>
                      <Input
                        style={msg === 2 ? { borderColor: "red" } : {}}

                        name="category"
                        id="category-field"
                        className="form-control fw-bold"
                        placeholder="Message Description"
                        type="text"
                        value={Category.message_description}
                        onChange={(e) => {
                          setMsg(0);
                          SetCategory({ ...Category, message_description: e.target.value })
                        }}

                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Category <span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "red" }}>{msg === 3 ? "Category cannot be empty!" : ""}</div>
                      </Label>
                      <Select


                        options={categoryOptions}
                        placeholder={Category.message_category == 1 ? "Task" : "Lead"}
                        onChange={(e) => {
                          setMsg(0);
                          SetCategory({ ...Category, message_category: e.value })
                        }}
                        className="fw-bold"
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Message Image</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="messageImage"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title=""
                              data-bs-original-title="Select Image"
                            >
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                  <i className="ri-image-fill"></i>
                                </div>
                              </div>
                            </label>
                            <input
                              className="form-control d-none"
                              id="messageImage"
                              type="file"
                              accept="image/png, image/gif, image/jpeg"

                              onChange={(e) => {
                                SetCategory({
                                  ...Category,
                                  image: e.target.files[0]
                                })
                              }}
                            />
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">

                              {Category.message_image && Category.image === undefined ?
                                <img
                                  src={IMG_API_URL + "message/" + Category.message_image}
                                  id="message-image"
                                  alt={Category.image}
                                  height="100px"
                                  width={"100px"}
                                />
                                : Category.image ? (
                                  <img
                                    src={URL.createObjectURL(Category.image)}
                                    id="message-image"
                                    alt={Category.image}
                                    height="100px"
                                    width={"100px"}
                                  />
                                ) : (
                                  ""
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                </Row>
              </Card>
            </ModalBody>
            <div className="modal-footer">

              <button
                ref={submitButtonRef}
                name="close"
                id="close"
                type="submit"
                className="btn btn-primary"
              // onClick={() => SubmitData()}
              >
                <i className="ri-save-3-line align-bottom me-1"></i>
                Update
              </button>
              <button
                name="close"
                id="close"
                type="button"
                className="btn btn-danger"
                onClick={() => Close()}
              >
                <i className="ri-close-line me-1 align-middle" />
                Close
              </button>
            </div>
          </Form>
        </span>
      </Modal>
    </div>
  );
};

export default MessageEdit;
