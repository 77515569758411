import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Button,
  Form,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
import CustomInput from "../Unit/Input";

const ShiftAdd = (props) => {
  const { https } = AuthUser();
  const [modal, setModal] = useState(false);
  const [Shift, SetShift] = useState({
    shift_name: "",
    shift_intime: "",
    shift_outime: "",
  });
  // console.log(Shift);
  const handleTimeChange = (field, value) => {
    // Regular expression to validate 24-hour Indian time format (HH:MM)
    const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

    if (timeRegex.test(value)) {
      // If the entered time matches the Indian time format regex, update the Shift state
      SetShift({ ...Shift, [field]: value });
    }
  };
  const Close = () => {
    setModal(false);
    props.setModalStates();
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [checkNameStatus, setCheckStatus] = useState({});
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const SubmitData = () => {
    if (loading) return;
    setLoading(true);

    if (
      Shift.shift_name.trim() === "" ||
      Shift.shift_intime.trim() === "" ||
      Shift.shift_outime.trim() === ""
    ) {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsg("Shift fields cannot be empty!");
    } else {
      https
        .post("/shift/store", Shift)
        .then(function (response) {
          // console.log(response.data);
          props.checkchang(response.data.message, response.data.status);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          // Reset loading state to allow further submissions if necessary
          setLoading(false);
        });
      setModal(false);
    }
  };

  const handleShift = (e) => {
    setCheckStatus({});
    setMsg("");
    SetShift(e.target.value);
  };

  // shortcuts for save and close

  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        props.setModalStates(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    <div>
      <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Create Shift
        </ModalHeader>
        <span className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              SubmitData();
              return false;
            }}
            action="#"
          >
            <ModalBody>
              <Card className="border card-border-success p-3 shadow-lg">
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Shiftname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Shift Name<span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "red" }}>{msg}</div>
                      </Label>
                      <CustomInput
                        checkNameStatus={checkNameStatus}
                        name="shift_name"
                        id="Shift-field"
                        className="form-control fw-bold"
                        placeholder="Shift Name"
                        type="text"
                        // value={Shift}
                        onChange={(e) =>
                          SetShift({ ...Shift, ["shift_name"]: e.target.value })
                        }
                      //
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Shiftname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Shift InTime<span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "red" }}>{msg}</div>
                      </Label>
                      <Input
                        checkNameStatus={checkNameStatus}
                        name="shift_intime"
                        id="shift_intime"
                        className="form-control fw-bold"
                        type="time"
                        onChange={(e) =>
                          handleTimeChange("shift_intime", e.target.value)
                        }

                      // onChange={(e) => SetShift({ ...Shift, ['shift_intime']: e.target.value })}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Shiftname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Shift OutTime<span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "red" }}>{msg}</div>
                      </Label>
                      <Input
                        checkNameStatus={checkNameStatus}
                        name="shift_outime"
                        id="shift_outime"
                        className="form-control fw-bold"
                        type="time"
                        onChange={(e) =>
                          handleTimeChange("shift_outime", e.target.value)
                        }
                      // onChange={(e) => SetShift({ ...Shift, ['shift_outime']: e.target.value })}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <button
                ref={submitButtonRef}
                name="close"
                id="close"
                type="submit"
                className="btn btn-primary"
              // onClick={() => SubmitData()}
              disabled={loading} // Disable button if loading
              >
                <i className="ri-save-3-line align-bottom me-1"></i>
                {loading ? "Saving..." : "Save"}
              </button>
              <button
                name="close"
                id="close"
                type="button"
                className="btn btn-danger"
                onClick={() => Close()}
              >
                <i className="ri-close-line me-1 align-middle" />
                Close
              </button>
            </div>
          </Form>
        </span>
      </Modal>
    </div>
  );
};

export default ShiftAdd;
