import React, { useEffect, useState, useCallback, useRef } from "react";
import { Card, Label, Input, Row, Col, Button, Form } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import CustomInput from "../../Unit/Input";
import AuthUser from "../../../helpers/Authuser";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import { toast } from "react-toastify";

const FollowUpAdd = (props) => {
  const { http, user } = AuthUser();
  const [followUpData, setfollowUpData] = useState({
    follow_up_send_by: user.user.uid,
    follow_up_lead_id: props.edit_data.lead_id ? props.edit_data.lead_id : "",
    follow_up_description: "",
    follow_up_task_id: props.edit_data.task_id ? props.edit_data.task_id : "",
  });

  const [manageStatus, setmanageStatus] = useState(0);
  const SubmitFollowUp = () => {
    if (followUpData.follow_up_description === "") {
      setmanageStatus(2);
    } else {
      http
        .post("/follow_up/lead", followUpData)
        .then((res) => {
          if (res.data.status === 1) {
            props.checkchang(res.data.message, res.data.status);
            setfollowUpData({
              ...followUpData,
              follow_up_description: "",
            });
          } else {
            toast.warn(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div>
      <Card className=" p-3">
        <Row>
          <Col lg={12}>
            <Label
              htmlFor="Leadname-field"
              className="form-label fw-bold d-flex justify-content-between"
            >
              <div>Follow Up</div>
              <div style={{ color: "red" }}>
                {manageStatus === 2 ? "Follow up cannot be empty!" : ""}
              </div>
            </Label>
            <CKEditor
              rows={1}
              editor={ClassicEditor}
              onChange={(e, editor) => {
                setmanageStatus(0);
                setfollowUpData({
                  ...followUpData,
                  follow_up_description: editor.getData(),
                });
              }}
              data={followUpData.follow_up_description}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 text-center" lg={12}>
            <Button
              name="close"
              id="close"
              type="button"
              className="btn btn-primary"
              onClick={() => SubmitFollowUp()}
            >
              <i className="ri-save-3-line align-bottom me-1"></i>
              Add Follow Up
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default FollowUpAdd;
