import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Form,
  Button,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useRef } from "react";
import AuthUser from "../../../helpers/Authuser";
import ShiftAdd from "../../Shift Slots/ShiftAdd";
import RoleCreateModal from "../Roles/RoleCreateModal";
import Flatpickr from "react-flatpickr";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

const FranchiseCreateModal = (props) => {
  const [modal, setModal] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const { https, http, user } = AuthUser();
  const [modalStatess1, setModalStatess1] = useState(false);
  const [modalStatess, setModalStatess] = useState(false);
  const [counts, Setcounts] = useState(1);
  const [ActiveShift, setActiveShift] = useState(false);
  const [activeRole, setActiveRole] = useState(false);
  const [EmployeeShift, setEmployeeShift] = useState();
  const [EmployeeRole, setEmployeeRole] = useState([]);
  const [ManageShift, setManageShift] = useState(0);
  const [ManageRole, setManageRole] = useState(0);

  const handleCallback = (data) => {
    Setcounts(counts + 1);
    setManageRole(1);
    setManageShift(1);
    toast.success(data);
    setModalStatess(false);
    setModalStatess1(false);
  };
  const [EmployeeAdhar, SetEmployeeAdhar] = useState("");
  const [EmployeeProfile, SetEmployeeProfile] = useState("");
  const [EmployeeDoc, SetEmployeeDoc] = useState("");
  const [EmployeeAudio, SetEmployeeAudio] = useState("");
  const [EmployeeVideo, SetEmployeeVideo] = useState("");
  const [EmployeePan, SetEmployeePan] = useState("");
  const [EmployeeQrCode, SetEmployeeQrCode] = useState("");
  const [employeeData, setemployeeData] = useState({
    u_type: 5,
    user_user_id: user && user.user.uid,
    name: "",
    address: "",
    send_message: "",
    user_role_id: 2,
    date_of_joining: "",
    shift_id: 1,
    mobile_no: "",
    emergency_contact: "",
    email: "",
    password: "",
    profile_photo: "",
    document: "",
    audio: "",
    video: "",
  });

  useEffect(() => {
    http
      .get("/role/list")
      .then(function (response) {
        if (response.data.length == 0) {
          setActiveRole(false);
        } else {
          setEmployeeRole(response.data);
          setActiveRole(true);
          setemployeeData(() => ({
            ...employeeData,
            user_role_id:
              ManageRole == 0
                ? response.data[0].role_id
                : response.data[response.data.length - 1].role_id,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    http
      .get("/shift/list")
      .then(function (response) {
        if (response.data.length == 0) {
          setActiveShift(false);
        } else {
          setEmployeeShift(response.data);
          setActiveShift(true);
          setemployeeData(() => ({
            ...employeeData,
            shift_id:
              ManageShift == 0
                ? response.data[0].shift_id
                : response.data[response.data.length - 1].shift_id,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [counts + 1]);

  const getSelectedCityValue = (e) => {
    setemployeeData({ ...employeeData, shift_id: e.value });
  };
  const getSelectedRoleValue = (e) => {
    setemployeeData({ ...employeeData, user_role_id: e.value });
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStatess1]);
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStatess]);

  // console.log(employeeData);
  const Close = () => {
    setModal(false);
    props.setModalStates();
  };

  // GETTING ROLES LIST
  const getRolesList = async () => {
    try {
      const apiResponse = await https.get("/role/list");
      setRolesList(apiResponse.data);
    } catch (error) {
      console.log(error);
    }
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [checkNameStatus, setCheckStatus] = useState({});
  const [checkEmailStatus, setEmailCheckStatus] = useState({});
  const [checkPasswordStatus, setPasswordCheckStatus] = useState({});

  const [msg, setMsg] = useState("");
  const [msg1, setMsg1] = useState("");
  const [EmailMsg, setEmailMsg] = useState("");
  const [PasswordMsg, setPasswordMsg] = useState("");
  const validateField = (
    fieldType,
    value,
    setStateFunction,
    setMsgFunction,
    errorMessage
  ) => {
    if (value === "") {
      setStateFunction({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsgFunction(errorMessage);
      return false; // Validation failed
    }
    return true; // Validation passed
  };

  const [loading, setLoading] = useState(false);
  const SubmitData = () => {
    // Prevent further submissions if already submitting
    if (loading) return;
    setLoading(true);

    // Validate fields
    const isNameValid = validateField(
      "name",
      nameInput,
      setCheckStatus,
      setMsg,
      "Name cannot be empty!"
    );
    const isEmailValid = validateField(
      "email",
      employeeData.email,
      setEmailCheckStatus,
      setEmailMsg,
      "Email cannot be empty!"
    );
    const isPasswordValid = validateField(
      "password",
      passwordInput,
      setPasswordCheckStatus,
      setPasswordMsg,
      "Password cannot be empty!"
    );

    if (isNameValid && isEmailValid && isPasswordValid) {
      // Set loading to true to prevent further clicks
      setLoading(true);

      const updatedEmployeeData = {
        ...employeeData,
        send_message: messageInput,
        name: nameInput,
        address: addressInput,
        mobile_no: mobileInput,
        emergency_contact: emergencyContactInput,
        password: passwordInput,
      };

      https
        .post("/user/store", updatedEmployeeData)
        .then(function (response) {
          toast.success(response.data.message);
          props.checkchang(response.data.message, response.data.status);
          setModal(false);
        })
        .catch(function (error) {
          console.log("Error", error);
        })
        .finally(() => {
          // Reset loading state to allow further submissions if necessary
          setLoading(false);
        });
    }
    else {
      // If validation fails, reset loading state
      setLoading(false);
    }
  };

  const handleEmployee = (e) => {
    setCheckStatus({});
    setMsg("");
    setemployeeData({ ...employeeData, [e.target.name]: e.target.value });
  };


  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        props.setModalStates(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [props]);

  const [messageInput, setMessageInput] = useState("");
  const [nameInput, setNameInput] = useState("");
  const [addressInput, setAddressInput] = useState("");
  const [mobileInput, setMobileInput] = useState("");
  const [emergencyContactInput, setEmergencyContactInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [isMessageListening, setIsMessageListening] = useState(false);
  const [isNameListening, setIsNameListening] = useState(false);
  const [isAddressListening, setIsAddressListening] = useState(false);
  const [isMobileListening, setIsMobileListening] = useState(false);
  const [isEmergencyContactListening, setIsEmergencyContactListening] =
    useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { transcript, browserSupportsSpeechRecognition, resetTranscript } =
    useSpeechRecognition();
  const numberAndSymbolMapping = {
    zero: "0",
    one: "1",
    two: "2",
    three: "3",
    four: "4",
    five: "5",
    six: "6",
    seven: "7",
    eight: "8",
    nine: "9",
    ten: "10",
    dot: ".",
    comma: ",",
    dash: "-",
    plus: "+",
    at: "@",
    hashtag: "#",
    dollar: "$",
    percent: "%",
    ampersand: "&",
    asterisk: "*",
    exclamation: "!",
    question: "?",
    slash: "/",
    backslash: "\\",
    parenthesis: "()",
    brackets: "[]",
    brace: "{}",
    semicolon: ";",
    colon: ":",
    quote: '"',
    "single quote": "'",
    equal: "=",
    "greater than": ">",
    "less than": "<",
  };

  const convertSpokenNumbersToDigits = (text) => {
    return text
      .split(" ")
      .map((word) => numberAndSymbolMapping[word.toLowerCase()] || word)
      .join(" ");
  };

  // Update the appropriate input based on which mic is active
  useEffect(() => {
    if (transcript) {
      const convertedTranscript = convertSpokenNumbersToDigits(transcript);
      if (isMessageListening) {
        setMessageInput(convertedTranscript);
      } else if (isNameListening) {
        setNameInput(convertedTranscript); // Allow both numbers and strings
      } else if (isAddressListening) {
        setAddressInput(convertedTranscript);
      } else if (isMobileListening) {
        // Allow only numeric input
        const numericInput = convertedTranscript.replace(/\D/g, "");
        setMobileInput(numericInput);
      } else if (isEmergencyContactListening) {
        // Allow only numeric input
        const numericInput = convertedTranscript.replace(/\D/g, "");
        setEmergencyContactInput(numericInput);
      }
    }
  }, [
    transcript,
    isMessageListening,
    isNameListening,
    isAddressListening,
    isMobileListening,
    isEmergencyContactListening,
    convertSpokenNumbersToDigits, // Add this function to dependencies if it's defined inside the component
  ]);

  // Handle speech recognition state based on the active mic
  useEffect(() => {
    if (
      isMessageListening ||
      isNameListening ||
      isAddressListening ||
      isMobileListening ||
      isEmergencyContactListening
    ) {
      SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
    } else {
      SpeechRecognition.stopListening();
      resetTranscript();
    }
  }, [
    isMessageListening,
    isNameListening,
    isAddressListening,
    isMobileListening,
    isEmergencyContactListening,
    resetTranscript,
  ]);

  if (!browserSupportsSpeechRecognition) {
    return <div>Your browser does not support speech recognition.</div>;
  }

  const handleMessageMicClick = () => {
    setIsMessageListening(!isMessageListening);
    setIsNameListening(false);
    setIsAddressListening(false);
    setIsMobileListening(false);
    setIsEmergencyContactListening(false);
  };

  const handleNameMicClick = () => {
    setIsNameListening(!isNameListening);
    setIsMessageListening(false);
    setIsAddressListening(false);
    setIsMobileListening(false);
    setIsEmergencyContactListening(false);
  };

  const handleAddressMicClick = () => {
    setIsAddressListening(!isAddressListening);
    setIsMessageListening(false);
    setIsNameListening(false);
    setIsMobileListening(false);
    setIsEmergencyContactListening(false);
  };

  const handleMobileMicClick = () => {
    setIsMobileListening(!isMobileListening);
    setIsMessageListening(false);
    setIsNameListening(false);
    setIsAddressListening(false);
    setIsEmergencyContactListening(false);
  };

  const handleEmergencyContactMicClick = () => {
    setIsEmergencyContactListening(!isEmergencyContactListening);
    setIsMessageListening(false);
    setIsNameListening(false);
    setIsAddressListening(false);
    setIsMobileListening(false);
  };

  // const handlePasswordMicClick = () => {
  //   setIsPasswordListening(!isPasswordListening);
  //   setIsMessageListening(false);
  //   setIsNameListening(false);
  //   setIsAddressListening(false);
  //   setIsMobileListening(false);
  //   setIsEmergencyContactListening(false);
  // };

  // const toggleShowPassword = () => {
  //   setShowPassword(!showPassword);
  // };

  const handleMessageChange = (e) => {
    setMessageInput(e.target.value);
  };

  const handleNameChange = (e) => {
    setNameInput(e.target.value);
  };

  const handleAddressChange = (e) => {
    setAddressInput(e.target.value);
  };

  const handleMobileChange = (e) => {
    const numericInput = e.target.value.replace(/\D/g, "");
    setMobileInput(numericInput);
  };

  const handleEmergencyContactChange = (e) => {
    const numericInput = e.target.value.replace(/\D/g, "");
    setEmergencyContactInput(numericInput);
  };

  const handlePasswordChange = (e) => {
    setPasswordInput(e.target.value);
  };

  return (
    <div>
      <Modal
        id="showModal"
        size="xl"
        isOpen={modal}
        toggle={toggle}
        style={{ marginTop: "5px", paddingTop: "10px" }}
        centered
      >
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Add Franchises
        </ModalHeader>
        <div className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              SubmitData();
              return false;
            }}
            action="#"
          >
            <ModalBody>
              <Card className="border card-border-success p-3 shadow-lg">
                <div className="mb-3">
                  <Row>
                    <Col lg={3}>
                      <Label
                        htmlFor="role-name-field"
                        className="form-label fw-bold d-flex justify-content-between mt-3"
                      >
                        Franchise Name
                      </Label>
                      <div style={{ position: "relative" }}>
                        <input
                          type="text"
                          name="name"
                          id="role-name-field"
                          placeholder="Name"
                          className="form-control fw-bold"
                          value={nameInput} // Display transcribed text
                          onChange={handleNameChange} // Handle manual text input
                          style={{ paddingRight: "40px" }} // Prevent text overlap with the button
                        />
                        {/* <Button
                          type="button"
                          color="none"
                          className="" // Remove all classes
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            width: "30px",
                            height: "30px",
                            padding: "0",
                            background: "none", // Remove any background
                            border: "none", // Remove any border
                            boxShadow: "none", // Remove any shadow
                          }}
                          onClick={handleNameMicClick} // Handle mic button click for name input
                        >
                          <i
                            className={
                              isNameListening
                                ? "ri-stop-circle-fill align-bottom"
                                : "ri-mic-fill align-bottom"
                            }
                          ></i>
                        </Button> */}
                      </div>
                    </Col>

                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between mt-3"
                      >
                        <div>
                          Mobile Number
                          <span className="text-danger fw-bold"> *</span>
                        </div>
                      </Label>

                      <div style={{ position: "relative" }}>
                        <Input
                          type="text"
                          value={mobileInput}
                          onChange={handleMobileChange}
                          className="fw-bold"
                          placeholder="Mobile Number"
                          style={{ paddingRight: "40px" }} // Add padding to prevent text overlap with button
                        />

                        {/* <Button
                          type="button"
                          color="none"
                          // className="chat-send waves-effect waves-light rounded-circle"
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            width: "30px",
                            height: "30px",
                            padding: "0",
                            zIndex: "1", // Ensure the button is above the input field
                            background: "none", // Remove any background
                            border: "none", // Remove any border
                            boxShadow: "none", // Remove any shadow
                          }}
                          onClick={handleMobileMicClick}
                        >
                          <i
                            className={
                              isMobileListening
                                ? "ri-stop-circle-fill align-bottom"
                                : "ri-mic-fill align-bottom"
                            }
                          ></i>
                        </Button> */}
                      </div>
                    </Col>

                    <Col lg={3} className="position-relative">
                      {" "}
                      {/* Make the Col container relative */}
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between mt-3"
                      >
                        <div>Emergency Mob no</div>
                      </Label>
                      <div className="position-relative">
                        {" "}
                        {/* Container for the input and button */}
                        <Input
                          type="text"
                          value={emergencyContactInput}
                          onChange={handleEmergencyContactChange}
                          className="fw-bold pe-5" // Add padding end to make space for the button
                          placeholder="Emergency Mobile Number"
                        />
                        {/* <Button
                          type="button"
                          color="none"
                          className="chat-send waves-effect waves-light rounded-circle position-absolute" // Add position-absolute
                          style={{
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            width: "30px",
                            height: "30px",
                            padding: "0",
                            zIndex: "1", // Ensure the button is above the input field
                            background: "none", // Remove any background
                            border: "none", // Remove any border
                            boxShadow: "none", // Remove any shadow
                          }}
                          onClick={handleEmergencyContactMicClick}
                        >
                          <i
                            className={
                              isEmergencyContactListening
                                ? "ri-stop-circle-fill align-bottom"
                                : "ri-mic-fill align-bottom"
                            }
                          ></i>
                        </Button> */}
                      </div>
                    </Col>

                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        <div>
                          Email Address<span className="text-danger"> *</span>{" "}
                        </div>
                        <div style={{ color: "red" }}>{EmailMsg}</div>
                      </Label>
                      <Input
                        checkEmailStatus={checkEmailStatus}
                        name="email"
                        id="email"
                        onChange={(e) => handleEmployee(e)}
                        className="form-control fw-bold"
                        placeholder="Email address"
                        type="email"
                      />
                    </Col>

                    <Col lg={3} className="position-relative">
                      <Label
                        htmlFor="password"
                        className="form-label fw-bold d-flex justify-content-between mt-3"
                      >
                        <div>
                          Password<span className="text-danger"> *</span>
                        </div>
                        <div style={{ color: "red" }}>{PasswordMsg}</div>
                      </Label>
                      <div className="position-relative">
                        <Input
                          checkPasswordStatus={checkPasswordStatus}
                          name="password"
                          id="password"
                          value={passwordInput} // Binding input value
                          onChange={handlePasswordChange} // Update value on change
                          className="form-control fw-bold"
                          placeholder="Password"
                          type={showPassword ? "text" : "password"} // Toggle between text and password
                        />
                        
                        <i
                          className={`ri-eye${showPassword ? "-off" : ""}-fill`}
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "20px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                          onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                        ></i>
                      </div>
                    </Col>

                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        <div> Franchise Created Date</div>
                      </Label>
                      <Flatpickr
                        className="form-control fw-bold"
                        options={{
                          dateFormat: "d/m/Y",
                          defaultDate: "today",
                        }}
                        name="payment_date"
                        placeholder="Select Joining Date"
                        onChange={(selectedDates) => {
                          const selectedDate = selectedDates[0];

                          const year = selectedDate.getFullYear();
                          const month = (selectedDate.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const day = selectedDate
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const formattedDate = `${year}-${month}-${day} 00:00:00`;

                          setemployeeData({
                            ...employeeData,
                            date_of_joining: formattedDate,
                          });
                        }}
                      />
                    </Col>

                    <Col lg={6} style={{ position: "relative" }}>
                      <Label
                        htmlFor="address"
                        className="form-label fw-bold d-flex justify-content-between mt-3"
                      >
                        Address
                      </Label>
                      <div style={{ position: "relative" }}>
                        <textarea
                          name="address"
                          id="address"
                          placeholder="Address"
                          className="form-control fw-bold"
                          onChange={handleAddressChange}
                          value={addressInput}
                          style={{
                            paddingRight: "50px", // Add padding to make space for the button inside the textarea
                            height: "50px", // Adjust height as needed
                            resize: "none", // Prevent resizing
                          }}
                        ></textarea>
                        <Button
                          type="button"
                          color="none"
                          // className="chat-send waves-effect waves-light rounded-circle"
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            width: "30px",
                            height: "30px",
                            padding: "0",
                            borderRadius: "50%",
                            zIndex: "10",
                            background: "none", // Remove any background
                            border: "none", // Remove any border
                            boxShadow: "none", // Remove any shadow
                          }}
                          onClick={handleAddressMicClick}
                        >
                          <i
                            className={
                              isAddressListening
                                ? "ri-stop-circle-fill align-bottom"
                                : "ri-mic-fill align-bottom"
                            }
                          ></i>
                        </Button>
                      </div>
                    </Col>

                    {/* <Col lg={6}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between mt-3"
                      >
                        Message
                      </Label>
                      <div style={{ position: "relative" }}>
                        <textarea
                          name="send_message"
                          id="send_message"
                          placeholder="Message"
                          className="form-control fw-bold"
                          value={messageInput} // Display combined text and speech-transcribed text
                          onChange={handleMessageChange} // Update text in textarea
                          style={{ paddingRight: "40px" }} // Add padding to prevent text overlap with the button
                        ></textarea>
                        <Button
                          type="button"
                          color="none"
                          // className="chat-send waves-effect waves-light rounded-circle"
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            width: "30px",
                            height: "30px",
                            padding: "0",
                            background: "none", // Remove any background
                            border: "none", // Remove any border
                            boxShadow: "none", // Remove any shadow
                          }}
                          onClick={handleMessageMicClick}
                        >
                          <i
                            className={
                              isMessageListening
                                ? "ri-stop-circle-fill align-bottom"
                                : "ri-mic-fill align-bottom"
                            }
                          ></i>
                        </Button>
                      </div>
                    </Col> */}

                    <Col lg={3}>
                      <div className="mb-4 mt-3">
                        <h5 className="fs-15 mb-1">
                          Franchise logo <span className="text-danger">*</span>
                          <div style={{ color: "red" }}>{msg1}</div>
                        </h5>

                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute top-100 start-100 translate-middle">
                              <label
                                htmlFor="empoyeeProfile"
                                className="mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title=""
                                data-bs-original-title="Select Image"
                              >
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i
                                      className="ri-image-fill"
                                      style={{
                                        color: "#009CA4",
                                        fontSize: "20px",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="empoyeeProfile"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={(e) => {
                                  setemployeeData({
                                    ...employeeData,
                                    profile_photo: e.target.files[0],
                                  });
                                  SetEmployeeProfile(e.target.files[0]);
                                }}
                              />
                            </div>
                            <div className="avatar-lg">
                              <div className="avatar-title bg-light rounded">
                                {EmployeeProfile ? (
                                  <img
                                    src={URL.createObjectURL(EmployeeProfile)}
                                    id="product-img"
                                    alt="product-img"
                                    height={"100px"}
                                    width={"100px"}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    {/* <Col lg={3}>
                      <div className="mb-4 mt-3">
                        <h5 className="fs-15 mb-1">
                          Document
                          <div style={{ color: "red" }}>{msg1}</div>
                        </h5>

                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute top-100 start-100 translate-middle">
                              <label
                                htmlFor="employeeDocument"
                                className="mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Select Document"
                              >
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i
                                      className="ri-file-upload-fill"
                                      style={{
                                        color: "#009CA4",
                                        fontSize: "20px",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="employeeDocument"
                                type="file"
                                accept=".pdf,.doc,.docx,.txt,.xlsx"
                                onChange={(e) => {
                                  setemployeeData({
                                    ...employeeData,
                                    document: e.target.files[0],
                                  });
                                  SetEmployeeDoc(e.target.files[0]);
                                }}
                              />
                            </div>
                            <div className="avatar-lg">

                              {EmployeeDoc ? (
                                EmployeeDoc.type.startsWith("image/") ? (
                                  <img
                                    src={URL.createObjectURL(EmployeeDoc)}
                                    alt="Selected file preview"
                                    height="50px"
                                    width="50px"
                                  />
                                ) : (
                                  <div className="d-flex flex-column align-items-center">
                                    <div>
                                      {EmployeeDoc.type.includes("pdf") && (
                                        <i
                                          className="ri-file-pdf-fill"
                                          style={{
                                            fontSize: "50px",
                                            color: "#FF0000",
                                          }}
                                        ></i>
                                      )}
                                      {EmployeeDoc.type.includes("word") && (
                                        <i
                                          className="ri-file-word-fill"
                                          style={{
                                            fontSize: "50px",
                                            color: "#1E90FF",
                                          }}
                                        ></i>
                                      )}
                                      {EmployeeDoc.type.includes("excel") && (
                                        <i
                                          className="ri-file-excel-fill"
                                          style={{
                                            fontSize: "50px",
                                            color: "#28A745",
                                          }}
                                        ></i>
                                      )}
                                      {!EmployeeDoc.type.includes("pdf") &&
                                        !EmployeeDoc.type.includes("word") &&
                                        !EmployeeDoc.type.includes("excel") && (
                                          <i
                                            className="ri-file-fill"
                                            style={{
                                              fontSize: "50px",
                                              color: "#6c757d",
                                            }}
                                          ></i>
                                        )}
                                    </div>
                                    <p style={{ color: "#007bff" }}>
                                      {EmployeeDoc.name
                                        ? `${EmployeeDoc.name.substring(
                                          0,
                                          5
                                        )}.${EmployeeDoc.name
                                          .split(".")
                                          .pop()}`
                                        : ""}
                                    </p>
                                  </div>
                                )
                              ) : (
                                ""
                              )}
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col> */}

                    {/* <Col lg={3}>
                      <div className="mb-4 mt-3">
                        <h5 className="fs-15 mb-1">
                          Audio
                          <div style={{ color: "red" }}>{msg1}</div>
                        </h5>

                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute top-100 start-100 translate-middle">
                              <label
                                htmlFor="employeeAudio"
                                className="mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Select Audio"
                              >
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i
                                      className="ri-music-fill"
                                      style={{
                                        color: "#009CA4",
                                        fontSize: "20px",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="employeeAudio"
                                type="file"
                                accept=".mp3,.aac,.wav,.flac,.mpeg"
                                onChange={(e) => {
                                  setemployeeData({
                                    ...employeeData,
                                    audio: e.target.files[0],
                                  });
                                  SetEmployeeAudio(e.target.files[0]);
                                }}
                              />
                            </div>
                            <div className="avatar-lg">

                              {EmployeeAudio ? (
                                <div className="d-flex flex-column align-items-center">

                                  <i
                                    className="ri-file-music-fill"
                                    style={{
                                      fontSize: "50px",
                                      color: "#007bff",
                                    }}
                                  ></i>

                                  <p style={{ color: "#007bff" }}>
                                    {EmployeeAudio.name
                                      ? `${EmployeeAudio.name.substring(
                                        0,
                                        5
                                      )}.${EmployeeAudio.name
                                        .split(".")
                                        .pop()}`
                                      : ""}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}

                            </div>
                          </div>
                        </div>
                      </div>
                    </Col> */}

                    {/* <Col lg={3}>
                      <div className="mb-4 mt-3">
                        <h5 className="fs-15 mb-1">
                          Video
                          <div style={{ color: "red" }}>{msg1}</div>
                        </h5>

                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute top-100 start-100 translate-middle">
                              <label
                                htmlFor="employeeVideo"
                                className="mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Select Video"
                              >
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i
                                      className="ri-video-fill"
                                      style={{
                                        color: "#009CA4",
                                        fontSize: "20px",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="employeeVideo"
                                type="file"
                                accept="video/mp4, video/avi, video/mkv"
                                onChange={(e) => {
                                  setemployeeData({
                                    ...employeeData,
                                    video: e.target.files[0],
                                  });
                                  SetEmployeeVideo(e.target.files[0]);
                                }}
                              />
                            </div>
                            <div className="avatar-lg">
                            
                              {EmployeeVideo ? (
                                <div className="d-flex flex-column align-items-center">
                                 
                                  <i
                                    className="ri-video-fill"
                                    style={{
                                      fontSize: "50px",
                                      color: "#007bff",
                                    }}
                                  ></i>
                                  
                                  <p style={{ color: "#007bff" }}>
                                    {EmployeeVideo.name
                                      ? `${EmployeeVideo.name.substring(
                                        0,
                                        5
                                      )}.${EmployeeVideo.name
                                        .split(".")
                                        .pop()}`
                                      : ""}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                        
                          </div>
                        </div>
                      </div>
                    </Col> */}

                  </Row>
                </div>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <button
                ref={submitButtonRef}
                name="submit"
                id="submit"
                type="submit"
                className="btn btn-primary"
                // onClick={SubmitData}
                disabled={loading} // Disable button if loading
              >
                <i className="ri-save-3-line align-bottom me-1"></i>
                {loading ? "Saving..." : "Save"}
              </button>
              <button
                name="close"
                id="close"
                type="button"
                className="btn btn-danger"
                onClick={() => Close()}
              >
                <i className="ri-close-line me-1 align-middle" />
                Close
              </button>
            </div>
          </Form>
        </div>
      </Modal>

      {modalStatess1 === true ? (
        <ShiftAdd
          modalStates={modalStatess1}
          setModalStates={() => {
            setModalStatess1(false);
          }}
          checkchang={handleCallback}
        />
      ) : modalStatess === true ? (
        <RoleCreateModal
          modalStates={modalStatess}
          setModalStates={() => {
            setModalStatess(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default FranchiseCreateModal;


