import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Button,
  Form,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
import CustomInput from "../Unit/Input";

const RefrencesAdd = (props) => {
  const { https } = AuthUser();
  const [modal, setModal] = useState(false);
  const [Refrences, SetRefrences] = useState("");

  const Close = () => {
    setModal(false);
    props.setModalStates();
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [checkNameStatus, setCheckStatus] = useState({});
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const SubmitData = () => {
    if (loading) return;
    setLoading(true);

    if (Refrences == "") {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsg("Refrences can not be empty!");
    } else {
      https
        .post("/reference/store", {
          reference_name: Refrences,
        })
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
          setModal(!modal);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          // Reset loading state to allow further submissions if necessary
          setLoading(false);
        });
    }
  };
  const handleRefrences = (e) => {
    setCheckStatus({});
    setMsg("");
    SetRefrences(e.target.value);
  };

 // shortcuts for save and close
 const submitButtonRef = useRef();
 useEffect(() => {
   const handleKeyDown = (event) => {
     if (event.altKey && event.key === "Escape") {
       event.preventDefault();
       props.setModalStates(false);
     }
     if (
       (event.altKey && event.key === "s") ||
       (event.altKey && event.key === "S")
     ) {
       event.preventDefault();
       submitButtonRef.current.click();
     }
     if (
       (event.altKey && event.key === "c") ||
       (event.altKey && event.key === "C")
     ) {
       event.preventDefault();
       props.setModalStates(false);
     }
   };

   window.addEventListener("keydown", handleKeyDown);

   return () => {
     window.removeEventListener("keydown", handleKeyDown);
   };
 }, []);

  return (
    <div>
      <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Create Refrences
        </ModalHeader>
        <span className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              SubmitData();
              return false;
            }}
            action="#"
          >
            <ModalBody>
              <Card className="border card-border-success p-3 shadow-lg">
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Refrencesname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Refrences Name<span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "red" }}>{msg}</div>
                      </Label>
                      <CustomInput
                        checkNameStatus={checkNameStatus}
                        name="Refrences_name"
                        id="Refrences-field"
                        className="form-control fw-bold"
                        placeholder="Refrences Name"
                        type="text"
                        value={Refrences}
                        onChange={handleRefrences}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <button
                ref={submitButtonRef}
                name="close"
                id="close"
                type="submit"
                className="btn btn-primary"
                // onClick={() => SubmitData()}
                disabled={loading} // Disable button if loading
              >
                <i className="ri-save-3-line align-bottom me-1"></i>
                {loading ? "Saving..." : "Save"}
              </button>
              <button
                name="close"
                id="close"
                type="button"
                className="btn btn-danger"
                onClick={() => Close()}
              >
                <i className="ri-close-line me-1 align-middle" />
                Close
              </button>
            </div>
          </Form>
        </span>
      </Modal>
    </div>
  );
};

export default RefrencesAdd;
