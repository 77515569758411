import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Button,
  Row,
  Col,
  Form,
} from "reactstrap";

import Flatpickr from "react-flatpickr";

import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import LeadStatusAdd from "../LeadStatus/LeadStatusAdd";
import SourceAdd from "../Source/SourceAdd";
import CustomerAdd from "../Customers/CustomerAdd";
import RefrencesAdd from "../Refrences/RefrencesAdd";
import ProductAdd from "../Product/ProductAdd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import D_img from "../D_img";
import FollowUpAdd from "./FollowUp/FollowUpAdd";
import FollowUpList from "./FollowUp/FollowUpList";
import { API_URL } from "../../helpers/url_helper";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';

const LeadsAdd = (props) => {

  const { http, https, user } = AuthUser();
  const [smsStatus, SetsmsStatus] = useState(false);

  const [tempValid, settempValid] = useState(0);
  const [modal, setModal] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null); // State for audio blob
  const recorderControls = useAudioRecorder();

  const [leadsData, setLeads] = useState({
    customer: "",
    product: 1,
    department: user.user.u_type != 1 || user.user.u_type != 3 || user.user.u_type != 5 ? user.user.user_role_id : 0,
    value: 0,
    today_date: new Date(),
    minimum_due_date: new Date(),
    ref_by: 1,
    maximum_due_date: new Date(),
    source: 1,
    priority: 1,
    description: "",
    assigned_by: (user.user.u_type == 4 || user.user.u_type == 5 || user.user.u_type == 2) ? `[${user.user.uid}]` : "[]",
    tags: "[]",
    status: 1,
    image: [],
    // repeat_every_day: tempRe.temp_num+" "+tempRe.temp_label,
    repeat_every_day: "",
    next_follow_up: new Date(),
    total_cycles: "",
    lead_created_by: user.user.uid,
    // audio_file: audioBlob,
  });
  console.log(leadsData);

  const addAudioElement = (blob) => {
    const uniqueFileName = `recording_${Date.now()}_${Math.floor(Math.random() * 10000)}.mp3`;

    // Create a new File object from the blob with the random file name
    const mp3File = new File([blob], uniqueFileName, { type: 'audio/mp3' });

    // Update the audio blob state and leadsData with the mp3 file
    setAudioBlob(mp3File);
    setLeads({
      ...leadsData,
      audio_file: mp3File // Add the mp3 file to leadsData
    });
  };

  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);

    // Update the leadsData with the selected images
    setLeads({
      ...leadsData,
      image: [...leadsData.image, ...selectedImages],
    });
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...leadsData.image];
    updatedImages.splice(index, 1);

    setLeads({
      ...leadsData,
      image: updatedImages,
    });
  };
  const [EmployeeDoc, SetEmployeeDoc] = useState("");
  const [EmployeeVideo, SetEmployeeVideo] = useState("");
  const [manageSource, setmanageSource] = useState(0);
  const [manageProduct, setmanageProduct] = useState(0);
  const [manageCustomer, setmanageCustomer] = useState(0);
  const [manageLeadStatus, setmanageLeadStatus] = useState(0);
  const [manageReferBy, setmanageReferBy] = useState(0);

  const [statusModal, setstatusModal] = useState(false);
  const [productModal, setproductModal] = useState(false);
  const [sourceModal, setsourceModal] = useState(false);
  const [customerModal, setcustomerModal] = useState(false);
  const [referBy, setreferBy] = useState(false);

  const priorityData = [
    { label: "Low", value: "1" },
    { label: "Medium", value: "2" },
    { label: "High", value: "3" },
    { label: "Urgent", value: "4" },
  ];

  const [count, setCount] = useState(0);
  const handleCallback = (data, status) => {
    if (status == 1) {
      toast.success(data);
      setCount(count + 1);
    } else {
      toast.warn(data);
      setCount(count + 1);
    }
    getcustomerData();
    getproductData();
    getreferByData();
    getstatusData();
    gettagData();
    getAssignedBy();
    getcustomerData();
    getsourceData();
  };

  const [tagData, settagData] = useState([]);
  const gettagData = async () => {
    http
      .get("/tags/list")
      .then((res) => {
        if (res.data.length > 0) {
          settagData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [statusData, setstatusData] = useState([]);
  const getstatusData = async () => {
    http
      .get("/lead/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageLeadStatus === 1) {
            setLeads({
              ...leadsData,
              status: res.data[res.data.length - 1].lead_status_id,
            });
          }
          setstatusData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [referByData, setreferByData] = useState([]);
  const getreferByData = async () => {
    http
      .get("/reference/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageReferBy === 1) {
            setLeads({
              ...leadsData,
              ref_by: res.data[res.data.length - 1].reference_id,
            });
          }
          setreferByData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [productData, setproductData] = useState([]);
  const getproductData = async () => {
    http
      .get("/product/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageProduct === 1) {
            setLeads({
              ...leadsData,
              product: res.data[res.data.length - 1].product_id,
            });
          }
          setproductData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [sourceData, setsourceData] = useState([]);
  const getsourceData = async () => {
    http
      .get("/source/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageSource === 1) {
            setLeads({
              ...leadsData,
              source: res.data[res.data.length - 1].source_id,
            });
          }
          setsourceData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [customerData, setcustomerData] = useState([]);
  const getcustomerData = async () => {
    http
      .get("/customer/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageCustomer === 1) {


            setLeads({
              ...leadsData,
              customer: res.data[res.data.length - 1].customer_id,
            });
          }


          setcustomerData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [assignedBy, setAssignedBy] = useState([]);
  const getAssignedBy = async () => {
    try {

      const res = await http.get("/franchisebusiness/list");
      const filteredEmployee = res.data.filter(
        (e) => e.u_type !== 3 && e.u_type !== 5
      );
      if (selectedRoleIds !== null) {
        const filteredEmployee = res.data.filter(
          (e) => e.u_type !== 3 && e.u_type !== 5 && (e.user_role_id === selectedRoleIds || e.role_created_for == selectedRoleIds)
        );
        setAssignedBy(filteredEmployee);
      } else {
        setAssignedBy(filteredEmployee);
      }
    } catch (err) {
      console.log(err);
    }
  };


  const [selectedRoleIds, setSelectedRoleIds] = useState((user.user.u_type == 2 && user.user.user_behave == 1) ? user.user.user_role_id : null);
  const [selectedRoleUserIds, setSelectedRoleUserIds] = useState([]);
  const [statuscheckforAssignedBy, setstatuscheckforAssignedBy] = useState(0);
  const [role, setRole] = useState([]);

  useEffect(() => {
    getAssignedBy();
  }, [statuscheckforAssignedBy])
  const getRole = async () => {
    try {
      const response = await http.get("/role/list");
      const filteredData = user && (user.user.u_type === 1 || user.user.u_type === 2)
        ? response.data.filter(
          (role) =>
            parseInt(role.role_created_user_id) === user.user.user_user_id ||
            parseInt(role.role_created_user_id) === user.user.uid
        )
        : response.data.filter(
          (role) => parseInt(role.role_created_user_id) === user.user.uid
        );
      setRole(filteredData);
    } catch (error) {
      console.log(error);
    }
  };



  const [message, setmessage] = useState([]);
  const getMessageList = async () => {
    http
      .get("/message/list")
      .then((res) => {
        if (res.data.length > 0) {
          setmessage(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getcustomerData();
    getproductData();
    getreferByData();
    getMessageList();
    getstatusData();
    gettagData();
    // getAssignedBy();
    getRole();
    getsourceData();
  }, [count]);

  const Close = () => {
    setModal(false);
    props.setModalStates();
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [Selecteduser, SetSelectedUser] = useState([]);
  useEffect(() => {
    // Parse the string representation of the array into an actual array of IDs
    const assignedByArray = JSON.parse(leadsData.assigned_by);

    // Use map to find the corresponding user for each ID
    const users = assignedByArray.map((userId) =>
      assignedBy.find((user) => user.uid === userId)
    );

    // Set the state with the array of selected users
    SetSelectedUser(users.filter(Boolean)); // Filter out undefined values
  }, [leadsData.assigned_by, assignedBy]);


  const [loading, setLoading] = useState(false);
  const SubmitData = () => {
   if (loading) return;
   setLoading(true);
    if (leadsData.assigned_by === "[]") {
      settempValid(1);
    } else if (leadsData.customer === "") {
      settempValid(2);
    } else if (leadsData.description === "") {
      settempValid(3);
    } else {
      // const formData = new FormData();
      // // Append the leadsData object as a JSON string
      // formData.append("leadsData", JSON.stringify(leadsData));
      // if (audioBlob) {
      //   formData.append("audio_file", audioBlob, "recording.wav"); // Append the audio file
      // }
      https
        .post("/leads/store", leadsData)
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
          if (smsStatus === true) {
            sendSMS();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          // Reset loading state to allow further submissions if necessary
          setLoading(false);
        });
    }
  };
  const sendSMS = async () => {
    // Ensure that Selecteduser is not an empty array
    if (Selecteduser.length === 0) {
      console.error("No selected user to send SMS");
      return;
    }



    const url = `${API_URL}/send-msg`;
    try {
      // Iterate over each user in Selecteduser array
      const filteredCustomerData = customerData.filter(customer => customer.customer_id === leadsData.customer);

      const number = filteredCustomerData[0].customer_whatsapp_no;


      const data = new URLSearchParams();
      data.append("number", number);
      data.append('type', 2)
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        timeout: 100000,
      });

    } catch (e) {
      console.log(e);
    }
  };
  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const [tempCustomer, settempCustomer] = useState(0);







  const [isDescriptionListening, setIsDescriptionListening] = useState(false);


  const {
    transcript,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  // Mapping spoken numbers and symbols to digits and symbols
  const numberAndSymbolMapping = {
    zero: "0",
    one: "1",
    two: "2",
    three: "3",
    four: "4",
    five: "5",
    six: "6",
    seven: "7",
    eight: "8",
    nine: "9",
    dot: ".",
    comma: ",",
    dash: "-",
    plus: "+",
    at: "@",
    hashtag: "#",
    dollar: "$",
    percent: "%",
    ampersand: "&",
    asterisk: "*",
    exclamation: "!",
    question: "?",
    slash: "/",
    backslash: "\\",
  };

  // Convert spoken numbers and symbols to digits/symbols
  const convertSpokenNumbersToDigits = (text) => {
    return text
      .split(" ")
      .map((word) => numberAndSymbolMapping[word.toLowerCase()] || word)
      .join(" ");
  };

  // Update description field based on voice input
  useEffect(() => {
    if (isDescriptionListening && transcript) {
      const convertedTranscript = convertSpokenNumbersToDigits(transcript);
      setLeads((prevData) => ({
        ...prevData,
        description: prevData.description + " " + convertedTranscript, // Append voice input to description
      }));
      resetTranscript(); // Clear transcript after appending
    }
  }, [transcript, isDescriptionListening]);

  // Toggle microphone button
  useEffect(() => {
    if (isDescriptionListening) {
      SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
    } else {
      SpeechRecognition.stopListening();
    }
  }, [isDescriptionListening]);

  // Handle microphone button click
  const handleDescriptionMicClick = () => {
    setIsDescriptionListening(!isDescriptionListening); // Toggle mic on/off
  };

  if (!browserSupportsSpeechRecognition) {
    return <div>Your browser does not support speech recognition.</div>;
  }



  return (
    <div>
      <Modal
        className="modal-xl"
        id="fullscreeexampleModal"
        isOpen={modal}
        size={"xl"}
        toggle={toggle}
        centered
      >
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Create Leads
        </ModalHeader>
        <span className="tablelist-form">
          <ModalBody style={{ maxHeight: "80vh", overflowY: "auto" }}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                SubmitData();
                return false;
              }}
              action="#"
            >
              <Card className="border card-border-success p-3 shadow-lg">
                <div className="mb-3">
                  <Row>
                    {user.user.u_type === 1 || user.user.u_type === 3 ? (
                      <>
                        <Col lg={3}>
                          <Label
                            htmlFor="categoryname-field"
                            className="form-label fw-bold d-flex justify-content-between  "
                          >
                            <div>
                              Department<span className="text-danger"> *</span>
                            </div>
                            <div style={{ color: "red" }}>
                              {tempValid === 1 ? "Please select the assigned!" : ""}
                            </div>
                          </Label>
                          <Select
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused ? 'white' : 'white',
                              }),
                            }}
                            className={tempValid === 1 ? "border border-danger" : ""}
                            options={role.map((e) => ({
                              value: e.role_id,
                              label: e.role_name,
                            }))} // Map the role data to options
                            onChange={(e) => {
                              setLeads({
                                ...leadsData,
                                department: e.value,
                              });
                              setSelectedRoleIds(e.value); // Update the selected role ID(s)

                              setstatuscheckforAssignedBy(statuscheckforAssignedBy + 1)


                            }}
                          />





                        </Col>
                      </>
                    ) : (<></>)}
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  "
                      >
                        <div>
                          Team Member<span className="text-danger"> *</span>
                        </div>
                        <div style={{ color: "red" }}>
                          {tempValid === 1 ? "Please select the assigned!" : ""}
                        </div>
                      </Label>
                      {user.user.u_type === 1 || user.user.u_type === 3 || (user.user.u_type === 2 && user.user.user_behave == 1) ? (
                        <Select
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused ? 'white' : 'white',
                            }),
                          }}
                          className={tempValid === 1 ? "border border-danger" : ""}
                          options={[
                            {
                              label: 'Managers', // Group heading for Managers
                              options: assignedBy
                                .filter(e => e.user_behave === 1 && (selectedRoleUserIds.length === 0 || selectedRoleUserIds.includes(e.uid.toString())))
                                .map((e, i) => ({
                                  value: e.uid,
                                  label: e.name, // Show the name under Managers group
                                  index: i,
                                  behaveType: e.user_behave
                                }))
                            },
                            {
                              label: 'Employees', // Group heading for Employees
                              options: assignedBy
                                .filter(e => e.user_behave === 0 && (selectedRoleUserIds.length === 0 || selectedRoleUserIds.includes(e.uid.toString())))
                                .map((e, i) => ({
                                  value: e.uid,
                                  label: e.name, // Show the name under Employees group
                                  index: i,
                                  behaveType: e.user_behave
                                }))
                            }
                          ]}
                          isMulti
                          onChange={(e) => {
                            if (e) {
                              const selectedValues = e.map((option) => option.value);
                              setLeads({
                                ...leadsData,
                                assigned_by: `[${selectedValues.join(",")}]`,
                              });
                            }
                            settempValid(0);
                          }}
                        />
                      ) : (
                        <Input
                          value={user.user.name}
                          disabled
                          className="fw-bold"
                        />
                      )}



                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  "
                      >
                        <div>Status</div>
                        <button
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => {
                            setmanageLeadStatus(1);
                            setstatusModal(!statusModal);
                          }}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </button>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        options={statusData.map((e, i) => ({
                          value: e.lead_status_id,
                          label: e.lead_status_name,
                        }))}
                        className="fw-bold"
                        name="leads_status"
                        id="leads_status"
                        onChange={(e) => {
                          setLeads({
                            ...leadsData,
                            status: e.value,
                          });
                        }}
                        placeholder={
                          statusData.length != 0
                            ? manageLeadStatus == 0
                              ? statusData[0].lead_status_name
                              : statusData[statusData.length - 1]
                                .lead_status_name
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  "
                      >
                        <div>Priority</div>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        className="fw-bold"
                        name="priority"
                        id="priority"
                        options={priorityData.map((e, i) => ({
                          value: e.value,
                          label: e.label,
                        }))}
                        onChange={(e) => {
                          setLeads({
                            ...leadsData,
                            priority: e.value,
                          });
                        }}
                        placeholder={priorityData[0].label}
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Source</div>
                        <button
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => {
                            setmanageSource(1);
                            setsourceModal(!sourceModal);
                          }}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </button>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        placeholder={
                          sourceData.length !== 0
                            ? manageSource == 0
                              ? sourceData[0].source_name
                              : sourceData[sourceData.length - 1].source_name
                            : ""
                        }
                        options={sourceData.map((e, i) => ({
                          value: e.source_id,
                          label: e.source_name,
                        }))}
                        onChange={(e) => {
                          setLeads({
                            ...leadsData,
                            source: e.value,
                          });
                        }}
                        className="fw-bold"
                        name="source"
                        id="source"
                      />
                    </Col>

                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>
                          Customer<span className="text-danger"> *</span>
                        </div>
                        <div style={{ color: "red" }}>
                          {tempValid === 2 ? "Select the customer!" : ""}
                          <button
                            className="btn btn-success btn-sm px-1"
                            style={{ padding: "0px" }}
                            onClick={() => {
                              setcustomerModal(!customerModal);
                            }}
                          >
                            <i className="ri-add-line align-bottom"></i>
                          </button>
                        </div>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        className={
                          tempValid === 2
                            ? "fw-bold border border-danger"
                            : "fw-bold"
                        }
                        name="customer"
                        id="customer"
                        placeholder={"Customer "}
                        onChange={(e) => {
                          settempValid(0);
                          setmanageCustomer(1);
                          settempCustomer(e.index);
                          setLeads({
                            ...leadsData,
                            customer: e.value,
                          });
                        }}
                        options={customerData.map((e, i) => ({
                          value: e.customer_id,
                          label: e.customer_name,
                          index: i,
                        }))}
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Phone</div>
                      </Label>
                      <Input
                        readOnly
                        name="phone"
                        id="phone"
                        className="form-control fw-bold"
                        value={
                          manageCustomer !== 0
                            ? customerData[tempCustomer].customer_whatsapp_no
                            : ""
                        }
                        type="text"
                        disabled
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Email</div>
                      </Label>
                      <Input
                        readOnly
                        name="email"
                        id="email"
                        className="form-control fw-bold"
                        placeholder="Email"
                        type="text"
                        value={
                          manageCustomer !== 0
                            ? customerData[tempCustomer].customer_email
                            : ""
                        }
                        disabled
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>City</div>
                      </Label>
                      <Input
                        readOnly
                        name="city"
                        id="city"
                        className=" fw-bold "
                        placeholder="City"
                        type="text"
                        value={
                          manageCustomer !== 0
                            ? customerData[tempCustomer].city_name
                            : ""
                        }
                        disabled
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Refer By</div>
                        <button
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => {
                            setmanageReferBy(1);
                            setreferBy(!referBy);
                          }}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </button>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        className="fw-bold"
                        name="ref_by"
                        id="ref_by"
                        placeholder={
                          referByData.length != 0
                            ? manageReferBy == 0
                              ? referByData[0].reference_name
                              : referByData[referByData.length - 1]
                                .reference_name
                            : ""
                        }
                        onChange={(e) => {
                          setLeads({
                            ...leadsData,
                            ref_by: e.value,
                          });
                        }}
                        options={referByData.map((e, i) => ({
                          value: e.reference_id,
                          label: e.reference_name,
                        }))}
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Product</div>
                        <button
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => {
                            setmanageProduct(1);
                            setproductModal(!productModal);
                          }}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </button>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',

                          }),
                        }}
                        className="fw-bold"
                        name="product"
                        id="product"
                        placeholder={
                          productData != 0
                            ? manageProduct == 0
                              ? productData[0].product_name
                              : productData[productData.length - 1].product_name
                            : ""
                        }
                        onChange={(e) => {
                          setLeads({
                            ...leadsData,
                            product: e.value,
                          });
                        }}
                        options={productData.map((e, i) => ({
                          value: e.product_id,
                          label: e.product_name,
                        }))}
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Value</div>
                      </Label>
                      <Input
                        required
                        name="value"
                        id="value"
                        className="form-control fw-bold"
                        placeholder="0"
                        onChange={(e) => {
                          setLeads({
                            ...leadsData,
                            value: e.target.value,
                          });
                        }}
                        type="text"
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Tags</div>
                      </Label>
                      {tagData.length !== 0 ? (
                        <Select
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused ? 'white' : 'white',

                            }),
                          }}
                          options={tagData.map((e, i) => ({
                            label: e.tag_name,
                            value: e.tag_id,
                            index: i,
                          }))}
                          isMulti
                          // value={selectedOptions}
                          onChange={(e) => {
                            if (e) {
                              // Extract values from selected options and store in an array
                              const selectedValues = e.map(
                                (option) => option.value
                              );
                              setLeads({
                                ...leadsData,
                                tags: `[${selectedValues.join(",")}]`,
                              });
                            }
                          }}
                        />
                      ) : (
                        <span className="fw-bold text-danger">
                          No tags added
                        </span>
                      )}
                    </Col>
                    {/* <Col lg={2}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Repeat every</div>
                      </Label>
                      
                      {statusOption !== 0 ? (
                        <div className="d-flex   align-items-center">
                          <Col lg={3}>
                            <Input type="number"
                            placeholder="0"
                             onChange={(e)=>{
                              settempRe({
                                  ...tempRe,
                                  temp_num:e.target.value
                                })
                                setLeads({
                                  ...leadsData,
                                  repeat_every_day:e.target.value+" "+tempRe.temp_label
                                })
                            }}/>
                          </Col>
                          <Col lg={9}>
                            <Select
                            placeholder="Day(s)"
                              options={[
                                { value: "Day(s)", label: "Day(s)" },
                                { value: "Week(s)", label: "Week(s)" },
                                { value: "Month(s)", label: "Month(s)" },
                                { value: "Year(s)", label: "Year(s)" },
                              ]}
                              onChange={(e)=>{
                                settempRe({
                                  ...tempRe,
                                  temp_label:e.value
                                })
                                setLeads({
                                  ...leadsData,
                                  repeat_every_day:tempRe.temp_num+" "+e.value
                                })
                              }}
                            ></Select>
                          </Col>
                        </div>
                      ) : (
                        <Select
                          options={[
                            { value: "1 Day(s)", label: "1 Day(s)" },
                            { value: "1 Week(s)", label: "1 Week(s)" },
                            { value: "1 Month(s)", label: "1 Month(s)" },
                            { value: "1 Year(s)", label: "1 Year(s)" },
                            { value: "custom", label: "custom" },
                          ]}
                          onChange={(e) => {
                            if (e.value === "custom") {
                              setstatusOption(1);
                            }else{
                              setLeads({
                                ...leadsData,
                                repeat_every_day:e.value
                              })
                            }
                          }}
                        ></Select>
                      )}
                    </Col>
                    <Col lg={2}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Total Cycles</div>
                      </Label>

                      <div className="d-flex   align-items-center">
                        {" "}
                        <Col lg={2} className="text-center ">
                          <Input type="checkbox"
                          onClick={(e)=>{
                            if(e.target.checked){
                            settc(!tc);
                            setLeads({
                                ...leadsData,
                                total_cycles:"Infinity"
                              })
                            }else{
                              settc(!tc);
                            setLeads({
                                ...leadsData,
                                total_cycles:0
                              })
                            }
                          }}
                          ></Input>{" "}
                        </Col>
                        <Col lg={10}>
                          <Input
                          disabled={tc}
                            className="fw-bold"
                            placeholder="0"
                            value={leadsData.total_cycles}
                            onChange={(e)=>{
                              setLeads({
                                ...leadsData,
                                total_cycles:e.target.value
                              })
                            }}
                          />
                        </Col>
                      </div>
                    </Col> */}
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Start Date</div>
                      </Label>
                      <Flatpickr
                        className="form-control fw-bold"
                        options={{
                          dateFormat: "Y-m-d",
                          defaultDate: new Date(),
                        }}
                        onChange={(selectedDates) => {
                          const selectedDate = selectedDates[0];
                          const day = selectedDate
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const month = (selectedDate.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const year = selectedDate.getFullYear();
                          const formattedDate = `${year}-${month}-${day}`;
                          setLeads({
                            ...leadsData,
                            minimum_due_date: formattedDate,
                          });
                        }}
                        name="date"
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>End Date</div>
                      </Label>
                      <Flatpickr
                        className="form-control fw-bold"
                        options={{
                          dateFormat: "Y-m-d",
                          defaultDate: new Date(),
                        }}
                        onChange={(selectedDates) => {
                          const selectedDate = selectedDates[0];
                          const day = selectedDate
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const month = (selectedDate.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const year = selectedDate.getFullYear();
                          const formattedDate = `${year}-${month}-${day}`;
                          setLeads({
                            ...leadsData,
                            maximum_due_date: formattedDate,
                          });
                        }}
                        name="date"
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Next Follow Up Date</div>
                      </Label>
                      <Flatpickr
                        className="form-control fw-bold  shadow-sm "
                        options={{
                          dateFormat: "Y-m-d h:i K", // Use 'h:i K' for 12-hour time with AM/PM
                          enableTime: true,          // Enable time selection
                          time_12hr: true,           // Enable 12-hour time format with AM/PM
                          defaultDate: leadsData.next_follow_up,
                        }}
                        onChange={(selectedDates) => {
                          const selectedDate = selectedDates[0];

                          // Extract date components
                          const day = selectedDate.getDate().toString().padStart(2, "0");
                          const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
                          const year = selectedDate.getFullYear();

                          // Extract time components
                          const hours = selectedDate.getHours() % 12 || 12; // 12-hour format
                          const minutes = selectedDate.getMinutes().toString().padStart(2, "0");
                          const ampm = selectedDate.getHours() >= 12 ? "PM" : "AM"; // AM/PM indicator

                          // Format the date and time as "YYYY-MM-DD h:i AM/PM"
                          const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;

                          // Set the task data with both date and time
                          setLeads({
                            ...leadsData,
                            next_follow_up: formattedDateTime,
                          });
                        }}
                        name="date"
                      />
                    </Col>


                    <Col lg={6}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>
                          Description<span className="text-danger"> *</span>
                        </div>
                        <div className="text-danger">
                          {tempValid === 3
                            ? "Description cannot be empty!"
                            : ""}
                        </div>
                      </Label>
                      {/* Microphone Button */}
                      <Button
                        type="button"
                        color="none"
                        style={{
                          position: "absolute",
                          top: "70%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          width: "50px",
                          height: "50px",
                          padding: "0",
                          borderRadius: "50%",
                          zIndex: "10",
                          background: "none", // No background
                          border: "none", // No border
                          boxShadow: "none", // No shadow
                        }}
                        onClick={handleDescriptionMicClick} // Toggle microphone on click
                      >
                        <i
                          className={
                            isDescriptionListening
                              ? "ri-stop-circle-fill align-bottom" // Icon when listening
                              : "ri-mic-fill align-bottom" // Icon when not listening
                          }
                        ></i>
                      </Button>
                      <CKEditor
                        rows={1}
                        editor={ClassicEditor}
                        data={leadsData.description} 
                        onChange={(e, editor) => {
                          settempValid(0);
                          setLeads({
                            ...leadsData,
                            description: editor.getData(),
                          });
                        }}
                      />

                    </Col>
                    <Col lg={6}>
                      <div className="mt-5">
                        <h5 className="fs-15 mb-1">Upload Images</h5>
                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="avatar-lg d-inline-block align-top"> {/* Changed the parent div to inline-block */}
                              <div className="avatar-title bg-light rounded">
                                {leadsData.image.length > 0 ? (
                                  leadsData.image.map((image, index) => (
                                    <div key={index} className="position-relative">
                                      <button
                                        type="button"
                                        className="position-absolute top-0 end-0 btn-close"
                                        onClick={() => handleRemoveImage(index)}
                                      ></button>
                                      <img
                                        src={URL.createObjectURL(image)}
                                        alt={`product-img-${index}`}
                                        className="m-2"
                                        width={"150px"}
                                        height={"100px"}
                                      />

                                    </div>
                                  ))
                                ) : (
                                  <D_img />
                                )}
                              </div>
                            </div>
                            <div className="position-absolute top-0 end-0 translate-middle" style={{ marginRight: "-30px" }}> {/* Positioning the upload button */}
                              <label
                                htmlFor="taskImages"
                                className="mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title=""
                                data-bs-original-title="Select Images"
                              >
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i className="ri-image-fill"></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="taskImages"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                multiple // Allow multiple image selection
                                onChange={handleImageChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mt-5">
                        <h5 className="fs-15 mb-1">AudioRecorder</h5>
                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="avatar-lg d-inline-block align-top"> {/* Changed the parent div to inline-block */}
                              <AudioRecorder
                                onRecordingComplete={addAudioElement}
                                recorderControls={recorderControls}
                              />
                              {/* <button onClick={recorderControls.stopRecording}>Stop recording</button> */}
                              {audioBlob && ( // Render audio element if blob exists
                                <audio controls>
                                  <source src={URL.createObjectURL(audioBlob)} type="audio/wav" />
                                  Your browser does not support the audio element.
                                </audio>
                              )}
                            </div>

                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg={2}>
                      <div className="mt-5">
                        <h5 className="fs-15 mb-1">Video</h5>
                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute top-100 start-100 translate-middle">
                              <label
                                htmlFor="employeeVideo"
                                className="mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Select Video"
                              >
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i
                                      className="ri-video-fill"
                                      style={{
                                        color: "#009CA4",
                                        fontSize: "20px",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="employeeVideo"
                                type="file"
                                accept="video/mp4, video/avi, video/mkv"
                                onChange={(e) => {
                                  setLeads({
                                    ...leadsData,
                                    video: e.target.files[0],
                                  });
                                  SetEmployeeVideo(e.target.files[0]);
                                }}
                              />
                            </div>
                            <div className="avatar-lg">
                              {EmployeeVideo ? (
                                <div className="d-flex flex-column align-items-center">
                                  <i
                                    className="ri-video-fill"
                                    style={{
                                      fontSize: "50px",
                                      color: "#007bff",

                                    }}
                                  ></i>
                                  <p style={{ color: "#007bff" }}>
                                    {EmployeeVideo.name
                                      ? `${EmployeeVideo.name.substring(0, 5)}.${EmployeeVideo.name.split('.').pop()}`
                                      : ''}
                                  </p>

                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg={2}>
                      <div className="mt-5">
                        <h5 className="fs-15 mb-1">Document</h5>
                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute top-100 start-100 translate-middle">
                              <label
                                htmlFor="employeeDocument"
                                className="mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Select Document"
                              >
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i
                                      className="ri-file-upload-fill"
                                      style={{
                                        color: "#009CA4",
                                        fontSize: "20px",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="employeeDocument"
                                type="file"
                                accept=".pdf,.doc,.docx,.txt,.xlsx"
                                onChange={(e) => {
                                  setLeads({
                                    ...leadsData,
                                    document: e.target.files[0],
                                  });
                                  SetEmployeeDoc(e.target.files[0]);
                                }}
                              />
                            </div>
                            <div className="avatar-lg">
                              {EmployeeDoc ? (
                                EmployeeDoc.type.startsWith("image/") ? (
                                  <img
                                    src={URL.createObjectURL(EmployeeDoc)}
                                    alt="Selected file preview"
                                    height="50px"
                                    width="50px"
                                  />
                                ) : (
                                  <div className="d-flex flex-column align-items-center">
                                    <div>
                                      {EmployeeDoc.type.includes("pdf") && (
                                        <i
                                          className="ri-file-pdf-fill"
                                          style={{
                                            fontSize: "50px",
                                            color: "#FF0000",
                                          }}
                                        ></i>
                                      )}
                                      {EmployeeDoc.type.includes("word") && (
                                        <i
                                          className="ri-file-word-fill"
                                          style={{
                                            fontSize: "50px",
                                            color: "#1E90FF",
                                          }}
                                        ></i>
                                      )}
                                      {EmployeeDoc.type.includes("excel") && (
                                        <i
                                          className="ri-file-excel-fill"
                                          style={{
                                            fontSize: "50px",
                                            color: "#28A745",
                                          }}
                                        ></i>
                                      )}
                                      {!EmployeeDoc.type.includes("pdf") &&
                                        !EmployeeDoc.type.includes("word") &&
                                        !EmployeeDoc.type.includes(
                                          "excel"
                                        ) && (
                                          <i
                                            className="ri-file-fill"
                                            style={{
                                              fontSize: "50px",
                                              color: "#6c757d",
                                            }}
                                          ></i>
                                        )}
                                    </div>
                                    <p style={{ color: "#007bff" }}>
                                      {EmployeeDoc.name
                                        ? `${EmployeeDoc.name.substring(0, 5)}.${EmployeeDoc.name.split('.').pop()}`
                                        : ''}
                                    </p>
                                  </div>
                                )
                              ) : (
                                ""
                              )}
                            </div>

                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>



                    <div className="mt-3">
                      <Input
                        type="checkbox"
                        onClick={(e) => SetsmsStatus(true)}
                      ></Input>{" "}
                      Send Notification To Whatsapp
                    </div>
                  </Row>
                </div>
              </Card>
            </Form>
          </ModalBody>
          <div className="modal-footer">
            <button
              ref={submitButtonRef}
              name="close"
              id="close"
              type="submit"
              className="btn btn-primary"
              onClick={() => SubmitData()}
              disabled={loading} // Disable button if loading
            >
              <i className="ri-save-3-line align-bottom me-1"></i>
              {loading ? "Saving..." : "Save"}
            </button>
            <button
              name="close"
              id="close"
              type="button"
              className="btn btn-danger"
              onClick={() => Close()}
            >
              <i className="ri-close-line me-1 align-middle" />
              Close
            </button>
          </div>
        </span>
      </Modal>

      {statusModal === true ? (
        <LeadStatusAdd
          modalStates={statusModal}
          setModalStates={() => {
            setstatusModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {productModal === true ? (
        <ProductAdd
          modalStates={productModal}
          setModalStates={() => {
            setproductModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {referBy === true ? (
        <RefrencesAdd
          modalStates={referBy}
          setModalStates={() => {
            setreferBy(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {customerModal === true ? (
        <CustomerAdd
          modalStates={customerModal}
          setModalStates={() => {
            setcustomerModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {sourceModal === true ? (
        <SourceAdd
          modalStates={sourceModal}
          setModalStates={() => {
            setsourceModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default LeadsAdd;
