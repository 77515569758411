import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Button,
  Form,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
import CustomInput from "../Unit/Input";

const LeadStatusAdd = (props) => {
  const { https } = AuthUser();
  const [modal, setModal] = useState(false);
  const [Lead, SetLead] = useState("");
  const [statusColor, setStatusColor] = useState('info');

  const Close = () => {
    setModal(false);
    props.setModalStates();
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [checkNameStatus, setCheckStatus] = useState({});
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const SubmitData = () => {
    if (loading) return;
    setLoading(true);

    if (Lead.trim() === "") {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsg("Lead name can not be empty!");
    } else {
      https
        .post("/lead/store", {
          lead_status_name: Lead,
          lead_status_color: statusColor
        })
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          // Reset loading state to allow further submissions if necessary
          setLoading(false);
        });
      setModal(false);
    }
  };

  const handleLead = (e) => {
    setCheckStatus({});
    setMsg("");
    SetLead(e.target.value);
  };

  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        props.setModalStates(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    <div>
      <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Create Status
        </ModalHeader>
        <span className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              SubmitData();
              return false;
            }}
            action="#"
          >
            <ModalBody>
              <Card className="border card-border-success p-3 shadow-lg">
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Leadname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Status Name<span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "red" }}>{msg}</div>
                      </Label>
                      <CustomInput
                        checkNameStatus={checkNameStatus}
                        name="lead_status_name"
                        id="Lead-field"
                        className="form-control fw-bold"
                        placeholder="Status Name"
                        type="text"
                        value={Lead}
                        onChange={handleLead}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Leadname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Color <span className="text-danger"> *</span>
                        </div>
                      </Label>

                      <div className="d-flex justify-content-between mb-3">
                        <div className={statusColor == 'info' ? "btn btn-info" : "btn btn-outline-info"} onClick={() => {
                          setStatusColor("info");
                        }}> Info </div>
                        <div className={statusColor == 'primary' ? "btn btn-primary" : "btn btn-outline-primary"} onClick={() => {
                          setStatusColor("primary");
                        }}>Primary </div>
                        <div className={statusColor == 'warning' ? "btn btn-warning" : "btn btn-outline-warning"} onClick={() => {
                          setStatusColor("warning");
                        }}>Warning </div>
                        <div className={statusColor == 'success' ? "btn btn-success" : "btn btn-outline-success"} onClick={() => {
                          setStatusColor("success");
                        }}>Success </div>

                      </div>
                      <div className="d-flex justify-content-between mb-3 ">

                        <div className={statusColor == 'danger' ? "btn btn-danger" : "btn btn-outline-danger"}
                          onClick={() => {
                            setStatusColor("danger");
                          }}>Danger </div>
                        {/* 
                        <div
                          className={statusColor === 'orange' ? 'btn btn-orange' : 'btn btn-outline-orange'}
                          onClick={() => setStatusColor('orange')}
                        >Orange
                        </div>
                        <div
                          className={statusColor === 'yellow' ? 'btn btn-yellow' : 'btn btn-outline-yellow'}
                          onClick={() => setStatusColor('yellow')}
                        >Yellow
                        </div> */}


                        <div className={statusColor == 'dark' ? " btn btn-dark" : " btn btn-outline-dark"} onClick={() => {
                          setStatusColor("dark");
                        }}>Dark </div>


                        <div
                          className={statusColor === 'orange' ? 'btn btn-orange' : 'btn btn-outline-orange'}
                          onClick={() => setStatusColor('orange')}
                        >Orange
                        </div>

                        <div
                          className={statusColor === 'yellow' ? 'btn btn-yellow' : 'btn btn-outline-yellow'}
                          onClick={() => setStatusColor('yellow')}
                        >Yellow
                        </div> 
                      </div>


                    </div>
                  </Col>
                </Row>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <button
                ref={submitButtonRef}
                name="close"
                id="close"
                type="submit"
                className="btn btn-primary"
                // onClick={() => SubmitData()}
                disabled={loading} // Disable button if loading
              >
                <i className="ri-save-3-line align-bottom me-1"></i>
                {loading ? "Saving..." : "Save"}
              </button>
              <button
                name="close"
                id="close"
                type="button"
                className="btn btn-danger"
                onClick={() => Close()}
              >
                <i className="ri-close-line me-1 align-middle" />
                Close
              </button>

            </div>
          </Form>
        </span>
      </Modal>
    </div>
  );
};

export default LeadStatusAdd;
