import React, { useRef, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  Row,
  Button,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import AttendenceAdd from "./AttendenceAdd";
import AttendenceEdit from "./AttendenceEdit";
import AuthUser from "../../helpers/Authuser";
import { useEffect } from "react";
import { IMG_API_URL } from "../../helpers/url_helper";
import InfiniteScroll from "react-infinite-scroll-component";
import D_img from "../D_img";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import jsPDF from "jspdf";
import ImgCom from "../Fullscreenimg/ImgCom";
import 'jspdf';
import 'jspdf-autotable';

const AttendenceAllLists = () => {

  const { http, https, user } = AuthUser();
  const { type } = useParams();
  const [filterData, setfilterData] = useState({
    start_date: new Date().toISOString().split("T")[0] + " 00:00:00",
    end_date: new Date().toISOString().split("T")[0] + " 23:59:59",
    remark: 0,
    attendance_id: type,
    user_id: 0,
  });
  // console.log(filterData);
  const [modalStates, setModalStates] = useState(false);
  const [UpdatemodalStates, setUpdateModalStates] = useState(false);

  const [counts, Setcounts] = useState(1);
  const [Attendance, SetAttendance] = useState([]);
  console.log(Attendance);
  // infinity
  const [Pages, SetPages] = useState(1);
  const [NoMore, SetNoMore] = useState(true);


  const [assignedByData, setassignedByData] = useState([]);
  const getassignedByData = async () => {
    http
      .get("/franchisebusiness/list")
      .then((response) => {
        if (response.data.length > 0) {
          const filteredemployee = response.data.filter((e) => e.u_type !== 3 && e.u_type !== 5);
          setassignedByData(filteredemployee);
          // setassignedByData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // console.log(Attendance);
  const handleFilter = async () => {
    http
      .post(`/attendence/filter`, filterData)
      .then(function (response) {
        if (response.data.length > 0) {


          // const filteredData = response.data.filter(
          //   (attendence) => parseInt(attendence.user_user_id) === user.user.uid
          // );


          // if (user && user.user.u_type === 1 || user && user.user.u_type === 4 || user && user.user.u_type === 2) {
          SetAttendance(response.data);
          // } else {
          //   SetAttendance(filteredData);
          // }
          // SetAttendance(response.data);
        } else {
          SetAttendance([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    handleFilter();
  }, [filterData])
  useEffect(() => {
    getassignedByData();
    document.title = "Attendence | CRM";
    handleFilter();
  }, [counts]);

  const fetchData = () => {
    Setcounts(counts + 1);
  };




  // shortcuts for opening add form
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.altKey && event.key === "a") ||
        (event.altKey && event.key === "A")
      ) {
        event.preventDefault();
        setModalStates(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  //   end Alert
  const handleCallback = (data, status) => {
    SetAttendance([]);
    SetPages(1);
    if (status == 1) {
      setModalStates(false);
      setUpdateModalStates(false);
      toast.success(data);
      Setcounts(counts + 1);
    } else {
      toast.warn(data);
      Setcounts(counts + 1);
    }
  };
  // Edit Data
  const [FindData, SetFind] = useState([]);
  const EditUpdate = (index) => {
    let FindArray = Attendance.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setUpdateModalStates(!UpdatemodalStates);
  };






  const AttenStatus =
    [
      { value: '0', label: 'All' },
      { value: '1', label: 'Present' },
      { value: '2', label: 'Absent' }
    ]

  const calculateDuration = (inTime, outTime) => {
    const inTimeDate = new Date(`2024-01-03 ${inTime}`);
    const outTimeDate = new Date(`2024-01-03 ${outTime}`);
    const timeDiffMilliseconds = outTimeDate - inTimeDate;
    const timeDiffHours = timeDiffMilliseconds / (1000 * 60 * 60);
    return timeDiffHours.toFixed(2); // Display hours with two decimal places
  };
  // Function to format time to 12-hour format
  const formatTime = (timeString) => {
    if (!timeString) return '';

    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    const [hours, minutes] = timeString.split(':');
    const date = new Date();
    date.setHours(parseInt(hours, 10), parseInt(minutes, 10));

    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  const generatePDF = () => {
    const doc = new jsPDF('landscape'); // Set to landscape orientation

    // Set table headers
    const headers = [
      '#',
      'Employee Name',
      'Attendance Date',
      'In Time',
      'In location',
      'In Photo',
      'Out Time',
      'Out Photo',
      'Out Location',
      'Remark',
    ];

    // Set table rows
    const data = Attendance.map((item, index) => [
      index + 1,
      item.name,
      item.attendance_date,
      item.in_time,
      item.in_location,
      item.in_photo,
      item.out_time,
      item.out_photo,
      item.out_location,
      item.remark,
    ]);

    // Set table style
    doc.setFontSize(12);
    doc.text('Attendance List', 14, 15);
    doc.autoTable({
      head: [headers],
      body: data,
      startY: 20,
    });

    // Save the PDF
    doc.save('AttendanceList.pdf');

    toast.success('PDF generated successfully!');
  };

  const convertToCSVexcel = () => {
    // CSV header
    let csvContent = '#,Employee Name,Attendance Date,In Time,In Location,In Photo,Out Time,Out Photo,Out Location,Remark\r\n';

    // CSV rows
    Attendance.forEach((item, index) => {
      csvContent += `${index + 1},"${item.name}","${item.attendance_date}","${item.in_time}","${item.in_location}","${item.in_photo}","${item.out_time}","${item.out_photo}","${item.out_location}","${item.remark}"\r\n`;
    });

    // Create a blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a link element
    const link = document.createElement('a');
    if (link.download !== undefined) { // Feature detection
      // Create a URL for the blob
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'AttendanceList.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };


  return (
    <div className="page-content">

      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="text-center pt-2 pb-4">
                    <h2 className="fw-bold mb-0">
                      {filterData && filterData.user_id > 0
                        ? (assignedByData && assignedByData.length > 0 && assignedByData.filter((data) => data.uid === filterData.user_id).length > 0
                          ? `${assignedByData.filter((data) => data.uid === filterData.user_id)[0].name} `
                          : "User not found")
                        : "All "}
                      Attendance
                    </h2>



                  </div>
                </Row>
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    {/* <h5 className="card-title mb-0">Attendence All List</h5> */}
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      {user.user.u_type === 1 || user.user.u_type === 3 ?
                        <div>
                          <div className="fw-bold">By employee</div>
                          <Select
                            className="fw-bold"
                            placeholder="Select Employee"
                            options={[
                              { value: 0, label: 'All' },
                              ...assignedByData.map((e) => ({
                                value: e.uid,
                                label: e.name,
                              })),
                            ]}
                            onChange={(e) => {
                              setfilterData({
                                ...filterData,
                                user_id: e.value,
                              });
                            }}
                          ></Select>

                        </div> : ""}
                      <div>
                        <div className="fw-bold">Attendence Status</div>
                        <Select
                          className="fw-bold"
                          placeholder="Select Status"
                          options={AttenStatus.map((e) => ({
                            value: e.value,
                            label: e.label,
                          }))}
                          onChange={(e) => {
                            setfilterData({
                              ...filterData,
                              remark: e.value,
                            });
                          }}
                        >

                        </Select>
                      </div>
                      <div>
                        <div className="fw-bold">Start Date</div>
                        <Flatpickr
                          className="form-control fw-bold"
                          options={{
                            dateFormat: "d/m/Y",
                            defaultDate: "today",
                          }}
                          name="payment_date"
                          placeholder="Enter start date"
                          onChange={(selectedDates) => {
                            const selectedDate = selectedDates[0];
                            // Convert to YYYY-MM-DD format
                            const year = selectedDate.getFullYear();
                            const month = (selectedDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0");
                            const day = selectedDate
                              .getDate()
                              .toString()
                              .padStart(2, "0");
                            const formattedDate = `${year}-${month}-${day} 00:00:00`;

                            setfilterData({
                              ...filterData,
                              start_date: formattedDate,
                            });
                          }}
                        />
                      </div>

                      <div>
                        <div className="fw-bold">End Date</div>
                        <Flatpickr
                          className="form-control fw-bold"
                          options={{
                            dateFormat: "d/m/Y",
                            defaultDate: "today",
                          }}
                          name="payment_date"
                          placeholder="Enter start date"
                          onChange={(selectedDates) => {
                            const selectedDate = selectedDates[0];
                            // Convert to YYYY-MM-DD format
                            const year = selectedDate.getFullYear();
                            const month = (selectedDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0");
                            const day = selectedDate
                              .getDate()
                              .toString()
                              .padStart(2, "0");
                            const formattedDate = `${year}-${month}-${day} 23:59:59`;

                            setfilterData({
                              ...filterData,
                              end_date: formattedDate,
                            });
                          }}
                        />
                      </div>
                      <div>
                        <button
                          className="btn mt-3 btn-success "
                          onClick={(e) => {
                            handleFilter();
                          }}
                        >
                          Search
                        </button>
                      </div>
                      {user && user.user.u_type === 2 && user.user.user_behave === 1 && (
                        <div>
                          <Link
                            type="button"
                            className="btn mt-3 btn-info"
                            to={'/attendence'}
                          >
                            <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                            My Attendance
                          </Link>
                        </div>
                      )}
                      {user && user.user.u_type === 4 && user.user.user_behave === 1 && (
                        <div>
                          <Link
                            type="button"
                            className="btn mt-3 btn-info"
                            to={'/attendence'}
                          >
                            <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                            My Attendance
                          </Link>
                        </div>
                      )}
                      <div>
                        <button
                          type="button"
                          className="btn mt-3 btn-info"
                          onClick={convertToCSVexcel}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          Export
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn mt-3 btn-danger"
                          onClick={generatePDF}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          PDF
                        </button>
                      </div>
                      <div>

                      </div>
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  ></Nav>
                  <InfiniteScroll
                    dataLength={0}

                  >
                    <table
                      role="table"
                      id="tableToPrint"
                      // ref={tableRef}
                      className="align-middle table-nowrap table table-hover"
                    >
                      <thead className="table-light text-muted text-uppercase">
                        <tr>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >#</th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            Employee Name
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            Date
                          </th>

                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            IN TIME
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            IN LOCATION
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            IN PHOTO
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            OUT TIME
                          </th>
                          <th>OUT LOCATION</th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            OUT PHOTO
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            WORKING TIME
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            STATUS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Attendance.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <a
                                className="fw-medium link-primary"
                                href="/apps-ecommerce-order-details"
                              >
                                {index + 1}
                              </a>
                            </td>
                            <td>{item.name}</td>
                            <td>
                              {new Date(item.attendance_date).toLocaleDateString("en-GB")}
                            </td>
                            <td>{formatTime(item.in_time)}</td>
                            <td style={{ "maxWidth": "150px", "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap" }}>{item.in_location}</td>
                            <td>
                              {item.in_photo != "/attendance/null" ? (
                                <ImgCom
                                  imageUrl={IMG_API_URL + item.in_photo}
                                  alt={item.in_photo}
                                />
                              ) : (
                                <D_img />
                              )}


                            </td>
                            <td>{formatTime(item.out_time)}</td>
                            <td style={{ "maxWidth": "150px", "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap" }}>{item.out_location}</td>
                            <td>
                              {item.out_photo != "/attendance/null" ? (

                                <ImgCom

                                  imageUrl={IMG_API_URL + item.out_photo}
                                  alt={item.out_photo}
                                  width={"100px"}
                                />
                              ) : (
                                <D_img />
                              )}

                            </td>

                            <td>

                              {calculateDuration(item.in_time, item.out_time)} -Hours
                            </td>
                            <td>

                              {item.remark == 1 ? (<span className="badge bg-success-subtle text-success text-uppercase">
                                <i className="ri--line align-bottom me-1"></i>Present
                              </span>) : (<span className="badge bg-danger-subtle text-danger text-uppercase">
                                <i className="ri--line align-bottom me-1"></i>Absent
                              </span>)}


                            </td>


                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </InfiniteScroll>
                </div>


                {UpdatemodalStates === true ? (
                  <AttendenceEdit
                    modalStates={UpdatemodalStates}
                    setModalStates={() => {
                      setUpdateModalStates(false);
                    }}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                <ToastContainer closeButton={false} limit={1} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AttendenceAllLists;
