import React from "react";
import CountUp from "react-countup";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row, CardHeader } from "reactstrap";
import { crmWidgets } from "../../common/data";
import FeatherIcon from 'feather-icons-react';
// import DashboardCharts from "./DashboardCharts";
const Widgets = ({ Comp, emp, todayleave }) => {
  const navigate = useNavigate();
  const ecomWidgets = [
    {
      id: 1,
      cardColor: "primary",
      label: "Task",
      counter: Comp.taskCount ? Comp.taskCount : 0,
      link: "View All Task",
      a: "/task-list",
      bgcolor: "success",
      icon: "bx bx-task",
    },
    {
      id: 3,
      cardColor: "success",
      label: "Leads",
      counter: Comp.leadCount,
      link: "View All Leads",
      a: "/leads-list",
      bgcolor: "warning",
      icon: "bx bx-user-circle",
    },
    {
      id: 4,
      cardColor: "info",
      label: "Total Customer",
      counter: Comp.customerCount,
      link: "View All Customer",
      a: "/customer-list",
      bgcolor: "danger",
      icon: "bx bx-user",
    },
    {
      id: 11,
      cardColor: "info",
      label: "Attendence",
      counter: Comp.CUSTOMER_creadit ? Comp.CUSTOMER_creadit : 0,
      link: "View All Attendence",
      a: "/attendence",
      bgcolor: "danger",
      icon: "bx bx-user",
    },
  ];
  return (
    <React.Fragment>


      <Col xl={12}>
        <Card style={{ height: '430px' }}>
          <CardHeader>
            <h4 className="card-title mb-0">  <FeatherIcon icon="users" className="ml-2" />  &nbsp;&nbsp;&nbsp;   Team Member Overview</h4>
          </CardHeader>
          <CardBody>
            <Row>
              <Col xl={6}>
                <Card>
                  <CardHeader style={{ height: '45px' }}>
                    <h4 className="mb-0 text-center fw-bold">{emp.length}</h4>
                  </CardHeader>
                  <Link to="/users-list">
                    <CardBody>
                      <h4 className="card-title mb-0 text-center">Team Members</h4>
                    </CardBody>
                  </Link>
                </Card></Col>
              <Col xl={6}>
                <Card>
                  <CardHeader style={{ height: '45px' }}>
                    <h4 className="mb-0 text-center fw-bold" style={{ color: '#FFD700' }}>{todayleave}</h4>

                  </CardHeader>
                  <Link to="/leave">
                    <CardBody>
                      <h4 className="card-title mb-0 text-center">On Leave Today</h4>
                    </CardBody>
                  </Link>
                </Card></Col>
              <Col xl={6}>
                <Card>
                  <CardHeader style={{ height: '55px' }}>
                    <h4 className=" mb-0 text-center fw-bold text-info" >{Comp.attendanceCount}</h4>
                    <div className="progress">
                      <div
                        className="progress-bar bg-info"
                        role="progressbar"
                        style={{
                          width: `${(Comp.attendanceCount / emp.length) * 100}%`, // Adjust width dynamically
                        }}
                        aria-valuenow={Comp.attendanceCount}
                        aria-valuemin="0"
                        aria-valuemax={emp.length}
                      ></div>
                    </div>
                  </CardHeader>
                  <Link to="/attendence-lists">
                    <CardBody>
                      <h4 className="card-title mb-0 text-center">Members Checked In</h4>
                    </CardBody>
                  </Link>
                </Card></Col>
              <Col xl={6}>
                <Card>
                  <CardHeader style={{ height: '55px' }}>
                    <h4 className=" mb-0 text-center fw-bold text-danger">{Comp.attendanceCountout}</h4>
                    <div className="progress">
                      <div
                        className="progress-bar bg-danger"
                        role="progressbar"
                        style={{
                          width: `${(Comp.attendanceCountout / emp.length) * 100}%`, // Adjust width dynamically
                        }}
                        aria-valuenow={Comp.attendanceCountout}
                        aria-valuemin="0"
                        aria-valuemax={emp.length}
                      ></div>
                    </div>
                  </CardHeader>
                  <Link to="/attendence-lists">
                    <CardBody>
                      <h5 className="card-title mb-0 text-center">Members Checked Out</h5>
                    </CardBody>
                  </Link>
                </Card></Col>
            </Row>
          </CardBody>
        </Card>
      </Col>



      {/* {ecomWidgets.map((item, key) => (
        <Col xl={3} md={6} key={key}>
          <Card className="card-animate">
            <CardBody style={{
              cursor: "pointer"
            }} onClick={() => navigate(item.a)}>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    {item.label}
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <h5 className={"fs-14 mb-0 text-" + item.badgeClass}>
                    {item.badge ? (
                      <i className={"fs-13 align-middle " + item.badge}></i>
                    ) : null}{" "}
                    {item.percentage} %
                  </h5>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target="559.25">
                      <CountUp
                        start={0}
                        prefix={item.prefix}
                        suffix={item.suffix}
                        separator={item.separator}
                        end={item.counter}
                        decimals={item.decimals}
                        duration={4}
                      />
                    </span>
                  </h4>
                  <Link to={item.a} className="text-decoration-underline">
                    {item.link}
                  </Link>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span
                    className={"avatar-title rounded fs-3 bg-" + item.bgcolor}
                  >
                    <i className={`${item.icon}`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))} */}
    </React.Fragment>
  );
};

export default Widgets;
