import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Form,
  Button,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import CustomInput from "../Unit/Input";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Select from "react-select";
import { IMG_API_URL } from "../../helpers/url_helper";
import ImgCom from "../Fullscreenimg/ImgCom";
import D_img from "../D_img";


const LeaveStatus = (props) => {
  // console.log(props.edit_data);
  const [modal, setModal] = useState(false);

  const [BrandID] = useState(props.edit_data.leave_id);

  const { https, http, user } = AuthUser();
  const [checkNameStatus, setCheckStatus] = useState({});
  const [msg, setMsg] = useState("");
  const [Data, setData] = useState([]);

  const [Leave, SetLeave] = useState({
    leave_id: props.edit_data.leave_id,
    leave_approved_by: user.user.uid,
    leave_status: props.edit_data.leave_status,
    leave_reject_reason: ''
  });
  // console.log(Data);
  const Close = () => {
    setModal(false);
    props.setModalStates();
  };

  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalState]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const SubmitData = () => {
    // console.log(Leave);
    if (Leave.leave_reject_reason === '') {
      // setCheckStatus({
      //   borderColor: "red",
      //   borderStyle: "groove",
      // });
      setMsg("Reason cannot be empty!");
    } else {
      // alert("hello");
      // console.log(Leave);
      https
        .put("/leave/update", Leave)
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

  };
  const handleLeave = (e) => {
    setCheckStatus({});
    setMsg("");
    SetLeave({ ...Leave, leave_status: e.value });
  };

  const options =
    props.edit_data.leave_status === '1'
      ? [
        { value: '2', label: 'Approved' },
        { value: '3', label: 'Rejected' }
      ]
      : props.edit_data.leave_status === '2'
        ? [{ value: '3', label: 'Rejected' }]
        : [];

  //       //   
  //       // Add other options as needed

  useEffect(() => {
    http
      .get(`/leave/show/${props.edit_data.leave_id}`)
      .then(function (response) {
        // console.log(response.data);
        setData(response.data)
        // props.checkchang(response.data.message, response.data.status);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])
  return (
    <div>
      <Modal id="showModal" isOpen={modal} toggle={toggle} size="lg" centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Leave Edit
        </ModalHeader>
        <span className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              SubmitData();
              return false;
            }}
            action="#"
          >
            <ModalBody>
              <Card className="border card-border-success p-3 shadow-lg">
                <Row>

                  <Col lg={3}>
                    <div className="mb-3 text-center">

                      {Data.profile_photo !== null ? (

                        <ImgCom
                          imageUrl={`${IMG_API_URL}/user/${Data.profile_photo}`}
                          style={{ height: "100px", width: "100px" }}
                        />
                      ) : (
                        <D_img />
                      )}


                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-3">
                      <Label
                        htmlFor="username-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >Name
                      </Label>
                      <Input type="text" value={Data.name} readOnly />
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-3">
                      <Label
                        htmlFor="email-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        Email:
                      </Label>
                      <Input type="text" value={Data.email} readOnly />

                    </div>
                  </Col>

                  <Col lg={3}>
                    <div className="mb-3">
                      <Label
                        htmlFor="email-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        Address :
                      </Label>
                      <Input type="text" value={Data.address} readOnly />

                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-3">
                      <Label
                        htmlFor="email-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        Mobile No
                      </Label>
                      <Input type="text" value={Data.mobile_no} readOnly />
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-3">
                      <Label
                        htmlFor="email-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        Application Date
                      </Label>
                      <Input type="text" value={new Date(Data.leavesdate).toLocaleDateString("en-GB")}
                        readOnly />
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-3">
                      <Label
                        htmlFor="email-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        From Date
                      </Label>
                      <Input type="text" value={new Date(Data.from_date).toLocaleDateString("en-GB")} readOnly />
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-3">
                      <Label
                        htmlFor="email-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        To Date
                      </Label>
                      <Input type="text" value={new Date(Data.to_date).toLocaleDateString("en-GB")} readOnly />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="email-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        Leave Reason
                      </Label>
                      <Input
                        type="text"
                        value={(Data.leave_reason || '').replace(/<\/?[^>]+(>|$)|&nbsp;/g, '')}
                        readOnly
                      />


                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Leaveyname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Leave Status<span style={{ color: "red" }}> *</span>
                        </div>
                        {/* <div style={{ color: "red" }}>{msg}</div> */}
                      </Label>
                      <Select
                        placeholder={`${props.edit_data.leave_status === '1' ? ('Pending') : props.edit_data.leave_status === '2' ? ('Approved') : props.edit_data.leave_status === '3' ? ('Rejected') : ('')}`}

                        onChange={handleLeave}
                        options={options}
                        name="leave_status"
                        id="leave_status"
                        className="fw-bold"
                      />
                    </div>
                  </Col>
                  {/* {Leave.leave_status === '3' ? ( */}
                  <Col lg={8}>
                    <div className="mb-3">
                      <Label
                        htmlFor="cityname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Leave Application Reason<span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "red" }}>{msg}</div>
                      </Label>
                      <CKEditor
                        // checkNameStatus={checkNameStatus}
                        rows={1}
                        editor={ClassicEditor}
                        onChange={(e, editor) => {
                          // settempValid(0);
                          SetLeave({
                            ...Leave,
                            leave_reject_reason: editor.getData(),
                          });
                        }}
                      />

                    </div>
                  </Col>
                  {/* ) : ('')

                  } */}
                </Row>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <Button
                name="close"
                id="close"
                type="submit"
                className="btn btn-primary"
              // onClick={() => SubmitData()}
              >
                <i className="ri-save-3-line align-bottom me-1"></i>
                Save
              </Button>
              <button
                name="close"
                id="close"
                type="button"
                className="btn btn-danger"
                onClick={() => Close()}
              >
                <i className="ri-close-line me-1 align-middle" />
                Close
              </button>

            </div>
          </Form>
        </span>
      </Modal>
    </div>
  );
};

export default LeaveStatus;
