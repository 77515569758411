// TodoForm.js
import React, { useCallback, useEffect, useRef, useState } from 'react';
import AuthUser from '../../helpers/Authuser';
import { Button, Card, Col, Form, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import CustomInput from '../Unit/Input';

const TodoForm = (props) => {
    const { https, user } = AuthUser();
    const [modal, setModal] = useState(false);
    const [Todo, SetTodo] = useState({
        todo_user_id: user.user.uid,
        todo_name: ''
    });

    // console.log(Todo);
    const Close = () => {
        setModal(false);
        props.setModalStates();
    };

    useEffect(() => {
        setModal(false);
        toggle();
    }, [props.modalStates]);

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            props.setModalStates();
        } else {
            setModal(true);
        }
    }, [modal]);

    const [checkNameStatus, setCheckStatus] = useState({});
    const [msg, setMsg] = useState("");
    const SubmitData = () => {
        if (Todo == "") {
            setCheckStatus({
                borderColor: "red",
                borderStyle: "groove",
            });
            setMsg("Todo connot be empty!");
        } else {
            https
                .post("/todo/store", Todo)
                .then(function (response) {
                    props.checkchang(response.data.message, response.data.status);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    };
    const handleTodo = (e) => {
        setCheckStatus({});
        setMsg("");
        SetTodo({ ...Todo, ['todo_name']: e.target.value });
    };

    // shortcuts for save and close
    const submitButtonRef = useRef();
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.altKey && event.key === "Escape") {
                event.preventDefault();
                props.setModalStates(false);
            }
            if (
                (event.altKey && event.key === "s") ||
                (event.altKey && event.key === "S")
            ) {
                event.preventDefault();
                submitButtonRef.current.click();
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);


    return (
        <div>
            <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                <ModalHeader className="bg-light p-3" toggle={toggle}>
                    Create Todos
                </ModalHeader>
                <span className="tablelist-form">
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            SubmitData();
                            return false;
                        }}
                        action="#"
                    >
                        <ModalBody>
                            <Card className="border card-border-success p-3 shadow-lg">
                                <Row>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label
                                                htmlFor="cityname-field"
                                                className="form-label fw-bold d-flex justify-content-between"
                                            >
                                                <div>
                                                    Todo<span style={{ color: "red" }}> *</span>
                                                </div>
                                                <div style={{ color: "red" }}>{msg}</div>
                                            </Label>
                                            <CustomInput
                                                checkNameStatus={checkNameStatus}
                                                name="todo_name"
                                                id="todo-field"
                                                className="form-control fw-bold"
                                                placeholder="Todo  Name"
                                                type="text"
                                                value={Todo.todo_name}
                                                onChange={handleTodo}
                                            />
                                        </div>
                                    </Col>

                                </Row>
                            </Card>
                        </ModalBody>
                        <div className="modal-footer">
                            <button
                                name="close"
                                id="close"
                                type="button"
                                className="btn btn-danger"
                                onClick={() => Close()}
                            >
                                <i className="ri-close-line me-1 align-middle" />
                                Close
                            </button>
                            <Button
                                ref={submitButtonRef}
                                name="close"
                                id="close"
                                type="submit"
                                className="btn btn-primary"
                            // onClick={() => SubmitData()}
                            >
                                <i className="ri-save-3-line align-bottom me-1"></i>
                                Save
                            </Button>
                        </div>
                    </Form>
                </span>
            </Modal>
        </div>
    );
};

export default TodoForm;