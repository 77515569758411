import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Button,
  Form,
  CardBody,
  Container,
  Nav,
  Badge,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import CustomInput from "../../Unit/Input";
import AuthUser from "../../../helpers/Authuser";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import { ToastContainer } from "react-toastify";

const ShiftLeadList = (props) => {
  const { http, user } = AuthUser();
  const [modal, setModal] = useState(false);

  function tog_fullscreen1() {
    setModal(!modal);
  }

  const [assignedByData, setassignedByData] = useState([]);
  const getassignedByData = async () => {
    http
      .get("/franchisebusiness/list")
      .then((response) => {
        if (response.data.length > 0) {
          setassignedByData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Close = () => {
    setModal(false);
    props.setModalStates();
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [ShifLeadData, setShifLeadData] = useState([]);
  const getShifLeadData = async () => {
    http
      .post("/slt/history", { leadOrTask: props.status, slt_send_by: user.user.uid })
      .then(function (response) {
        console.log("response data: ", response.data);

        if (response.data.length > 0) {
          setShifLeadData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getShifLeadData();
    getassignedByData();
  }, []);

  return (
    <div>
      <Modal
        size="xl"
        isOpen={modal}
        toggle={() => {
          tog_fullscreen1();
        }}
        className="modal-fullscreen"
        id="fullscreeexampleModal"
      >
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Shift {props.status === 0 ? "Leads" : "Task"} List
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className="pt-0">
                  <div>
                    <Nav
                      className="nav-tabs nav-tabs-custom nav-success"
                      role="tablist"
                    ></Nav>
                    <div className="table-responsive">
                      <table
                        role="table"
                        className="align-middle table-nowrap text-center table table-hover"
                      >
                        <thead className="table-light text-muted text-uppercase">
                          <tr>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              #
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                            >
                              Start Date
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                            >
                              End Date
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                            >
                              Next FollowUp
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                            >
                            Task Title
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                            >
                            Shifted By
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                            >
                            Current Employee
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                            >
                            Reason
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                            >
                            Status
                            </th>
                            {/* <th>Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {ShifLeadData.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {/* {item.today_date.split("T")[0]} */}
                                {new Date(item.minimum_due_date).toLocaleDateString("en-GB")}
                                <br />

                                {new Date(item.minimum_due_date).toLocaleTimeString("en-US", {
                                  hour12: true,
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })
                                }
                              </td>
                              <td
                                title="Start Date"
                              >
                                {new Date(item.maximum_due_date).toLocaleDateString(
                                  "en-GB"
                                )}
                                <br />
                                {new Date(item.maximum_due_date).toLocaleTimeString("en-US", {
                                  hour12: true,
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })
                                }
                              </td>
                              <td
                                title="Start Date"
                              >
                                {new Date(item.next_follow_up).toLocaleDateString(
                                  "en-GB"
                                )}
                                <br />
                                {new Date(item.next_follow_up).toLocaleTimeString("en-US", {
                                  hour12: true,
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })
                                }
                              </td>
                              <td
                                title="Task Title"
                              >
                                {item.task_title}
                              </td>
                              <td>
                                <Badge className="badge-gradient-primary shadow">
                                  <div className="btn btn-sm text-white ">
                                    {item.name}
                                  </div>
                                </Badge>

                              </td>
                              <td>
                                {assignedByData != null && item.slt_send_to
                                  ? assignedByData.map((e) =>
                                    item.slt_send_to == e.uid ? (
                                      <div key={e.uid} className="p-1">
                                        <Badge className="badge-gradient-dark shadow">
                                          <div className="btn btn-sm text-white ">
                                            {e.name}
                                          </div>
                                        </Badge>
                                      </div>
                                    ) : null
                                  )
                                  : "Loading..."}
                              </td>
                              <td>{item.slt_reason.replace(/<[^>]*>/g, "")}</td>
                              <td>
                                <Badge className={`badge-gradient-${item.lead_status_color} p-2`}>
                                  {item.lead_status_name}
                                </Badge>
                              </td>
                              {/* <td>
                                <ul className="list-inline hstack gap-2 d-flex justify-content-center mb-0">
                                  <li className="list-inline-item edit">
                                    <button
                                      className="text-primary   border-0 bg-transparent"
                                      // onClick={() => EditUpdate(index)}
                                    >
                                      <i className="ri-eye-fill fs-16" />
                                    </button>
                                  </li>
                                </ul>
                              </td> */}
                            </tr>
                          ))}
                          {ShifLeadData.length === 0 ? (
                            <tr>
                              <td colSpan={9} align="center">
                                <div className="fw-bold text-danger">
                                  No Shifted leads added.
                                </div>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ShiftLeadList;
