import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CustomInput from "../../Unit/Input";
import { useRef } from "react";
import Select from "react-select";
import AuthUser from "../../../helpers/Authuser";
import ShiftAdd from "../../Shift Slots/ShiftAdd";
import CityAdd from "../../City/CityAdd";
import RoleCreateModal from "../Roles/RoleCreateModal";
import { IMG_API_URL } from "../../../helpers/url_helper";
import Flatpickr from "react-flatpickr";

const UserCreateModal = (props) => {
  console.log("props.edit_data",props.edit_data);
  
  const [modal, setModal] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const { https, http, user } = AuthUser();
  const [modalStatess1, setModalStatess1] = useState(false);
  const [modalStatess, setModalStatess] = useState(false);
  const [employeeData1, setemployeeData1] = useState({
    temp_role_id: ""
  })
  const [counts, Setcounts] = useState(1);
  const [ActiveShift, setActiveShift] = useState(false);
  const [activeRole, setActiveRole] = useState(false);
  const [EmployeeShift, setEmployeeShift] = useState([]);
  const [EmployeeRole, setEmployeeRole] = useState([]);
  const [ManageShift, setManageShift] = useState(0);
  const [ManageRole, setManageRole] = useState(0);

  const handleCallback = (data) => {
    Setcounts(counts);
    setManageRole(1);
    setManageShift(1);
    toast.success(data);
    setModalStatess(false);
    setModalStatess1(false);
  };
  const [EmployeeAdhar, SetEmployeeAdhar] = useState("");
  const [EmployeeProfile, SetEmployeeProfile] = useState("");
  const [EmployeePan, SetEmployeePan] = useState("");
  const [EmployeeQrCode, SetEmployeeQrCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [employeeData, setEmployeeData] = useState(props.edit_data || {});
  console.log("emp Data", employeeData);

  const [EmployeeDoc, SetEmployeeDoc] = useState("");
  const [EmployeeAudio, SetEmployeeAudio] = useState("");
  const [EmployeeVideo, SetEmployeeVideo] = useState("");
  // const leaveTypes = Array.isArray(props.leaveType) ? props.leaveType : [];
  const [leaveTypes, setLeaveType] = useState([]); // Initialize as an empty array
  const [selectedLeaveType, setSelectedLeaveType] = useState([]);
  const [daysInput, setDaysInput] = useState({});





  useEffect(() => {
    // Fetch leave types assigned to employee from API
    const fetchLeaveTypes = async () => {
      try {
        const response = await http.get("/user/lavetypelist"); // Call your API endpoint here
        const data = response.data;

        console.log(" leave type data from API:", data);
        console.log("Employee Data:", employeeData);

        // Filter leave types to show only those assigned to the specific employee's uid
        const filteredLeaveTypes = data.filter(
          (item) => item.employee_id === employeeData.uid
        );

        // Pre-select leave types assigned to employee and set days input
        const assignedLeaveTypes = filteredLeaveTypes.map(
          (item) => item.leavetype_id
        );
        const assignedDays = {};
        filteredLeaveTypes.forEach((item) => {
          assignedDays[item.leavetype_id] = item.days_assigned;
        });

        setSelectedLeaveType(assignedLeaveTypes);
        setDaysInput(assignedDays);

        // Set filtered leave types
        setLeaveType(filteredLeaveTypes);
      } catch (error) {
        console.error("Error fetching leave types:", error);
      }
    };

    if (employeeData.uid) {
      // Call fetchLeaveTypes only if employeeData.uid is available
      fetchLeaveTypes();
    }
  }, [employeeData.uid]);

  // Fetch Leave Types from the API
  const fetchLeaveType = () => {
    http
      .get(`leavetype/list`)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setLeaveType(response.data); // Set the leave types array if the data is valid
        } else {
          console.error("Invalid leave type data received from the server.");
          setLeaveType([]); // Fallback to an empty array if invalid data is received
        }
      })
      .catch((error) => {
        console.error("Error fetching leave types:", error);
        setLeaveType([]); // Handle the error by setting an empty array
      });
  };

  useEffect(() => {
    fetchLeaveType();
  }, []);


  // Handle leave type selection (checkbox)
  const handleLeaveTypeChange = (e, leavetype_id) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedLeaveType((prev) => [...prev, leavetype_id]); // Add leave type ID to selectedLeaveType
    } else {
      setSelectedLeaveType((prev) =>
        prev.filter((id) => id !== leavetype_id)
      ); // Remove leave type ID if unchecked
      setDaysInput((prev) => {
        const updatedDays = { ...prev };
        delete updatedDays[leavetype_id]; // Clear the days input for the unchecked leave type
        return updatedDays;
      });
    }
  };

  // Handle the days input change
  const handleDaysInputChange = (e, leavetype_id) => {
    const value = e.target.value;
    setDaysInput((prev) => ({
      ...prev,
      [leavetype_id]: value, // Update the days input for the selected leave type
    }));
  };





  console.log(props.edit_data);
  useEffect(() => {

    http
      .get("/role/list")
      .then(function (response) {
        if (response.data.length == 0) {
          setActiveRole(false);
        } else {

          const filteredData = user && user.user.u_type === 1 || user.user.u_type === 2
            ? response.data.filter(
              (role) => parseInt(role.role_created_user_id) === user.user.user_user_id || parseInt(role.role_created_user_id) === user.user.uid
            ) :

            response.data.filter(
              (role) => parseInt(role.role_created_user_id) === user.user.uid
            );
          setEmployeeRole(filteredData);
          // setActiveRole(true);
          setEmployeeData(() => ({


            ...employeeData,
            user_role_id: ManageRole != 0 ? response.data[response.data.length - 1].role_id : employeeData.role_name

          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });


    http
      .get("/shift/list")
      .then(function (response) {
        // console.log(response.data);
        setEmployeeShift(response.data);
        // setActiveShift(true);
        setEmployeeData(() => ({
          ...employeeData,
          shift_id: ManageShift != 0 ? response.data[response.data.length - 1].shift_id : employeeData.shift_id

        }));

      })
      .catch(function (error) {
        console.log(error);
      });
  }, [counts]);
  console.log(employeeData);

  const getSelectedCityValue = (e) => {
    setEmployeeData({ ...employeeData, shift_id: e.value });
  };
  const getSelectedRoleValue = (e) => {
    setEmployeeData({
      ...employeeData,
      user_role_id: e.value,
      user_behave: e.value
    });
   
  };
  const getSelectedRoleValue1 = (e) => {
    setEmployeeData({
      ...employeeData,
      temp_role_id: e.value,
      user_role_id: e.value,
      user_behave: 1
    })

  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStatess1]);
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStatess]);

  // console.log(employeeData);
  const Close = () => {
    setModal(false);
    props.setModalStates();
  };


  //   http
  //     .get("/city/list")
  //     .then(function (response) {
  //       if (response.data.length == 0) {
  //         setActiveShift(false);
  //       } else {
  //         setEmployeeShift(response.data);
  //         setActiveShift(true);
  //         setCustomersData(() => ({
  //           ...CustomersData,
  //           customer_city:
  //             ManageShift == 0
  //               ? response.data[0].city_id
  //               : response.data[response.data.length - 1].city_id,
  //         }));
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, [counts + 1]);
  // useEffect(() => {
  //   setModal(false);
  //   // getRolesList();
  //   toggle();
  // }, [props.modalStates]);

  // GETTING ROLES LIST
  const getRolesList = async () => {
    try {
      const apiResponse = await https.get("/role/list");
      setRolesList(apiResponse.data);
    } catch (error) {
      console.log(error);
    }
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [checkNameStatus, setCheckStatus] = useState({});


  const [msg, setMsg] = useState("");


  // Function to prepare the data to be sent to the API
  const SubmitData = () => {
    if (employeeData.employee_name === "") {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsg("Employee cannot be empty!");
    } else {
      // Create an array of objects to store leave type details (leave_type_id, days, and employee_id)
      const leaves = selectedLeaveType.map((leavetype_id) => ({
        leave_type_id: leavetype_id,
        days: daysInput[leavetype_id] || 0, // Default to 0 if no days entered
        employee_id: employeeData.uid, // Use employeeData.uid for the employee_id
      }));

      // Prepare the data to be sent to the API
      const dataToSubmit = {
        ...employeeData, // Existing employee details
        leaves, // Add the leave type data array
      };



      // Send the updated employee and leave data to the API

      // console.log("dataToSubmit",dataToSubmit);
      
      https
        .put("/user/update", dataToSubmit)
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
        })
        .catch(function (error) {
          console.log("Error", error);
        });
    }
  };



  const handleEmployee = (e) => {
    setCheckStatus({});
    setMsg("");
    setEmployeeData({ ...employeeData, [e.target.name]: e.target.value });
  };

  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
    };



    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <Modal id="showModal" size="xl" isOpen={modal} toggle={toggle} style={{ marginTop: '5px', paddingTop: '10px' }} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Update Team Member
        </ModalHeader>
        <span className="tablelist-form">
          <ModalBody>
            <Card className="border card-border-success p-3 shadow-lg">
              <div className="mb-3">
                <Row>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                      Team Member Name <span className="text-danger">*</span>{" "}
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <CustomInput
                      checkNameStatus={checkNameStatus}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder="Employee Name"
                      type="text"
                      name="name"
                      value={employeeData.name}
                      onChange={handleEmployee}
                    />
                  </Col>

                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>Mobile Number<span className="text-danger fw-bold"> *</span></div>
                    </Label>
                    <Input
                      value={employeeData.mobile_no}
                      id="mobile_no"
                      name="mobile_no"
                      className="form-control fw-bold"
                      placeholder="Mobile Number"
                      type="number"
                      onChange={handleEmployee}
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>Emergency Mobile Number</div>
                    </Label>
                    <Input
                      value={employeeData.emergency_contact}
                      name="emergency_contact"
                      id="emergency_contact"
                      onChange={(e) => handleEmployee(e)}
                      className="form-control fw-bold"
                      placeholder="Emergency Mobile Number "
                      type="text"
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>Email Address<span className="text-danger fw-bold"> *</span></div>
                    </Label>
                    <Input
                      value={employeeData.email}
                      name="email"
                      id="email"
                      onChange={(e) => handleEmployee(e)}
                      className="form-control fw-bold"
                      placeholder="Email Address"
                      type="text"
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>Password <span className="text-danger fw-bold"> *</span></div>
                    </Label>
                    <Input
                      value={employeeData.password}
                      name="password"
                      id="password"
                      onChange={(e) => handleEmployee(e)}
                      className="form-control fw-bold"
                      placeholder="Password"
                      type={showPassword ? "text" : "password"} // Toggle between text and password

                    />
                    <i
                      className={`ri-eye${showPassword ? "-off" : ""}-fill`}
                      style={{
                        position: "absolute",
                        top: "80%",
                        right: "20px",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                    ></i>
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div> Salary<span className="text-danger fw-bold"> *</span></div>
                    </Label>
                    <Input
                      value={employeeData.salary}
                      name="salary"
                      id="salary"
                      onChange={(e) => handleEmployee(e)}
                      className="form-control fw-bold"
                      placeholder="Salary"
                      type="text"
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>Date of Joining</div>
                    </Label>
                    <Flatpickr
                      className="form-control fw-bold"
                      options={{
                        dateFormat: "d/m/Y",
                        defaultDate: employeeData.date_of_joining,
                      }}
                      name="payment_date"
                      placeholder="Date of joining"
                      onChange={(selectedDates) => {
                        const selectedDate = selectedDates[0];

                        const year = selectedDate.getFullYear();
                        const month = (selectedDate.getMonth() + 1)
                          .toString()
                          .padStart(2, "0");
                        const day = selectedDate
                          .getDate()
                          .toString()
                          .padStart(2, "0");
                        const formattedDate = `${year}-${month}-${day} 00:00:00`;

                        setEmployeeData({
                          ...employeeData,
                          date_of_joining: formattedDate,
                        });
                      }}
                    />


                  </Col>

                  <Col lg={3}>
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      <div> Shift <span className="text-danger fw-bold"> *</span></div>
                      <button
                        className="btn btn-success btn-sm px-1"
                        style={{ padding: "0px" }}
                        onClick={() => setModalStatess1(!false)}
                      >
                        <i className="ri-add-line align-bottom"></i>
                      </button>
                    </Label>
                    <Select
                      placeholder={ManageShift == 0 ? employeeData.shift_name : EmployeeRole[EmployeeRole.length - 1].shift_name}
                      // value={employeeData.role_id}
                      onChange={getSelectedCityValue}
                      options={EmployeeShift.map((item) => ({ value: item.shift_id, label: item.shift_name }))}
                      name="shift_id"
                      id="shift_id"
                      className="fw-bold"
                    />
                  </Col>


                  <Col lg={3}>
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-3 fw-bold d-flex justify-content-between"
                    >
                      <div>
                        Department<span className="text-danger fw-bold"> *</span>
                      </div>
                      <a
                        className="btn btn-success btn-sm px-1"
                        style={{ padding: "0px" }}
                        onClick={() => setModalStatess(!false)}
                      >
                        <i className="ri-add-line align-bottom"></i>
                      </a>
                    </Label>

                    <Select
                      placeholder={ManageRole == 0 ? employeeData.role_name : EmployeeRole[EmployeeRole.length - 1].role_name}
                      // onChange={getSelectedRoleValue}
                      onChange={getSelectedRoleValue1}
                      options={(EmployeeRole || []).filter(item => item.role_created_for == null).map((group) => ({
                        value: group.role_id,
                        label: group.role_name,
                      }))}
                      name="user_role_id"
                      id="user_role_id"
                      className="fw-bold"
                    
                    />

                  </Col>
                  {user.user.u_type == 1 || user.user.u_type == 3 || user.user.u_type == 5 || (user.user.u_type == 2 && user.user.user_behave == 1) || (user.user.u_type == 4 && user.user.user_behave == 1) ?
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        <div>Role</div>
                        {/* <div style={{ color: "red" }}>{msg}</div> */}
                      </Label>
                      <Select
                        className="fw-bold"
                        placeholder={ManageRole == 0 ? employeeData.role_name : null}
                      onChange={getSelectedRoleValue}
                       
                        options={(EmployeeRole || []).filter(item => item.role_created_for == employeeData.temp_role_id).map((group) => ({
                          value: group.role_id,
                          label: group.role_name,
                        }))}
                      />
                    </Col>
                    : <></>}
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                        Last Experience
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <Input
                      value={employeeData.last_experience}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder="Last Experience"
                      type="text"
                      name="last_experience"
                      onChange={handleEmployee}
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                        Last Working Company
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <Input
                      value={employeeData.last_working_company}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder="last Working Company"
                      type="text"
                      name="last_working_company"
                      onChange={handleEmployee}
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                        Last Company Salary
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <Input
                      value={employeeData.last_company_salary}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder="Last Company Salary"
                      type="text"
                      name="last_company_salary"
                      onChange={handleEmployee}
                    />
                  </Col>
                 
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                        Aadhar Number
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <Input
                      value={employeeData.aadhar_no}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder="Aadhar Number"
                      type="text"
                      name="aadhar_no"
                      onChange={handleEmployee}
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                        Pan Number
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <Input
                      value={employeeData.pan_no}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder="Pan Number"
                      type="text"
                      name="pan_no"
                      onChange={handleEmployee}
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                        User Upi
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <Input
                      value={employeeData.user_upi}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder="User upi"
                      type="text"
                      name="user_upi"
                      onChange={handleEmployee}
                    />
                  </Col>
                  <Col lg={6}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      Address
                    </Label>
                    <textarea
                      value={employeeData.address}
                      name="address"
                      id="address"
                      placeholder="Address"
                      className="form-control fw-bold"
                      onChange={handleEmployee}
                    ></textarea>
                  </Col>


                  <Col lg={6}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between mt-3"
                    >
                      <div>Leave-Type</div>
                    </Label>

                    {/* Safeguard for leaveTypes array */}
                    {Array.isArray(leaveTypes) && leaveTypes.length > 0 ? (
                      leaveTypes.map((leavetype) => (
                        <div key={leavetype.leavetype_id} className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`leaveType-${leavetype.leavetype_id}`}
                            value={leavetype.leavetype_id}
                            checked={selectedLeaveType.includes(leavetype.leavetype_id)}
                            onChange={(e) => handleLeaveTypeChange(e, leavetype.leavetype_id)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`leaveType-${leavetype.leavetype_id}`}
                          >
                            {leavetype.leavetype_name}
                          </label>

                          {/* Show the days input box if the leave type is checked */}
                          {selectedLeaveType.includes(leavetype.leavetype_id) && (
                            <input
                              type="number"
                              className="form-control mt-2"
                              placeholder="Add Day's"
                              value={leavetype.days}
                              onChange={(e) => handleDaysInputChange(e, leavetype.leavetype_id)}
                            />
                          )}
                        </div>
                      ))
                    ) : (
                      <p>No leave types available for this employee.</p>
                    )}
                  </Col>



                  <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Adhar Image</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="employeeAdhar"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title=""
                              data-bs-original-title="Select Image"
                            >
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                  <i className="ri-image-fill"></i>
                                </div>
                              </div>
                            </label>
                            <input
                              // value={IMG_API_URL + employeeData.adhaar_photo}
                              className="form-control d-none"
                              id="employeeAdhar"
                              type="file"
                              accept="image/png, image/gif, image/jpeg"
                              onChange={(e) => {
                                setEmployeeData({
                                  ...employeeData,
                                  aadhar_photo: e.target.files[0],
                                });
                                SetEmployeeAdhar(e.target.files[0]);
                              }}
                            />
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {EmployeeAdhar ? (
                                <img
                                  src={URL.createObjectURL(EmployeeAdhar)}
                                  id="product-img"
                                  alt="product-img"
                                  height={"100px"}
                                  width={"100px"}
                                />
                              ) : employeeData.
                                aadhar_photo
                                !== "/user/null" ? (
                                <img
                                  src={IMG_API_URL + employeeData.
                                    aadhar_photo
                                  }
                                  id="product-img"
                                  alt={IMG_API_URL + employeeData.
                                    aadhar_photo
                                  }
                                  height={"100px"}
                                  width={"100px"}
                                />
                              ) : ("")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Pan Image</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="employeePan"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title=""
                              data-bs-original-title="Select Image"
                            >
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                  <i className="ri-image-fill"></i>
                                </div>
                              </div>
                            </label>
                            <input
                              // value={IMG_API_URL + employeeData.pan_photo}
                              className="form-control d-none"
                              id="employeePan"
                              type="file"
                              accept="image/png, image/gif, image/jpeg"
                              onChange={(e) => {
                                setEmployeeData({
                                  ...employeeData,
                                  pan_photo: e.target.files[0],
                                });
                                SetEmployeePan(e.target.files[0]);
                              }}
                            />
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {EmployeePan ? (
                                <img
                                  src={URL.createObjectURL(EmployeePan)}
                                  id="product-img"
                                  alt="product-img"
                                  height={"100px"}
                                  width={"100px"}
                                />
                              ) : employeeData.pan_photo !== "/user/null" ? (
                                <img
                                  src={IMG_API_URL + employeeData.pan_photo}
                                  id="product-img"
                                  alt="product-img"
                                  height={"100px"}
                                  width={"100px"}
                                />
                              ) : ("")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Profile</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="empoyeeProfile"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title=""
                              data-bs-original-title="Select Image"
                            >
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                  <i className="ri-image-fill"></i>
                                </div>
                              </div>
                            </label>
                            <input
                              // value={IMG_API_URL + employeeData.profile_photo}
                              className="form-control d-none"
                              id="empoyeeProfile"
                              type="file"
                              accept="image/png, image/gif, image/jpeg"
                              onChange={(e) => {
                                setEmployeeData({
                                  ...employeeData,
                                  profile_photo: e.target.files[0],
                                });
                                SetEmployeeProfile(e.target.files[0]);
                              }}
                            />
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {EmployeeProfile ? (
                                <img
                                  src={URL.createObjectURL(EmployeeProfile)}
                                  id="product-img"
                                  alt="product-img"
                                  height={"100px"}
                                  width={"100px"}
                                />
                              ) : employeeData.profile_photo !== "/user/null" ? (
                                <img
                                  src={IMG_API_URL + employeeData.profile_photo}
                                  id="product-img"
                                  alt="product-img"
                                  height={"100px"}
                                  width={"100px"}
                                />
                              ) : ("")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Qr Code</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="employeeQrCode"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title=""
                              data-bs-original-title="Select Image"
                            >
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                  <i className="ri-image-fill"></i>
                                </div>
                              </div>
                            </label>
                            <input
                              // value={IMG_API_URL + employeeData.bank_passbook_photo}
                              className="form-control d-none"
                              id="employeeQrCode"
                              type="file"
                              accept="image/png, image/gif, image/jpeg"
                              onChange={(e) => {
                                setEmployeeData({
                                  ...employeeData,
                                  bank_passbook_photo: e.target.files[0],
                                });
                                SetEmployeeQrCode(e.target.files[0]);
                              }}
                            />
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {EmployeeQrCode ? (
                                <img
                                  src={URL.createObjectURL(EmployeeQrCode)}
                                  id="product-img"
                                  alt="product-img"
                                  height={"100px"}
                                  width={"100px"}
                                />
                              ) : employeeData.bank_passbook_photo !== "/user/null" ? (
                                <img
                                  src={IMG_API_URL + employeeData.bank_passbook_photo}
                                  id="product-img"
                                  alt="product-img"
                                  height={"100px"}
                                  width={"100px"}
                                />
                              ) : ("")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Document</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="employeeProfile"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title=""
                              data-bs-original-title="Select Document"
                            >
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                  <i className="ri-file-upload-fill"></i>
                                </div>
                              </div>
                            </label>
                            <input
                              className="form-control d-none"
                              id="employeeProfile"
                              type="file"
                              accept=".pdf,.doc,.docx,.txt,.xlsx"
                              onChange={(e) => {
                                setEmployeeData({
                                  ...employeeData,
                                  document: e.target.files[0],
                                });
                                SetEmployeeDoc(e.target.files[0]);
                              }}
                            />
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {EmployeeDoc ? (
                                EmployeeDoc.type.startsWith("image/") ? (
                                  <img
                                    src={URL.createObjectURL(EmployeeDoc)}
                                    alt="Selected file preview"
                                    height="100px"
                                    width="100px"
                                  />
                                ) : (
                                  <div>
                                    <p>{EmployeeDoc.name}</p>
                                    <p>{EmployeeDoc.type}</p>
                                  </div>
                                )
                              ) : employeeData.document && employeeData.document !== "/user/null" ? (
                                <img
                                  src={IMG_API_URL + employeeData.document}
                                  alt="Document preview"
                                  height="100px"
                                  width="100px"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col> */}
                  {/* <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Audio</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="employeeAudio"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title=""
                              data-bs-original-title="Select Document"
                            >
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                  <i className="ri-file-upload-fill"></i>
                                </div>
                              </div>
                            </label>
                            <input
                              className="form-control d-none"
                              id="employeeAudio"
                              type="file"
                              accept=".mp3,.aac,.wav,.flac,.mpeg"
                              onChange={(e) => {
                                  setemployeeData({
                                    ...employeeData,
                                    audio: e.target.files[0],
                                  });
                                  SetEmployeeAudio(e.target.files[0]);
                                }}
                            />
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {EmployeeAudio ? (
                                EmployeeAudio.type.startsWith("image/") ? (
                                  <img
                                    src={URL.createObjectURL(EmployeeAudio)}
                                    alt="Selected file preview"
                                    height="100px"
                                    width="100px"
                                  />
                                ) : (
                                  <div>
                                    <p>{EmployeeAudio.name}</p>
                                    <p>{EmployeeAudio.type}</p>
                                  </div>
                                )
                              ) : employeeData.audio && employeeData.audio !== "/user/null" ? (
                                <img
                                  src={IMG_API_URL + employeeData.audio}
                                  alt="Document preview"
                                  height="100px"
                                  width="100px"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col> */}
                  {/* <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Video</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="employeeVideo"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title=""
                              data-bs-original-title="Select Document"
                            >
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                  <i className="ri-file-upload-fill"></i>
                                </div>
                              </div>
                            </label>
                            <input
                              className="form-control d-none"
                              id="employeeVideo"
                              type="file"
                              accept="video/mp4, video/avi, video/mkv"
                                onChange={(e) => {
                                  setEmployeeData({
                                    ...employeeData,
                                    video: e.target.files[0],
                                  });
                                  SetEmployeeVideo(e.target.files[0]);
                                }}
                            />
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {EmployeeVideo ? (
                                EmployeeVideo.type.startsWith("image/") ? (
                                  <img
                                    src={URL.createObjectURL(EmployeeVideo)}
                                    alt="Selected file preview"
                                    height="100px"
                                    width="100px"
                                  />
                                ) : (
                                  <div>
                                    <p>{EmployeeVideo.name}</p>
                                    <p>{EmployeeVideo.type}</p>
                                  </div>
                                )
                              ) : employeeData.video && employeeData.video !== "/user/null" ? (
                                <img
                                  src={IMG_API_URL + employeeData.video}
                                  alt="Document preview"
                                  height="100px"
                                  width="100px"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col> */}
                </Row>
              </div>
            </Card>
          </ModalBody>
          <div className="modal-footer">
            <button
              ref={submitButtonRef}
              name="close"
              id="close"
              type="button"
              className="btn btn-primary"
              onClick={() => SubmitData()}
            >
              <i className="ri-save-3-line align-bottom me-1"></i>
              Update
            </button>
            <button
              name="close"
              id="close"
              type="button"
              className="btn btn-danger"
              onClick={() => Close()}
            >
              <i className="ri-close-line me-1 align-middle" />
              Close
            </button>

          </div>
        </span>
      </Modal>

      {modalStatess1 === true ? (
        <ShiftAdd
          modalStates={modalStatess1}
          setModalStates={() => {
            setModalStatess1(false);
          }}
          checkchang={handleCallback}
        />
      ) : modalStatess === true ? (
        <RoleCreateModal
          modalStates={modalStatess}
          setModalStates={() => {
            setModalStatess(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default UserCreateModal;