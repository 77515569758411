import React, { useEffect, useState, useRef } from 'react';
import { Dropdown, DropdownToggle } from 'reactstrap';
import validAudio from "../../assets/audio/success.mp3";
import AuthUser from '../../helpers/Authuser';
import { Link } from 'react-router-dom';

const MessageNotification = () => {
    const { http, user } = AuthUser();
    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
    const [unreadCounts, setUnreadCounts] = useState({});
    const [msg, setMsg] = useState([]);
    const previousTotalUnreadCount = useRef(0);
    const bellSound = useRef(new Audio(validAudio));
    const hasInteracted = useRef(false); // To track if the user has interacted

    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
        hasInteracted.current = true; // Mark as interacted when the dropdown is toggled
    };

    const fetchMsg = async () => {
        try {
            const response = await http.get("/message/list");
            setMsg(response.data);

            const counts = {};
            response.data.forEach((message) => {
                if (
                    message.receiver_id === user.user.uid &&
                    !message.read &&
                    message.message_status === 1
                ) {
                    counts[message.sender_id] = (counts[message.sender_id] || 0) + 1;
                }
            });

            setUnreadCounts(counts);
        } catch (error) {
            console.error("Error Fetching Message Data:", error);
        }
    };

    useEffect(() => {
        fetchMsg();
    }, []);

    const calculateTotalUnreadCount = () => {
        return Object.values(unreadCounts).reduce((sum, count) => sum + count, 0);
    };

    const totalUnreadCount = calculateTotalUnreadCount();

    useEffect(() => {
        if (hasInteracted.current && totalUnreadCount > previousTotalUnreadCount.current) {
            // Play the bell sound when unread message count increases
            bellSound.current.play().catch(error => {
                console.error("Error playing sound:", error);
            });
        }
        previousTotalUnreadCount.current = totalUnreadCount;
    }, [totalUnreadCount]);



    return (
        <React.Fragment>
            <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="topbar-head-dropdown ms-1 header-item">
                <Link to="/chat-app">
                    <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none">
                        <i className='bx bx-message fs-22'></i>
                        <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                            {totalUnreadCount ? totalUnreadCount : 0}
                            <span className="visually-hidden">unread messages</span>
                        </span>
                    </DropdownToggle>
                </Link>
            </Dropdown>
        </React.Fragment>
    );
};

export default MessageNotification;
