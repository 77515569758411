import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  Row,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import AuthUser from "../../helpers/Authuser";
import { useEffect } from "react";
import D_img from "../D_img";
import InfiniteScroll from "react-infinite-scroll-component";
import { IMG_API_URL } from "../../helpers/url_helper";
import ImgCom from "../Fullscreenimg/ImgCom";
import jsPDF from "jspdf";
import CreatePlanAdd from "./CreatePlanAdd";
import CreatePlanUpdate from "./CreatePlanUpdate";

const CreatePlanList = () => {
  const [modalStates, setModalStates] = useState(false);
  const [UpdatemodalStates, setUpdateModalStates] = useState(false);
  const [viewModalState, setviewModalState] = useState(false);
  const { http, permission } = AuthUser();
  //   Delete Aleart
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, SetID] = useState();
  const [dstatus, Setdstatus] = useState();
  const onClickDelete = (data) => {
    console.log(data.status);
    SetID(data);
    Setdstatus(data.status);
    setDeleteModal(true);
  };
  const handleDeleteOrder = (data) => {
    if (data._reactName == "onClick") {
      http
        .delete(`/subscription-plans/delete/${ID}`)
        .then(function (response) {
          if (response.data.status == 0) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
          Setcounts(counts + 1);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    setcreateplan([]);
    // SetPages(1);
    setDeleteModal(false);
  };

  //   end Alert
  const handleCallback = (data, status) => {
    setModalStates(false);
    setUpdateModalStates(false);
    setcreateplan([]);
    if (status == 1) {
      toast.success(data);
      Setcounts(counts + 1);
    } else {
      toast.warn(data);
      // toast.error(data);
      Setcounts(counts + 1);
    }
    // Setcounts(counts + 1);

  };
  const [NoMore, SetNoMore] = useState(true);

  const [counts, Setcounts] = useState(1);
  const [Employee, setcreateplan] = useState([]);
  // console.log(Employee);
  useEffect(() => {
    http
      .get("/subscription-plans/list")
      .then(function (response) {
        if (response.data.length > 0) {
          // console.log("byeeeeeeeeiiiiiiiiiiii", response.data);
          setcreateplan(response.data);
        }

      })
      .catch(function (error) {
        console.log(error);
        SetNoMore(false);

      });
  }, [counts]);
  const fetchData = () => {
    Setcounts(counts + 1);
  };
  // Edit Data
  const [FindData, SetFind] = useState([]);
  const EditUpdate = (index) => {

    let FindArray = Employee.filter((data) => data.uid === index);
    // console.log(FindArray[0])
    SetFind(FindArray[0]);
    setUpdateModalStates(!UpdatemodalStates);
  };

  // shortcut to get add form
  // shortcuts for opening add form
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "a" || event.altKey && event.key === "A") {
        event.preventDefault();
        setModalStates(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const generatePDF = () => {
    const doc = new jsPDF('landscape');

    // Set table headers
    const headers = [
      "#",
      "BUSINESS NAME",
      "BUSINESS EMAIL",
      "MOBILE NO",


    ];
    // Set table rows
    const data = Employee.filter((data) => data.u_type == '3').map((item, index) => [
      index + 1,
      item.name,
      item.email,
      item.mobile_no


    ]);

    // Set table style
    doc.setFontSize(12);
    doc.text("Business List", 14, 15);
    doc.autoTable({
      head: [headers],
      body: data,
      startY: 20,
    });

    // Save the PDF
    doc.save("BusinessList.pdf");

    toast.success("PDF generated successfully!");
  };
  const convertToCSVexcel = () => {
    let csvContent = "#,BUSINESS NAME,BUSINESS EMAIL,MOBILE NO";

    Employee.filter((data) => data.u_type == '5').forEach((item, index) => {
      csvContent += `\n${index + 1},"${item.name}","${item.email}","${item.mobile_no}"`;
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "BusinessList.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    toast.success("CSV generated successfully!");
  };
  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="text-center pt-2 pb-4">
                    <h2 className="fw-bold mb-0">
                      Subscription Plan
                    </h2>
                  </div>
                </Row>
                <Row className="align-items-center gy-3">
                  <div className="col-sm">

                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      <button
                        type="button"
                        className="btn mt-3 btn-success fw-bold"
                        id="create-btn"
                        onClick={() => setModalStates(!modalStates)}
                      >
                        <i className="ri-add-line align-bottom me-1"></i> Add
                        Plan
                      </button>
                      <button
                        type="button"
                        className="btn  btn-info mt-3 fw-bold"
                        onClick={convertToCSVexcel}
                      >
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        Export
                      </button>

                      <button
                        type="button"
                        className="btn  btn-danger mt-3 fw-bold"
                        onClick={generatePDF}
                      >
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        PDF
                      </button>
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  ></Nav>
                  <div className=" table-responsive">
                    <InfiniteScroll
                      dataLength={Employee.length}
                      next={fetchData}
                      hasMore={NoMore}
                    >

                      <table
                        role="table"
                        className="align-middle  table-nowrap table table-hover"
                      >
                        <thead className="table-light text-muted text-uppercase">
                          <tr>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              #
                            </th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              subscription title
                            </th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              subscription price
                            </th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              subscription feature
                            </th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              subscription duration
                            </th>
                            {/* <th
                                title="Toggle SortBy"
                                style={{ cursor: "pointer" }}
                              >
                                Franchises Role
                              </th> */}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Employee.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <a
                                  className="fw-medium link-primary"
                                  href="/apps-ecommerce-order-details"
                                >
                                  {index + 1}
                                </a>
                              </td>
                              <td>{item.subscription_type_name}</td>
                              <td>{item.subscription_price}</td>
                              <td>
                                {item.subscription_feature}
                              
                              </td>

                              <td>{item.subscription_duration}</td>
                              {/* <td>{item.role_name}</td> */}
                              <td>
                                <ul className="list-inline hstack gap-2 mb-0">

                                  <li className="list-inline-item edit">
                                    {permission.find(permission => permission.permission_category === "EMPLOYEE" && permission.permission_path === "3") && (
                                      <button
                                        className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                        onClick={() => EditUpdate(item.uid)}
                                      >
                                        <i className="ri-pencil-fill fs-16" />
                                      </button>
                                    )}
                                  </li>

                                  <li className="list-inline-item">
                                    {permission.find(permission => permission.permission_category === "EMPLOYEE" && permission.permission_path === "4") && (
                                      <button
                                        onClick={() =>
                                          onClickDelete(item.subscription_id)
                                        }
                                        className="text-danger d-inline-block remove-item-btn  border-0 bg-transparent"
                                      >
                                        <i className="ri-delete-bin-5-fill fs-16" />
                                      </button>)}
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                    </InfiniteScroll>
                  </div>
                </div>


                {UpdatemodalStates === true ? (
                  <CreatePlanUpdate
                    modalStates={UpdatemodalStates}
                    setModalStates={() => {
                      setUpdateModalStates(false);
                    }}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                {modalStates === true ? (
                  <CreatePlanAdd
                    modalStates={modalStates}
                    setModalStates={() => {
                      setModalStates(false);
                    }}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                <ToastContainer closeButton={false} limit={1} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreatePlanList;
