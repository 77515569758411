import React, { useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    CardHeader,
    Nav,
    Row,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";

import AuthUser from "../../helpers/Authuser";
import { useEffect } from "react";
import SubscriptionfeachersUpdatee from "./SubscriptionfeachersUpdatee";
import SubscriptionfeachersAdd from "./SubscriptionfeachersAdd";


const SubscriptionfeachersList = () => {
    const [modalStates, setModalStates] = useState(false);
    const [UpdatemodalStates, setUpdateModalStates] = useState(false);
    const { http } = AuthUser();
    const [counts, Setcounts] = useState(1);
    const [SubscriptionfeachersList, SetSubscriptionfeachersList] = useState([]);
    // infinity
    const [Pages, SetPages] = useState(1);
    const [NoMore, SetNoMore] = useState(true);



    const convertTo12HourFormat = (time) => {
        const [hours, minutes] = time.split(':');
        let parsedHours = parseInt(hours, 10);
        const parsedMinutes = parseInt(minutes, 10);
        const period = parsedHours >= 12 ? 'PM' : 'AM';
        parsedHours = parsedHours % 12 || 12;
        const formattedTime = `${parsedHours}:${(parsedMinutes < 10 ? '0' : '') + parsedMinutes} ${period}`;
        return formattedTime;
    };
    useEffect(() => {
        document.title = "Shift | CRM";

        http
            .get(`/subscriptionfeachers/list`)
            .then(function (response) {
                SetSubscriptionfeachersList(response.data);

            })
            .catch(function (error) {
                console.log(error);
                SetNoMore(false);
            });
    }, [counts]);
    const fetchData = () => {
        Setcounts(counts + 1);
    };
    //   Delete Aleart
    const [deleteModal, setDeleteModal] = useState(false);
    const [ID, SetID] = useState();
    const onClickDelete = (subscriptionfuture_id) => {
        // console.log(subscriptionfuture_id);
        SetID(subscriptionfuture_id);
        setDeleteModal(true);
    };
    const handleDeleteOrder = (subscriptionfuture_id) => {
        if (subscriptionfuture_id._reactName == "onClick") {
            http
                .delete(`/subscriptionfeachers/delete/${ID}`)
                .then(function (response) {
                    toast.success(response.data.message);
                    Setcounts(counts + 1);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        SetPages(1);
        setDeleteModal(false);
    };

    // shortcuts for opening add form
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.altKey && event.key === "a" || event.altKey && event.key === "A") {
                event.preventDefault();
                setModalStates(true);
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);
    //   end Alert
    const handleCallback = (data, status) => {

        SetPages(1);
        if (status == 1) {
            toast.success(data);
            Setcounts(counts + 1);
        } else {
            toast.warn(data);
            Setcounts(counts + 1);
        }
        setModalStates(false);
        setUpdateModalStates(false);
    };

    const [FindData, SetFind] = useState([]);
    const EditUpdate = (item) => {

        SetFind(item);
        setUpdateModalStates(!UpdatemodalStates);
    };
    return (
        <div className="page-content">
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteOrder}
                onCloseClick={() => setDeleteModal(false)}
            />
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader className="card-header border-0">
                                <Row className="align-items-center gy-3">
                                    <div className="col-sm">
                                        <h5 className="card-title mb-0">Feachers  List</h5>
                                    </div>
                                    <div className="col-sm-auto">
                                        <div className="d-flex gap-1 flex-wrap">
                                            <button
                                                type="button"
                                                className="btn fw-bold btn-success add-btn"
                                                id="create-btn"
                                                onClick={() => setModalStates(!modalStates)}
                                            >
                                                <i className="ri-add-line align-bottom me-1"></i> Add
                                                Feachers
                                            </button>
                                        </div>
                                    </div>
                                </Row>
                            </CardHeader>

                            <CardBody className="pt-0">
                                <div>
                                    <Nav
                                        className="nav-tabs nav-tabs-custom nav-success"
                                        role="tablist"
                                    ></Nav>

                                    <table role="table" className="align-middle table-nowrap table table-hover">
                                        <thead className="table-light text-muted text-uppercase">
                                            <tr>
                                                <th title="Toggle SortBy" >#</th>
                                                <th title="Toggle SortBy" >Feachers Name</th>

                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {SubscriptionfeachersList.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <a className="fw-medium link-primary" href="/apps-ecommerce-order-details">
                                                            {index + 1}
                                                        </a>
                                                    </td>
                                                    <td>{item.subscriptionfuture_name}</td>
                                                    <td>
                                                        <ul className="list-inline hstack gap-2 mb-0">
                                                            <li className="list-inline-item edit">
                                                                <button
                                                                    className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                                                    onClick={() => EditUpdate(item)}
                                                                >
                                                                    <i className="ri-pencil-fill fs-16" />
                                                                </button>
                                                            </li>
                                                            <li className="list-inline-item">
                                                                {item.shift_id !== 1 && (
                                                                    <button
                                                                        onClick={() => onClickDelete(item.subscriptionfuture_id)}
                                                                        className="text-danger d-inline-block remove-item-btn border-0 bg-transparent"
                                                                    >
                                                                        <i className="ri-delete-bin-5-fill fs-16" />
                                                                    </button>
                                                                )}
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>
                                {modalStates === true ? (
                                    <SubscriptionfeachersAdd
                                        modalStates={modalStates}
                                        setModalStates={() => {
                                            setModalStates(false);
                                        }}
                                        checkchang={handleCallback}
                                    />
                                ) : (
                                    ""
                                )}
                                {UpdatemodalStates === true ? (
                                    <SubscriptionfeachersUpdatee
                                        modalStates={UpdatemodalStates}
                                        setModalStates={() => {
                                            setUpdateModalStates(false);
                                        }}
                                        checkchang={handleCallback}
                                        edit_data={FindData}
                                    />
                                ) : (
                                    ""
                                )}
                                <ToastContainer closeButton={false} limit={1} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SubscriptionfeachersList;
