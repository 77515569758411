import React, { useEffect, useRef, useState, useCallback } from "react";
import Select from "react-select";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Label,
  Input,
  Row,
  Col,
  CardHeader,
  CardBody,
  Button,
  Form,
  Nav,
} from "reactstrap";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from "react-toastify";

export const SalaryAdd = (props) => {
  const submitButtonRef = useRef();
  const { http, user } = AuthUser();
  const [tempData, settempData] = useState({
    employee_salary: 0,
    startDate: "",
    endDate: "",
    totalDays: 0,
    presentCount: 0,
  });

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = 0; // January is zero-based (0-11)

  // Calculate the last day of the month
  const lastDayOfMonth = new Date(year, props.day.value, 0);

  // Get the day part (number of days in the month)
  const totalDaysInMonth = lastDayOfMonth.getDate();

  // console.log(`Total days in January: ${totalDaysInMonth}`);
  const months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  const [salary, SetSalary] = useState([]);

  const [employeeData, setemployeeData] = useState([]);
  const getemployeeData = async () => {
    http
      .get("/franchisebusiness/list")
      .then(function (response) {
        if (response.data.length > 0) {
          setemployeeData(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const [modal, setModal] = useState(false);
  useEffect(() => {
    getemployeeData();

    document.title = "Salary Generation";
  }, []);
  const Close = () => {
    setModal(false);
    props.setModalStates();
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);
  const [count, setcount] = useState(1);
  const [absentpresent, setAbsentPresent] = useState([]);

  useEffect(() => {
    // Extract unique employee ids and months from employeeData
    const uniqueEmployeeData = [
      ...new Map(employeeData.map((item) => [item.uid, item])).values(),
    ];

    // Fetch API data for each unique employee id and month
    const fetchApiData = async () => {
      try {
        const apiResponses = await Promise.all(
          uniqueEmployeeData.map(
            ({ uid }) =>
              http
                .post("/attendence/present/", {
                  employeeId: uid,
                  month: props.day.value,
                })
                .then((res) => res.data) // Extract data from the response
          )
        );
        // Extract and set API data
        setAbsentPresent(apiResponses);
      } catch (error) {
        console.error("Error fetching API data:", error);
      }
    };
    // Call the function to fetch API data
    fetchApiData();
  }, [count, employeeData]);
  const [incentive, setInsentive] = useState(0);
  // Call the asynchronous function when the component mounts

  const setEmployeeIncentive = (employeeId, incentive) => {
    setInsentive((prevIncentives) => ({
      ...prevIncentives,
      [employeeId]: incentive,
    }));
  };

  // ... (other code)

  useEffect(() => {
    // Call the function to retrieve table data
    retrieveTableData();
  }, [count, employeeData, absentpresent, incentive]);
  const retrieveTableData = () => {
    const tableData = employeeData.map((item, index) => ({
      salary_receiver_id: item.uid,
      salary_month: parseInt(props.day.value),
      salary_generator_id: user.user.uid,
      salary: item.salary,
      salary_date: new Date().toISOString().split("T")[0],
      salary_present_days: absentpresent
        .filter((apiItem) => apiItem.user_id === item.uid)
        .map((filteredApiItem) => filteredApiItem.presentAttendanceCount)
        .reduce((acc, count) => acc + count, 0),
      salary_absent_days: absentpresent
        .filter((apiItem) => apiItem.user_id === item.uid)
        .map((filteredApiItem) => filteredApiItem.absentAttendanceCount)
        .reduce((acc, count) => acc + count, 0),
      calculated_salary:
        (item.salary / totalDaysInMonth) *
        absentpresent
          .filter((apiItem) => apiItem.user_id === item.uid)
          .map((filteredApiItem) => filteredApiItem.presentAttendanceCount)
          .reduce((acc, count) => acc + count, 0),
      salary_incentive: parseFloat(incentive[item.uid] || 0),
      salary_total_days: totalDaysInMonth,
      total_salary_amount: (() => {
        const employeeData = absentpresent.find(
          (apiItem) => apiItem.user_id === item.uid
        );
        if (employeeData) {
          const calculatedSalary =
            (item.salary / totalDaysInMonth) *
            employeeData.presentAttendanceCount;
          const totalSalary =
            calculatedSalary + parseFloat(incentive[item.uid] || 0);
          const roundedTotalSalary = Math.floor(totalSalary);
          return roundedTotalSalary;
        } else {
          return 0;
        }
      })(),
    }));

    SetSalary(tableData);
  };

  const HandleSave = async (e) => {
    e.preventDefault();
    http
      .post("/salary/store", salary)
      .then((res) => {
        toast.success(res.data.message);
        setModal(false);
        props.setModalStates();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // console.log(salary);
  return (
    <div className="page-content">
      <Modal
        className="modal-xl"
        id="fullscreeexampleModal"
        isOpen={modal}
        size={"xl"}
        toggle={toggle}
        centered
      >
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Generate Salary
        </ModalHeader>
        <span className="tablelist-form">
          <ModalBody>
            <Form onSubmit={(e) => HandleSave(e)} action="#"></Form>
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <Row className="align-items-center gy-3"></Row>
                  <div className="pt-0">
                    <div className="mb-3">
                      <Row>
                        <Col lg={3}>
                          <Label className="form-label fw-bold mt-3 d-flex justify-content-between  ">

                          </Label>
                        </Col>
                        <Col lg={3}>
                          <Label className="form-label fw-bold mt-3 d-flex justify-content-between  ">
                            <div> month</div>
                          </Label>
                          <Input value={props.day.label} disabled />
                        </Col>
                        <Col lg={3}>
                          <Label className="form-label fw-bold mt-3 d-flex justify-content-between  ">
                            <div>Year</div>
                          </Label>
                          <Input value={new Date().getFullYear()} disabled />
                        </Col>
                        <Col lg={3}>

                        </Col>
                        <Col lg={3}>

                        </Col>
                        <Col lg={3}>
                          <Label className="form-label fw-bold mt-3 d-flex justify-content-between  ">

                          </Label>

                        </Col>

                        <Col lg={2}>
                          <Label className="form-label fw-bold mt-3 d-flex justify-content-between  ">

                          </Label>

                        </Col>

                        <Col lg={2}>
                          <Label className="form-label fw-bold mt-3 d-flex justify-content-between  ">

                          </Label>

                        </Col>

                        <Col lg={2}>
                          <Label className="form-label fw-bold mt-3 d-flex justify-content-between  ">

                          </Label>
                        </Col>
                        <Col lg={3}>

                        </Col>
                        <Col lg={3}>

                        </Col>
                        <Col lg={3} className="text-center">
                          <Label className="form-label fw-bold mt-3 d-flex justify-content-between  "></Label>

                        </Col>
                        <div className="table-responsive">
                          <table
                            role="table"
                            className="align-middle table-nowrap table table-hover"
                          >
                            <thead className="table-light text-muted text-uppercase">
                              <tr>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  #
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  Name
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  Salary
                                </th>
                                <th>Present</th>
                                <th>Absent</th>
                                <th>Calculate Salary</th>
                                <th>Incentive</th>
                                <th>Total Salary</th>
                              </tr>
                            </thead>
                            <tbody>
                              {employeeData.map((item, index) => (
                                <tr key={index}>
                                  <td
                                    title="Toggle SortBy"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {count + index}
                                  </td>
                                  <td
                                    title="Toggle SortBy"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {item.name}
                                  </td>
                                  <td
                                    title="Toggle SortBy"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {item.salary}
                                  </td>
                                  <td>
                                    {absentpresent
                                      .filter(
                                        (apiItem) =>
                                          apiItem.user_id === item.uid
                                      )
                                      .map((filteredApiItem, apiIndex) => (
                                        <div key={apiIndex}>
                                          {/* {/ Render API data as needed /} */}
                                          {
                                            filteredApiItem.presentAttendanceCount
                                          }
                                        </div>
                                      ))}
                                  </td>
                                  <td>
                                    {absentpresent
                                      .filter(
                                        (apiItem) =>
                                          apiItem.user_id === item.uid
                                      )
                                      .map((filteredApiItem, apiIndex) => (
                                        <div key={apiIndex}>
                                          {/* {/ Render API data as needed /} */}
                                          {
                                            filteredApiItem.absentAttendanceCount
                                          }
                                        </div>
                                      ))}
                                  </td>
                                  <td>
                                    {(
                                      (item.salary / totalDaysInMonth) *
                                      absentpresent
                                        .filter((apiItem) => apiItem.user_id === item.uid)
                                        .map((filteredApiItem) => filteredApiItem.presentAttendanceCount)
                                        .reduce((acc, count) => acc + count, 0)
                                    ).toFixed(2)}
                                  </td>

                                  <td>
                                    <Input
                                      type="number"
                                      onChange={(e) =>
                                        setEmployeeIncentive(
                                          item.uid,
                                          parseFloat(e.target.value)
                                        )
                                      }
                                      className="w-50"
                                    />
                                  </td>
                                  <td>
                                    {(() => {
                                      const employeeData = absentpresent.find(
                                        (apiItem) =>
                                          apiItem.user_id === item.uid
                                      );
                                      if (employeeData) {
                                        const calculatedSalary =
                                          (item.salary / totalDaysInMonth) *
                                          employeeData.presentAttendanceCount;
                                        const totalSalary =
                                          calculatedSalary +
                                          parseFloat(incentive[item.uid] || 0);
                                        const roundedTotalSalary =
                                          Math.floor(totalSalary);
                                        return roundedTotalSalary;
                                      } else {
                                        return 0;
                                      }
                                    })()}
                                  </td>
                                  {/* <td><span className="badge bg-success-subtle text-success text-uppercase">
                                <i className="ri--line align-bottom me-1"></i>Paid
                              </span></td>
                              <td><button className="btn btn-primary btn-sm">Pay</button></td> */}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container fluid></Container>
          </ModalBody>
          <div className="modal-footer">
            <button
              ref={submitButtonRef}
              name="close"
              id="close"
              type="submit"
              onClick={(e) => HandleSave(e)}
              className="btn btn-primary"
            >
              <i className="ri-save-3-line align-bottom me-1"></i>
              Save
            </button>
            <button
              name="close"
              id="close"
              type="button"
              className="btn btn-danger"
              onClick={() => Close()}
            >
              <i className="ri-close-line me-1 align-middle" />
              Close
            </button>
          </div>
        </span>
      </Modal>
    </div>
  );
};
