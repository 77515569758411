import React, { useEffect, useState, useCallback, useRef } from "react";
import {
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Row,
    Col,
    Button,
    Form,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import CustomInput from "../Unit/Input";
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';

import Select from "react-select";


const Feachers_Add = (props, fetchData) => {
    const { https } = AuthUser();
    const [modal, setModal] = useState(false);
    const [Subscription, SetSubscription] = useState({
        feachers_name: "",    // Other fields remain untouched
        feachers_type_id: ""  // This will be updated
    });
    const submitButtonRef = useRef();

    const [audioBlob, setAudioBlob] = useState(null); // State for audio blob
    const recorderControls = useAudioRecorder();
    const [SubscriptionData, SetSubscriptionData] = useState([]);
    const [errors, setErrors] = useState({}); // State to store validation errors

    useEffect(() => {
        document.title = "Subscription | CRM";

        https
            .get('/subscriptiontype/list')
            .then(function (response) {
                SetSubscriptionData([...SubscriptionData, ...response.data]);
                console.log("Use Effect is working");
            })
            .catch(function (error) {
                console.log(error);

            });
    }, []);

    const addAudioElement = (blob) => {
        setAudioBlob(blob); // Update the audio blob state
    };

    const Close = () => {
        setModal(false);
        props.setModalStates();
    };

    useEffect(() => {
        setModal(false);
        toggle();
    }, [props.modalStates]);

    const toggle = useCallback(() => {
        setModal(!modal);
    }, [modal]);

    const [checkNameStatus, setCheckStatus] = useState({});
    const [msg, setMsg] = useState("");

    const validateForm = () => {
        let formErrors = {};
        if (!Subscription.feachers_name) {
            formErrors.feachers_name = "Feachers Name is required";
        }
        if (!Subscription.feachers_type_id) {
            formErrors.feachers_type_id = "Feachers Type is required";
        }
        setErrors(formErrors);

        // Return true if there are no errors
        return Object.keys(formErrors).length === 0;
    };

    const SubmitData = () => {
        if (!validateForm()) {
            return; // Prevent form submission if validation fails
        }

        if (audioBlob) {
            Subscription.append("audio_file", audioBlob, "recording.wav"); // Append the audio file
        }
        https
            .post("/feachers/store", Subscription)
            .then(response => {
                props.checkchang(response.data.message, response.data.status);
                fetchData();
            })
            .catch(error => {
                console.log(error);
            });
    };

    return (
        <div>
            <Modal id="showModal" isOpen={modal} size="xl" toggle={toggle} centered>
                <ModalHeader className="bg-light p-3" toggle={toggle}>
                    Create feature
                </ModalHeader>
                <span className="tablelist-form">
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            SubmitData();
                            return false;
                        }}
                        action="#"
                    >
                        <ModalBody>
                            <Card className="border card-border-success p-3 shadow-lg">
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label
                                                htmlFor="feachers-name-field"
                                                className="form-label fw-bold d-flex justify-content-between"
                                            >
                                                <div>
                                                Feature Name<span style={{ color: "red" }}> *</span>
                                                </div>
                                            </Label>
                                            <CustomInput
                                                checkNameStatus={checkNameStatus}
                                                name="feachers_name"
                                                id="feachers-name-field"
                                                className={`form-control fw-bold ${errors.feachers_name ? 'is-invalid' : ''}`}
                                                placeholder="Feachers Name"
                                                type="text"
                                                onChange={(e) => SetSubscription({
                                                    ...Subscription, feachers_name: e.target.value
                                                })}
                                            />
                                            {errors.feachers_name && (
                                                <div className="invalid-feedback">{errors.feachers_name}</div>
                                            )}
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label      
                                                htmlFor="feachers-type-field"
                                                className="form-label fw-bold d-flex justify-content-between"
                                            >
                                                <div>
                                                Feature Type<span style={{ color: "red" }}> *</span>
                                                </div>
                                            </Label>
                                            <Select
                                                options={SubscriptionData.map((e) => ({
                                                    value: e.subscription_type_id,
                                                    label: e.subscription_type_name
                                                }))}
                                                value={SubscriptionData.find(option => option.value === Subscription.feachers_type_id)}
                                                onChange={(selectedOption) => {
                                                    SetSubscription(prevState => ({
                                                        ...prevState,
                                                        feachers_type_id: selectedOption.value
                                                    }));
                                                }}
                                                className={errors.feachers_type_id ? 'is-invalid' : ''}
                                            />
                                            {errors.feachers_type_id && (
                                                <div className="invalid-feedback d-block">{errors.feachers_type_id}</div>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </ModalBody>
                        <div className="modal-footer">
                            <button
                                ref={submitButtonRef}
                                name="close"
                                id="close"
                                type="submit"
                                className="btn btn-primary"
                            >
                                <i className="ri-save-3-line align-bottom me-1"></i>
                                Save
                            </button>
                            <button
                                name="close"
                                id="close"
                                type="button"
                                className="btn btn-danger"
                                onClick={Close}
                            >
                                <i className="ri-close-line me-1 align-middle" />
                                Close
                            </button>
                        </div>
                    </Form>
                </span>
            </Modal>
        </div>
    );
};

export default Feachers_Add;
