import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Form,
  Button,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
import CustomInput from "../Unit/Input";
import CustomerGroupAdd from "../CustomerGroup/CustomerGroupAdd";
import CityAdd from "../City/CityAdd";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

const CustomerAdd = (props) => {
  // On form submit getCustomerData imported

  const { http, user } = AuthUser();
  // console.log("user id",user.user.uid);
  const [CustomerGroup, setCustomerGroup] = useState();
  const [activeGroup, setActiveGroup] = useState(false);
  const [CustomerCity, setCustomerCity] = useState();
  const [activeCity, setActiveCity] = useState(false);
  const [modalStatess, setModalStatess] = useState(false);
  const [modalStatess1, setModalStatess1] = useState(false);
  const [counts, Setcounts] = useState(1);
  const [manageGroup, setManageGroup] = useState(0);
  const [manageCity, setManageCity] = useState(0);
  const handleCallback = (data) => {
    Setcounts(counts + 1);
    setManageGroup(1);
    setManageCity(1);
    toast.success(data);
    setModalStatess(false);
    setModalStatess1(false);
  };
  const [validStatus, setValidStatus] = useState(0);
  useEffect(() => {
    http
      .get("/all_customer_groups")
      .then(function (response) {
        if (response.data.length == 0) {
          setActiveGroup(false);
        } else {
          setCustomerGroup(response.data);
          setActiveGroup(true);
          setCustomersData(() => ({
            ...CustomersData,
            customer_group:
              manageGroup == 0
                ? response.data[0].customer_group_id
                : response.data[response.data.length - 1].customer_group_id,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    http
      .get("/city/list")
      .then(function (response) {
        if (response.data.length == 0) {
          setActiveCity(false);
        } else {
          setCustomerCity(response.data);
          setActiveCity(true);
          setCustomersData(() => ({
            ...CustomersData,
            customer_city:
              manageCity == 0
                ? response.data[0].city_id
                : response.data[response.data.length - 1].city_id,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [counts + 1]);

  const [CustomersData, setCustomersData] = useState({
    customer_name: "",
    customer_whatsapp_no: "",
    customer_alternative_no: "",
    customer_email: "",
    customer_city: 1,
    customer_group: 1,
    customer_designation: "",
    customer_address: "",
    customer_compnay_name: "",
  });

  const getCustomerData = (e) => {
    setCustomersData({ ...CustomersData, [e.target.name]: e.target.value });
    if (CustomersData.customer_name != "") {
      setCheckStatus({});
      setMsg("");
    }
  };
  const getSelectedGroupValue = (e) => {
    setCustomersData({ ...CustomersData, customer_group: e.value });
  };
  const getSelectedCityValue = (e) => {
    setCustomersData({ ...CustomersData, customer_city: e.value });
  };
  const [checkNameStatus, setCheckStatus] = useState({});
  const [msg, setMsg] = useState("");


  const [loading, setLoading] = useState(false);
  const OnSubmited = () => {
    if (loading) return;
    setLoading(true);
    if (CustomersData.customer_name === "") {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsg("Customer name can not be empty!");
    }
    else if (CustomersData.customer_whatsapp_no === "") {
      setValidStatus(1);
    }
    else {
      const mainCustomerArray = {
        allCustomers: CustomersData,
      };
      http
        .post("/customer/store", mainCustomerArray.allCustomers)
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
          setModal(false);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          // Reset loading state to allow further submissions if necessary
          setLoading(false);
        });
    }
    
  };

  const [modal, setModal] = useState(false);

  const Close = () => {
    setModal(false);
    props.setModalStates();
  };

  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        props.setModalStates(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);





  
  const { transcript, browserSupportsSpeechRecognition, resetTranscript } = useSpeechRecognition();

  const numberAndSymbolMapping = {
    zero: "0", one: "1", two: "2", three: "3", four: "4", five: "5",
    six: "6", seven: "7", eight: "8", nine: "9", ten: "10",
    dot: ".", comma: ",", dash: "-", plus: "+", at: "@", hashtag: "#",
    dollar: "$", percent: "%", ampersand: "&", asterisk: "*",
    exclamation: "!", question: "?", slash: "/", backslash: "\\",
    parenthesis: "()", brackets: "[]", brace: "{}", semicolon: ";",
    colon: ":", quote: '"', "single quote": "'", equal: "=",
    "greater than": ">", "less than": "<"
  };

  const convertSpokenNumbersToDigits = (text) => {
    return text.split(" ").map((word) => numberAndSymbolMapping[word.toLowerCase()] || word).join(" ");
  };

  const [isAddressListening, setIsAddressListening] = useState(false);


  // Updating the address field based on transcript
  useEffect(() => {
    if (isAddressListening && transcript) {
      const convertedTranscript = convertSpokenNumbersToDigits(transcript);
      setCustomersData((prevData) => ({
        ...prevData,
        customer_address: prevData.customer_address + " " + convertedTranscript,  // Append new transcript
      }));
    }
  }, [transcript, isAddressListening]);

  // Handle speech recognition activation
  useEffect(() => {
    if (isAddressListening) {
      SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
    } else {
      SpeechRecognition.stopListening();
      resetTranscript();
    }
  }, [isAddressListening, resetTranscript]);

  // Check if browser supports speech recognition
  if (!browserSupportsSpeechRecognition) {
    return <div>Your browser does not support speech recognition.</div>;
  }

  const handleAddressMicClick = () => {
    setIsAddressListening(!isAddressListening);  // Toggle microphone
  };

  return (
    <div>
      <Modal id="showModal" size="xl" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Create Customer
        </ModalHeader>
        <div className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              OnSubmited();
              return false;
            }}
            action="#"
          >
            <ModalBody>
              <Card className="border card-border-success  p-3 shadow-lg">
                <div className="mb-3 row">
                  <div className="col-4">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      <div>
                        Full Name<span style={{ color: "red" }}> *</span>
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <CustomInput
                      checkNameStatus={checkNameStatus}
                      onChange={getCustomerData}
                      name="customer_name"
                      id="customer_name"
                      className="form-control fw-bold"
                      placeholder="Full Name"
                      type="text"
                    />
                  </div>

                  <div className="col-4">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      <div> WhatsApp Number<span className="text-danger fw-bold"> *</span></div>
                      <div className="text-danger fw-bold">{validStatus === 1 ? "Whatsapp cannot be empty !" : ""}</div>
                    </Label>
                    {/* <Input
                      onChange={getCustomerData}
                      name="customer_whatsapp_no"
                      id="customer_whatsapp_no"
                      className="form-control fw-bold"
                      placeholder="Mobile No"
                      type="text"
                    /> */}
                    <Input
                      type="number"
                      value={CustomersData ? CustomersData.customer_whatsapp_no : ""}
                      onChange={(e) => {
                        setValidStatus(0);
                        // Remove non-numeric characters
                        const input = e.target.value.replace(/\D/g, "");

                        // Apply validation for 10-digit number
                        if (input.length <= 10) {
                          setCustomersData({
                            ...CustomersData,
                            customer_whatsapp_no: input,
                          });
                        }

                        // Clear input on backspace
                        if (
                          e.nativeEvent.inputType === "deleteContentBackward" &&
                          input.length === 0
                        ) {
                          setCustomersData({
                            ...CustomersData,
                            customer_whatsapp_no: "",
                          });
                        }
                      }}
                      className="fw-bold"
                      placeholder="WhatsApp Number"
                    />
                  </div>
                  <div className="col-4">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold"
                    >
                      Alternative Mobile Number
                    </Label>
                    <Input
                      type="text"
                      value={CustomersData ? CustomersData.customer_alternative_no : ""}
                      onChange={(e) => {
                        // Remove non-numeric characters
                        const input = e.target.value.replace(/\D/g, "");

                        // Apply validation for 10-digit number
                        if (input.length <= 10) {
                          setCustomersData({
                            ...CustomersData,
                            customer_alternative_no: input,
                          });
                        }

                        // Clear input on backspace
                        if (
                          e.nativeEvent.inputType === "deleteContentBackward" &&
                          input.length === 0
                        ) {
                          setCustomersData({
                            ...CustomersData,
                            customer_alternative_no: "",
                          });
                        }
                      }}
                      className="fw-bold"
                      placeholder="Alternative Mobile Number"
                    />
                  </div>
                  <div className="col-4">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold"
                    >
                      Email
                    </Label>
                    <Input
                      onChange={getCustomerData}
                      name="customer_email"
                      id="customer_email"
                      className="form-control fw-bold"
                      placeholder="Email"
                      type="text"
                    />
                  </div>
                  <div className="col-4">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      City
                      <a
                        className="btn btn-success btn-sm px-1"
                        style={{ padding: "0px" }}
                        onClick={() => setModalStatess1(!false)}
                      >
                        <i className="ri-add-line align-bottom"></i>
                      </a>
                    </Label>
                    {activeCity ? (
                      <Select
                        onChange={getSelectedCityValue}
                        options={CustomerCity.map((group) => ({
                          value: group.city_id,
                          label: group.city_name,
                        }))}
                        name="customer_city"
                        id="customer_city"
                        className=" fw-bold"
                        placeholder={
                          manageCity == 0
                            ? CustomerCity[0].city_name
                            : CustomerCity[CustomerCity.length - 1].city_name
                        }
                      />
                    ) : (
                      <Input
                        type="text"
                        readOnly
                        className="form-control fw-bold "
                        style={{ color: "red" }}
                        value="First Fill the City *"
                        placeholder=""
                      />
                    )}
                  </div>
                  <div className="col-4">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      Group Type
                      <a
                        className="btn btn-success btn-sm px-1"
                        style={{ padding: "0px" }}
                        onClick={() => setModalStatess(!false)}
                      >
                        <i className="ri-add-line align-bottom"></i>
                      </a>
                    </Label>
                    {activeGroup ? (
                      <Select
                        onChange={getSelectedGroupValue}
                        options={CustomerGroup.map((group) => ({
                          value: group.customer_group_id,
                          label: group.customer_group_name,
                        }))}
                        name="customer_group"
                        id="customer_group"
                        className=" fw-bold"
                        placeholder={
                          manageGroup == 0
                            ? CustomerGroup[0].customer_group_name
                            : CustomerGroup[CustomerGroup.length - 1]
                              .customer_group_name
                        }
                      />
                    ) : (
                      <Input
                        type="text"
                        readOnly
                        className="form-control fw-bold "
                        style={{ color: "red" }}
                        value="First Fill the Customer Group *"
                        placeholder=""
                      />
                    )}
                  </div>
                  <div className="col-12">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-3 fw-bold"
                    >
                      Address
                    </Label>
                    <textarea
                      onChange={getCustomerData}
                      value={CustomersData.customer_address}  // Controlled value
                      rows={3}
                      placeholder="Enter address"
                      className="form-control fw-bold"
                      name="customer_address"
                      id="customer_address"
                    />
                    
                      {/* Microphone button positioned inside the input */}
                      <Button
                        type="button"
                        color="none"
                        style={{
                          position: "absolute",
                          top: "70%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          width: "50px",
                          height: "50px",
                          padding: "0",
                          borderRadius: "50%",
                          zIndex: "10",
                          background: "none",  // No background
                          border: "none",  // No border
                          boxShadow: "none",  // No shadow
                        }}
                        onClick={handleAddressMicClick}  // Toggle microphone
                      >
                        <i
                          className={
                            isAddressListening
                              ? "ri-stop-circle-fill align-bottom"  // Icon when listening
                              : "ri-mic-fill align-bottom"  // Icon when not listening
                          }
                        ></i>
                      </Button>
                  </div>
                </div>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <div className="hstack gap-2 justify-content-end">
                {activeGroup ? (
                  <button
                    type="submit"
                    name="sumbit"
                    id="submit"
                    className="btn btn-primary"
                    // onClick={() => OnSubmited()}
                    ref={submitButtonRef}
                    disabled={loading} // Disable button if loading
                  >
                    <i className="ri-save-3-line align-bottom me-1"></i>
               {loading ? "Saving..." : "Save"}
                  </button>
                ) : (
                  <span style={{ color: "red" }}>
                    First fill basic information then save.
                  </span>
                )}
                <Button
                  name="close"
                  id="close"
                  type="button"
                  className="btn btn-danger"
                  onClick={() => Close()}
                >
                  <i className="ri-close-line me-1 align-middle" />
                  Close
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
      {modalStatess === true ? (
        <CustomerGroupAdd
          modalStates={modalStatess}
          setModalStates={() => {
            setModalStatess(false);
          }}
          checkchang={handleCallback}
        />
      ) : modalStatess1 === true ? (
        <CityAdd
          modalStates={modalStatess}
          setModalStates={() => {
            setModalStatess1(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default CustomerAdd;
