import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  Row,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../../Components/Common/DeleteModal";
import UserUpdate from "./FranchiseUpdate";
import AuthUser from "../../../helpers/Authuser";
import { useEffect } from "react";
import UserCreateModal from "./FranchiseCreateModal";
import UserView from "./FranchiseView";
import D_img from "../../D_img";
import InfiniteScroll from "react-infinite-scroll-component";
import { IMG_API_URL } from "../../../helpers/url_helper";
import ImgCom from "../../Fullscreenimg/ImgCom";
import jsPDF from "jspdf";

const FranchiseList = () => {
  const [modalStates, setModalStates] = useState(false);
  const [UpdatemodalStates, setUpdateModalStates] = useState(false);
  const [viewModalState, setviewModalState] = useState(false);
  const { http, permission } = AuthUser();
  //   Delete Aleart
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, SetID] = useState();
  const [dstatus, Setdstatus] = useState();
  const onClickDelete = (data) => {
    // console.log(data.status);
    SetID(data.id);
    Setdstatus(data.status);
    setDeleteModal(true);
  };
  const handleDeleteOrder = (data) => {
    if (data._reactName === "onClick") {
      http
        .get(`/user/delete/${ID}/${dstatus}`)
        .then(function (response) {
          if (response.data.status === 1) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);

          }
          Setcounts(counts - 1);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    setEmployee([]);
    setDeleteModal(false);
  };

  //   end Alert
  const handleCallback = (data, status) => {
    setModalStates(false);
    setUpdateModalStates(false);
    setEmployee([]);
    if (status == 1) {
      toast.success(data);
      Setcounts(counts + 1);
    } else {
      toast.warn(data);
      // toast.error(data);
      Setcounts(counts + 1);
    }
    // Setcounts(counts + 1);

  };
  const [NoMore, SetNoMore] = useState(true);

  const [counts, Setcounts] = useState(1);
  const [Employee, setEmployee] = useState([]);
  // console.log(Employee);
  useEffect(() => {
    http
      .get("/franchisebusiness/list")
      .then(function (response) {
        console.log(response.data);
        if (response.data.length > 0) {
          setEmployee(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        SetNoMore(false);

      });
  }, [counts]);
  const fetchData = () => {
    Setcounts(counts + 1);
  };
  // Edit Data
  const [FindData, SetFind] = useState([]);
  const EditUpdate = (index) => {
    let FindArray = Employee.filter((data) => data.uid === index);
    // console.log(FindArray[0]);
    SetFind(FindArray[0]);
    setUpdateModalStates(!UpdatemodalStates);
  };
  const ViewData = (index) => {
    let FindArray = Employee.filter((data) => data.uid === index);
    // console.log(FindArray[0]);
    SetFind(FindArray[0]);
    setviewModalState(!viewModalState);
  };
  // shortcut to get add form
  // shortcuts for opening add form
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "a" || event.altKey && event.key === "A") {
        event.preventDefault();
        setModalStates(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  
  const generatePDF = () => {
    const doc = new jsPDF('landscape');

    // Set table headers
    const headers = [
      "#",
      "FRANCHISES NAME",
      "FRANCHISES EMAIL",
      "MOBILE NO",
      

    ];
    // Set table rows
    const data = Employee.filter((data) => data.u_type == '5').map((item, index) => [
      index + 1,
      item.name,
      item.email,
      item.mobile_no
     

    ]);

    // Set table style
    doc.setFontSize(12);
    doc.text("Franchises List", 14, 15);
    doc.autoTable({
      head: [headers],
      body: data,
      startY: 20,
    });

    // Save the PDF
    doc.save("FranchisesList.pdf");

    toast.success("PDF generated successfully!");
  };
  const convertToCSVexcel = () => {
    let csvContent =  "#,FRANCHISES PROFILE,FRANCHISES NAME,FRANCHISES EMAIL,MOBILE NO";

    Employee.filter((data) => data.u_type == '5').forEach((item, index) => {
      csvContent += `\n${index + 1},"${item.profile_photo}","${item.name}","${item.email}","${item.mobile_no}"`;
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "FranchisesList.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    toast.success("CSV generated successfully!");
  };
  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="text-center pt-2 pb-4">
                    <h2 className="fw-bold mb-0">
                      Franchises
                    </h2>
                  </div>
                </Row>
                <Row className="align-items-center gy-3">
                  <div className="col-sm">

                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      {permission.find(permission => permission.permission_category === "EMPLOYEE" && permission.permission_path === "2") && (
                        <button
                          type="button"
                          className=" btn  btn-success mt-3"
                          id="create-btn"
                          onClick={() => setModalStates(!modalStates)}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Franchise
                        </button>)}


                      <button
                        type="button"
                        className="btn  btn-info mt-3"
                        onClick={convertToCSVexcel}
                      >
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        Export
                      </button>

                      <button
                        type="button"
                        className="btn  btn-danger mt-3"
                        onClick={generatePDF}
                      >
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        PDF
                      </button>

                    </div>
                  </div>

                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  ></Nav>
                  <div className=" table-responsive">
                    <InfiniteScroll
                      dataLength={Employee.length}
                      next={fetchData}
                      hasMore={NoMore}
                    >
                      {permission.find(permission => permission.permission_category === "EMPLOYEE" && permission.permission_path === "1") && (
                        <table
                          role="table"
                          className="align-middle  table-nowrap table table-hover"
                        >
                          <thead className="table-light text-muted text-uppercase">
                            <tr>
                              <th
                                title="Toggle SortBy"
                                style={{ cursor: "pointer" }}
                              >
                                #
                              </th>
                              <th
                                title="Toggle SortBy"
                                style={{ cursor: "pointer" }}
                              >
                                Profile
                              </th>
                              <th
                                title="Toggle SortBy"
                                style={{ cursor: "pointer" }}
                              >
                                Franchises Name
                              </th>
                              <th
                                title="Toggle SortBy"
                                style={{ cursor: "pointer" }}
                              >
                                Franchises Email
                              </th>
                              <th
                                title="Toggle SortBy"
                                style={{ cursor: "pointer" }}
                              >
                                Mobile Number
                              </th>
                              {/* <th
                                title="Toggle SortBy"
                                style={{ cursor: "pointer" }}
                              >
                                Franchises Role
                              </th> */}
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Employee.filter((data) => data.u_type == '5').map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <a
                                    className="fw-medium link-primary"
                                    href="/apps-ecommerce-order-details"
                                  >
                                    {index + 1}
                                  </a>
                                </td>
                                <td>
                                  {item.profile_photo != "/user/null" ? (
                                    <ImgCom
                                      imageUrl={IMG_API_URL + item.profile_photo}
                                      style={{ height: "100px", width: "100px" }}
                                    />
                                  ) : (
                                    <D_img />
                                  )}
                                </td>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{item.mobile_no}</td>
                                {/* <td>{item.role_name}</td> */}
                                <td>
                                  <ul className="list-inline hstack gap-2 mb-0">
                                    <li className="list-inline-item edit">
                                      <button
                                        className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                        onClick={() => ViewData(item.uid)}
                                      >
                                        <i className="ri-eye-fill fs-16" />
                                      </button>
                                    </li>
                                    <li className="list-inline-item edit">
                                      {permission.find(permission => permission.permission_category === "EMPLOYEE" && permission.permission_path === "3") && (
                                        <button
                                          className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                          onClick={() => EditUpdate(item.uid)}
                                        >
                                          <i className="ri-pencil-fill fs-16" />
                                        </button>
                                      )}
                                    </li>

                                    <li className="list-inline-item">
                                      {permission.find(permission => permission.permission_category === "EMPLOYEE" && permission.permission_path === "4") && (
                                        <button
                                          onClick={() =>
                                            onClickDelete({ id: item.uid, status: 0 })
                                          }
                                          className="text-danger d-inline-block remove-item-btn  border-0 bg-transparent"
                                        >
                                          <i className="ri-delete-bin-5-fill fs-16" />
                                        </button>)}
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </InfiniteScroll>
                  </div>
                </div>

                {modalStates === true ? (
                  <UserCreateModal
                    modalStates={modalStates}
                    setModalStates={() => {
                      setModalStates(false);
                    }}
                    checkchang={handleCallback}
                  />
                ) : (
                  ""
                )}
                {UpdatemodalStates === true ? (
                  <UserUpdate
                    modalStates={UpdatemodalStates}
                    setModalStates={() => {
                      setUpdateModalStates(false);
                    }}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                {viewModalState === true ? (
                  <UserView
                    modalStates={viewModalState}
                    setModalStates={() => {
                      setviewModalState(false);
                    }}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                <ToastContainer closeButton={false} limit={1} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FranchiseList;
