import React from 'react';
import ReactApexChart from "react-apexcharts";


import getChartColorsArray from "../../../../Components/Common/ChartsDynamicColor";


const SimpleDonut = ({ dataColors, additionalData }) => {

    var chartDonutBasicColors = getChartColorsArray(dataColors);
    const seriesData = Object.values(additionalData); // Extract values from additionalData object

    const series = seriesData.length > 0 ? seriesData : [0, 0, 0, 0, 0];
    const labels = Object.keys(additionalData).map(key => {


        return `<span style="font-weight: bold; color:black;">${key} </span> `;
    });
    var options = {
        chart: {
            height: 300,
            type: 'donut',
        },
        legend: {
            position: 'bottom',
            show: false,
        },
        dataLabels: {
            dropShadow: {
                enabled: false,
            }
        },
        colors: chartDonutBasicColors,
        labels: labels
    };
    return (
        <ReactApexChart
            dir="ltr"
            className="apex-charts"
            series={series}
            options={options}
            type="donut"
            height={300}
        />

    )
}



const SimpleDonut1 = ({ dataColors, additionalData, goToStatus, statusData }) => {
    const chartDonutBasicColors = getChartColorsArray(dataColors);
    const seriesData = Object.values(additionalData); 
    const series = seriesData.length > 0 ? seriesData : [0, 0, 0, 0, 0];
    const labels = Object.keys(additionalData);

    // Create options with dataPointSelection event
    const options = {
        chart: {
            height: 300,
            type: 'donut',
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    const selectedLabel = labels[config.dataPointIndex];
                    const statusInfo = statusData.length > 0 ? statusData.filter((item)=> item.lead_status_name === selectedLabel)[0]:null;  // Retrieve the status info from statusData

                    if (statusInfo!=null) {
                        goToStatus(statusInfo.lead_status_id, statusInfo.lead_status_color);
                    }
                }
            }
        },
        legend: {
            position: 'bottom',
            show: false,
        },
        dataLabels: {
            dropShadow: {
                enabled: false,
            }
        },
        colors: chartDonutBasicColors,
        labels: labels.map(key => `<span style="font-weight: bold; color:black;">${key}</span>`),
    };

    return (
        <ReactApexChart
            dir="ltr"
            className="apex-charts"
            series={series}
            options={options}
            type="donut"
            height={300}
        />
    );
};




export { SimpleDonut, SimpleDonut1 }